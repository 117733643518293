<div class="f-1-0" [ngClass]="isMailbox ? 'displayFlowAdvanced' : ''">
    <div class="flex-column gap-2 py-3 px-1 pb-5" style="min-height: fit-content; font-size: 12px;" [ngClass]="{'px-2': !isMailbox, 'disabled': userPolicy.allow_admin_policy_only.value}">

        <span class="fw-500 mb-2">{{'advanced.emailSecurity.emailSecurity' | translate}}</span>

        <!--Authentication method dropdown-->
        <div class="flex-row w-100 justify-content-between gap-2 align-items-center"
             [ngClass]="{'disabled': !methods.secure_send.value}">
            <div class="flex-row f-1-0 align-items-center gap-1">
                <span class="text-nowrap" [ngClass]="{'opacity-50': !methods.secure_send.value}">{{'advanced.emailSecurity.authMethod' | translate}}:</span>
                <app-dropdown class="f-1-0" style="max-width: 120px; height: 22px; z-index: 50;"
                              [isDisabled]="!methods.secure_send.value"
                              [text]="_.startCase(advanced.secure?.method_2factor?.value) || ''">
                    <li *ngFor="let method of dic.CONSTANTS.authenticationMethod | keyvalue: gs.returnZero"
                        (click)="allowedAuthMethods.includes(method.value) ? updateAuthMethod(method.value) : $event.stopPropagation();"
                        [ngClass]="{'disabled': !allowedAuthMethods.includes(method.value)}"
                        [id]="'AuthMethod' + method.value">
                        {{_.startCase(method.value)}}
                        <i *ngIf="!allowedAuthMethods.includes(method.value)"
                           style="color: var(--t-gray); padding-left:1px; position: relative;"
                           class="zmdi zmdi-lock-outline"
                           tooltip="{{'advanced.blockedByAdmin' | translate}}">
                        </i>
                    </li>
                </app-dropdown>
            </div>

            <div class="flex-row align-items-center gap-1 ms-auto">
                <i *ngIf="!methods.secure_send.value" tooltip="{{'advanced.authToEnable' | translate}}" style="color: var(--t-gray); top: 2px; position: relative;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
                <i style="font-weight: 400;" class="fas fa-question-circle m-0" tooltip="{{'advanced.emailSecurity.methodTooltip' | translate}}"></i>
            </div>
        </div>

        <!--Password input + hint input-->
        <div class="flex-column gap-2 ps-3" style="max-width: 264px; min-height: fit-content;"
             [ngClass]="{'disabled': !methods.secure_send.value}"
             *ngIf="advanced.secure?.method_2factor.value === dic.CONSTANTS.authenticationMethod.password">
            <div class="flex-row f-1-0 align-items-center gap-2">
                <span>{{'advanced.emailSecurity.passwordPlaceholder' | translate}}:</span>
                <div class="position-relative f-1-0">
                    <input id="method_2factor_password"
                           [type]="showPassword ? 'text' : 'password'"
                           class="w-100" style="height: 22px;"
                           placeholder="{{'advanced.emailSecurity.passwordPlaceholder' | translate}}"
                           [(ngModel)]="advanced.secure.method_2factor_password.value"
                           (change)="changeAdvanced();"
                           [ngClass]="{'validation-error' :(isRequirePassword() && !advanced.secure.method_2factor_password.value && !advanced.secure.use_contact_2factor_password.value),
													   'nofill': advanced.secure.method_2factor_password.value && !showPassword}">
                    <label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer hvr-darken">
                        <input type="checkbox" [(ngModel)]="showPassword"/>
                        <i class="fa fa-eye unchecked"></i>
                        <i class="fa fa-eye-slash checked"></i>
                    </label>
                </div>
            </div>
            <div class="flex-row f-1-0 align-items-center gap-2" *ngIf="advanced.secure.method_2factor.value === dic.CONSTANTS.authenticationMethod.password">
                <span>{{'advanced.emailSecurity.passwordHint' | translate}}:</span>
                <div class="position-relative f-1-0">
                    <input id="method_2factor_password_hint"
                           type="text"
                           class="w-100" style="height: 22px;"
                           placeholder="{{'advanced.emailSecurity.passwordHintPlaceholder' | translate}}"
                           [(ngModel)]="advanced.secure.method_2factor_password_hint.value"
                           (change)="changeAdvanced();">
                </div>
            </div>
            <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.use_contact_2factor_password.value',
                        id: 'useContact2factorPassword',
                        text: 'advanced.emailSecurity.contactPassword',
                        tooltip: 'advanced.emailSecurity.contactPasswordTooltip'}">
            </ng-container>
        </div>


        <!--2FA input-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
          context: {modelRef: 'secure.enable_smart_authentication.value',
                    id: 'smartAuthentication',
                    text: 'advanced.emailSecurity.smartAuth',
                    tooltip: 'advanced.emailSecurity.smartAuthTooltip',
                    isLocked: !methods.secure_send.value,
                    lockedTooltip: 'advanced.authToEnable',
                    isDisabled: !methods.secure_send.value}">
        </ng-container>

        <div class="flex-row w-100 justify-content-between gap-2 align-items-center"
             [ngClass]="{'disabled' : userPolicy.expired_enable.strict || (!methods.secure_send.value && !methods.encrypt_content.value)}">
            <div class="flex-row f-1-0 align-items-center hSpace1" [ngClass]="{'md-checkbox': !isMailbox}">
                <ng-container *ngIf="!isMailbox">
                    <input id="expiresInXdays" type="checkbox" style="height: 0; width: 0;"
                           [ngClass]="{'disabled' : userPolicy.expired_enable.strict}"
                           [(ngModel)]="advanced.secure.expired_enable.value"
                           (ngModelChange)="changeAdvanced();"/>
                </ng-container>
                <ng-container *ngIf="isMailbox">
                    <app-toggle-switch
                            id="expiresInXdays"
                            [isDisabled]="userPolicy.expired_enable.strict"
                            [hideText]="true"
                            [(model)]="advanced.secure.expired_enable.value"
                            (modelChange)="changeAdvanced()">
                    </app-toggle-switch>
                </ng-container>
                <label for="expiresInXdays" style="margin: 0; position: relative;">
                    {{'advanced.emailSecurity.emailExpire' | translate}}
                </label>
                <input id="expired_days" type="number" [ngStyle]="{'opacity' : advanced.secure.expired_enable.value && !userPolicy.expired_enable.strict ? '1' : '0.6'}"
                       [disabled]="userPolicy.expired_enable.strict || !advanced.secure.expired_enable.value || (!methods.secure_send.value && !methods.encrypt_content.value)"
                       min="1" max="365"
                       class="mx-1" style="height: 22px; width: 55px; border: 1px solid #ccc; border-radius: 4px;"
                       [(ngModel)]="advanced.secure.expired_days.value"
                       (change)="changeAdvanced();"
                       placeholder="{{'advanced.emailSecurity.daysPlaceholder' | translate}}" />
            </div>

            <div class="flex-row align-items-center gap-1 ms-auto">
                <i *ngIf="userPolicy?.expired_enable.strict || (!methods.secure_send.value && !methods.encrypt_content.value)" [tooltip]="userPolicy?.expired_enable.strict ? ('compose.methods.methodLockedByAdmin' | translate) : ('advanced.encryptOrAuthToEnable' | translate)" style="color: var(--t-gray); top: 2px; position: relative;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
                <i style="font-weight: 400;" class="fas fa-question-circle m-0" tooltip="{{'advanced.emailSecurity.emailExpireTooltip' | translate}}"></i>
            </div>
        </div>

        <!--Days until attachments expire input-->
        <div class="flex-row w-100 justify-content-between gap-2 align-items-center"
             [ngClass]="{'disabled' : (isMailbox && (!methods.secure_send.value && !methods.encrypt_content.value))}">
            <div class="flex-row f-1-0 align-items-center hSpace1" [ngClass]="{'md-checkbox': !isMailbox}">
                <ng-container *ngIf="!isMailbox">
                    <input id="deleteAttachmentsInXdays" type="checkbox" style="height: 0; width: 0;"
                           [(ngModel)]="advanced.secure.delete_attachment_enable.value"
                           (ngModelChange)="changeAdvanced();"/>
                </ng-container>
                <ng-container *ngIf="isMailbox">
                    <app-toggle-switch
                            id="deleteAttachmentsInXdays"
                            [hideText]="true"
                            [isDisabled]="!methods.secure_send.value && !methods.encrypt_content.value"
                            [(model)]="advanced.secure.delete_attachment_enable.value"
                            (modelChange)="changeAdvanced()">
                    </app-toggle-switch>
                </ng-container>
                <label for="deleteAttachmentsInXdays" style="margin: 0; position: relative;">
                    {{'advanced.emailSecurity.deleteAttachments' | translate}}
                </label>
                <input id="delete_attachments_days" type="number" [ngStyle]="{'opacity' : !advanced.secure.delete_attachment_enable.value || ( isMailbox && !methods.secure_send.value && !methods.encrypt_content.value) ? '0.6' : '1'}"
                       [disabled]="!advanced.secure.delete_attachment_enable.value || (!methods.secure_send.value && !methods.encrypt_content.value)"
                       min="1" max="365"
                       class="mx-1" style="height: 22px; width: 55px; border: 1px solid #ccc; border-radius: 4px;"
                       [(ngModel)]="advanced.secure.delete_attachment_days.value"
                       (change)="changeAdvanced();"
                       placeholder="{{'advanced.emailSecurity.daysPlaceholder' | translate}}" />
            </div>

            <div class="flex-row align-items-center gap-1 ps-2 ms-auto">
                <i *ngIf="userPolicy.delete_attachment_enable.strict" [tooltip]="userPolicy.delete_attachment_enable.strict ? ('compose.methods.methodLockedByAdmin' | translate) : ('advanced.encryptToEnable' | translate)" style="color: var(--t-gray); top: 2px; position: relative;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
                <i style="font-weight: 400;" class="fas fa-question-circle m-0" tooltip="{{'advanced.emailSecurity.deleteAttachmentsTooltip' | translate}}"></i>
            </div>
        </div>

        <!--Enforce attachment encryption checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.enforce_attachment_encryption.value',
                        id: 'enforceAttachmentEncryption',
                        text: 'advanced.emailSecurity.enforceAttachmentEncryption',
                        tooltip: 'advanced.emailSecurity.enforceAttachmentEncryptionTooltip',
                        lockedTooltip: 'advanced.general.cannotBeChanged',
                        isLocked: isMailbox,
                        isDisabled: isMailbox}">
        </ng-container>

        <!--Attachments preview mode checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.attachments_preview_mode.value',
                        id: 'attachments_preview_mode',
                        text: 'advanced.emailSecurity.attachmentsPreviewMode',
                        tooltip: 'advanced.emailSecurity.attachmentsPreviewModeTooltip',
                        isLocked: isMailbox ? (!attachmentsExist || (!methods.secure_send.value && !methods.encrypt_content.value)) : (!methods.secure_send.value && !methods.encrypt_content.value),
                        lockedTooltip: (!methods.secure_send.value && !methods.encrypt_content.value) ? 'advanced.encryptOrAuthToEnable' : 'advanced.encryptToEnable',
                        isDisabled: isMailbox ? (!attachmentsExist || (!methods.secure_send.value && !methods.encrypt_content.value)) : (!methods.secure_send.value && !methods.encrypt_content.value)}">
        </ng-container>

        <!--Allow print checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.enable_print.value',
                        id: 'allowPrint',
                        text: 'advanced.emailSecurity.allowPrint',
                        tooltip: 'advanced.emailSecurity.allowPrintTooltip',
                        isLocked: !methods.secure_send.value && !methods.encrypt_content.value,
                        lockedTooltip: 'advanced.encryptOrAuthToEnable',
                        isDisabled: !methods.secure_send.value && !methods.encrypt_content.value}">
        </ng-container>

        <!--Require Authentication on replies checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.secure_received.value',
                        id: 'authOnReplies',
                        text: 'advanced.emailSecurity.requireAuthReply',
                        tooltip: 'advanced.emailSecurity.requireAuthReplyTooltip',
                        isLocked: (!methods.secure_send.value && !methods.encrypt_content.value) || userPolicy.secure_received?.strict,
                        lockedTooltip: userPolicy.secure_received?.strict ? 'compose.methods.methodLockedByAdmin' : 'advanced.encryptOrAuthToEnable',
                        isDisabled: (!methods.secure_send.value && !methods.encrypt_content.value) || userPolicy.secure_received?.strict}">
        </ng-container>

        <!--Access only once checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.open_only_once.value',
                        id: 'accessOnlyOnce',
                        text: 'advanced.emailSecurity.accessOnce',
                        tooltip: 'advanced.emailSecurity.accessOnceTooltip',
                        isDisabled: isMailbox ? (!attachmentsExist && !methods.encrypt_content.value && !methods.secure_send.value) : (!methods.secure_send.value && !methods.encrypt_content.value)}">
        </ng-container>

        <br/>
        <span class="fw-500 mb-2">{{'advanced.general.general' | translate}}</span>

        <!--Notify on opened checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'email_me.on_any_opened.value',
                        id: 'notifyEmailOpened',
                        text: 'advanced.general.notifyEmailsOpened',
                        tooltip: 'advanced.general.notifyEmailsOpenedTooltip'}">
        </ng-container>

        <!--Notify on link clicked checkbox/toggle-->
        <ng-container *ngIf="advanced.email_me.on_link_clicked">
            <ng-container *ngTemplateOutlet="advancedOptionTemplate;
                  context: {modelRef: 'email_me.on_link_clicked.value',
                            id: 'notifyLinksClicked',
                            isDisabled: isMailbox && !advanced.general.track_links.value,
                            isLocked: isMailbox && !advanced.general.track_links.value,
                            lockedTooltip: 'advanced.trackLinksEnable',
                            text: 'advanced.general.notifyLinksClicked',
                            tooltip: 'advanced.general.notifyLinksClickedTooltip'}">
            </ng-container>
        </ng-container>

        <!--Notify on attachment download checkbox/toggle-->
        <ng-container *ngIf="advanced.email_me.on_attachment_download">
            <ng-container *ngTemplateOutlet="advancedOptionTemplate;
                  context: {modelRef: 'email_me.on_attachment_download.value',
                            id: 'notifyFilesDownloaded',
                            isLocked: !attachmentsExist && isMailbox,
                            isDisabled: !attachmentsExist && isMailbox,
                            lockedTooltip: 'advanced.encryptToEnable',
                            text: 'advanced.general.notifyDownloadFiles',
                            tooltip: 'advanced.general.notifyDownloadFilesTooltip'}">
            </ng-container>
        </ng-container>

        <!--Reply to email input-->
        <div class="flex-row w-100 justify-content-between gap-2 align-items-center">
            <div class="flxRow-start f-1-0 hSpace1" style="align-items: center;" [ngClass]="{'md-checkbox': !isMailbox, 'disabled': isMailbox}">
                <ng-container *ngIf="!isMailbox">
                    <input id="reply_to_enable" type="checkbox" style="height: 0; width: 0;"
                           [(ngModel)]="advanced.general.reply_to_enable.value"
                           (ngModelChange)="changeAdvanced();"/>
                </ng-container>
                <ng-container *ngIf="isMailbox">
                    <app-toggle-switch
                            id="reply_to_enable"
                            [hideText]="true" [isDisabled]="isMailbox"
                            [(model)]="advanced.general.reply_to_enable.value"
                            (modelChange)="changeAdvanced()">
                    </app-toggle-switch>
                </ng-container>
                <label for="reply_to_enable" style="margin: 0; position: relative;">
                    {{'advanced.general.replyTo' | translate}}
                </label>
                <input id="reply_to_email" type="text" class="m-0 ms-2 f-1-0" style="max-width: 135px; height: 22px;"
                       *ngIf="advanced.general.reply_to_enable.value"
                       placeholder="{{'advanced.general.replyToMyEmail' | translate}}"
                       [disabled]="isMailbox"
                       [ngClass]="{'validation-error' : advanced.general.reply_to_enable.value && advanced.general.reply_to_email.value ? !gs.validateEmail(advanced.general.reply_to_email.value) : false}"
                       [(ngModel)]="advanced.general.reply_to_email.value"
                       (change)="changeAdvanced();"/>
                <input type="text" class="m-0 ms-2 f-1-0" style="max-width: 135px; height: 22px;"
                       *ngIf="!advanced.general.reply_to_enable.value"
                       [disabled]="true"
                       [placeholder]="'do-not-reply@' + (trustifiDefault.name ? trustifiDefault.name.toLowerCase() : 'trustifi') + '.com'"/>
            </div>
            <div class="flex-row align-items-center gap-1 ps-2 ms-auto">
                <i *ngIf="isMailbox" tooltip="{{'advanced.general.cannotBeChanged' | translate}}" style="top: 2px; color: var(--t-gray); position: relative;" class="zmdi zmdi-lock-outline"></i>
                <i style="font-weight: 400;" class="m-0 fas fa-question-circle m-0" tooltip="{{'advanced.general.replyToToolip' | translate}}"></i>
            </div>
        </div>

        <!--Use template checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.trustifi_wrapper.value',
                        id: 'useTemplate',
                        isDisabled: isMailbox,
                        lockedTooltip: 'advanced.general.cannotBeChanged',
                        isLocked: isMailbox,
                        text: 'advanced.general.useTemplate',
                        tooltip: 'advanced.general.useTemplateTooltip'}">
        </ng-container>

        <!--Track links checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.track_links.value',
                        id: 'trackLinks',
                        text: 'advanced.general.trackLinks',
                        tooltip: 'advanced.general.trackLinksTooltip',
                        isLocked: isMailbox ? true : (userPolicy.track_links.strict || advanced.email_me.on_link_clicked.value),
                        lockedTooltip: isMailbox ? 'advanced.general.cannotBeChanged' : (userPolicy.track_links.strict ? 'compose.methods.methodLockedByAdmin' : 'advanced.general.trackLinksLockedTooltip'),
                        isDisabled: isMailbox ? true : (userPolicy.track_links.strict || advanced.email_me.on_link_clicked.value)}">
        </ng-container>


        <!--Track attachments checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.multi_replies.value',
                        id: 'unlimitedReplies',
                        text: 'advanced.general.unlimitedReplies',
                        tooltip: 'advanced.general.unlimitedRepliesTooltip',
                        isLocked: !methods.secure_send.value && !methods.encrypt_content.value,
                        lockedTooltip: 'advanced.encryptOrAuthToEnable',
                        isDisabled: !methods.secure_send.value && !methods.encrypt_content.value}">
        </ng-container>

        <!--Enforce reply all checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.enforce_reply_all.value',
                        id: 'enforceReplyAll',
                        text: 'advanced.general.enforceReplyAll',
                        tooltip: 'advanced.general.enforceReplyAllTooltip',
                        isLocked: !methods.secure_send.value && !methods.encrypt_content.value,
                        lockedTooltip: 'advanced.encryptOrAuthToEnable',
                        isDisabled: !methods.secure_send.value && !methods.encrypt_content.value}">
        </ng-container>


        <!--Allow download as EML checkbox/toggle-->
        <ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.allow_download_as_eml.value',
                        id: 'allowDownloadEml',
                        text: 'advanced.general.allowDownloadingAsEML',
                        tooltip: 'advanced.general.allowDownloadingAsEMLTooltip',
                        isLocked: userPolicy.allow_download_as_eml.strict || (!methods.secure_send.value && !methods.encrypt_content.value),
                        lockedTooltip: userPolicy.allow_download_as_eml.strict ? 'compose.methods.methodLockedByAdmin' : 'advanced.encryptOrAuthToEnable',
                        isDisabled: userPolicy.allow_download_as_eml.strict || (!methods.secure_send.value && !methods.encrypt_content.value)}">
        </ng-container>


        <ng-template #advancedOptionTemplate let-id="id" let-modelRef="modelRef" let-text="text" let-tooltip="tooltip" let-isLocked="isLocked" let-lockedTooltip="lockedTooltip" let-isDisabled="isDisabled">
            <!-- Checkbox Version -->
            <div *ngIf="!isMailbox" class="flex-row w-100 justify-content-between gap-2 align-items-center">
                <div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;" [ngClass]="{'disabled' : isDisabled}">
                    <input [id]="id" type="checkbox" style="height: 0; width: 0;"
                           (click)="!isDisabled && _.set(advanced, modelRef, !_.get(advanced, modelRef)); !isDisabled && changeAdvanced();"
                           [checked]="_.get(advanced, modelRef)"/>
                    <label [for]="id" style="margin: 0; position: relative;">{{text | translate}}</label>
                </div>
                <div class="flex-row align-items-center gap-1 ps-2 ms-auto">
                    <i *ngIf="isLocked" tooltip="{{lockedTooltip | translate}}" style="top: 2px; color: var(--t-gray); position: relative;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
                    <i style="font-weight: 400;" class="fas fa-question-circle m-0" tooltip="{{tooltip | translate}}"></i>
                </div>
            </div>

            <!-- Toggle Version -->
            <div *ngIf="isMailbox" class="f-1-0 flex-row gap-2 align-items-center" [ngClass]="{'disabled': isDisabled}">
                <app-toggle-switch
                        [id]="id"
                        [hideText]="true"
                        [model]="_.get(advanced, modelRef)"
                        (modelChange)="setAdvancedValue(modelRef, $event)">
                </app-toggle-switch>
                <label [for]="id" style="display: inline-block;">{{text | translate}}</label>
                <div class="flex-row align-items-center gap-1 ms-auto">
                    <i *ngIf="isLocked" tooltip="{{lockedTooltip | translate}}" style="top: 2px; color: var(--t-gray); position: relative;" class="zmdi zmdi-lock-outline"></i>
                    <i style="font-weight: 400; top: 0;" class="fas fa-question-circle m-0" tooltip="{{tooltip | translate}}"></i>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="flex-row justify-content-end gap-2 pe-1 t-bg-primary" *ngIf="!isMailbox" style="font-size: 12px; border-bottom: solid 1px #f9f9f9; border-top: solid 1px #f9f9f9; z-index: 50;">
        <btn class="auto-height" style="min-width: 0;"
             variant="text" endIcon="fa fa-sync"
             btnTooltip="{{'advanced.resetToDefault' | translate}}"
             (action)="ResetAdvanced();">
            {{'advanced.reset' | translate}}
        </btn>
        <btn class="auto-height" style="min-width: 0;"
             variant="text" endIcon="fa fa-save"
             (action)="this.saveDefaultsEvent.emit(true);">
            {{'advanced.saveDefault' | translate}}
        </btn>
    </div>
</div>
