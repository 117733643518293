import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import { dic } from '../../dictionary';
import {ENV_CONSTS} from '../../constants';
import {AuthService} from '../../services/auth.service';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';
import {Office365Service} from '../../services/office365.service';
import {RestService} from '../../services/rest.service';
import {NotificationService} from '../../services/notification.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
	hasWKTextSecurity;
	userEmailInput: string;
	password: string;
	fullname: string;
	loading: boolean;
	enableResend: boolean;
	disableConfirmAuth: boolean;
	dic = dic;
	successMessage = '';
	errorMsg = '';
	data2Fa = null;
	initialEmailError;
	sendInitialEmailInProcess = false;
	userGuideUrl = ENV_CONSTS.corpLinks.outlook_ug;
	corpBaseUrl = ENV_CONSTS.corpLinks.url;
	inflogin = true;
	trustifiDefault;
	changePasswordTimer;
	showPassword = false;
	blockUserError = '';
	isPhone;
	isEmail;
	notificationSubscriber;
	notification: { message: string; type: null };

	loginStatus;
	loginStatuses = {
		emailOrSSOForm: 'emailOrSSO',
		passwordLoginForm: 'passwordLoginForm',
		signupForm: 'signupForm',
		mfaCodeForm: 'mfaCodeForm'
	};

	constructor(private authService: AuthService,
				private gs: GeneralService,
				private office365Service: Office365Service,
				private rs: RestService,
				private translateService: TranslationService,
				private notificationService: NotificationService,
				private zone: NgZone) {
		this.hasWKTextSecurity = this.gs.hasWKTextSecurity();
		this.trustifiDefault = this.gs.trustifiDefault;

		this.notificationSubscriber = this.notificationService.updateGlobalNotification$.subscribe((data: any) => {
			this.zone.run(() => {
				this.notification = data;
				if (data.type === 'error') {
					this.errorMsg = data.message;
				}
			});
		});
	}

	lowmacver = (() => {
		// "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.84 Safari/537.36"
		const match = /Mac\sOS\sX\s([^)]+)\)/g.exec(navigator.userAgent);
		if (!match || !match[1]) { return null; }

		const ver = match[1].split('_');
		return parseInt(ver[1], 10) < 11 ? dic.ERRORS.lowmacver.replace('%s', ver.join('.')) : null;
	})();

	ngOnInit() {
		this.updateLoginStatus(this.loginStatuses.emailOrSSOForm);
		this.notification = this.notificationService.notification;
		const profile = this.office365Service.getUserProfile();
		this.userEmailInput = profile.user.emailAddress || '';
		this.fullname = profile.user.displayName || '';
		/*console.error = (originLogFn => function () {
			// do smth with the error
			originLogFn.apply(this, arguments);
		})(console.error);*/

		if (this.authService.data2Fa) {
			this.init2FAData(this.authService.data2Fa);
		}
	}

	ngOnDestroy(): void {
		this.notificationSubscriber.unsubscribe();
		this.authService.data2Fa = null;
	}

	logout() {
		this.authService.data2Fa = null;
		this.authService.logout();
	}

	continue() {
		this.clearMessage();
		this.successMessage = '';

		if (!this.userEmailInput || !this.password) {
			if (!this.password) {
				this.errorMsg = this.translateService.getTranslationText('login.loginWrongCreds');
			}
			return;
		}

		const creds = {
			username: this.userEmailInput.trim(),
			password: this.password.trim(),
			inflogin: this.inflogin
		};

		this.loading = true;

		this.authService.login(creds, err => {
			this.loading = false;
			this.clearMessage();

			if (err) {
				this.errorMsg = err;
				const password =  document.querySelector('[type="password"]') as HTMLElement;
				if (password) {
					password.focus();
					password.autofocus = true;
				}
			} else {
				this.clearMessage();
			}
		});
	}

	socialLogin() {
		this.loading = true;
		this.authService.socialLogin(this.userEmailInput, this.inflogin, true, err => {
			this.loading = false;
			if (!err) {
				this.errorMsg = err;
			}
		});
	}
	changePassword() {
		this.clearMessage();
		if (!this.userEmailInput || !this.gs.validateEmail(this.userEmailInput)) {
			this.translateService.getTranslatedError(dic.ERRORS.emailInvalidOrAbsent, (txtMsg) => {
				this.errorMsg = txtMsg;
			});
			return;
		}

		if (this.changePasswordDisabled()) { return; }
		this.changePasswordTimer = Date.now();

		this.authService.changePassword(this.userEmailInput, (err, message) => {
			if (err) {
				this.translateService.getTranslatedError(dic.ERRORS.changefailed, (txtMsg) => {
					this.errorMsg = txtMsg;
				});
				return;
			}
			this.successMessage = message;
		});
	}
	changePasswordDisabled() {
		return this.changePasswordTimer && Date.now() - this.changePasswordTimer < 10000;
	}
	navigateToLogin() {
		this.updateLoginStatus(this.loginStatuses.emailOrSSOForm);
		this.logout();
	}

	updateLoginStatus(newStatus) {
		this.clearMessage();
		this.loginStatus = newStatus;
	}

	confirmInitialEmail(email) {
		if (!email || !this.gs.validateEmail(email.trim())) {
			this.initialEmailError = true;
			return;
		}

		if (this.loginStatus === this.loginStatuses.passwordLoginForm) {
			return this.continue();
		}

		this.sendInitialEmailInProcess = true;

		this.rs.userLogin(email.trim()).then(response => {
			if (response && response.isIdp) {
				// okta or saml
				this.clearMessage();
				this.authService.IDPLogin(email, this.inflogin, true, err => {
					if (err && err !== true) {
						if (typeof err !== 'string') {
							err = err.data && err.data.message || err.statusText || dic.ERRORS.authSignInError;
						}
						this.errorMsg = err;
					}
				});
			}
			else {
				this.updateLoginStatus(this.loginStatuses.passwordLoginForm);
			}
			this.sendInitialEmailInProcess = false;
		}, (err) => {
			this.sendInitialEmailInProcess = false;
		});
	}

	getPinCode = (resend) => {
		if ((!resend && (!this.data2Fa.phone.phone_number || this.disableConfirmAuth)) || (resend && !this.enableResend)) {
			return;
		}

		// check if the phone number contains letters or longer than 16 digits
		if (!(Math.floor(Number(this.data2Fa.phone.phone_number)) === Number(this.data2Fa.phone.phone_number))) {
			this.errorMsg = dic.ERRORS.invalidPhoneNumber;
			return;
		}
		this.clearMessage();
		this.disableConfirmAuth = true;
		const type = resend ? this.data2Fa.resendType : this.data2Fa.type;

		this.rs.do2FaAuthentication({
			action: dic.CONSTANTS.authenticationStatus.phone,
			phone: this.data2Fa.phone,
			resend,
			codeType: type
		}).then(response => {
				this.notificationService.clear();
				this.data2Fa.status = response.status;
				this.data2Fa.method = response.auth;
				if (this.data2Fa.status === dic.CONSTANTS.authenticationStatus.code) {
					this.isPhone = this.data2Fa.method === dic.CONSTANTS.authenticationStatus.phone;
					this.isEmail = this.data2Fa.method === dic.CONSTANTS.authenticationStatus.email;
				}
				this.gs.formatPhone(this.data2Fa);
				setTimeout(() => {
					this.enableResend = true;
				}, dic.CONSTANTS.resendTimeout);

				this.disableConfirmAuth = false;
				this.enableResend = false;

				if (resend) {
					this.successMessage = this.translateService.getTranslationText('MESSAGES.resendPinCode');
					this.data2Fa.type = this.data2Fa.resendType;
				} else {
					this.successMessage = response.data;
				}

			},
			error => {
				this.confirmFailure(error, dic.CONSTANTS.authenticationStatus.phone);
			});
	}

	confirmPinCode = () => {
		if (this.disableConfirmAuth || !this.data2Fa.pinCode) {
			return;
		}
		this.clearMessage();
		this.disableConfirmAuth = true;

		this.rs.do2FaAuthentication({
			action: dic.CONSTANTS.authenticationStatus.code,
			code: parseInt(this.data2Fa.pinCode, 10)
		}).then(response => {
			this.confirmSuccess(response);
		}, error => {
			this.confirmFailure(error, dic.CONSTANTS.authenticationStatus.code);
		});
	}

	confirmEmail = (resend) => {
		this.data2Fa.invalidEmail = false;
		if ((!resend && (!this.data2Fa.emailAuth || this.disableConfirmAuth)) || (resend && !this.enableResend)) {
			return;
		}
		this.clearMessage();
		if (!this.gs.validateEmail(this.data2Fa.emailAuth.trim())) {
			this.data2Fa.invalidEmail = true;
			this.errorMsg = this.translateService.getTranslationText('signup.emailAddressError');
			return;
		}

		this.clearMessage();
		this.disableConfirmAuth = true;
		this.rs.do2FaAuthentication({
			action: dic.CONSTANTS.authenticationStatus.email,
			email: this.data2Fa.emailAuth.trim()
		}).then(response => {
			this.notificationService.clear();
			this.data2Fa.status = response.status;
			this.data2Fa.method = response.auth;
			if (this.data2Fa.status === dic.CONSTANTS.authenticationStatus.code) {
				this.isPhone = this.data2Fa.method === dic.CONSTANTS.authenticationStatus.phone;
				this.isEmail = this.data2Fa.method === dic.CONSTANTS.authenticationStatus.email;
			}
			this.disableConfirmAuth = false;

			setTimeout(() => {
				this.enableResend = true;
			}, dic.CONSTANTS.resendTimeout);
			this.enableResend = false;

			if (resend) {
				this.successMessage = this.translateService.getTranslationText('MESSAGES.resendPinCode');
				this.data2Fa.type = this.data2Fa.resendType;
			}
		}, error => {
			this.confirmFailure(error, dic.CONSTANTS.authenticationStatus.email);
		});
	}

	confirmTotp() {
		if (this.disableConfirmAuth || !this.data2Fa.totpCode) {
			return;
		}
		this.clearMessage();

		this.disableConfirmAuth = true;

		this.rs.do2FaAuthentication({
			action: this.data2Fa.status,
			password: this.data2Fa.totpCode
		}).then(res => {
			this.confirmSuccess(res);
		}, error => {
			this.confirmFailure(error, dic.CONSTANTS.authenticationStatus.totp);
		});
	}

	private clearMessage() {
		this.errorMsg = '';
		this.successMessage = '';
	}

	private finish2Fa() {
		this.zone.run(() => {
			this.authService.data2Fa = null;

			this.clearMessage();
			this.authService.isFullyAuthenticated = true;
		});
	}

	private confirmSuccess(response) {
		this.data2Fa.status = dic.CONSTANTS.authenticationStatus.authenticated;
		this.disableConfirmAuth = false;
		this.notificationService.clear();
		this.clearMessage();

		if (response.fingerprint) {
			this.authService.addFingerprint(response.fingerprint, () => {
				this.finish2Fa();
			});
		}
	}

	private confirmFailure(error, currentStatus) {
		this.notificationService.clear();
		this.clearMessage();
		this.disableConfirmAuth = false;
		if (error.data.status === currentStatus) {
			if (error.data.remain_attempts) {
				const msg = error.data.remain_attempts > 1 ? 'You have ' + error.data.remain_attempts + ' attempts remaining' : 'You have one attempt remaining';
				// TODO: translate
				this.errorMsg = msg;
				return;
			}
		} else {
			if (error.data.status) {
				this.data2Fa.status = error.data.status;
			}
			this.data2Fa.flagIcon = this.gs.getCountryCodeFlag(this.data2Fa.country_code);
			this.data2Fa.pinCode = '';
		}
		if (!error.data.display_bar) {
			this.errorMsg = error.data.blockNote || error.data.message;
		} else {
			this.errorMsg = error.data.message;
		}
	}

	private init2FAData(data) {
		this.data2Fa = data;
		this.clearMessage();
		this.notificationService.clear();
		if (this.data2Fa.status === dic.CONSTANTS.authenticationStatus.blocked) {
			this.data2Fa.currentBlockMessage = this.data2Fa.blockNote || '';
			this.zone.run(() => {
				this.updateLoginStatus(this.loginStatuses.mfaCodeForm);
			});
			return;
		}
		this.data2Fa.method = this.data2Fa.auth;
		if (this.data2Fa.status === dic.CONSTANTS.authenticationStatus.code) {
			this.isPhone = this.data2Fa.method === dic.CONSTANTS.authenticationStatus.phone;
			this.isEmail = this.data2Fa.method === dic.CONSTANTS.authenticationStatus.email;
		}
		this.data2Fa.emailAuth = this.data2Fa.recipientEmail;
		this.data2Fa.type = dic.CONSTANTS.pinType.text;
		this.data2Fa.resendType = dic.CONSTANTS.pinType.text;
		this.data2Fa.flagIcon = this.gs.getCountryCodeFlag(this.data2Fa.country_code);
		this.data2Fa.showPassword = false;
		this.data2Fa.totpCode = '';
		if (!this.data2Fa.phone) {
			this.data2Fa.phone = {
				country_code: this.data2Fa.country_code,
				phone_number: ''
			};
		}
		this.data2Fa.phone.phone_number_after = '';
		if (this.data2Fa.phone.phone_number) {
			this.gs.formatPhone(this.data2Fa);
		}

		if (this.data2Fa.status === dic.CONSTANTS.authenticationStatus.code) {
			setTimeout(() => {
				this.enableResend = true;
			}, dic.CONSTANTS.resendTimeout);
		}

		this.zone.run(() => {
			this.updateLoginStatus(this.loginStatuses.mfaCodeForm);
		});
	}
}
