<div class="flex-column f-1-0 w-100 gap-3 login-box" *ngIf="signupState !== dic.CONSTANTS.signupState.success">
	<span class="fw-500 text-muted my-2 mb-3 text-center" style="font-size: 1rem;">{{'signup.createANewAccount' | translate}}</span>
	<div class="position-relative w-100">
		<input id="signupEmail" type="text" class="bs-form-control"
			   name="{{'accountDetailsEmail'+random}}"
			   [ngClass]="{'inputError' : emailError}"
			   (keydown)="emailError = null;"
			   autocomplete="off"
			   placeholder="{{'authentication.authWithEmailPlaceholder' | translate}}"
			   [autocomplete]="random"
			   [(ngModel)]="signupData.accountDetails.email">
		<span class="animated fadeIn" style="position: absolute; bottom: 2px; right: 0; transform: translateY(100%) translateY(3px); font-size: 11.5px; color: red;"
			  *ngIf="emailError">
					{{emailError}}
				</span>
	</div>

	<div class="flex-row gap-1 w-100">
		<div class="position-relative f-1-0">
			<input id="signupFirstName" type="text" class="bs-form-control"
				   name="{{'firstName'+random}}"
				   placeholder="{{'signup.firstName' | translate}}"
				   [ngClass]="{'inputError' : firstNameError}"
				   (keydown)="firstNameError = null;"
				   [autocomplete]="random"
				   autocomplete="off"
				   [(ngModel)]="signupData.accountDetails.firstName">
			<span class="animated fadeIn" style="position: absolute; bottom: 2px; right: 0; transform: translateY(100%) translateY(3px); font-size: 11.5px; color: red;"
				  *ngIf="firstNameError">
						{{'ERRORS.requiredField' | translate}}
					</span>
		</div>
		<div class="position-relative f-1-0">
			<input id="signupLastName" type="text" class="bs-form-control"
				   name="{{'lastName'+random}}"
				   placeholder="{{'signup.lastName' | translate}}"
				   [ngClass]="{'inputError' : lastNameError}"
				   (keydown)="lastNameError = null;"
				   [autocomplete]="random"
				   autocomplete="off"
				   [(ngModel)]="signupData.accountDetails.lastName">
			<span class="animated fadeIn" style="position: absolute; bottom: 2px; right: 0; transform: translateY(100%) translateY(3px); font-size: 11.5px; color: red;"
				  *ngIf="lastNameError">
						{{'ERRORS.requiredField' | translate}}
					</span>
		</div>
	</div>
	<div class="flex-row gap-1 position-relative w-100">
		<international-phone-number
				class="country-code"
				style="z-index: 1000;"
				[(ngModel)]="signupData.accountDetails.phone.country_code"
				[ngModelOptions]="{standalone: true}"
				placeholder="{{'signup.phoneNumber' | translate}}"
				[maxlength]="20"
				[allowedCountries]="allowedCountries">
		</international-phone-number>
		<input id="signupPhoneNumber"
			   class="bs-form-control f-1-0"
			   type="text"
			   (keydown)="phoneNumberError = null;"
			   knifeOnlyNumbers
			   placeholder="{{'signup.phoneNumber' | translate}}"
			   name="{{'phoneNumber'+random}}"
			   pattern="^[0-9]*$"
			   [autocomplete]="random"
			   (blur)="phoneState = 'blured'"
			   [ngClass]="{'inputError' : phoneNumberError}"
			   [(ngModel)]="signupData.accountDetails.phone.phone_number">

		<i style="font-weight: 400; position: absolute; right: 0; top: 50%; transform: translateX(100%) translateX(6px) translateY(-50%); z-index: 1030;" class="fas fa-question-circle"
		   tooltip="{{'signup.phoneNumberTooltip' | translate}}" placement="left"></i>
		<span class="animated fadeIn" style="position: absolute; bottom: 2px; right: 0; transform: translateY(100%) translateY(3px); font-size: 11.5px; color: red;"
			  *ngIf="phoneNumberError">
					{{phoneNumberError}}
				</span>
	</div>
	<div class="position-relative w-100">
		<input id="signupPassword" required class="bs-form-control w-100" style="padding-right: 27px;"
			   type="{{!showPassword && !hasWKTextSecurity ? 'password' : 'text'}}"
			   [ngClass]="{'inputError' : passwordError, 'nofill': signupData.accountDetails.password && !showPassword}"
			   placeholder="{{'signup.password' | translate}}"
			   (keydown)="passwordError = null;"
			   minlength="6"
			   [autocomplete]="random"
			   [(ngModel)]="signupData.accountDetails.password">
		<label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer">
			<input type="checkbox" [(ngModel)]="showPassword"/>
			<i class="fa fa-eye unchecked"></i>
			<i class="fa fa-eye-slash checked"></i>
		</label>
		<span class="animated fadeIn" style="position: absolute; bottom: 2px; right: 0; transform: translateY(100%) translateY(3px); color: red;"
			  [ngStyle]="{'font-size' : signupData.accountDetails.password ? '10.3px' : '11.5px'}"
			  *ngIf="passwordError">
					{{passwordError}}
				</span>
	</div>
	<div class="position-relative w-100">
		<input id="signupPassword2" required class="bs-form-control w-100" style="padding-right: 27px;"
			   type="{{!showReTypePassword && !hasWKTextSecurity ? 'password' : 'text'}}"
			   [ngClass]="{'inputError' : reTypePasswordError, 'nofill': reTypePassword && !showReTypePassword}"
			   placeholder="{{'signup.retypePassword' | translate}}"
			   (keydown)="reTypePasswordError = null;"
			   minlength="6"
			   [autocomplete]="random"
			   [(ngModel)]="reTypePassword">
		<label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer">
			<input type="checkbox" [(ngModel)]="showReTypePassword"/>
			<i class="fa fa-eye unchecked"></i>
			<i class="fa fa-eye-slash checked"></i>
		</label>
		<span class="animated fadeIn" style="position: absolute; bottom: 2px; right: 0; transform: translateY(100%) translateY(3px); font-size: 11.5px; color: red;"
			  *ngIf="reTypePasswordError">
					{{reTypePasswordError}}
				</span>
	</div>
	<div class="single-button flex-row centralize w-100 mt-2" style="flex-shrink: 0;"
		 [ngClass]="{'disabled' : signingUpInProcess}"
		 (click)="errMsg = ''; validateSignUpForm() && continue()">
		<i class="fa fa-spin fa-spinner" *ngIf="signingUpInProcess"></i>
		<span class="fw-normal" *ngIf="!signingUpInProcess">{{'login.signUpNow' | translate}}</span>
	</div>
	<div class="flex-column gap-1 centralize my-3">
		<span>{{'signup.alreadyHaveAccount' | translate}}</span>
		<a class="blue-link no-underline" (click)="backToLogin()">
			{{'signup.logIn' | translate}}
		</a>
	</div>
	<span class="opacity-75 w-100 text-center" style="font-size: 0.7rem;">
				{{'signup.bySigningUp' | translate}} <a class="blue-link fw-500 no-underline hvr-underline" target="_blank" rel="noopener" href="{{corpLinks.eula}}">{{'signup.termsOfUse' | translate}}</a>, <a class="blue-link fw-500 no-underline hvr-underline" target="_blank" rel="noopener" href="{{corpLinks.privacy}}">{{'signup.privacy' | translate}}</a>, {{'general.and' | translate}} <a
			class="blue-link fw-500 no-underline hvr-underline" target="_blank" rel="noopener" href="{{corpLinks.antiSpam}}">{{'signup.antiSpamPolicy' | translate}}</a>
			</span>
</div>
<div class="flex-column w-100 f-1-0 justify-content-evenly" *ngIf="signupState === dic.CONSTANTS.signupState.success">
	<div class="successm flex-column align-items-center">
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style="width: 30%; max-width: 80px; min-width: 50px;">
			<circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6"
					stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"></circle>
			<polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6"
					  stroke-linecap="round" stroke-miterlimit="10"
					  points="100.2,40.2 51.5,88.8 29.8,67.5 "></polyline>
		</svg>
		<p class="success my-2">{{'signup.signupSuccess' | translate}}</p>
	</div>
	<p style="font-size:14px; line-height: 1.5em; text-align:center;">
		{{'signup.signupSuccessMessage' | translate}}
		<br/><br/>
		{{'signup.signupSuccessMessage2' | translate}}
		<br/>
		{{'signup.signupSuccessMessage3' | translate}}
		<br/>
		{{'signup.signupSuccessMessage4' | translate}}
	</p>
	<div class="single-button flex-row centralize w-100 mt-1"
		 [ngClass]="{'disabled' : navigateToAppAfterCompleteInProcess}"
		 (click)="navigateToAppAfterComplete()">
		<i class="fa fa-spin fa-spinner" *ngIf="navigateToAppAfterCompleteInProcess"></i>
		<span class="fw-normal" *ngIf="!navigateToAppAfterCompleteInProcess">{{'signup.continueToApp' | translate}}</span>
	</div>
</div>

<div class="flxClmn-end gap-2 pt-4" style="flex: 1 0;">
	<div class="t-color-danger text-start animated fadeIn w-100" *ngIf="errMsg">
		{{errMsg}}
	</div>
</div>
