<div class="popup-wrap popup-wrap-alt danger-message t-bg-primary" style="outline:none;height: 100vh; overflow: auto; position: fixed; top: 0; left: 0; z-index: 1050;">
	<div class="form-block flxClmn" style="position: relative; outline: none" tabindex="0">
		 <span class="title" style="margin:0">
		 {{'support.contactUs' | translate}}
		 </span>
		<hr style="margin:0.7rem 0"/>
		<a class="close1" (click)="gs.toggleSupportForm(false);"><i class="zmdi zmdi-close" style="font-size:24px;"></i></a>
		<p style="text-align: center; font-size: 0.75rem; padding: 15px 5px; background: #e2e2e240;">{{'support.contactUsDescription' | translate}}</p>
		<!--contact form-->
		<div class="flxClmn vSpace2 t-bg-primary" style="margin-top: 10px">
			<div class="flxClmn vSpace1">
				<!--OS-->
				<span>{{'support.operatingSystem' | translate}}</span>
				<div class="dropdown" style="width:100%;margin:0;top:0;z-index:auto;"
					 (clickOutside)="ddsOS = false;"
					 [ngClass]="{'active' : ddsOS}"
					 (click)="ddsOS = !ddsOS"
					 (focus)="ddsOS=true"
					 (blur)="ddsOS=false">
					<div class="select" style="padding:10px;float:initial">
						<span>{{ddsOSSelectedType}}</span>
						<i class="fa fa-chevron-left"></i>
					</div>
					<ul class="dropdown-menu" style="overflow:auto"
						[ngClass]="{'active' : ddsOS}"
						[ngStyle]="ddsOS && {'max-height':'none'}">
						<li (click)="ddsOSSelectedType = option"
							*ngFor="let option of dssOSTypes"
							[attr.id]="option">
							{{option}}
						</li>
					</ul>
				</div>
				<input type="text" style=" height: 35px; width: 100%"
					   *ngIf="ddsOSSelectedType === 'Other'"
					   placeholder="{{'support.operatingSystemName' | translate}}"
					   autofocus
					   [(ngModel)]="ddsOtherOS">
			</div>

			<!--Platform-->
			<div class="flxClmn vSpace1">
				<span>{{'support.platform' | translate}}</span>
				<div class="dropdown" style="width:100%;margin:0;top:0;z-index:auto;"
					 (clickOutside)="dssPlt = false;"
					 [ngClass]="{'active' : dssPlt}"
					 (click)="dssPlt = !dssPlt"
					 (focus)="dssPlt=true"
					 (blur)="dssPlt=false">
					<div class="select" style="padding:10px;float:initial">
						<span>{{ddsPlatformSelected}}</span>
						<i class="fa fa-chevron-left"></i>
					</div>
					<ul class="dropdown-menu" style="overflow:auto"
						[ngClass]="{'active' : dssPlt}"
						[ngStyle]="dssPlt && {'max-height':'none'}">
						<li (click)="ddsPlatformSelected = option"
							*ngFor="let option of dssPlatforms"
							[attr.id]="option">
							{{option}}
						</li>
					</ul>
				</div>
				<input type="text" style=" height: 35px; width: 100%"
					   *ngIf="ddsPlatformSelected === 'Other'"
					   placeholder="{{'support.platformPlaceholder' | translate}}"
					   autofocus
					   [(ngModel)]="ddsOtherPlt">
			</div>

			<!--Issue type-->
			<div class="flxClmn vSpace1">
				<span>{{'support.issueType' | translate}}</span>
				<div class="dropdown" style="width:100%;margin:0;top:0;z-index:auto;"
					 (clickOutside)="dssRsn = false;"
					 [ngClass]="{'active' : dssRsn}"
					 (click)="dssRsn = !dssRsn"
					 (focus)="dssRsn=true"
					 (blur)="dssRsn=false">
					<div class="select" style="padding:10px;float:initial">
						<span [ngStyle]="!ddsReasonSelected && {'color':'var(--gray-dark)'}">{{ddsReasonSelected || ('support.issueTypePlaceholder' | translate)}}</span>
						<i class="fa fa-chevron-left"></i>
					</div>
					<ul class="dropdown-menu" style="overflow:auto"
						[ngClass]="{'active' : dssRsn}"
						[ngStyle]="dssRsn && {'max-height':'none'}">
						<li (click)="ddsReasonSelected = option"
							*ngFor="let option of dssReasons"
							[attr.id]="option">
							{{option}}
						</li>
					</ul>
				</div>
			</div>

			<!--Title-->
			<div class="flxClmn vSpace1">
				<span>{{'support.title' | translate}}</span>
				<input type="text" style=" height: 35px; width: 100%"
					   placeholder="{{'support.titlePlaceholder' | translate}}"
					   autofocus
					   [(ngModel)]="messageTitle">
			</div>

			<!--Message-->
			<div class="flxClmn vSpace1">
				<span>{{'support.message' | translate}}</span>
				<textarea style="height:15vh; min-height: 80px; resize: none;"
						  placeholder="{{'support.messagePlaceholder' | translate}}"
						  [(ngModel)]="textMessage"></textarea>
			</div>

			<div class="flxRow-center btn-block">
				<button type="button" style="float:right; margin: 10px"
						(click)="sendMessageToSupport()"
						[disabled]="!textMessage.length"
						[ngStyle]="textMessage.length<1 && {'background':'gray', 'cursor':'not-allowed'}" class="btn">
					{{'support.send' | translate}}
				</button>
			</div>
		</div>
	</div>
</div>

