import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
    @Input() text: string;
    @Input() title: string;
    @Input() error: string;
    @Input() isLoading: boolean;
    @Input() isDisabled: boolean;
    @Output() confirmCb = new EventEmitter<any>();
    @Output() closeCb = new EventEmitter<any>();
}
