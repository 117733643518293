import { Injectable } from '@angular/core';
import {ENV_CONSTS} from '../constants';
import {dic} from '../dictionary';
import * as _ from 'lodash';
import * as util from 'util';
import * as $ from 'jquery';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {DownloadFilesService} from './download-files.service';
import {Restangular} from 'ngx-restangular';
import {BehaviorSubject} from 'rxjs';
import {NotificationService} from './notification.service';
import {TranslationService} from './translation.service';
import moment from 'moment';
import {tz} from "moment-timezone";
import { CountryService } from 'ngx-international-phone-number';

@Injectable({
	providedIn: 'root'
})
export class GeneralService {

	companyName = '';

	constructor(private restangular: Restangular,
				 private http: HttpClient,
				 private countryService: CountryService,
				 private downloadFilesService: DownloadFilesService,
				private translateService: TranslationService,
				private notificationService: NotificationService) {
		if (ENV_CONSTS.lf_info) {
			this.initLookAndFeel(ENV_CONSTS.lf_info);
			this.setMainLogo();
		}

		const defaultDialCode = this.getDefaultDialCode();
		if (defaultDialCode) {
			this.trustifiDefault.country_code = '+' + defaultDialCode;
		}

		const domainName = location.origin.split('://')[1].split('.')[1] && location.origin.split('://')[1].split('.')[1].toLowerCase();
		if (domainName !== 'trustifi') {
			this.companyName = domainName || location.origin.split('://')[1]; // take localhost:5000;;
		} else {
			this.companyName = location.origin.split('://')[1].split('.')[0].toLowerCase();
		}

		if (dic.SUB_DOMAINS.indexOf(this.companyName) < 0) {
			this.companyName = ENV_CONSTS.trustifiDefault;
		}
	}

	allowedCountries = ['us', 'au', 'es', 'br', 'gb', 'il', 'ch', 'nl', 'in', 'no', 'hk', 'nz', 'za', 'gr', 'mu', 'pa'];
	countryCodes = {
		israel: '972',
		usa: '1',
		brazil: '55',
		greece: '30',
		australia: '61',
		spain: '34',
		unitedKingdom: '44',
		norway: '47',
		swiss: '41',
		india: '91',
		netherlands: '31',
		hongKong: '852',
		newZealand: '64',
		southAfrica: '27',
		panama: '507',
		mauritius: '230'
	};
	defaultCountry;

	public triggerContactUsFormSubj = new BehaviorSubject<any>(null);
	public refreshDataSubj = new BehaviorSubject<any>(null);

	public threatDetectionCache: any = {};

	EMAIL_REGEX = /^([!#-\'*+\/-9=?A-Z^-~\\\\-]{1,64}(\.[!#-\'*+\/-9=?A-Z^-~\\\\-]{1,64})*|"([\]!#-[^-~\ \t\@\\\\]|(\\[\t\ -~]))+")@([0-9A-Z]([0-9A-Z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Z]([0-9A-Z-]{0,61}[0-9A-Za-z])?))+$/i;

	subjectRegex = /\[(((enc(rypt)?)|(sec(ure)?)|(pos)|(post(mark)?)|(rep(ly)?)|(adv_[a-h]?)|(?:.*?)))]/gi;
	cardFilter = /\b(((4\d{3})|(5[1-5]\d{2})|(\d{4})|(34\d{1})|(37\d{1}))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7][\d\s-]{15}|(?:3[47][0-9]{13}))\b/; // Credit Cards
	keywordFilter = /\b(?:password|classified|top secret|non disclosure|sobpoena|billing|bank (?:account|information)|pin code|social security|ssn|passport|visa|master(?:\s|)card|american express|card(?:\s|)holder(?:s|)|credit(?:\s|)card|product key|payment(?:\s|)card|card(?:\s|)number|confidential|iban)\b/; // Keywords
	ssnFilter = /\b(?:ss|sn|ssn|ssns|social security|immigration|visa|petition).*\d{3}-\d{2}-\d{4}\b/; // Social Security Number
	uspFilter = /\b(?:passport).*([0-9]{9})\b/; // Passports (US/IL)
	hipaaFilter = /\b(?:blood|phi|patient|health|medical|hospital|medicine|doctor|accident|prescription|nino|health(?:\s|)care|nurse|drug(?:s|)|prescription(?:s|)|pharmacy|health(?:care|)|npi|insurance|hipaa|dea)\b/; // HIPAA
	chiFilter = /\b([A-CEGHJ-NOPR-TW-Z]{2}([?\s|-])([0-9]{6}([?\s|-])|[0-9]{2}([?\s|-])[0-9]{2}([?\s|-])[0-9]{2})([?\s|-])[ABCD\s]|[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z][0-9]{6}[A-DFM]?)\b/; // United Kingdom national insurance number
	ibanFilter = /\b[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}\b/; // IBAN Number (International Bank Account Number)
	npiFilter = /\b80840\d{10}\b/; // NPI Number (Health Identification Card Number)
	prodkeyFilter = /\b([A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5})\b/; // Product key

	userInfo = null;
	trustifiDefault = {
		name: 'Trustifi',
		color: '#486db5',
		hoverColor: '#7e98cc',
		boldColor: '',
		country_code: ENV_CONSTS.country_code || '+1',
		supportEmail: 'support@trustificorp.com'
	};

	showSupportForm = false;

	clonedContacts = {};

	downloadProgress = '';
	downloadProgressStyle = 0;
	message = {
		recipients: [],
		lists: [],
		contacts: [],
		title: '',
		html: '',
		methods: {
			track: {value: true, strict: false},
			postmark: {value: false, strict: false},
			secure_send: {value: false, strict: false},
			secure_reply: {value: true, strict: false},
			encrypt_content: {value: false, strict: false},
			allow_admin_policy_only: {value: false}
		},
		attachments: []
	};

	advanced = {
		general: {
			trustifi_wrapper: {value: true, strict: false},
			reply_to_enable: {value: true, strict: false},
			reply_to_email: {value: '', strict: false},
			track_links: {value: true, strict: false},
			multi_replies: {value: true, strict: false},
			enforce_reply_all: {value: false, strict: false}
		},
		email_me: {
			on_any_opened: {value: false, strict: false},
			on_link_clicked: {value: false, strict: false},
			on_attachment_download: {value: false, strict: false}
		},
		secure: {
			secure_received: {value: false, strict: false},
			require_2factor: {value: true, strict: false},
			method_2factor: {value: 'phone', strict: false}, // could be password
			method_2factor_password: {value: '', strict: false},
			use_contact_2factor_password: {value: true, strict: false},
			expired_enable: {value: true, strict: false},
			expired_days: {value: 30, strict: false},
			delete_attachment_enable: {value: false, strict: false},
			delete_attachment_days: {value: 30, strict: false},
			enable_print: {value: true, strict: false},
			open_only_once: {value: false, strict: false},
			enable_smart_authentication: {value: false, strict: false}
		}
	};

	contactImportSubject = new BehaviorSubject<any>(null);

	getUserInformationApi = () => this.restangular.all('user').get('').toPromise();

	getUserInfo = (reload) => {
		if (reload || !this.userInfo) {
			return this.getUserInformationApi().then(response => {
				this.userInfo = response;
				this.getInitialTheme();
				return response;
			});
		}

		return Promise.resolve(this.userInfo);
	}

	getInitialTheme = () => {
		if (this.userInfo.dark_mode) {
			document.body.setAttribute('data-theme', 'dark');
		} else {
			document.body.removeAttribute('data-theme');
		}
	}

	getDefaultDialCode() {
		// the first item has wildcard: "Australia/*"
		const timeZonesByCountry = {
			au: ['Australia'],
			br: ['Brazil', 'America/Fortaleza', 'America/Sao_Paulo', 'America/Rio_Branco', 'America/Noronha', 'America/Manaus'],
			il: ['', 'Israel', 'Asia/Jerusalem'],
			gb: ['', 'GMT', 'GMT0', 'GMT+0', 'GMT-0', 'Etc/GMT', 'Etc/GMT0', 'Etc/GMT-0', 'Etc/GMT+0', 'GB', 'GB-Eire', 'Greenwich', 'Europe/Belfast', 'Europe/London'],
			ch: ['', 'Europe/Zurich'],
			nl: ['', 'Europe/Amsterdam', 'Europe/Berlin'],
			hk: ['', 'Asia/Hong_Kong', 'PRC', 'Asia/Shanghai', 'Asia/Urumqi', 'Asia/Kashgar', 'Asia/Harbin', 'Asia/Chungking', 'Asia/Chongqing'],
			no: ['', 'Europe/Oslo'],
			in: ['','Asia/Kolkata'],
			es: ['', 'Europe/Madrid', 'Atlantic/Canary', 'Africa/Ceuta'],
			nz: ['', 'NZ', 'NZ-CHAT', 'Antarctica/McMurdo', 'Antarctica/South_Pole', 'Pacific/Auckland', 'Pacific/Chatham'],
			za: ['', 'Africa/Johannesburg', 'Africa/Mbabane', 'Africa/Maseru']
		};

		try {
			let timeZone;
			if (Intl && Intl.DateTimeFormat) {
				timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			} else {
				timeZone = moment.tz.guess();
			}

			if (!timeZone) { return; }
			const countryCode = Object.entries(timeZonesByCountry).find(([key, value]) => {
				return value[0] && timeZone.includes(value[0]) || value.includes(timeZone);
			});
			this.defaultCountry = countryCode && countryCode[0];
			if (!this.defaultCountry) { return; }

			const countryData = this.countryService.getCountries();
			const defaultCode = countryData.find(itm => itm.countryCode === this.defaultCountry);
			if (defaultCode && defaultCode.dialCode) {
				return defaultCode.dialCode;
			}
		} catch (ex) {
			console.error('[Trustifi] timeZone error: ' + ex.message);
		}
	}

	getDomain(email) {
		try {
			if (!email) {
				return '';
			}
			const domainObj = email.split('@');
			if (domainObj.length !== 2) {
				return '';
			}
			return domainObj[1].toLowerCase() || '';
		} catch (ex) {
			return '';
		}
	}

	initLookAndFeel(lookAndFeelData) {
		const colorDiff = this.colorDifference(this.trustifiDefault.hoverColor, this.trustifiDefault.color);

		if (lookAndFeelData.color) {
			this.trustifiDefault.color = lookAndFeelData.color;
		}
		if (lookAndFeelData.name) {
			this.trustifiDefault.name = lookAndFeelData.name;
		}
		if (lookAndFeelData.supportEmail) {
			this.trustifiDefault.supportEmail = lookAndFeelData.supportEmail;
		}

		this.trustifiDefault.hoverColor = this.lightenDarkenColor(this.trustifiDefault.color, colorDiff);
		this.trustifiDefault.boldColor = this.boldedColor(this.trustifiDefault.color, colorDiff);

		return true;
	}

	setMainLogo = () => {
		const color = this.trustifiDefault.color;
		const hoverColor = this.trustifiDefault.hoverColor;
		const boldColor = this.trustifiDefault.boldColor;
		let styleColors = [
			// general btn
			color,
			// general btn hover
			hoverColor,
			// loading-bar
			color,
			// mailbox tabs line
			color,
			// mailbox tabs line
			color,
			// methods checkmark buttons
			color,
			// .actionLabel
			boldColor,
			// .loading-spinner
			boldColor,
			// multiple hover colors,
			hoverColor,
			// fa-question-circle
			hoverColor,
			// fa active
			boldColor,
			// redborder-adv
			boldColor,
			// scroll-bar
			color,
			// scroll-bar hover
			hoverColor,
			// scroll-bar active
			boldColor,
		];

		let styleElement = document.getElementById('lookAndFeelStyle');
		this.updateStyles(styleElement, styleColors);

		styleColors = [
			'linear-gradient(to right, ' + color + ' 50%, ' + hoverColor + ' 50%, ' + color + ' 100%)',
			'linear-gradient(to right, ' + color + ' 50%, ' + hoverColor + ' 50%, ' + color + ' 100%)'
		];

		styleElement = document.getElementById('lookAndFeelStyle3');
		this.updateStyles(styleElement, styleColors);
	}

	setSummernoteLogo = (iframe) => {
		const color = this.trustifiDefault.color;
		const hoverColor = this.trustifiDefault.hoverColor;
		const styleColors = [color, hoverColor, color, color, hoverColor, color];
		const styleElement = iframe.document.getElementById('lookAndFeelStyle');

		this.updateStyles(styleElement, styleColors);
	}

	private parseHexColor(col) {
		const num = parseInt(col.slice(1), 16);
		return [num >> 16, num & 0x0000FF, (num >> 8) & 0x00FF];    // RGB
	}

	private colorDifference(a, b) {
		a = this.parseHexColor(a);
		b = this.parseHexColor(b);
		return [a[0] - b[0], a[1] - b[1], a[2] - b[2]];
	}

	private lightenDarkenColor(col, amt) {
		col = this.parseHexColor(col);
		for (const i in col) {
			col[i] += amt[i];
			if (col[i] > 255) { col[i] = 255; } else if (col[i] < 0) { col[i] = 0; }
		}
		return '#' + ((col[0] << 16) | col[1] | (col[2] << 8)).toString(16);    // RGB
	}

	private boldedColor(col, amt) {
		col = this.parseHexColor(col);
		for (const i in col) {
			col[i] -= amt[i];
			if (col[i] > 255) { col[i] = 255; } else if (col[i] < 0) { col[i] = 0; }
		}
		return '#' + ((col[0] << 16) | col[1] | (col[2] << 8)).toString(16);    // RGB
	}

	private updateStyles(styleElement, styleColors) {
		const rules = Array.from(styleElement.sheet.cssRules);
		for (let idx = 0; idx < rules.length; idx++) {
			const style = rules[idx]['style'];

			if (style.background.indexOf('-gradient') >= 0) {
				style.background = styleColors[idx];
			} else {
				style.color = style.color && styleColors[idx];
				style.backgroundColor = style.backgroundColor && styleColors[idx];
				if (style.borderColor) {
					style.borderColor = styleColors[idx];
				}
				if (style.borderTopColor) {
					style.borderTopColor = styleColors[idx];
				}
				if (style.borderLeftColor) {
					style.borderLeftColor = styleColors[idx];
				}
			}
		}
	}

	checkHIPAA(html) {
		if (!html) { return false; }
		const hipaa = html.toLowerCase();
		const hipaaHTML = hipaa.replace(/(\r\n|\n|\r|&nbsp;|\s+|<\/?[^>]+>)/gm, ' ').replace(/(\s+)/gm, ' ');
		return !!(hipaaHTML.match(this.keywordFilter) || hipaaHTML.match(this.cardFilter) || hipaaHTML.match(this.ssnFilter) || hipaaHTML.match(this.uspFilter) || hipaaHTML.match(this.hipaaFilter) || hipaaHTML.match(this.chiFilter) || hipaaHTML.match(this.ibanFilter) || hipaaHTML.match(this.npiFilter) || hipaaHTML.match(this.prodkeyFilter));
	}
	validateEmail(email) {
		return email.length < dic.CONSTANTS.emailMaxSize && this.EMAIL_REGEX && this.EMAIL_REGEX.test(email);
	}
	validateTel(phone) {
		if (!phone || !phone.phone_number) { return true; }
		if (!phone.country_code || phone.country_code.length > 4 || phone.phone_number.length > 12) { return false; }

		const phoneTmp = _.clone(phone);
		if (phoneTmp.country_code[0] === '+') {
			phoneTmp.country_code = phoneTmp.country_code.substr(1);
		}

		phoneTmp.phone_number = phoneTmp.phone_number.replace(/-/g, '');
		let indexNotZero = 0;
		for (; indexNotZero < phoneTmp.phone_number.length && phoneTmp.phone_number[indexNotZero] === '0'; indexNotZero++) { }
		phoneTmp.phone_number = phoneTmp.phone_number.substr(indexNotZero);

		let length = [];
		switch (phoneTmp.country_code) {
			case this.countryCodes.usa:
			case this.countryCodes.unitedKingdom:
				length = [10];
				break;

			case this.countryCodes.israel:
			case this.countryCodes.australia:
			case this.countryCodes.spain:
			case this.countryCodes.swiss:
			case this.countryCodes.netherlands:
			case this.countryCodes.southAfrica:
				length = [9];
				break;

			case this.countryCodes.brazil:
				length = [11];
				break;

			case this.countryCodes.greece:
				length = [10];
				break;

			case this.countryCodes.hongKong:
			case this.countryCodes.mauritius:
			case this.countryCodes.panama:
				length = [8];
				break;

			case this.countryCodes.norway:
				length = [8,12];
				break;

			case this.countryCodes.newZealand:
				length = [8,9];
				break;

			case this.countryCodes.india:
				length = [10];
				break;

			default:
				return false;
		}

		if (!length.some(len => len === phoneTmp.phone_number.length)) {
			return false;
		}

		return !!phoneTmp.phone_number.match(/^\d*$/) && !!phoneTmp.country_code.match(/^\d*$/);
	}
	getMessage() {
		return _.cloneDeep(this.message);
	}
	getAdvanced() {
		return _.cloneDeep(this.advanced);
	}
	flatAdvanced(defaultAdvanced) {
		const flatAdvancedObj = {
			general: {},
			email_me: {},
			secure: {},
			postmark: {}
		};

		if (defaultAdvanced.general) {
			Object.keys(defaultAdvanced.general).forEach((key) => {
				flatAdvancedObj.general[key] = defaultAdvanced.general[key].value;
			});
		}

		if (defaultAdvanced.email_me) {
			Object.keys(defaultAdvanced.email_me).forEach((key) => {
				flatAdvancedObj.email_me[key] = defaultAdvanced.email_me[key].value;
			});
		}

		if (defaultAdvanced.secure) {
			Object.keys(defaultAdvanced.secure).forEach((key) => {
				flatAdvancedObj.secure[key] = defaultAdvanced.secure[key].value;
			});
		}

		if (defaultAdvanced.postmark) {
			Object.keys(defaultAdvanced.postmark).forEach((key) => {
				flatAdvancedObj.postmark[key] = defaultAdvanced.postmark[key].value;
			});
		}

		return flatAdvancedObj;
	}
	deflateAdvanced(advanced) {
		const deflateAdvancedObj: any = {
			general: {},
			email_me: {},
			secure: {},
			postmark: {}
		};

		if (advanced.general) {
			Object.keys(advanced.general).forEach((key) => {
				deflateAdvancedObj.general[key] = {value: advanced.general[key]};
			});
		}

		if (advanced.email_me) {
			Object.keys(advanced.email_me).forEach((key) => {
				deflateAdvancedObj.email_me[key] = {value: advanced.email_me[key]};
			});
		}

		if (advanced.secure) {
			if (!advanced.secure.hasOwnProperty('delete_attachment_enable')) {
				advanced.secure.delete_attachment_enable = false;
				advanced.secure.delete_attachment_days = 30;
			}
			Object.keys(advanced.secure).forEach((key) => {
				deflateAdvancedObj.secure[key] = {value: advanced.secure[key]};
			});

			if (_.isUndefined(deflateAdvancedObj.secure.secure_received)) {
				deflateAdvancedObj.secure.secure_received = {value: false};
			}
		}

		if (advanced.postmark) {
			Object.keys(advanced.postmark).forEach((key) => {
				deflateAdvancedObj.postmark[key] = {value: advanced.postmark[key]};
			});
		}

		return deflateAdvancedObj;
	}

	initSummary(message, advanced, isCompose) {
		if (!message || !advanced) {
			return '';
		}

		// message opening
		let summaryTxt = this.translateService.getTranslationText(isCompose ? 'compose.messages.composeYourEmailWillBe' : 'compose.messages.mailboxEmailSummary');

		// secure method status
		const secureStatusMsg = message.methods.encrypt_content.value && message.methods.secure_send.value ? 'compose.messages.encryptAndAuthenticated' :
						        message.methods.encrypt_content.value ? 'compose.messages.encryptContentOnly' :
						        message.methods.secure_send.value ? 'compose.messages.authenticatedOnly' :
								        'compose.messages.emailTrack';
		summaryTxt += ' ' + this.translateService.getTranslationText(secureStatusMsg);

		// auth method status
		let mfaMethod = '';
		if (message.methods.secure_send.value) {
			mfaMethod = advanced.secure.method_2factor.value === dic.CONSTANTS.authenticationMethod.password ? this.translateService.getTranslationText('compose.recipients.password') :
					    advanced.secure.method_2factor.value === dic.CONSTANTS.authenticationMethod.sso ? 'SSO' :
							this.translateService.getTranslationText('advanced.emailSecurity.pinCodePlaceholder');
		}
		summaryTxt = util.format(summaryTxt, mfaMethod);

		return summaryTxt;
	}

	isIE() {
		return (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== 'undefined' && $.browser.msie == 1));
	}

	hasWKTextSecurity() {
		const x = document.getElementsByTagName('input')[0];
		if (!x) { return; }
		const style = window.getComputedStyle(x);
		return !!style['webkitTextSecurity'];
	}

	trackByIndex(index, item) {
		return index;
	}
	returnZero() {
		return 0
	}

	downloadFile = (url, fileName, fileType, size, headers, isEmlDownload, cb) => {
		this.downloadProgress = '0.0';
		this.downloadProgressStyle = 0;
		let count = 0;

		headers = new HttpHeaders(headers);

		const req = new HttpRequest('GET', url, {}, {
			headers,
			reportProgress: true,
			responseType: 'arraybuffer'
		});


		this.http.request(req)
		.subscribe((event: any) => {
			// reporting percentages - updating local variables
			if (event.type === HttpEventType.DownloadProgress) {
				// Update progress styles once in 2 reports - improve UX
				if (count % 2 === 0) {
					this.downloadProgress = ((event.loaded / (event.total || size)) * 100).toFixed(1);
					this.downloadProgressStyle = 130 * Number(this.downloadProgress) / 100;
				}
				count++;
			} else if (event.type === HttpEventType.Response) {
				if (event.body && event.body.byteLength === 0) {
					return cb();
				}

				let contentType = event.headers['content-type'];
				if (!contentType || contentType === 'bytes') {
					contentType = fileType;
				}

				if (isEmlDownload) {
					contentType = 'message/rfc822';
				}

				const blob = new Blob([event.body], { type: contentType });

				this.downloadFilesService.download(blob, fileName, contentType);
				this.downloadProgress = '0';
				this.downloadProgressStyle = 0;
				cb();
			}
		}, errResponse => {
			console.error('[Trustifi] '+errResponse.message, errResponse);
			this.downloadProgress = '0';
			this.downloadProgressStyle = 0;
			cb(errResponse);
		});
	}

	toCapitalize(val) {
		return val && val.replace(/^./, str => str.toUpperCase());
	}

	getCountryCodeFlag = (countryCode) => {
		if (!countryCode) {
			return dic.CONSTANTS.countryCodeToPngSrc[dic.CONSTANTS.countryCode.us];
		}
		return dic.CONSTANTS.countryCodeToPngSrc[countryCode];
	}

	formatPhone = (pinCodeData) => {
		pinCodeData.phone.phone_number_after = pinCodeData.phone.phone_number.replace(/-/g, '');

		let indexNotZero = 0;
		for (; indexNotZero < pinCodeData.phone.phone_number_after.length && pinCodeData.phone.phone_number_after[indexNotZero] === '0'; indexNotZero++) {  }
		pinCodeData.phone.phone_number_after = pinCodeData.phone.phone_number_after.substr(indexNotZero);

		switch (pinCodeData.phone.country_code) {
			case '1':
				pinCodeData.phone_number_after = '(' + pinCodeData.phone.phone_number_after.substr(0, 3) + ') ' + pinCodeData.phone.phone_number_after.substr(3, 3) + '-' + pinCodeData.phone.phone_number_after.substr(6, 4);
				break;
			case '972':
				pinCodeData.phone_number_after = '(' + pinCodeData.phone.phone_number_after.substr(0, 3) + ') ' + pinCodeData.phone.phone_number_after.substr(3, 2) + '-' + pinCodeData.phone.phone_number_after.substr(5, 4);
				break;

			default:
				pinCodeData.phone_number_after = pinCodeData.phone.phone_number_after;
		}
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) { return '0 Bytes'; }
		const k = 1024,
			dm = decimals || 2,
			sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	checkUploadFileSize(fileSize, fileName) {
		if (fileSize <= 0) {
			this.notificationService.showErrorMessage(fileName + ' is empty');
			return false;
		}

		const fileSizeInMB = fileSize * 0.000001;
		if (fileSizeInMB > 500) {
			this.notificationService.showErrorMessage(fileName + ' size is too large. Maximum file size is 500 Mb');
			return false;
		}
		return true;
	}

	round(date, duration, method) {
		return moment(Math[method]((+date) / (+duration)) * (+duration));
	}

	findEmailPasswords(html) {
		const matches = [];
		html.replace(/\b(?:\b(?:pin[-]?code|pass\W?(?:word)?|pwd|pw)\b(?![.,])(?:.{1,3})?(?:for.{1,30})?(?:has\sbeen.{1,10})?\b\s?(?:is|to|[:#=-])\s?\b\s*([^<\s]+))\b/ig, (_, arg1) => matches.push(arg1.trim()));
		return matches;
	}

	toggleSupportForm = (value) => {
		this.showSupportForm = value;
	}

}
