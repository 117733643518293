import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import * as util from "util";
import * as html2pdf from 'html2pdf.js';

import {Buffer} from 'safe-buffer';
import { dic } from '../../../dictionary';
import {NotificationService} from "../../../services/notification.service";
import {Office365Service} from "../../../services/office365.service";
import {RestService} from "../../../services/rest.service";
import {AccessClientService} from "../../../services/access-client.service";
import {GeneralService} from "../../../services/general.service";
import {ENV_CONSTS} from "../../../constants";
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {FileUploader} from "ng2-file-upload";
import {AuthService} from "../../../services/auth.service";
import {TranslationService} from "../../../services/translation.service";

@Component({
	selector: 'app-message-authentication',
	templateUrl: './message-authentication.component.html',
	styleUrls: ['./message-authentication.component.scss']
})
export class MessageAuthenticationComponent implements OnInit, OnDestroy {

	dic = dic;
	trustifiDefault = this.gs.trustifiDefault;
	flagIcon = 'us.png';
	isVoiceSupported = true;
	notification = {
		message: '',
		type: ''
	};
	private msgId: any;
	private editorHeight: number;
	private data;
	private uploadedFiles: number;
	private userHash: string;
	private logoData: any;
	private disableConfirmAuth = false;
	private enableResend = false;
	private showWrongPhoneBtn = true;
	private showAuthChangeBtn = true;
	private authMatchError = false;
	private secureTrackHelp = false;
	private enableReply = true;
	private enforceReplyAll = false;
	private isReplySent = false;
	private initSummernote = true;
	private disableReplySendBtn = false;
	private errorMsg = '';
	private isEditorFullScreen = false;
	private mouseOverAttachment = false;
	private disableApply = false;
	private innerContent = '';
	private singleDownload = false;
	decryptionStatus = dic.CONSTANTS.contentStatus.initial;
	decryptionInProcess = false;
	isIE: boolean;
	private accessOnce = false;
	private multipleReply = true;
	private allowDownloadEml = false;
	public attachmentsPreviewMode = false;
	private html = '';
	private htmlReply = '';
	private showAskAccess = false;
	private showAccessText = '';
	private showAskAccessBlocked = false;
	private disableDownloadBtn = false;
	private isEmlDownload = false;
	private password = '';
	private emailAuth = '';
	private showPassword = false;
	private isOriginalSender = false;

	private phoneHint = '';
	private pinCodeData = {
		phone: {country_code: '', phone_number: '', phone_number_after: ''},
		type: dic.CONSTANTS.pinType.text,
		resendType: dic.CONSTANTS.pinType.text,
		phone_number_after: '',
		pinCode: ''
	};

	private replyMessage = {attachments: []};
	private showLoader = false;
	private firstRecipient = '';
	private firstCCRecipient = '';
	private htmlHeight: number;
	step: string;
	emailInfo;
	private confirmButtonTxt: string;
	encryptedContent;
	private uploadSubscribers = {};
	authPinCodeMethod: any;
	private hint;
	private emailHint;
	private blockNote: string;
	private getMessage;
	private recipientsDropdown: any;
	private recipientsCCDropdown: any;
	private files: any;
	private showUploadedAttachmentsPopup = false;

	private resetDataSubscription: any;
	private updateDataSubscription: any;
	private globalNotificationSubscription: any;

	@Input() resetData: Observable<void>;
	@Input() notificationEvent: Observable<any>;
	@Input() globalNotificationEvent: Observable<void>;
	private retryLoadFrame = false;
	private contentLoadInterval;
	private uploader:FileUploader = new FileUploader({url: ENV_CONSTS.beBaseAccessAttachmentUrl + '/' + ENV_CONSTS.emailAccessURL + '/Attachment'});
	private isOutlookClient = Office.context.mailbox.diagnostics.hostName !== 'OutlookWebApp';
	private username: string;

	constructor(private notificationService: NotificationService,
				private office365Service: Office365Service,
				private gs: GeneralService,
				private rs: RestService,
				private accessClientService: AccessClientService,
				private translateService: TranslationService,
				private authService: AuthService,
				private http: HttpClient,
				private ngZone: NgZone) {
		this.getMessage = accessClientService.getMessage;

		this.updateDataSubscription = accessClientService.updateAuthData$.subscribe(
			data => {
			this.ngOnInit();
		});
	}

	ngOnInit() {
		this.resetDataSubscription = this.resetData.subscribe(() => {
			this.decryptionStatus = dic.CONSTANTS.contentStatus.initial;
			this.decryptionInProcess = false;
		});

		this.globalNotificationSubscription = this.notificationService.updateGlobalNotification$.subscribe(() => {
			this.showInfoMessage(this.notificationService.notification && this.notificationService.notification.message);
		});

		if (!this.accessClientService.data) return;

		this.isReplySent = false;
		this.errorMsg = '';
		this.editorHeight = window.innerHeight - 295;
		this.data = this.accessClientService.data;
		const msg = this.office365Service.roamingGet('message');

		this.clearNotification();
		this.showInfoMessage(_.clone(msg));
		this.office365Service.roamingSet('message', null, ()=>{});
		let profile = this.office365Service.getUserProfile();
		this.username = profile.user.emailAddress || '';

		this.uploadedFiles = 0;
		this.userHash = this.accessClientService.userHash;

		this.initFields();
		this.initAuthStatus();

		this.logoData = this.gs.trustifiDefault;
		this.isIE = this.gs.isIE();

		this.updateHtmlViewerHeight();
	}

	initFields = () => {
		this.clearNotification();
		this.disableConfirmAuth = false;
		this.enableResend = false;
		this.showWrongPhoneBtn = true;
		this.authMatchError = false;
		this.secureTrackHelp = false;
		this.enableReply = true;
		this.initSummernote = true;
		this.disableReplySendBtn = false;
		this.isEditorFullScreen = false;
		this.mouseOverAttachment = false;
		this.disableApply = false;
		this.innerContent = '';
		this.singleDownload = false;
		this.decryptionStatus = dic.CONSTANTS.contentStatus.initial;
		this.decryptionInProcess = false;
		this.accessOnce = false;
		this.multipleReply = true;
		this.html = '';
		this.htmlReply = '';
		this.showAskAccess = false;
		this.showAccessText = '';
		this.showAskAccessBlocked = false;
		this.disableDownloadBtn = false;
		this.password = '';
		this.emailAuth = '';
		this.showPassword = false;
		this.isOriginalSender = false;

		this.phoneHint = '';
		this.pinCodeData = {
			phone: {country_code: '', phone_number: '', phone_number_after: ''},
			type: dic.CONSTANTS.pinType.text,
			resendType: dic.CONSTANTS.pinType.text,
			phone_number_after: '',
			pinCode: ''
		};

		this.replyMessage = {attachments: []};
		this.showLoader = false;
		this.firstRecipient = '';
		this.firstCCRecipient = '';
	};

	updateHtmlViewerHeight = () => {
		this.htmlHeight = window.innerHeight - 330;

		if (this.step === dic.STEP.Authenticated) {
			if (this.emailInfo && this.emailInfo.sent.numAttachments > 0) {
				this.htmlHeight -= window.innerHeight < 780 ? 125 : 175;
			}
			else {
				this.htmlHeight -= 75;
			}
		}
		else {
			this.htmlHeight -= this.emailInfo && this.emailInfo.sent.numAttachments > 0 ? 105:65;
		}
	}

	ngOnDestroy(): void {
		this.resetDataSubscription.unsubscribe();
		this.updateDataSubscription.unsubscribe();
		this.globalNotificationSubscription.unsubscribe();
	}

	showErrorMessage(msg, ...messageParams: any[]) {
		if (!msg) return;
		this.notification.type = dic.CONSTANTS.NotificationTypes.ERROR;

		this.translateService.getTranslatedError(msg, (txtMsg) => {
			if (messageParams) {
				// @ts-ignore
				txtMsg = util.format(txtMsg, ...messageParams);
			}
			this.notification.message = txtMsg;
		});
	}

	showInfoMessage(msg, ...messageParams: any[]) {
		if (!msg) return;
		this.notification.type = dic.CONSTANTS.NotificationTypes.INFO;

		this.translateService.getTranslatedMessage(msg, (txtMsg) => {
			if (messageParams) {
				// @ts-ignore
				txtMsg = util.format(txtMsg, ...messageParams);
			}
			this.notification.message = txtMsg;
		});
	}

	clearNotification() {
		this.notification = {
			message: '',
			type: ''
		};
	}

	updateFrame() {
		if (!this.emailInfo || !this.emailInfo.sent) return;

		this.updateButton();

		let iframeDocument:any = document.querySelector('#currentMessageFrame');
		if (!iframeDocument || !iframeDocument.contentWindow) {
			if (!this.retryLoadFrame) {
				this.retryLoadFrame = true;
				this.contentLoadInterval = setInterval(()=> this.updateFrame(), 1000);
			}
			return;
		}
		clearInterval(this.contentLoadInterval);

		iframeDocument = iframeDocument.contentWindow.document;

		const color = this.gs.trustifiDefault.color;
		const hoverColor = this.gs.trustifiDefault.hoverColor;
		const boldColor = this.gs.trustifiDefault.boldColor;

		let marginPStyle;
		if (this.isOutlookClient) {
			marginPStyle = `<style>
                                html { overflow:hidden; overflow-x: auto; overflow-y: auto; padding-right: 8px; }
                                p {margin:0;padding:0;}
                            </style>`;
		}
		else {
			marginPStyle = `<style>
                                html { overflow:hidden; overflow-x: auto; overflow-y: auto; }
                                p {margin:0;padding:0;}
                            </style>`;
		}
		let scrollBarStyle = `<style>
                                ::-webkit-scrollbar  { width: 10px; height: 10px; }
                                ::-webkit-scrollbar-button { width: 0; height: 0; }
                                ::-webkit-scrollbar-thumb { border: 1px solid rgba(0, 0, 0, 0.5); border-radius: 50px;}           
                                ::-webkit-scrollbar-track { background: #ffffff;  border: 1px solid rgba(0,0,0,0.15); border-radius: 50px; }
                                ::-webkit-scrollbar-track:hover { background: #ffffff; }
                                ::-webkit-scrollbar-track:active { background: #ffffff; }
                                ::-webkit-scrollbar-corner {  background: transparent; }
                                ::-webkit-scrollbar-thumb {  background-color: ${color}; }
                                ::-webkit-scrollbar-thumb:hover { background-color: ${hoverColor}; }
                                ::-webkit-scrollbar-thumb:active { background: ${boldColor}; }
                              </style>`;

		iframeDocument.open('text/html', 'replace');
		iframeDocument.write(marginPStyle + scrollBarStyle + this.innerContent);
		iframeDocument.close();
		this.retryLoadFrame = false;
	};

	updateButton() {
		if (this.step === dic.STEP.Authenticated) {
			this.confirmButtonTxt = 'Authenticate';
			return;
		}
		if (this.emailInfo.is_content_encrypted) {
			if (this.emailInfo.sent.numAttachments > 0) {
				this.confirmButtonTxt = 'Decrypt Message and Attachment';
				if (this.emailInfo.sent.numAttachments > 1) this.confirmButtonTxt += 's';
				return;
			} else {
				this.confirmButtonTxt = 'Decrypt Message';
				return;
			}
		} else if (this.emailInfo.sent.numAttachments > 0) {
			this.confirmButtonTxt = 'Decrypt Attachment';
			if (this.emailInfo.sent.numAttachments > 1) this.confirmButtonTxt += 's';
			return;
		} else {
			this.confirmButtonTxt = 'Authenticate';
			return;
		}
	}

	downloadAsEml() {
		if (this.isEmlDownload) return;
		this.isEmlDownload = true;
		this.downloadFile('/downloadEml', null);
	}

	printContent() {
		if (this.encryptedContent) return;
		if (this.innerContent) {
			if (this.isOutlookClient) {
				const url = ENV_CONSTS.accessAppUrl + '/#/' + this.userHash;
				window.open(url);
			}
			else {
				let contentDiv = document.createElement('div');
				contentDiv.innerHTML = this.innerContent;
				if (this.emailInfo.sent.attachments && this.emailInfo.sent.attachments.length) {
					this.addAttachmentContent(contentDiv, this.emailInfo.sent.attachments);
				}
				let printTab = window.open();
				printTab.document.write(contentDiv.innerHTML);
				printTab.document.close();
				printTab.print();
				setTimeout(() => {
					printTab.close();
				}, 1000);
			}
		}
	}

	downloadFile(url, attachment) {
		if (this.disableDownloadBtn || this.encryptedContent || this.decryptionInProcess) {
			return;
		}

		if (this.attachmentsPreviewMode && !this.isEmlDownload) {
			const url = ENV_CONSTS.accessAppUrl + '/#/' + this.userHash;
			window.open(url);
			return;
		}

		this.disableDownloadBtn = true;

		let fullUrl = ENV_CONSTS.beBaseAccessAttachmentUrl + '/' + ENV_CONSTS.emailAccessURL + url;
		let fingerprintHash = this.accessClientService.useSmartAuthentication ? this.office365Service.roamingGet(this.dic.CONSTANTS.localStorageFp) : this.office365Service.roamingGet(this.userHash);

		const headers = {
			"x-access-enc": this.userHash,
		};
		if (fingerprintHash) {
			headers["x-trustifi-fingerprint"] = fingerprintHash;
		}

		let fileName, fileType, size;
		if (attachment && !this.accessOnce) {
			fileName = attachment.name;
			fileType = attachment.type;
			size = attachment.size;
			fullUrl += `/${attachment._id}`;
			this.singleDownload = true;
		}
		else {
			if (this.emailInfo.sent.attachments.length === 1) {
				fileName = this.emailInfo.sent.attachments[0].name;
				fileType = this.emailInfo.sent.attachments[0].type;
				size = this.emailInfo.sent.attachments[0].size;
				this.singleDownload = true;
			}
			else {
				fileName = 'secured_attachments.zip';
				fileType = 'application/zip';
				_.each(this.emailInfo.sent.attachments, a => size += a.size);
			}
		}

		if (this.isOutlookClient) {
			let url = fullUrl + '?enc='+this.userHash;
			if (fingerprintHash) {
				url += `&fingerprint=${fingerprintHash}`;
			}
			url += `&isOutlookClient=true`;

			window.open(url);
			
			this.disableDownloadBtn = false;
			this.singleDownload = false;
			this.isEmlDownload = false;
		}
		else {
			if (this.isEmlDownload) {
				fileName = this.emailInfo.sent.title + '.eml';
			}
			this.gs.downloadFile(fullUrl, fileName, fileType, size, headers, this.isEmlDownload, (error) => {
				this.disableDownloadBtn = false;
				this.singleDownload = false;
				this.isEmlDownload = false;

				if (error) {
					if (error.data && error.data instanceof ArrayBuffer && error.data.byteLength !== undefined ||
						error.error && error.error instanceof ArrayBuffer && error.error.byteLength !== undefined) {
						let err = <any>Buffer.from(error.data || error.error).toString();
						try {
							err = JSON.parse(err);
							let msg = err.message;
							if (err.status === dic.CONSTANTS.emailStatus.blocked) {
								msg = dic.ERRORS.downloadingNotAllowed;
							}
							this.showErrorMessage(msg);
						}
						catch(ex) {
							console.error(ex);
						}
						console.error(err);
					} else {
						console.error(error.data);
						this.showErrorMessage(error.data);
					}
				}
			});
		}
	}

	resetSummernote(content: string) {
		this.ngZone.run(() => {
			if (this.initSummernote) {
				this.html = '';
				this.htmlReply = '';
				this.initSummernote = false;
				this.rs.getRecipientSignature().then(response => {
					if (response?.html) {
						this.html = response.html;
						this.htmlReply = response.html;
					}
				}, (err) => {

				});
			}
			else {
				this.htmlReply = content;
			}
		});
	}

	wrongPhone() {
		if (!this.showWrongPhoneBtn) {
			return;
		}
		this.rs.doAuthAction(dic.CONSTANTS.recipientAuthAction.wrongPhone).then((response)=> {
			this.showInfoMessage(dic.MESSAGES.recipientNotMyPhone);
			this.showWrongPhoneBtn = false;
		});
	}

	authenticationChangeRequest() {
		if (!this.showAuthChangeBtn) {
			return;
		}

		this.rs.doAuthAction(dic.CONSTANTS.recipientAuthAction.changeAuthentication).then(response => {
			this.showAuthChangeBtn = false;
		}, (err) => {
			this.showErrorMessage(err.data.message);
		});
	}

	getPinCode(resend) {
		if ((!resend && (!this.pinCodeData.phone || this.disableConfirmAuth)) || (resend && !this.enableResend)) {
			return;
		}

		// check if the phone number contains letters
		if (!(Math.floor(Number(this.pinCodeData.phone.phone_number)) === Number(this.pinCodeData.phone.phone_number))) {
			this.showErrorMessage(dic.ERRORS.invalidPhoneNumber);
			return;
		}
		this.disableConfirmAuth = true;
		const type = resend ? this.pinCodeData.resendType : this.pinCodeData.type;

		this.gs.formatPhone(this.pinCodeData);

		const body = {
			action: dic.STEP.Phone,
			phone: this.pinCodeData.phone,
			resend: resend,
			codeType: type
		};
		this.rs.doRecipientAuthentication2Fa(body).then(response => {
			this.setStepData(response, false);
			this.disableConfirmAuth = false;
			this.authMatchError = false;
			this.enableResend = false;

			if (resend) {
				this.showInfoMessage(dic.MESSAGES.resendPinCode);
				this.pinCodeData.type = this.pinCodeData.resendType;
			}
			else {
				this.showInfoMessage(response.data);
			}
		}, error => {
			this.confirmFailure(error, dic.STEP.Phone);
			const pinCodeInputElement = document.getElementById('getPinCodeInput');
			if (pinCodeInputElement) {
				pinCodeInputElement.focus();
			}
		});
	}

	confirmPinCode() {
		if (this.disableConfirmAuth || !this.pinCodeData.pinCode) {
			return;
		}

		this.disableConfirmAuth = true;
		if (this.encryptedContent) {
			this.decryptionProcess(dic.CONSTANTS.contentStatus.process);
		}
		const body = {
			action: dic.STEP.Code,
			code: parseInt(this.pinCodeData.pinCode, 10),
			isTrustifiAddin: 'outlook',
			metrics: this.accessClientService.useSmartAuthentication ? this.accessClientService.localMetrics : null
		};

		this.rs.doRecipientAuthentication2Fa(body).then(response => {
			this.confirmSuccess(response);
		}, error => {
			this.confirmFailure(error, dic.STEP.Code);
			const element = document.getElementById('confirmPinCodeInput');
			element && element.focus();
		});
	}

	confirmEmail(resend) {
		if ((!resend && (!this.emailAuth || this.disableConfirmAuth)) || (resend && !this.enableResend)) {
			return;
		}

		this.disableConfirmAuth = true;
		this.enableResend = false;
		const body = {
			action: dic.STEP.Email,
			email: this.emailAuth.trim()
		};
		this.rs.doRecipientAuthentication2Fa(body).then(response => {
			this.setStepData(response, false);
			this.disableConfirmAuth = false;
			this.authMatchError = false;

			if (resend) {
				this.showInfoMessage(dic.MESSAGES.resendPinCode);
			}
		}, error => {
			this.confirmFailure(error, dic.STEP.Email);
			const element = document.getElementById('confirmEmailInput');
			element && element.focus();
		});
	}

	confirmPassword() {
		if (this.disableConfirmAuth || !this.password) {
			return;
		}

		this.disableConfirmAuth = true;
		if (this.encryptedContent) {
			this.decryptionProcess(dic.CONSTANTS.contentStatus.process);
		}
		const body = {
			action: dic.STEP.Password,
			password: this.password.trim(),
			isTrustifiAddin: 'outlook',
			metrics: this.accessClientService.useSmartAuthentication ? this.accessClientService.localMetrics : null
		};
		this.rs.doRecipientAuthentication2Fa(body).then(response => {
			this.confirmSuccess(response);
		}, error => {
			this.confirmFailure(error, dic.STEP.Password);
			const element = document.getElementById('confirmPasswordInput');
			element && element.focus();
		});
	}

	socialLogin() {
		this.authService.socialLogin(this.username, false, false, (err, accessToken, connection) => {
			if (accessToken) {
				this.disableConfirmAuth = true;
				if (this.encryptedContent) {
					this.decryptionProcess(dic.CONSTANTS.contentStatus.process);
				}

				this.rs.doRecipientAuthentication2Fa({
					action: this.dic.STEP.SSO,
					accessToken: accessToken,
					provider: connection,
					metrics: this.accessClientService.useSmartAuthentication ? this.accessClientService.localMetrics : null
				}).then(response => {
					this.confirmSuccess(response);
				}, error => {
					this.ngZone.run(() => {
						this.confirmFailure(error, this.dic.STEP.SSO);
					});
				});
			}
			else {
				console.error(err);
				this.showErrorMessage('SSO authentication failed');
			}
		});
	}

	ssoIdpAuthentication() {
		this.authService.IDPLogin(this.emailAuth, false, false, (err, accessToken, connection) => {
			if (accessToken) {
				this.disableConfirmAuth = true;
				if (this.encryptedContent) {
					this.decryptionProcess(dic.CONSTANTS.contentStatus.process);
				}

				this.rs.doRecipientAuthentication2Fa({
					action: this.dic.STEP.ssoIdp,
					accessToken: accessToken,
					provider: connection,
					metrics: this.accessClientService.useSmartAuthentication ? this.accessClientService.localMetrics : null
				}).then(response => {
					this.confirmSuccess(response);
				}, error => {
					this.ngZone.run(() => {
						this.confirmFailure(error, this.dic.STEP.ssoIdp);
					});
				});
			}
		});
	}

	confirmSuccess(response) {
		this.step = dic.STEP.Authenticated;
		this.clearNotification();
		this.disableConfirmAuth = false;
		this.authMatchError = false;

		if (response.fingerprint) {
			if (this.accessClientService.useSmartAuthentication) {
				this.office365Service.roamingSet(this.dic.CONSTANTS.localStorageFp, response.fingerprint || '',()=>{});
			}
			else {
				this.office365Service.roamingSet(this.userHash, response.fingerprint || '',()=>{});
			}
			this.rs.addDefaultHeaderForAuthentication(dic.HEADERS.xFingerprint, response.fingerprint);
		}

		this.emailInfo.recipient = response.recipient;
		if (response.sent) {
			this.emailInfo.sent.html = response.sent.html;
			this.emailInfo.sent.edited_timestamp = response.sent.edited_timestamp;
			this.emailInfo.enable_print = response.enable_print;
			if (this.encryptedContent) {
				this.decryptionProcess(dic.CONSTANTS.contentStatus.decrypted);
			}
		}
		this.showInfoMessage(response.data);
		this.updateHtmlViewerHeight();
	}

	confirmFailure(error, currentStatus) {
		this.disableConfirmAuth = false;
		this.errorMsg = error.data.message;
		this.authMatchError = true;
		if (error.data.remain_attempts) {
			let msg = error.data.message + '. \n'|| '';
			msg += error.data.remain_attempts > 1 ? "You have "+error.data.remain_attempts+" attempts remaining" : "You have one attempt remaining";
			this.showErrorMessage(msg);
		}
		else {
			this.showErrorMessage(error.data.message);
		}

		if (error.data.status !== currentStatus) {
			this.authMatchError = false;
			this.setStepData(error.data, true);
		}

		this.resetEncryptAnimation();
	}


	deleteAttachment(index) {
		let attachmentRemove = this.replyMessage.attachments[index];
		if (!attachmentRemove) {
			return;
		}

		if (attachmentRemove.id) {
			this.rs.replyDeleteAttachment(attachmentRemove.id.toString());
		}

		this.replyMessage.attachments.splice(index, 1);
		if (this.uploadedFiles > 0) {
			this.uploadedFiles--;
		}
		if (this.uploadSubscribers[attachmentRemove.name]) {
			this.uploadSubscribers[attachmentRemove.name].unsubscribe();
		}
		this.showInfoMessage(dic.MESSAGES.attachmentRemove);
	};

	decryptionProcess = (status) => {
		if (this.encryptedContent) {
			if (status === dic.CONSTANTS.contentStatus.process) {
				this.decryptionInProcess = true;
				this.decryptionStatus = dic.CONSTANTS.contentStatus.process;
			}
			else if (status === dic.CONSTANTS.contentStatus.decrypted) {
				this.accessClientService.timeout1 = setTimeout(() => {
					this.decryptionStatus = dic.CONSTANTS.contentStatus.decrypted;
				}, 1500);
				this.accessClientService.timeout2 = setTimeout(() => {
					this.decryptData();
				}, 2500);
			}
		}
		else if (status === dic.CONSTANTS.contentStatus.decrypted) {
			this.decryptData();
		}
	}

	setReplyRecipients(isReplyAll) {
		if (isReplyAll) {
			this.emailInfo.replyRecipients = _.map(this.emailInfo.replyRecipients, r => {
				r.selected = true;
				return r;
			});
		}
		else {
			this.emailInfo.replyRecipients = _.map(this.emailInfo.replyRecipients, r => {
				r.selected = r.email === this.emailInfo.sender || r.email === this.emailInfo.originalSender;
				return r;
			});
		}

		const clonedRecipients = _.cloneDeep(this.emailInfo.replyRecipients);

		this.emailInfo.replyRecipientsCC = clonedRecipients.map(r => {
			r.enforce && delete r.enforce;
			r.selected = false;
			return r;
		});

		this.getDropdownHeader();
		this.goToReply();
	}

	goToReply() {
		if (this.encryptedContent || this.decryptionInProcess) {
			return;
		}
		else {
			this.step = dic.STEP.Reply;
			this.updateFrame();
			this.showInfoMessage(dic.MESSAGES.replyMsg);
		}
	}

	replyMessageExecute() {
		if (this.disableReplySendBtn) {
			return;
		}
		this.clearNotification();
		this.disableReplySendBtn = true;

		let attachmentIds = [], attachmentNames = [];
		_.each(this.replyMessage.attachments, attachment => {
			attachmentIds.push(attachment.id);
			attachmentNames.push(attachment.name);
		});

		const recipients = _.map(_.filter(this.emailInfo.replyRecipients, 'selected'), 'email');
		const ccRecipients = _.map(_.filter(this.emailInfo.replyRecipientsCC, 'selected'), 'email');

		// Combine recipients and ccRecipients
		const allRecipients = recipients.concat(ccRecipients);
		if (!allRecipients?.length) {
			this.notificationService.showErrorMessage(dic.ERRORS.replyWithOneRecipient);
			this.disableReplySendBtn = false;
			return;
		}

		// Create toReplay and ccReply arrays in the specified format
		const toReply = recipients.map(email => ({ address: email }));
		const ccReply = ccRecipients.map(email => ({ address: email }));

		this.rs.replyMessage({
			html: this.htmlReply,
			attachments: attachmentIds,
			recipients: allRecipients,
			to: toReply,
			cc: ccReply,
			metrics: this.accessClientService.useSmartAuthentication ? this.accessClientService.localMetrics : null
		}).then(response => {
			this.office365Service.roamingSet('message', dic.MESSAGES.repliedSuccess, ()=>{});
			setTimeout(() => {
				this.showInfoMessage(dic.MESSAGES.repliedSuccess);
				this.step = dic.STEP.Authenticated;
				this.addReplyContent(this.htmlReply, toReply, ccReply, attachmentNames);
				this.html = '';
				this.htmlReply = '';
				this.replyMessage.attachments = [];
				this.disableReplySendBtn = false;

				if (!this.multipleReply) {
					this.enableReply = false;
				}
				this.isReplySent = true;
				this.updateHtmlViewerHeight();
			}, 1000);
		}, error => {
			this.showErrorMessage(error.data.message);
			this.disableReplySendBtn = false;
		}, () => {
			this.disableReplySendBtn = false;
		});
	}

	addReplyContent(replyContent, toReply, ccReply, attachmentNames) {
		let receivedAttachments = [];
		if (this.emailInfo.sent.attachments?.length) {
			receivedAttachments = this.emailInfo.sent.attachments.map((itm) => itm.name);
		}

		let replyContentHtml = `
			  <div>
				${toReply?.length ? `<div>To: ${toReply.map(itm => itm.address).join(', ')}</div>` : ''}
				${ccReply?.length ? `<div>Cc: ${ccReply.map(itm => itm.address).join(', ')}</div>` : ''}
				${attachmentNames?.length ? `<div>Attachments: ${attachmentNames.join(', ')}</div>` : ''}
			  </div>
			  <br>${replyContent}
			  <br><br><hr style="border-color: black">
			  <br>`;

		if (!this.isReplySent) {
			replyContentHtml += `
			  <div>
				${this.emailInfo.to?.length ? `<div>To: ${this.emailInfo.to.join(', ')}</div>` : ''}
				${this.emailInfo.cc?.length ? `<div>Cc: ${this.emailInfo.cc.join(', ')}</div>` : ''}
				${receivedAttachments.length ? `<div>Attachments: ${receivedAttachments.join(', ')}</div>` : ''}
			  </div>
			  <br/>`;
		}

		this.emailInfo.sent.html = replyContentHtml + this.emailInfo.sent.html;
		this.decryptData();
		if (!this.emailInfo.originalSender) {
			this.emailInfo.originalSender = this.emailInfo.sender;
		}
		this.emailInfo.sender = this.emailInfo.recipient || '';
		this.initSummernote = true;
	}

	resetEncryptAnimation() {
		this.decryptionStatus = dic.CONSTANTS.contentStatus.initial;
		this.decryptionInProcess = false;
	}

	uploadFiles(files) {
		if (!files || !files.length) return;

		files = _.map(files, f => f._file);

		if (files.length > dic.CONSTANTS.secureReplyMaxAttachments
			|| this.replyMessage.attachments.length + files.length > dic.CONSTANTS.secureReplyMaxAttachments) {
			files.length = 0;
			this.uploader.queue.length = 0;
			this.showErrorMessage(dic.ERRORS.recipAuthReplyTooManyAttachments);
			return;
		}

		let totalSize = 0;
		for (let i = 0; i < files.length; i++) {
			let file = files[i];
			if (_.find(this.replyMessage.attachments, {name: file.name, size: file.size})) {
				if (this.uploadedFiles > 0) {
					this.uploadedFiles = 0;
				}
				this.showErrorMessage(dic.ERRORS.attachmentAlreadyUploaded, file.name);
				files.length = 0;
				this.uploader.queue.length = 0;
				return;
			}
			totalSize += file.size;
		}
		const isValid = this.checkUploadFileSize(totalSize, 'Current upload attachments');
		if (totalSize === 0 || !isValid) {
			return;
		}

		let fingerprintHash = this.accessClientService.useSmartAuthentication ? this.office365Service.roamingGet(this.dic.CONSTANTS.localStorageFp) : this.office365Service.roamingGet(this.userHash) || '';

		const headers = new HttpHeaders()
		.set("x-access-enc", this.userHash)
		.set('x-trustifi-fingerprint', fingerprintHash);

		for (let i = 0; i < files.length; i++) {
			this.uploadFilesExecute(files[i], headers);
		}

		this.uploadedFiles += files.length;
		files.length = 0;
	}

	uploadFilesExecute(file, headers) {
		if (!file) return;

		this.clearNotification();

		const fd = new FormData();
		fd.append('file', file);

		if (this.accessClientService.useSmartAuthentication) {
			fd.append('metrics', JSON.stringify(this.accessClientService.localMetrics));
		}

		let currentAttachment:any = {
			name: file.name,
			size: file.size,
			finished: false,
			progressPercentage: 0,
			upload: {}
		};
		this.replyMessage.attachments.push(currentAttachment);

		const req = new HttpRequest('POST', ENV_CONSTS.beBaseAccessAttachmentUrl + '/' + ENV_CONSTS.emailAccessURL + '/Attachment', fd, {
			headers,
			reportProgress: true
		});

		this.uploadSubscribers[file.name] = this.http.request(req).subscribe((event:any) => {
			// reporting percentages - updating local variables
			if (event.type === HttpEventType.UploadProgress) {
				currentAttachment.progressPercentage = 100.0 * event.loaded / event.total;
			}
			// final response with the data
			else if (event.type === HttpEventType.Response) {
				//update finished state and new id for attachments
				currentAttachment.finished = true;
				currentAttachment.id = event.body[0];

				if (this.uploadedFiles > 0) {
					this.uploadedFiles--;
				}

				if (this.uploadedFiles === 0) {
					this.showInfoMessage(this.dic.MESSAGES.attachmentsUploaded, this.uploader.queue.length);
					this.uploader.queue.length = 0;
				}
			}
		}, err => {
			if (err.error && err.error.message) {
				this.showInfoMessage(err.error.message);
			}
			_.remove(this.replyMessage.attachments, attachment => {
				return currentAttachment.name === attachment.name;
			});

			if (this.uploadedFiles > 0) {
				this.uploadedFiles--;
			}
		});
	}

	initAuthStatus() {
		if (this.data.email_info) {
			this.emailInfo = this.data.email_info;
			this.updateButton();
			if (this.emailInfo.is_content_encrypted) {
				this.encryptedContent = true;
				if (this.emailInfo.is_content_decrypted) {
					this.decryptionProcess(dic.CONSTANTS.contentStatus.process);
					this.decryptionProcess(dic.CONSTANTS.contentStatus.decrypted);
				}
			}
			else {
				this.decryptionProcess(dic.CONSTANTS.contentStatus.decrypted);
			}

			if (this.data.email_info.methods.length === 1
				&& this.data.email_info.methods[0] === dic.STEP.Reply
				&& this.data.status === dic.STEP.Authenticated
				&& !this.encryptedContent) {
				this.data.status = dic.STEP.Reply;
				this.setReplyRecipients(false);
			}
		}
		this.multipleReply = this.data.multi_replies;
		this.allowDownloadEml = this.data.allow_download_as_eml;
		this.attachmentsPreviewMode = this.data.attachments_preview_mode;
		this.isOriginalSender = this.data.original_sender;
		this.enforceReplyAll = this.data.enforce_reply_all;

		this.setStepData(this.data, false);
		this.createMsgDescription();
	}

	createMsgDescription() {
		if (this.data.open_only_once){
			this.accessOnce = true;
			this.showInfoMessage(dic.MESSAGES.accessOnce);
		}
	}

	decryptData() {
		this.ngZone.run(() => {
			this.decryptionStatus = dic.CONSTANTS.contentStatus.initial;
			this.encryptedContent = false;
			this.decryptionInProcess = false;
		});

		this.innerContent = this.emailInfo.sent.html;
		this.innerContent = this.innerContent.replace(/Calibri, sans-serif/g, '');
		this.innerContent = this.innerContent.replace(/14.6667px/g, '16px');
		this.updateFrame();
	}

	setStepData(stepData, keepErrorMsg) {
		this.step = dic.STEP[stepData.status] || dic.STEP.Blocked;
		//reset notification message
		if (!keepErrorMsg) {
			this.clearNotification();
		}

		switch (stepData.status) {
			case dic.STEP.Phone:
				this.phoneHint = stepData.hint;
				this.pinCodeData.phone.country_code = "+" + stepData.country_code;
				this.flagIcon = this.gs.getCountryCodeFlag(stepData.country_code);
				this.isVoiceSupported = stepData.isVoiceSupported;
				break;

			// code can be received via phone or email
			case dic.STEP.Code:
				this.authPinCodeMethod = stepData.auth;
				this.emailAuth = stepData.recipientEmail;
				if (stepData.auth === dic.STEP.Phone) {
					this.pinCodeData.phone = stepData.phone;
					this.isVoiceSupported = stepData.isVoiceSupported
					this.gs.formatPhone(this.pinCodeData);
				}
				setTimeout(() => {
					this.enableResend = true;
				}, dic.CONSTANTS.resendTimeout);
				break;

			case dic.STEP.Password:
				this.hint = stepData.hint;
				break;

			case dic.STEP.Email:
				this.emailHint = stepData.hint;
				break;

			case dic.STEP.SSO:
				this.emailAuth = stepData.recipientEmail;
				break;

			case dic.STEP.ssoIdp:
				this.emailAuth = stepData.recipientEmail;
				break;

			case dic.STEP.Reply:
				this.showInfoMessage(dic.MESSAGES.replyMsg);
				break;

			case dic.STEP.Blocked:
				this.blockNote = stepData.blockNote || "";
				if (stepData.allowAskAccess) {
					this.showAskAccess = true;
					this.showAskAccessBlocked = false;
					this.showAccessText = 'compose.recipients.askSenderAccess';
				}
				else {
					if (stepData.reason === 'blocked') {
						this.showAskAccess = true;
						this.showAskAccessBlocked = true;
						this.showAccessText = 'compose.recipients.senderNotified';
					}
					else if (stepData.reason === 'deleted') {
						this.showAskAccess = false;
					}
				}
				break;
		}

		this.updateHtmlViewerHeight();
	}

	checkUploadFileSize(fileSize, fileName) {
		if (fileSize <= 0) {
			this.showErrorMessage(fileName + ' is empty');
			return false;
		}

		const fileSizeInMB = fileSize * 0.000001;
		if (fileSizeInMB > 250) {
			this.showErrorMessage(fileName + ' size is too large. Maximum reply file size is 250 Mb');
			return false;
		}
		return true;
	};

	updatePinCodeType(type) {
		this.pinCodeData.type = type;
		this.pinCodeData.resendType = type;
	};

	askForAccess = () => {
		if (this.showAskAccessBlocked) return;
		this.showAskAccessBlocked = true;
		this.rs.doAuthAction(dic.CONSTANTS.recipientAuthAction.askAccess).then((response)=> {
			this.showInfoMessage(dic.MESSAGES.askAccessSuccess);
			this.showAskAccess = false;
			this.showAskAccessBlocked = true;
			this.showAccessText = 'compose.recipients.senderNotified';
		})
		.catch(err => {
			this.showAskAccessBlocked = false;
			console.error(err);
		});
	};

	getDropdownHeader = (dropdown: string = null) => {
		const updateDropdownHeader = (items, selectedCount) => {
			if (items.length > 1 && selectedCount === items.length) {
				return this.translateService.getTranslationText('recipient.all');
			}
			else if (items.length === 1 || selectedCount < items.length) {
				const firstSelected = items.find(item => item.selected);
				if (firstSelected) {
					let header = firstSelected.email;
					if (selectedCount > 1) {
						header += ` +${selectedCount - 1}`;
					}
					return header;
				}
				else {
					return 'Choose';
				}
			}
			else {
				return 'Choose';
			}
		};

		if (dropdown === null || dropdown === 'to') {
			const selectedRecipients = this.emailInfo.replyRecipients.filter(r => r.selected).length;
			this.firstRecipient = updateDropdownHeader(this.emailInfo.replyRecipients, selectedRecipients);
		}

		if (dropdown === null || dropdown === 'cc') {
			const selectedCCRecipients = this.emailInfo.replyRecipientsCC.filter(cc => cc.selected).length;
			this.firstCCRecipient = updateDropdownHeader(this.emailInfo.replyRecipientsCC, selectedCCRecipients);
		}
	}

	fileOver(isFileOver: boolean) {
		this.mouseOverAttachment = isFileOver;
	}

	//Hack for IE cursor on file input
	loseFocus() {
		(<any>(document.activeElement)).blur();
	}

	private addAttachmentContent(element, attachments) {
		let attachmentsContent = '<br><div>Attachments:</div>';
		_.each(attachments, attachment => {
			attachmentsContent += `<div>${attachment.name} (${this.gs.formatBytes(attachment.size, 2)})</div>`;
		});
		element.innerHTML += `<span style="font-family: Times New Roman; font-size: medium;">${attachmentsContent}</span><br>`;
	}
}
