import {Injectable, NgZone} from '@angular/core';
import {GeneralService} from './general.service';
import {TranslationService} from './translation.service';
import {RestService} from './rest.service';
import {AUTH0_CONSTS, ENV_CONSTS} from '../constants';
import * as auth0 from 'auth0-js';
import * as util from 'util';
import * as qs from 'qs';

import '../../plugins/rawdeflate.js';
import '../../plugins/rawinflate.js';

import {Office365Service} from './office365.service';
import {dic} from '../dictionary';
import {NotificationService} from "./notification.service";

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	authNotify = false;

	Auth0Client = new auth0.WebAuth({
		domain: AUTH0_CONSTS.domain,
		clientID: AUTH0_CONSTS.clientId,
		redirectUri: AUTH0_CONSTS.auth0RedirectUrl,
		responseType: 'token',
		leeway : 60
	});
	data2Fa;
	isFullyAuthenticated;

	constructor(private rs: RestService,
				private gs: GeneralService,
				private ns: NotificationService,
				private ngZone: NgZone,
				private translateService: TranslationService,
				private office365Service: Office365Service) {
		if (this.isAuthenticated()) {
			this.rs.setDefaultHeaders(this.getHeaders());
		}

		this.isFullyAuthenticated = this.isAuthenticated() && !this.data2Fa;
	}

	private setUser(authResult, inflogin, reload, callback) {
		const fName = 'setUser';

		let createApiKey = Promise.resolve();

		this.rs.setDefaultHeaders({'x-access-token': authResult.accessToken, 'x-trustifi-source': 'outlook'});

		if (inflogin) {
			createApiKey = this.rs.createApiKey().then(apiRes => {
				this.rs.setDefaultHeaders({'x-trustifi-key': apiRes.key, 'x-trustifi-secret': apiRes.secret, 'x-trustifi-source': 'outlook'});
				return apiRes;
			}, err => {
				this.rs.sendLogs('error', fName, {message:'failed to acquire API Key/Secret', authUser: authResult.email, error: err.message});
				return null;
			});
		}
		else {
			console.log('logged in without API Key/Secret', authResult.email);
		}

		createApiKey.then((apiRes: any) => {
			const params: any = {
				expires_at: !apiRes && (authResult.expiresIn * 1000 + Date.now()) || 0,
				access_token: !apiRes && authResult.accessToken,
				oexpired: null,
				otoken: null,
				api_key: apiRes && apiRes.key,
				api_secret: apiRes && apiRes.secret,
				id_token: authResult.idToken,
				email: authResult.email,
				logoutUrl: authResult.logoutUrl
			};

			this.office365Service.roamingSet('userAuthenticated', params, () => {
				this.authNotify = true;

				setTimeout(() => {
					if (!this.data2Fa) {
						this.ngZone.run(() => {
							this.isFullyAuthenticated = true;
							this.office365Service.setUserProfile();
						});
					}
				});

				const mailbox = this.office365Service.profile?.user?.emailAddress;
				if (!mailbox || !authResult.email || mailbox.toLowerCase() !== authResult.email.toLowerCase()) {
					let error = {message: `Mailbox user ${mailbox} is different from token user ${authResult.email}`};
					this.rs.sendLogs('warn', fName, {error});
				}

				if (this.office365Service.sendInitData) {
					this.rs.sendLogs('warn', fName, this.office365Service.sendInitData);
					this.office365Service.sendInitData = null;
				}

				callback();
			});

		}, err => {
			callback();
		});
	}

	private parseAuthenticationResponse(authResult, inflogin, callback) {
		let connection = authResult.connection || authResult.idTokenPayload && authResult.idTokenPayload.identities && authResult.idTokenPayload.identities[0].connection;

		this.rs.setDefaultHeaders({'x-access-token': authResult.accessToken, 'x-trustifi-source': 'outlook'});
		this.office365Service.roamingSet('userAuthenticated', {
			access_token: authResult.accessToken,
			expires_at: authResult.expiresIn * 1000 + Date.now(),
			email: authResult.email,
			connection: connection
		}, () => {
			this.gs.getUserInfo(true).then((userInfo) => {
				if (userInfo) {
					this.setUser(authResult, inflogin, false, callback);
				} else {
					return callback(this.translateService.getTranslationText('login.loginWrongCreds'));
				}
			}, err => {
				if (err.status === 406) {
					return;
				}

				this.rs.setDefaultHeaders({'x-access-token': authResult.accessToken, 'x-trustifi-source': 'outlook'});

				this.rs.signupUser({
					connection: connection,
					company: this.gs.companyName
				}).then((response) => {
					if (response.error) {
						console.error('[Trustifi] '+connection, response);
						callback(response.error);
					} else {
						this.setUser(authResult, inflogin, true, callback);
					}
				}, err => {
					console.error('[Trustifi] '+connection, err);
					callback(err.error || err.message || err.data && err.data.message);
				});
			});
		});
	}

	login(credentials, callback) {
		this.Auth0Client.client.login({
			realm: AUTH0_CONSTS.connection,
			username: credentials.username,
			password: credentials.password
		}, (err, authResult) => {
			if (err) {
				let errorMsg = '';
				if (err.code === 'invalid_grant' || err.code === 'access_denied' || err.code === 'too_many_attempts') {
					errorMsg = 'login.loginWrongCreds';
				} else {
					if (err.code === 'unauthorized' && err.description === 'please change your password') {
						errorMsg = 'login.passwordExpired';
					} else {
						errorMsg = 'login.requestTimeout';
					}
				}

				this.rs.setDefaultHeaders({});
				return callback(this.translateService.getTranslationText(errorMsg));
			}
			else if (authResult && authResult.accessToken) {
				if (authResult.idToken) {
					const payload = JSON.parse(atob(authResult.idToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
					authResult.email = payload.email || payload.sub || payload.name || '';
				}
				else {
					authResult.email = credentials.username;
				}

				this.gs.userInfo = null;
				this.setUser(authResult, credentials.inflogin, false, callback);
			} else {
				return callback(this.translateService.getTranslationText('login.loginWrongCreds'));
			}
		});
	}

	IDPLogin(username, inflogin, isLogin, callback) {
		let url = `${ENV_CONSTS.beBaseAccessUrl}/callback/idp/${encodeURIComponent(window.location.origin)}/${username}`;

		this.office365Service.showDialog(url, 50, 60).then((event:any) => {
			const queryString = qs.parse(event.message);
			queryString.access_token = queryString.access_token || queryString['#access_token'] || queryString['?access_token'];
			queryString.id_token = queryString.id_token || queryString['#id_token'] || queryString['?id_token'];
			queryString.saml_token = queryString.saml_token || queryString['#saml_token'] || queryString['?saml_token'];

			if (queryString.error) {
				return callback('Sign in failed due to: ' + queryString.error_description);
			}

			try {
				if (queryString.id_token) {
					const payload = JSON.parse(atob((queryString.id_token as string).split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
					const email = payload.email || payload.sub || payload.name || '';

					if (!email || username && email.toLowerCase() !== username.toLowerCase()) {
						return callback(dic.ERRORS.multipleLogins);
					}

					this.rs.exchangeTokenOpenID({token: queryString.access_token}).then(res => {
						if (!isLogin) {
							return callback(null, res.oToken, 'openid');
						}

						const authResult = {
							idToken: queryString.id_token,
							accessToken: res.oToken,
							logoutUrl: res.logoutUrl,
							email: email,
							connection: 'openid',
							expiresIn: new Date(payload.exp)
						};

						this.parseAuthenticationResponse(authResult, inflogin, callback);
					}).catch(err => {
						callback(dic.ERRORS.authSignInError);
					});
				}
				else if (queryString.saml_token) {
					const logoutUrl = queryString.logout || queryString['#logoutUrl'];
					const email = queryString.email || queryString['#email'];

					if (!email || username && email.toLowerCase() !== username.toLowerCase()) {
						return callback(dic.ERRORS.multipleLogins);
					}

					if (!isLogin) {
						return callback(null, queryString.saml_token, 'saml');
					}

					const authResult = {
						accessToken: queryString.saml_token,
						email: email,
						connection: 'saml',
						logoutUrl: logoutUrl,
						expiresIn: 1800 * 1000 + Date.now()
					};

					this.parseAuthenticationResponse(authResult, inflogin, callback);
				}
			}
			catch(ex) {
				callback(ex.message);
			}
		}).catch(err => {
			console.error('[Trustifi] '+err.message, err);
		});
	}

	openIDLogout(userAuthenticated) {
		let logoutUrl = userAuthenticated.logoutUrl;
		let id_token = userAuthenticated.id_token;
		if (!logoutUrl || !id_token) return;

		let url = `${logoutUrl}?id_token_hint=${id_token}&post_logout_redirect_uri=${window.location.origin}/redirect.html`;

		//NOTE: we can do "window.fetch" instead of the "window.open" but in this case user will have to set FE url in "Trusted Origins" in SP.
		//window.open(url, '_blank', 'location=no,width=750,height=600,scrollbars=no,resizable=no');

		this.office365Service.showDialog(url, 50, 60).catch(err => {
			if (err) {
				console.error('[Trustifi] '+err.message, err);
			}
		});
	}

	samlLogout(userAuthenticated) {
		let logoutUrl = userAuthenticated.logoutUrl;
		let email = userAuthenticated.email;
		if (!logoutUrl || !email) return;

		const randomString = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 16);
		let logoutRequest = `
<samlp:LogoutRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion" 
                    ID="_${randomString}" Version="2.0"
                    IssueInstant="${new Date().toISOString()}"
                    Destination="${logoutUrl}">
  <saml:Issuer>${dic.CONSTANTS.samlAudience}</saml:Issuer>
  <saml:NameID Format="urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress">${email}</saml:NameID>
</samlp:LogoutRequest>`.replace(/\s+/g, ' ').trim();

		logoutRequest = encodeURIComponent(btoa(window['RawDeflate'].deflate(logoutRequest)));
		let url = `${logoutUrl}?SAMLRequest=${logoutRequest}&RelayState=${encodeURIComponent(window.location.origin)}`;

		this.office365Service.showDialog(url, 50, 60).catch(err => {
			if (err) {
				console.error('[Trustifi] '+err.message, err);
			}
		});
	}

	socialLogin(username, inflogin, isLogin, callback) {
		const url = `https://${AUTH0_CONSTS.domain}/authorize?client_id=${AUTH0_CONSTS.clientId}&response_type=token&redirect_uri=${window.location.origin}/redirect.html&connection=${AUTH0_CONSTS.MSConnection}&state=${inflogin}&scope=openid+email&prompt=select_account`;

		this.office365Service.showDialog(url, 50, 60).then((event: any) => {

			const queryString = qs.parse(event.message);
			queryString.access_token = queryString.access_token || queryString['#access_token'];

			if (queryString.error) {
				return callback('Sign in failed due to: ' + queryString.error_description);
			}

			if (!isLogin) {
				return callback(null, queryString.access_token, AUTH0_CONSTS.MSConnection);
			}

			try {
				const payload = JSON.parse(atob((queryString.id_token as string).split('.')[1]));
				username = payload.email;
			} catch (ex) {
				console.error('[Trustifi] '+ex.message, event.message);
			}

			const authResult = {
				expiresIn: parseInt(queryString.expires_in as string, 10) || 0,
				accessToken: queryString.access_token,
				connection: AUTH0_CONSTS.MSConnection,
				email: username
			};

			this.parseAuthenticationResponse(authResult, inflogin, callback);
		}).catch(err => {
			console.error('[Trustifi] '+err.message, err);
			callback(err);
		});
	}

	authForContacts(username, callback) {
		// &login_hint=${username}
		const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code
        &scope=openid+email+https://graph.microsoft.com/People.Read+https://graph.microsoft.com/Contacts.Read.Shared
        &state=${window.location.origin}|${username}|${this.gs.trustifiDefault.name}
        &client_id=${AUTH0_CONSTS.MSAppId}&redirect_uri=${ENV_CONSTS.beBaseGraphUrlOutbound}/outlook/callback`;

		this.office365Service.showDialog(url, 50, 60).then((event: any) => {
			const apiParam = event.message.split('=')[0];
			let apiValue = event.message.split('=')[1];

			if (apiParam.indexOf('error') >= 0) {
				if (apiValue) {
					apiValue = decodeURIComponent(apiValue.replace('}}', ''));
					// serverError has 11 letters
					if (apiValue.indexOf('AADSTS65004') >= 0) {
						apiValue = true;
					} else if (apiValue.startsWith('ServerError')) {
						apiValue = util.format(dic.ERRORS.serverError, parseFloat(apiValue.substring(11)));
										} else if (apiValue.match(/^AADSTS\d+:/)) {
						apiValue = util.format(dic.ERRORS.ssoError, apiValue.split(':')[0]);
										} else {
						apiValue = 'Sign in failed due to: ' + apiValue;
										}
				}

				return callback((apiValue || dic.ERRORS.authSignInError).replace('Sign in ', 'Import contacts '));
			}

			const apiRes = apiValue.split(':');
			const newContactsNumber = parseInt(apiRes[0], 10);

			callback(null, newContactsNumber);
		}).catch(err => {
			console.error('[Trustifi] '+err.message, err);
			callback(true);
		});
	}

	changePassword(email, callback) {
		this.Auth0Client.changePassword({
			connection: AUTH0_CONSTS.connection,
			email
		}, callback);
	}

	isAuthenticated() {
		const userAuthenticated = this.office365Service.roamingGet('userAuthenticated');
		return userAuthenticated && (userAuthenticated.api_key || userAuthenticated.access_token && Date.now() < userAuthenticated.expires_at);
	}

	logout() {
		const userAuthenticated = this.office365Service.roamingGet('userAuthenticated');
		if (userAuthenticated && userAuthenticated.connection) {
			if(userAuthenticated.connection === 'openid') {
				this.openIDLogout(userAuthenticated);
			}
			else if(userAuthenticated.connection === 'saml') {
				this.samlLogout(userAuthenticated);
			}
		}

		this.gs.userInfo = null;
		document.body.removeAttribute('data-theme');
		this.office365Service.logout();
		this.isFullyAuthenticated = false;
	}

	remoteLogin(authResult, inflogin) {
		this.setUser(authResult, inflogin, true, err => {
			if (err) { console.error('[Trustifi] '+err.message, err); }
		});
	}

	getHeaders = () => {
		const userAuthenticated = this.office365Service.roamingGet('userAuthenticated');
		const headers = {'x-trustifi-source': 'outlook'};

		if (userAuthenticated.api_key && userAuthenticated.api_secret) {
			headers['x-trustifi-key'] = userAuthenticated.api_key;
			headers['x-trustifi-secret'] = userAuthenticated.api_secret;
		} else {
			headers['x-access-token'] = userAuthenticated.access_token;
		}

		if (userAuthenticated[dic.HEADERS.x2FAFingerprint]) {
			headers['x-trustifi-2fa-fingerprint'] = userAuthenticated[dic.HEADERS.x2FAFingerprint];
		}
		return headers;
	}

	addFingerprint = (headerValue, cb) => {
		const userAuthenticated = this.office365Service.roamingGet('userAuthenticated');
		userAuthenticated['x-trustifi-2fa-fingerprint'] = headerValue;
		this.office365Service.roamingSet('userAuthenticated', userAuthenticated, () => {
			this.rs.setDefaultHeaders(this.getHeaders());
			cb();
		});
	}

	userNotAuthenticated(data) {
		const requireMfa = !!this.data2Fa;
		if (!this.isAuthenticated() || requireMfa) {
			return;
		}

		this.logout();
		if (data?.message) {
			setTimeout(() => {
				this.ns.showErrorMessage(data.message);
			}, 500);
		}
	}

	userRequireMfa(data) {
		this.isFullyAuthenticated = false;
		this.data2Fa = data;
	}
}
