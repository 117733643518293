<div class="flxClmn vSpace3" style="height: calc(100vh - 100px); position: relative">
	<!--Analysis Summary-->
	<div class="flxClmn vSpace1 analysis-summary-container" [ngClass]="{'is-shrinked' : isShrinkSummary || activeAccordionTab}" style="position: relative;"
		 *ngIf="!blockScan && ((userInfo && userInfo.plan && userInfo.plan.plan !== dic.CONSTANTS.planType.free) || (userInfo && userInfo.plan && userInfo.plan.scanned_emails <= dic.CONSTANTS.freePlanScansLimit))">
		<!--BG color-->
		<div class="analysis-summary-bg" style="z-index: 2;" [ngStyle]="{'background' : setInboundStatusStyles(threatProtection.status).displayColor}"></div>
		<!---->
		<!--shrink summary row-->
		<div class="shrink-summary-toggle flxRow-center" (click)="isShrinkSummary = !activeAccordionTab ? !isShrinkSummary : false; activeAccordionTab = null;">
			<i class="fa fa-chevron-up"></i>
		</div>
		<!---->
		<div class="flxClmn w100 vSpace1" style="margin-bottom: min(15px,1vh); z-index: 5;">
			<div class="flxRow-between" style="align-items: center">
				<span style="font-family: 'Roboto Condensed', sans-serif; font-size: 1.1rem;">{{'inboundShield.analysisSummary' | translate}}</span>
				<div class="flxRow-center hSpace1" style="border-radius: 250px; width: fit-content; align-items: center; font-weight: 500; background: transparent; border: solid 1px; padding: 4px 15px;"
					 [ngStyle]="{'border-color': setInboundStatusStyles(threatProtection.status).displayColor, 'box-shadow' : '0 0 2px ' + setInboundStatusStyles(threatProtection.status).color}">
					<i class="fas" [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.status).displayColor}"
					   [ngClass]="setInboundStatusStyles(threatProtection.status).icon">
					</i>
					<span style="font-size: 0.9rem; font-weight: bold; white-space: nowrap;" [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.status).displayColor}">
						{{threatProtection.status | translate}}
					</span>
				</div>
			</div>
			<div class="hide-in-shrink" style="background: linear-gradient(90deg, #9b9b9b, transparent); width: 100%; height: 1px;"></div>
		</div>
		<div class="flxClmn vSpace2 hide-in-shrink" style="padding: 10px 5px; z-index: 5;">
			<div class="flxClmn vSpace1">
				<div class="flxRow hSpace05" style="align-items: flex-start; cursor: pointer"
					 (click)="activeAccordionTab = accordionTabs.messageAnalysis; notificationService.clear(); actionToConfirm = null;"
					 [ngStyle]="{'color': setInboundStatusStyles(threatProtection.headers.status).displayColor}">
					<i class="fa fa-user" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
					<div class="flxClmn vSpace05" style="flex: 1 0;">
						<span class="t-color-primary"
							  [ngClass]="{'loading-dots': setInboundStatusStyles(threatProtection.headers.status).loading, 'status-text' : !setInboundStatusStyles(threatProtection.headers.status).loading}">
							{{threatProtection.headers.message | translate}}
						</span>
						<span style="word-break: break-word; word-wrap: break-word; display: block"
							  *ngIf="threatProtection.headers.from && threatProtection.headers.from.highlight"
							  [innerHTML]="threatProtection.headers.from.highlight"></span>
					</div>
					<div class="flxClmn-center">
						<i class="fa fa-chevron-right t-color-primary"></i>
					</div>
				</div>
				<div class="flxRow hSpace05" style="align-items: flex-start; cursor: pointer"
					 (click)="activeAccordionTab = accordionTabs.linksAnalysis; notificationService.clear(); actionToConfirm = null;"
					 [ngStyle]="{'color': setInboundStatusStyles(threatProtection.url.status).displayColor}">
					<i class="fa fa-link" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
					<span class="status-text t-color-primary" style="flex: 1 0;" [ngClass]="{'loading-dots': setInboundStatusStyles(threatProtection.url.status).loading, 'status-text' : !setInboundStatusStyles(threatProtection.url.status).loading}">
						{{threatProtection.url.message | translate}}
					</span>
					<div class="flxClmn-center">
						<i class="fa fa-chevron-right t-color-primary"></i>
					</div>
				</div>
				<div class="flxRow hSpace05" style="align-items: flex-start; cursor: pointer"
					 (click)="activeAccordionTab = accordionTabs.attachmentsAnalysis; notificationService.clear(); actionToConfirm = null;"
					 [ngStyle]="{'color': setInboundStatusStyles(threatProtection.file.status).displayColor}">
					<i class="fa fa-file" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
					<span class="status-text t-color-primary" style="flex: 1 0;" [ngClass]="{'loading-dots': setInboundStatusStyles(threatProtection.file.status).loading, 'status-text' : !setInboundStatusStyles(threatProtection.file.status).loading}">
						{{threatProtection.file.message | translate}}
					</span>
					<div class="flxClmn-center">
						<i class="fa fa-chevron-right t-color-primary"></i>
					</div>
				</div>
			</div>
			<div class="flxRow w100 animated fadeIn" *ngIf="threatProtection.status !== dic.CONSTANTS.threatProtection.status.analyzing && threatProtection.status !== dic.CONSTANTS.threatProtection.status.pending">
				<button class="flxRow-center simple-button"
						(click)="actionToConfirm =  dic.CONSTANTS.recipientTpActions.trust; reportTrustifi = true;"
						*ngIf="threatProtection.status !== dic.CONSTANTS.threatProtection.status.safe">
					<span>{{'inboundShield.trustEmail' | translate}}</span>
				</button>
				<button class="flxRow-center simple-button" style="margin-left: 5px;"
						(click)="actionToConfirm = dic.CONSTANTS.recipientTpActions.report;">
					<span>{{'inboundShield.reportEmail' | translate}}</span>
				</button>
			</div>
		</div>
	</div>


	<div class="flxClmn" style="flex: 1 0; margin-left: -10px; margin-right: -10px; padding: 5px 10px; margin-bottom: 20px; overflow: hidden;"
		 *ngIf="!blockScan && ((userInfo && userInfo.plan && userInfo.plan.plan !== dic.CONSTANTS.planType.free) || (userInfo && userInfo.plan && userInfo.plan.scanned_emails <= dic.CONSTANTS.freePlanScansLimit))">
		<!--analysis accordion section-->
		<div class="tp-analysis-accordion flxClmn-start" style="align-items: center;"
			 [ngClass]="{'active' : !actionToConfirm}"
			 [ngStyle]="{'max-height' : !activeAccordionTab ? 'calc(47px*3)' : 'calc(100vh - (47px*2))'}">
			<!--Message Analysis Data Card-->
			<div class="accordion-item flxClmn" [ngClass]="{'is-active' : activeAccordionTab === accordionTabs.messageAnalysis}">
				<div class="card-header flxRow-between" style="align-items: center;" (click)="activeAccordionTab = accordionTabs.messageAnalysis">
					<span class="card-title flxRow" [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.headers.status).color === 'darkred' ? 'var(--danger)' : ''}">
						<span>{{'inboundShield.headers.messageAnalysis' | translate}}</span>
						<i class="fa fa-sort-down" style="margin-left: 8px; opacity: 0.65;" *ngIf="activeAccordionTab !== accordionTabs.messageAnalysis"></i>
					</span>
					<div class="flxRow hSpace1">
						<i class="fa hvr-opacity" style="font-size: 1rem;"
						   [ngClass]="setInboundStatusStyles(threatProtection.headers.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(threatProtection.headers.status).icon"
						   [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.headers.status).displayColor}"
						   [tooltip]="(threatProtection.headers.message | translate)"></i>
					</div>
				</div>
				<div class="card-content" style="flex: 1 0; overflow: auto; border: solid 1px var(--gray2); border-left: none; border-right: none;">
					<div class="flxClmn vSpace05" style="padding: 15px 8px">
						<span style="text-decoration: underline;">{{'inboundShield.headers.senderIdentities' | translate}}</span>
						<table class="simpleDataTable w100 message-analysis-table middle" style="table-layout: fixed;" *ngIf="threatProtection.headers && threatProtection.headers.from">
							<tr>
								<th style="width: 92px; padding: 0;"></th>
								<th style="padding: 0;"></th>
								<th style="width: 23px; padding: 0;"></th>
							</tr>
							<tr *ngIf="threatProtection.headers.from.senderMail && threatProtection.headers.from.senderMail !== threatProtection.headers.from.senderMailFrom">
								<td>
									<span><b>{{'inboundShield.headers.appearsAs' | translate}}</b></span>
								</td>
								<td>
									<span style="padding: 0; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; display: block">{{threatProtection.headers.from.senderMail}}</span>
								</td>
								<td>
									<i [tooltip]="('inboundShield.headers.senderAddressTooltip' | translate)" class="fas fa-check" ></i>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.from.senderMailFromFlag" [tooltip]="('inboundShield.headers.mailFrom' | translate)" title-direction="right" class="fas fa-check" ></i>
									<a *ngIf="threatProtection.headers.from.senderMailFromFlag" [tooltip]="('inboundShield.headers.mailFrom' | translate)" title-direction="right"><i class="fas fa-times-circle"></i></a>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.from.senderMailFrom">
								<td>
									<span><b>{{'inboundShield.headers.sender' | translate}}</b></span>
								</td>
								<td>
									<span style="padding: 0; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; display: block">{{threatProtection.headers.from.senderMailFrom}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.from.senderMailFromFlag" [tooltip]="('inboundShield.headers.mailFrom' | translate)" title-direction="right" class="fas fa-check" ></i>
									<a *ngIf="threatProtection.headers.from.senderMailFromFlag" [tooltip]="('inboundShield.headers.mailFromTooltip' | translate)" title-direction="right"><i class="fas fa-times-circle"></i></a>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers && threatProtection.headers.relationshipLevel">
								<td><b>{{'inboundShield.headers.relationship' | translate}}</b></td>
								<td>
									<div class="flxRow hSpace1">
										<div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
											<div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
												 [ngStyle]="{ 'width': (threatProtection.headers.relationshipLevel && threatProtection.headers.relationshipLevel.total ? (threatProtection.headers.relationshipLevel.total * 0.95 + 5)  : 5) + '%', 'background-color': threatProtection.headers.relationshipLevel && threatProtection.headers.relationshipLevel.total >= 70 ? 'var(--success)' : threatProtection.headers.relationshipLevel && threatProtection.headers.relationshipLevel.total >= 50 ? 'var(--warning)' : 'var(--danger)'}"></div>
										</div>
										<span>{{threatProtection.headers.relationshipLevel && threatProtection.headers.relationshipLevel.total || 0}}%</span>
									</div>
								</td>
								<td>
									<i style="font-weight: 400;" class="fas fa-question-circle checkmark" [tooltip]="('inboundShield.headers.relationshipTooltip' | translate)"></i>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.from.replyTo">
								<td>
									<span><b>{{'inboundShield.headers.replyTo' | translate}}</b></span>
								</td>
								<td>
									<span style="padding: 0; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; display: block">{{threatProtection.headers.from.replyTo}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.from.replyToFlag" [tooltip]="('inboundShield.headers.replyTo' | translate)" title-direction="right" class="fas fa-check"></i>
									<a *ngIf="threatProtection.headers.from.replyToFlag" [tooltip]="('inboundShield.headers.replyTo' | translate)" title-direction="right"><i class="fas fa-times-circle"></i></a>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.from.returnPath">
								<td>
									<span><b>{{'inboundShield.headers.returnPath' | translate}}</b></span>
								</td>
								<td>
									<span style="padding: 0; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; display: block">{{threatProtection.headers.from.returnPath}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.from.returnPathFlag" [tooltip]="('inboundShield.headers.returnPath' | translate)" title-direction="right" class="fas fa-check"></i>
									<a *ngIf="threatProtection.headers.from.returnPathFlag" [tooltip]="('inboundShield.headers.returnPath' | translate)" title-direction="right"><i class="fas fa-times-circle"></i></a>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.from.messageID">
								<td>
									<span><b>{{'inboundShield.headers.messageId' | translate}}</b></span>
								</td>
								<td>
									<span style="word-break: break-all; display: block;">{{threatProtection.headers.from.messageID}}</span>
									<!--("display:block" for IE compatibility)-->
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.from.messageIDFlag" [tooltip]="('inboundShield.headers.messageId' | translate)" title-direction="right" class="fas fa-check"></i>
									<a *ngIf="threatProtection.headers.from.messageIDFlag" [tooltip]="('inboundShield.headers.messageId' | translate)" title-direction="right"><i class="fas fa-times-circle"></i></a>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.from.sendingMTA.domain">
								<td>
									<span><b>{{'inboundShield.headers.mtaDomain' | translate}}</b></span>
								</td>
								<td>
									<span style="padding: 0; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; display: block">{{threatProtection.headers.from.sendingMTAFull || threatProtection.headers.from.sendingMTA.domain}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.from.senderEmailServerFlag" [tooltip]="('inboundShield.headers.mtaDomain' | translate)" title-direction="right" class="fas fa-check"></i>
									<a *ngIf="threatProtection.headers.from.senderEmailServerFlag" [tooltip]="('inboundShield.headers.mtaDomain' | translate)" title-direction="right"><i class="fas fa-times-circle"></i></a>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.from.ipAddresses && threatProtection.headers.from.ipAddresses.length">
								<td>
									<span><b>{{'inboundShield.headers.ipAddresses' | translate}}</b></span>
								</td>
								<td>
									<span>{{threatProtection.headers.from.ipAddresses.join(', ')}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.from.senderIPAddressFlag" [tooltip]="('inboundShield.headers.ipAddresses' | translate)" title-direction="right" class="fas fa-check"></i>
									<a *ngIf="threatProtection.headers.from.senderIPAddressFlag" [tooltip]="('inboundShield.headers.ipAddresses' | translate)" title-direction="right"><i class="fas fa-times-circle"></i></a>
								</td>
							</tr>
						</table>
					</div>
					<div class="flxClmn vSpace05" style="padding: 15px 8px">
						<span style="text-decoration: underline;">{{'inboundShield.headers.aiConfidence' | translate}}</span>
						<table class="simpleDataTable w100 message-analysis-table middle" style="table-layout: fixed;" *ngIf="threatProtection.headers && threatProtection.headers.AIresults">
							<tr>
								<th style="width: 92px; padding: 0;"></th>
								<th style="padding: 0;"></th>
								<th style="width: 23px; padding: 0;"></th>
							</tr>
							<tr>
								<td>
									<span><b>{{'inboundShield.headers.aiDecision' | translate}}</b></span>
								</td>
								<td>
									<span style="text-transform: capitalize;" *ngIf="!threatProtection.headers.AIresults.skip">
										{{!threatProtection.headers.AIresults.decision ? ('inboundShield.headers.aiSafeResult' | translate) : threatProtection.headers.AIresults.decision === 'threat' ? ('inboundShield.headers.aiBecResult' | translate) : (threatProtection.headers.AIresults.decision | translate)}}
									</span>
									<span style="text-transform: capitalize;" *ngIf="threatProtection.headers.AIresults.skip">
										{{threatProtection.headers.AIresults.decision ? threatProtection.headers.AIresults.decision : ('inboundShield.headers.aiInternal' | translate)}}
									</span>
								</td>
								<td>
									<i style="font-weight: 400;" class="fas fa-question-circle checkmark" [tooltip]="('inboundShield.headers.aiDecisionTooltip' | translate)"></i>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.AIresults">
								<td>
									<span><b>- {{'inboundShield.headers.aiBEC' | translate}}</b></span>
								</td>
								<td>
									<div class="flxRow hSpace1" *ngIf="threatProtection.headers.AIresults.threat_body_conf">
										<div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
											<div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%; min-width: 5%;"
												 [ngStyle]="{ 'width': threatProtection.headers.AIresults.threat_body_conf > 1 ? 100 : (threatProtection.headers.AIresults.threat_body_conf * 100) + '%', 'background-color' : threatProtection.headers.AIresults.threat_body_conf <= 0.5 ? 'var(--success)' : threatProtection.headers.AIresults.threat_body_conf <= 0.7 ? 'var(--warning)' : 'var(--danger)'}"></div>
										</div>
										<span style="flex-basis: 32px;">{{threatProtection.headers.AIresults.threat_body_conf > 1 ? 100 : (threatProtection.headers.AIresults.threat_body_conf * 100) | number: 0}}%</span>
									</div>
									<span *ngIf="!threatProtection.headers.AIresults.threat_body_conf">-</span>
								</td>
								<td>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.AIresults">
								<td>
									<span><b>- {{'inboundShield.headers.aiSpam' | translate}}</b></span>
								</td>
								<td>
									<div class="flxRow hSpace1" *ngIf="threatProtection.headers.AIresults.spam_body_conf">
										<div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
											<div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%; min-width: 5%;"
												 [ngStyle]="{ 'width': threatProtection.headers.AIresults.spam_body_conf > 1 ? 100 : (threatProtection.headers.AIresults.spam_body_conf * 100) + '%', 'background-color' : threatProtection.headers.AIresults.spam_body_conf <= 0.5 ? 'var(--success)' : threatProtection.headers.AIresults.spam_body_conf <= 0.7 ? 'var(--warning)' : 'var(--danger)'}"></div>
										</div>
										<span style="flex-basis: 32px;">{{threatProtection.headers.AIresults.spam_body_conf > 1 ? 100 : (threatProtection.headers.AIresults.spam_body_conf * 100) | number: 0}}%</span>
									</div>
									<span *ngIf="!threatProtection.headers.AIresults.spam_body_conf">-</span>
								</td>
								<td>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.AIresults">
								<td>
									<span><b>- {{'inboundShield.headers.aiGraymail' | translate}}</b></span>
								</td>
								<td>
									<div class="flxRow hSpace1" *ngIf="threatProtection.headers.AIresults.graymail_body_conf">
										<div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
											<div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%; min-width: 5%;"
												 [ngStyle]="{ 'width': threatProtection.headers.AIresults.graymail_body_conf > 1 ? 100 : (threatProtection.headers.AIresults.graymail_body_conf * 100) + '%', 'background-color' : threatProtection.headers.AIresults.graymail_body_conf <= 0.5 ? 'var(--success)' : threatProtection.headers.AIresults.graymail_body_conf <= 0.7 ? 'var(--warning)' : 'var(--danger)'}"></div>
										</div>
										<span style="flex-basis: 32px;">{{threatProtection.headers.AIresults.graymail_body_conf > 1 ? 100 : (threatProtection.headers.AIresults.graymail_body_conf * 100) | number: 0}}%</span>
									</div>
									<span *ngIf="!threatProtection.headers.AIresults.graymail_body_conf">-</span>
								</td>
								<td>
								</td>
							</tr>
						</table>
					</div>
					<div class="flxClmn vSpace05" style="padding: 15px 8px">
						<span style="text-decoration: underline;">{{'inboundShield.headers.authStandards' | translate}}</span>
						<table class="simpleDataTable w100 message-analysis-table middle" style="table-layout: fixed;" *ngIf="threatProtection.headers">
							<tr>
								<th style="width: 92px; padding: 0;"></th>
								<th style="padding: 0;"></th>
								<th style="width: 23px; padding: 0;"></th>
							</tr>
							<tr *ngIf="threatProtection.headers.spf">
								<td>
									<span><b>SPF</b></span>
								</td>
								<td>
									<span style="padding: 0;">{{threatProtection.headers.spf.message ? (threatProtection.headers.spf.message | translate) : (threatProtection.headers.from.isInternal ? ('inboundShield.headers.spfInternal' | translate) : ('inboundShield.headers.noSpf' | translate))}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.spf.message" class="fas" [ngClass]="threatProtection.headers.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
									<i class="fas" *ngIf="threatProtection.headers.spf.message" [ngClass]="threatProtection.headers.spf.score === 2 ? 'fa-exclamation-circle' : threatProtection.headers.spf.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.arc">
								<td>
									<span><b>ARC</b></span>
								</td>
								<td>
									<span style="padding: 0;">{{threatProtection.headers.spf.message ? (threatProtection.headers.arc.message | translate) : (threatProtection.headers.from.isInternal ? ('inboundShield.headers.arcInternal' | translate) : ('inboundShield.headers.noArc' | translate))}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.arc.message" class="fas" [ngClass]="threatProtection.headers.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
									<i class="fas" *ngIf="threatProtection.headers.arc.message" [ngClass]="threatProtection.headers.arc.score === 2 ? 'fa-exclamation-circle' : threatProtection.headers.arc.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.dkim">
								<td><b>DKIM</b></td>
								<td>
									<span style="padding: 0;">{{threatProtection.headers.dkim.message ? (threatProtection.headers.dkim.message | translate): (threatProtection.headers.from.isInternal ? ('inboundShield.headers.dkimInternal' | translate) : ('inboundShield.headers.noDkim' | translate))}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.dkim.message" class="fas" [ngClass]="threatProtection.headers.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
									<i class="fas" *ngIf="threatProtection.headers.dkim.message" [ngClass]="threatProtection.headers.dkim.score === 2 ? 'fa-exclamation-circle' : threatProtection.headers.dkim.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.dmarc">
								<td><b>DMARC</b></td>
								<td>
									<span style="padding: 0;">{{threatProtection.headers.dmarc.message ? (threatProtection.headers.dmarc.message | translate) : (threatProtection.headers.from.isInternal ? ('inboundShield.headers.dmarcInternal' | translate) : ('inboundShield.headers.noDmarc' | translate))}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.dmarc.message" class="fas" [ngClass]="threatProtection.headers.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
									<i class="fas" *ngIf="threatProtection.headers.dmarc.message" [ngClass]="threatProtection.headers.dmarc.score === 2 ? 'fa-exclamation-circle' : threatProtection.headers.dmarc.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.compauth">
								<td><b>CompAuth</b></td>
								<td>
									<span style="padding: 0;">{{threatProtection.headers.compauth.message ? (threatProtection.headers.compauth.message | translate) : (threatProtection.headers.from.isInternal ? ('inboundShield.headers.compAuthInternal' | translate) : ('inboundShield.headers.noCompAuth' | translate))}}</span>
								</td>
								<td>
									<i *ngIf="!threatProtection.headers.compauth.message" class="fas" [ngClass]="threatProtection.headers.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
									<i class="fas" *ngIf="threatProtection.headers.compauth.message" [ngClass]="threatProtection.headers.compauth.score === 2 ? 'fa-exclamation-circle' : threatProtection.headers.compauth.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
								</td>
							</tr>
							<tr *ngIf="threatProtection.headers.from && threatProtection.headers.from.tls && ((threatProtection.headers.from.tls.message | translate) || (threatProtection.headers.from.tls.recipient.message | translate) || (threatProtection.headers.from.tls.external.message | translate) || (threatProtection.headers.from.tls.sender.message | translate))">
								<td>
									<span><b>TLS</b></span>
								</td>
								<td>
									<div class="flxClmn vSpace05">
										<span>{{threatProtection.headers.from.tls.message}}</span>
										<span *ngIf="threatProtection.headers.from.tls.recipient.message">{{threatProtection.headers.from.tls.recipient.message | translate}}</span>
										<span *ngIf="threatProtection.headers.from.tls.external.message">{{threatProtection.headers.from.tls.external.message | translate}}</span>
										<span *ngIf="threatProtection.headers.from.tls.sender.message">{{threatProtection.headers.from.tls.sender.message | translate}}</span>
									</div>
								</td>
								<td>
									<div class="flxClmn" style="align-items: flex-start">
										<i class="fas" [ngClass]="threatProtection.headers.from.tls.status === false ? 'fa-times-circle' : 'fa-check'"></i>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>

			<!--Links Analysis-->
			<div class="accordion-item flxClmn" style="flex: 1 0;" [ngClass]="{'is-active' : activeAccordionTab === accordionTabs.linksAnalysis}">
				<div class="card-header flxRow-between" style="align-items: center;" (click)="activeAccordionTab = accordionTabs.linksAnalysis">
					<span class="card-title flxRow hSpace05"
						  [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.url.status).color === 'darkred' ? 'var(--danger)' : ''}">
						<span>{{'inboundShield.links.linksTitle' | translate}}</span>
						<span>{{threatProtection.url && threatProtection.url.list && threatProtection.url.list.length ? '(' + threatProtection.url.list.length + ')' : ''}}</span>
						<i class="fa fa-sort-down" style="margin-left: 8px; opacity: 0.65;" *ngIf="activeAccordionTab !== accordionTabs.linksAnalysis"></i>
					</span>
					<div class="flxRow hSpace1">
						<i class="fa hvr-opacity" style="font-size: 1rem;"
						   [tooltip]="(threatProtection.url.status | translate)"
						   [ngClass]="setInboundStatusStyles(threatProtection.url.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(threatProtection.url.status).icon"
						   [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.url.status).displayColor}">
						</i>
					</div>
				</div>
				<div class="card-content" style="align-items: center; flex: 1 0; padding: 10px; overflow: auto; border: solid 1px var(--gray2); border-left: none; border-right: none; position: relative;">
					<div class="w100" style="flex: 1 0;" *ngIf="threatProtection.url.list && threatProtection.url.list.length">
						<table class="simpleDataTable w100 message-analysis-table middle" style="table-layout: fixed;">
							<tr>
								<th style="padding: 0;"></th>
							</tr>
							<tr *ngFor="let url of threatProtection.url.list; let i = index">
								<td>
									<div class="flxClmn vSpace1 ellipsis" style="padding: 10px 0;">
										<div class="flxRow" style="align-items: center;">
											<div style="flex: 1 0; padding-right: 15px;" class="ellipsis flxRow hSpace05">
												<b style="flex-basis: 40px">{{'inboundShield.links.name' | translate}}:</b>
												<span class="ellipsis" style="flex: 1 0;">{{url.name ? url.name : url.domain ? url.domain : url.url}}</span>
											</div>
											<div class="flxRow" style="align-items: center;">
												<i class="fas" *ngIf="!url.category" [ngClass]="setInboundStatusStyles(url.status).icon === 'fa-check-circle' ? 'fa-check' : setInboundStatusStyles(url.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(url.status).icon"
												   [ngStyle]="{'color': setInboundStatusStyles(url.status).displayColor}" title-direction="right" [tooltip]="(url.status | translate)"></i>
												<div class="flxRow hSpace05 t-bg-primary" style="width: fit-content; border: solid 2px; border-radius: 3px; padding: 2px 7px; align-items: center;"
													 *ngIf="url.category"
													 [ngStyle]="{'border-color': setInboundStatusStyles(url.status).displayColor,
                                                                                    'color': setInboundStatusStyles(url.status).displayColor}">
													<i class="fa" style="color: inherit; font-size: 10px;" [ngClass]="setInboundStatusStyles(url.status).icon"></i>
													<span style="color: inherit; font-size: 11px;">{{url.category | translate}}</span>
												</div>
											</div>
										</div>
										<div class="flxRow" style="align-items: flex-end;" *ngIf="url.original_url || url.url">
											<div class="flxRow hSpace05 ellipsis" style="padding-right: 45px; font-weight: 300; flex: 1 0;">
												<b style="flex-basis: 40px;">{{'inboundShield.links.url' | translate}}:</b>
												<span class="ellipsis" style="flex: 1 0;">{{url.original_url || url.url}}</span>
											</div>
											<a ngxClipboard [cbContent]="url.original_url || url.url" (click)="$event.stopPropagation();">
												<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="('inboundShield.links.urlTooltip' | translate)"></i>
											</a>
										</div>
										<div class="flxRow" style="align-items: flex-end;" *ngIf="url.url && url.original_url && url.redirect_url && url.original_url !== url.redirect_url">
											<div class="flxRow hSpace05 ellipsis" style="padding-right: 45px; font-weight: 300; flex: 1 0;">
												<b style="flex-basis: 40px;">{{'inboundShield.links.redirect' | translate}}:</b>
												<span class="ellipsis" style="flex: 1 0;">{{url.redirect_url}}</span>
											</div>
											<a ngxClipboard [cbContent]="url.redirect_url" (click)="$event.stopPropagation();">
												<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="('inboundShield.links.redirectTooltip' | translate)"></i>
											</a>
										</div>
										<div class="flxRow" style="align-items: flex-end;" *ngIf="url.malware_type">
																<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" >
																	<b>{{'inboundShield.links.malwareType' | translate}}</b>: <span>{{url.malware_type}}</span>
																</span>
											<a ngxClipboard [cbContent]="url.malware_type" (click)="$event.stopPropagation();">
												<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="('inboundShield.links.malwareTypeTooltip' | translate)"></i>
											</a>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div *ngIf="!threatProtection.url.list || !threatProtection.url.list.length" style="position: absolute; text-align: center; top: 50%; left: 50%; transform: translate(-50%,-50%);">
						<span *ngIf="threatProtection.url.status !== dic.CONSTANTS.threatProtection.status.analyzing && threatProtection.url.status !== dic.CONSTANTS.threatProtection.status.pending; else loadingLinks">
							{{'inboundShield.links.noLinksInEmail' | translate}}
						</span>
						<ng-template #loadingLinks>
							<span class="loading-dots">{{'inboundShield.links.analyzingLinks' | translate}}</span>
						</ng-template>
					</div>
					<span></span>
				</div>
			</div>

			<!--Attachments Analysis-->
			<div class="accordion-item flxClmn" style="flex: 1 0;" [ngClass]="{'is-active' : activeAccordionTab === accordionTabs.attachmentsAnalysis}">
				<div class="card-header flxRow-between" style="align-items: center;" (click)="activeAccordionTab = accordionTabs.attachmentsAnalysis">
					<span class="card-title flxRow hSpace05" [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.file.status).color === 'darkred' ? 'var(--danger)' : ''}">
						<span>{{'inboundShield.attachments.attachmentsTitle' | translate}}</span>
						<span>{{threatProtection.file && threatProtection.file.list && threatProtection.file.list.length ? '(' + threatProtection.file.list.length + ')' : ''}}</span>
						<i class="fa fa-sort-down" style="margin-left: 8px; opacity: 0.65;" *ngIf="activeAccordionTab !== accordionTabs.attachmentsAnalysis"></i>
					</span>
					<div class="flxRow hSpace1">
						<i class="fa hvr-opacity" style="font-size: 1rem;"
						   [tooltip]="(threatProtection.file.status | translate)"
						   [ngClass]="setInboundStatusStyles(threatProtection.file.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(threatProtection.file.status).icon"
						   [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.file.status).displayColor}">
						</i>
					</div>
				</div>
				<div class="card-content" style="align-items: center; flex: 1 0; padding: 10px; overflow: auto; border: solid 1px var(--gray2); border-left: none; border-right: none; position: relative;">
					<div style="flex: 1 0;" *ngIf="threatProtection.file.list && threatProtection.file.list.length">
						<table class="w100 simpleDataTable message-analysis-table middle" style="table-layout: fixed;">
							<tr>
								<th style="padding: 0;"></th>
							</tr>
							<tr *ngFor="let file of threatProtection.file.list; let i = index">
								<td>
									<div class="flxClmn vSpace1 ellipsis" style="padding: 10px 0;">
										<div class="flxRow" style="align-items: center;">
											<span style="font-weight: 500; flex: 1 0; padding-right: 15px;" class="ellipsis">
												{{file.name}}
											</span>
											<div class="flxRow" style="align-items: center;">
												<i class="fas" *ngIf="!file.category" [ngClass]="setInboundStatusStyles(file.status).icon === 'fa-check-circle' ? 'fa-check' : setInboundStatusStyles(file.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(file.status).icon"
												   [ngStyle]="{'color': setInboundStatusStyles(file.status).displayColor}" title-direction="right" [tooltip]="(file.status | translate)"></i>
												<div class="flxRow hSpace05 t-bg-primary" style="width: fit-content; border: solid 2px; border-radius: 3px; padding: 2px 7px; align-items: center;"
													 *ngIf="file.category"
													 [ngStyle]="{'border-color': setInboundStatusStyles(file.status).displayColor,
                                                                                    'color': setInboundStatusStyles(file.status).displayColor}">
													<i class="fa" style="color: inherit; font-size: 10px;" [ngClass]="setInboundStatusStyles(file.status).icon"></i>
													<span style="color: inherit; font-size: 11px;">{{file.category | translate}}</span>
												</div>
											</div>
										</div>
										<div class="flxRow" style="align-items: flex-end;">
											<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" *ngIf="file.type">
												<b>{{'inboundShield.attachments.type' | translate}}</b>: {{file.type}} ({{file.name && file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase()}})
											</span>
										</div>
										<div class="flxRow" style="align-items: flex-end;">
											<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" *ngIf="file.hash">
												<b>{{'inboundShield.attachments.hash' | translate}}</b>: {{file.hash}}
											</span>
											<a ngxClipboard [cbContent]="file.hash" (click)="$event.stopPropagation();" *ngIf="file.hash">
												<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="('inboundShield.attachments.hashTooltip' | translate)"></i>
											</a>
										</div>
										<div class="flxRow" style="align-items: flex-end;" *ngIf="file.malware_type">
											<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" >
												<b>{{'inboundShield.attachments.malwareType' | translate}}</b>: <span>{{file.malware_type}}</span>
											</span>
											<a ngxClipboard [cbContent]="file.malware_type" (click)="$event.stopPropagation();">
												<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="('inboundShield.attachments.malwareTypeTooltip' | translate)"></i>
											</a>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div *ngIf="!threatProtection.file.list || !threatProtection.file.list.length" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);">
						<span *ngIf="threatProtection.file.status !== dic.CONSTANTS.threatProtection.status.analyzing && threatProtection.file.status !== dic.CONSTANTS.threatProtection.status.pending; else loadingLinks">
							{{'inboundShield.attachments.noAttachmentsInEmail' | translate}}
						</span>
						<ng-template #loadingLinks>
							<span class="loading-dots">{{'inboundShield.attachments.analyzingAttachments' | translate}}</span>
						</ng-template>
					</div>
				</div>
			</div>
		</div>

		<!--confirm action dialog section-->
		<div class="flxClmn-center confirm-action-section" style="flex: 1 0; overflow: auto;"
			 [ngClass]="{'active' : actionToConfirm}">
			<!--Report email-->
			<div class="flxClmn t-bg-primary" style="overflow: auto; border: solid 1px #e3e4e4; border-left: none; border-right: none; font-size: 12px; line-height: 1.4; position: relative;"
				 *ngIf="actionToConfirm === dic.CONSTANTS.recipientTpActions.report">
				<div class="flxClmn" style="flex: 1 0 auto;" *ngIf="!reportSent">
					<div class="t-bg-primary" style="padding: 15px 20px 0; position: sticky; top: 0; z-index: 20;">
						<div class="flxRow-between" style="align-items: center; font-size: 13px;">
							<span style="font-weight: bold;">{{'inboundShield.report.reportThisEmail' | translate}}</span>
							<a class="t-color-primary" (click)="actionToConfirm = null; notificationService.clear();" [ngClass]="{'disabled': sendReportInProcess}">
								<i class="fa fa-times"></i>
							</a>
						</div>
						<hr style="width: 100%; margin: 15px 0 0; opacity: 0.3;">
					</div>
					<div class="flxClmn" style="flex: 1 0 auto; overflow: auto; padding: 5px 20px 15px; z-index: 15;">
						<ul style="list-style-type:none; padding: 0;" class="vSpace2">
							<li *ngFor="let option of reportOptions | keyvalue: asIsOrder; let index = index;"
								[hidden]="!option.value.show">
								<div class="md-checkbox" [ngClass]="{'disabled': sendReportInProcess}">
									<input id="report{{index}}" type="checkbox" name="reportTrustCheckbox" style="height: 0;"
										   [(ngModel)]="option.value.enabled">
									<label for="report{{index}}" class="t-color-primary">
										<span>{{option.value.label | translate}}</span>
									</label>
                                    <i style="font-weight: 400;top: 0;" class="fas fa-question-circle" [tooltip]="option.value.tooltip | translate"></i>
                                </div>
								<textarea style="margin: 7px 0 0 20px; height: 55px; resize: none; visibility: hidden; opacity: 0; transition: all 0.2s;"
										  class="t-bg-primary t-color-primary"
										  *ngIf="option.key === 'emailSuspicious'"
										  [ngStyle]="option.value.enabled ? {'opacity' : '1' , 'visibility' : 'visible'} : {'opacity' : '0' , 'visibility' : 'hidden'}"
										  placeholder="{{'inboundShield.report.suspiciousPlaceholder' | translate}}"
										  [(ngModel)]="option.value.reason">
								</textarea>
								<app-dropdown id="reportLinksSelect" class="animated fadeIn"  style="position: relative; z-index: 50; margin-left: 20px; display: block; margin-top: 7px;"
											  *ngIf="option.key === 'maliciousLinks' && option.value.enabled && threatProtection.url?.list?.length"
											  [error]="reportOptions.maliciousLinks.error"
											  [text]="!_.filter(threatProtection.url.list, 'selected').length ? ('general.select' | translate | titlecase) : _.filter(threatProtection.url.list, 'selected').length === threatProtection.url?.list?.length ? ('inboundShield.report.allLinks' | translate) : _.filter(threatProtection.url.list, 'selected').length + ' ' + ('inboundShield.report.link' | translate) + (_.filter(threatProtection.url.list, 'selected').length > 1 ? 's' : '')">
									<ul>
										<virtual-scroller class="f-1-0" style="max-height: 180px;"
														  [style.height]="threatProtection.url.list.length * 56 + 60 + 'px'"
														  #reportLinksListScroll
														  [enableUnequalChildrenSizes]="true"
														  [items]="threatProtection.url.list">
											<li (click)="selectAllLinksReport(); $event.stopPropagation()">
												<div class="gap-1 md-checkbox">
													<input type="checkbox" onclick="return false;" [checked]="_.every(threatProtection.url.list, 'selected')"/>
													<label><span style="font-size: 12px;">{{'inboundShield.report.allLinks' | translate}}</span></label>
												</div>
											</li>
											<li class="static-item pt-2 pb-3" style="min-height: 0; background: var(--t-bg-primary) !important;" (click)="$event.stopPropagation()">
												<hr class="m-0">
											</li>
											<li *ngFor="let url of reportLinksListScroll.viewPortItems "
												(click)="reportOptions.maliciousLinks.error = false; url.selected = !url.selected; $event.stopPropagation();"
												[id]="url.name || url.domain || url.url">
												<div class="gap-1 md-checkbox">
													<input type="checkbox" onclick="return false;" [checked]="url.selected"/>
													<label class="t-color-primary flex-column gap-1" style="font-size: 12px;">
														<span class="fw-500 ellipsis" style="font-size: 12px;" *ngIf="url.name || url.domain">{{url.name || url.domain}}</span>
														<span class="text-muted ellipsis" style="font-size: 12px;" *ngIf="(url.name && url.name !== url.url) || (!url.name && url.domain !== url.url) ">{{url.url}}</span>
													</label>
												</div>
											</li>
										</virtual-scroller>
									</ul>
								</app-dropdown>
							</li>
						</ul>
						<div class="flxClmn vSpace1" style="margin-top: 5px;"
							 *ngIf="userInfo.user_role === dic.CONSTANTS.userRole.admin || userInfo.user_role === dic.CONSTANTS.userRole.subAdmin">
							<div>{{'inboundShield.report.applyTo' | translate}}:</div>
							<div class="flxRow hSpace2" style="padding-left: 10px;">
								<label class="radio-container">{{'inboundShield.report.personalList' | translate}}
									<input type="radio" name="reportToList1"
										   [value]="dic.CONSTANTS.reportToList.personal"
										   [(ngModel)]="reportTo"
										   [checked]="reportTo === dic.CONSTANTS.reportToList.personal">
									<span class="checkmark"></span>
									<i style="font-weight: 400;top: 0;" class="fas fa-question-circle" [tooltip]="'inboundShield.report.personalListTooltip' | translate"></i>
								</label>
								<label class="radio-container">{{'inboundShield.report.globalList' | translate}}
									<input type="radio" name="reportToList1"
										   [value]="dic.CONSTANTS.reportToList.plan"
										   [(ngModel)]="reportTo"
										   [checked]="reportTo === dic.CONSTANTS.reportToList.plan">
									<span class="checkmark"></span>
									<i style="font-weight: 400;top: 0;" class="fas fa-question-circle" [tooltip]="('inboundShield.report.globalListTooltip' | translate)"></i>
								</label>
							</div>
						</div>
						<div class="flxClmn vSpace05" style="align-items: center; margin: 10px 0 0;">
							<button class="btn-rect" style="width: 150px; height: 27px;" (click)="notificationService.clear(); sendReport();" [ngClass]="{'disabled': sendReportInProcess  || isSendReportDisabled()}">
								<span *ngIf="!sendReportInProcess">{{'inboundShield.report.sendReport' | translate}}</span>
								<i class="fa fa-spinner fa-spin" *ngIf="sendReportInProcess"></i>
							</button>
							<button class="btn-rect silent"
									(click)="actionToConfirm = null; notificationService.clear();"
									[ngClass]="{'disabled': sendReportInProcess}">
								{{'general.cancel' | translate}}
							</button>
						</div>
						<div class="flxClmn vSpace05" style="margin-top: 5px;">
							<a href="{{inboundConfLink}}" class="hSpace05" style="color: royalblue; text-decoration: underline;" target="_blank">
								<i class="fa fa-external-link-alt"></i>
								<span>{{'inboundShield.report.manageLists' | translate}}</span>
							</a>
							<span style="color: red;" *ngIf="reportError">
							<div>{{reportError}}</div>
						</span>
						</div>
					</div>
				</div>
				<div class="flxClmn-center vSpace2 animated fadeIn report-sent-msg" *ngIf="reportSent">
					<span class="t-color-success">{{'inboundShield.report.reportSentMsg' | translate}}</span>
					<span style="width: 100%; flex: 1 0 auto;">{{'inboundShield.report.reportSentMsg2' | translate}}</span>
					<span style="width: 100%; flex: 1 0 auto;">{{'inboundShield.report.reportSentMsg3' | translate}}</span>
					<button class="btn-rect" style="align-self: center; min-width: 75px;" (click)="closePostReportMessage();">OK</button>
				</div>
			</div>

			<!--Trust email-->
			<div class="flxClmn t-bg-primary" style="overflow: auto; border: solid 1px #e3e4e4; border-left: none; border-right: none; font-size: 12px; line-height: 1.4; position: relative; overflow: auto;"
				 *ngIf="actionToConfirm === dic.CONSTANTS.recipientTpActions.trust">
				<div class="flxClmn" style="flex: 1 0 auto;" *ngIf="!reportSent">
					<div class="t-bg-primary" style="padding: 15px 20px 0; position: sticky; top: 0; z-index: 20;">
						<div class="flxRow-between" style="align-items: center; font-size: 13px;">
							<span style="font-weight: bold;">{{'inboundShield.trust.trustThisEmail' | translate}}</span>
							<a (click)="actionToConfirm = null; notificationService.clear();" [ngClass]="{'disabled': sendReportInProcess}">
								<i class="fa fa-times"></i>
							</a>
						</div>
						<hr style="width: 100%; margin: 15px 0 0; opacity: 0.3;">
					</div>
					<div class="flxClmn" style="flex: 1 0 auto; overflow: auto; padding: 15px 20px 15px;">
						<span>{{'inboundShield.trust.trustEmailDesc' | translate}}</span>
						<ul style="padding-left: 20px; margin: 5px 0;">
							<li>
								<span>{{'inboundShield.trust.trustActionSender' | translate}}</span>
							</li>
							<li>
								<span>{{'inboundShield.trust.trustActionLinks' | translate}}</span>
							</li>
							<li>
								<span>{{'inboundShield.trust.trustActionsFiles' | translate}}</span>
							</li>
						</ul>
						<div class="flxClmn vSpace1" style="margin-top: 20px;"
							 *ngIf="userInfo.user_role === dic.CONSTANTS.userRole.admin || userInfo.user_role === dic.CONSTANTS.userRole.subAdmin">
							<div>{{'inboundShield.report.applyTo' | translate}}:</div>
							<div class="flxRow hSpace2" style="padding-left: 10px;">
								<label class="radio-container">{{'inboundShield.report.personalList' | translate}}
									<input type="radio" name="reportToList1"
										   [value]="dic.CONSTANTS.reportToList.personal"
										   [(ngModel)]="reportTo"
										   [checked]="reportTo === dic.CONSTANTS.reportToList.personal">
									<span class="checkmark"></span>
									<i style="font-weight: 400;top: 0;" class="fas fa-question-circle" [tooltip]="('inboundShield.report.personalListTooltip' | translate)"></i>
								</label>
								<label class="radio-container">{{'inboundShield.report.globalList' | translate}}
									<input type="radio" name="reportToList1"
										   [value]="dic.CONSTANTS.reportToList.plan"
										   [(ngModel)]="reportTo"
										   [checked]="reportTo === dic.CONSTANTS.reportToList.plan">
									<span class="checkmark"></span>
									<i style="font-weight: 400;top: 0;" class="fas fa-question-circle" [tooltip]="('inboundShield.report.globalListTooltip' | translate)"></i>
								</label>
							</div>
						</div>
						<div class="flxRow-start hSpace05" style="margin-top: 15px;">
							<div class="md-checkbox" [ngClass]="{'disabled': sendReportInProcess}">
								<input id="reportTrustCheckbox" type="checkbox" name="reportTrustCheckbox" style="height: 0;"
									   [(ngModel)]="reportTrustifi">
								<label for="reportTrustCheckbox" class="t-color-primary">
									<span>{{'inboundShield.trust.reportToTrustifi' | translate}} <span style="font-size: 10px;">({{'inboundShield.trust.recommended' | translate}})</span></span>
								</label>
							</div>
							<i class="fa fa-question-circle" style="font-weight: 400;" [tooltip]="('inboundShield.trust.reportToTrustifiTooltip' | translate)"></i>
						</div>
						<div class="flxRow-center hSpace1" style=" align-items: center; margin: 20px 0 0; line-height: 1.2;">
							<i class="fa fa-exclamation-circle" style=" color: darkgoldenrod; font-size: 1rem; opacity: 0.85;"></i>
							<span style="color: darkgoldenrod; font-size: 11px;">{{'inboundShield.trust.trustEmailWarning' | translate}}</span></div>
						<div class="flxClmn vSpace05" style="align-items: center; margin: 15px 0 0;">
							<button class="btn-rect" style="width: 150px; min-height: 27px; height: auto;" (click)="notificationService.clear(); trustThisEmail();" [ngClass]="{'disabled': sendReportInProcess}">
								<span *ngIf="!sendReportInProcess">{{'inboundShield.trust.trustConfirm' | translate}}</span>
								<i class="fa fa-spinner fa-spin" *ngIf="sendReportInProcess"></i>
							</button>
							<button class="btn-rect silent"
									(click)="actionToConfirm = null; notificationService.clear();"
									[ngClass]="{'disabled': sendReportInProcess}">
								{{'general.cancel' | translate}}
							</button>
						</div>

						<div class="flxClmn vSpace05" style="margin-top: 15px;">
							<a href="{{inboundConfLink}}" class="hSpace05" style="color: royalblue; text-decoration: underline;" target="_blank">
								<i class="fa fa-external-link-alt"></i>
								<span>{{'inboundShield.report.manageLists' | translate}}</span>
							</a>
							<span style="color: red;" *ngIf="reportError">
							<div>{{reportError}}</div>
						</span>
						</div>
					</div>
				</div>
				<div class="flxClmn-center vSpace2 animated fadeIn report-sent-msg" *ngIf="reportSent">
					<span class="t-color-success">{{'inboundShield.report.reportSentMsg' | translate}}</span>
					<span style="width: 100%; flex: 1 0 auto;">{{'inboundShield.report.reportSentMsg2' | translate}}</span>
					<span style="width: 100%; flex: 1 0 auto;">{{'inboundShield.report.reportSentMsg3' | translate}}</span>
					<button class="btn-rect" style="align-self: center; min-width: 75px;" (click)="closePostReportMessage();">OK</button>
				</div>
			</div>
		</div>
	</div>

	<!--don't show analysis occasions:-->
	<div class="t-bg-secondary t-color-primary" *ngIf="!blockScan && userInfo && userInfo.plan && userInfo.plan.plan === dic.CONSTANTS.planType.free && userInfo.plan.scanned_emails > dic.CONSTANTS.freePlanScansLimit" style="padding: 5px 10px; font-size: 12px; min-height: 130px;">
		<h5>{{'inboundShield.noInboundShield.limitReached' | translate}}</h5>
		<hr style="border-width: 1px; border-color: black">
		<div style="margin-bottom: 20px;">{{'inboundShield.noInboundShield.proPlan' | translate}}</div>
		<a [style.color]="trustifiDefault.color" [href]="upgradePlanLink" target="_blank">{{'inboundShield.noInboundShield.upgradePlan' | translate}}</a>
	</div>
	<div *ngIf="blockScan" class="t-bg-secondary t-color-primary" style="padding: 5px 10px; font-size: 12px; min-height: 130px;">
		<h5>{{'inboundShield.noInboundShield.tpNotAvailable' | translate}}</h5>
		<hr style="border-width: 1px; border-color: black">
		<div style="margin-bottom: 20px;">{{'inboundShield.noInboundShield.tpNotAvailableDesc' | translate}}</div>
		<a [style.color]="trustifiDefault.color" href="https://www.trustifi.com/products/inbound-shield/" target="_blank">{{'inboundShield.noInboundShield.inboundShieldDoc' | translate}}</a>
	</div>

</div>
