import {Inject, Injectable } from '@angular/core';
import {dic} from "../dictionary";
import {ENV_CONSTS} from "../constants";
import * as shaJs from 'sha.js';
import {Buffer} from 'safe-buffer';
import {RestService} from "./rest.service";
import * as $ from "jquery";
import * as util from 'util';

const SOAP_ENVELOPE:string = `<?xml version="1.0" encoding="utf-8"?>
<s:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xmlns:xsd="http://www.w3.org/2001/XMLSchema"
        xmlns:s="http://schemas.xmlsoap.org/soap/envelope/" 
        xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
<s:Header>
<t:RequestServerVersion Version="Exchange2010_SP1"/>
</s:Header>
<s:Body>
%s
</s:Body>
</s:Envelope>`;

const EXPORT_REQUEST:string = `<m:ExportItems xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
  <m:ItemIds><t:ItemId Id="%s"/></m:ItemIds>
</m:ExportItems>`;

const MIME_REQUEST:string = `<m:GetItem xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
  <m:ItemShape>
	<t:BaseShape>IdOnly</t:BaseShape>
	<t:IncludeMimeContent>true</t:IncludeMimeContent>
  </m:ItemShape>
  <m:ItemIds><t:ItemId Id="%s"/></m:ItemIds>
</m:GetItem>`;

const HEADERS_REQUEST:string = `<m:GetItem xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
  <m:ItemShape>
	<t:BaseShape>IdOnly</t:BaseShape>
	<t:BodyType>Text</t:BodyType>
	<t:AdditionalProperties>
		<t:ExtendedFieldURI PropertyTag="0x007D" PropertyType="String" />
	</t:AdditionalProperties>
  </m:ItemShape>
  <m:ItemIds><t:ItemId Id="%s"/></m:ItemIds>
</m:GetItem>`;

const ATTACHMENT_REQUEST:string = `<m:GetAttachment xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
  <m:AttachmentShape>
   <t:IncludeMimeContent>true</t:IncludeMimeContent>
   <t:BodyType>Text</t:BodyType>
  </m:AttachmentShape>
  <m:AttachmentIds>%s</m:AttachmentIds>
</m:GetAttachment>`;


@Injectable({
	providedIn: 'root'
})
export class Office365EWSService {

	constructor(private rs: RestService) { }

	getAttachmentsContentEWS(cb) {
		const fName = 'getAttachmentsContentEWS';

		let attachments:any[] = Office.context.mailbox.item.attachments.filter(itm => !itm.isInline);
		if (!attachments.length) {
			return cb();
		}

		attachments = attachments.map(att => ({
			id: att.id,
			name: att.name,
			type: att.contentType,
			size: att.size
		}));

		let template = attachments.map(att => `<t:AttachmentId Id="${att.id}"/>`).join('\n');
		template = util.format(ATTACHMENT_REQUEST, template);
		this.makeEwsRequest(fName, template, (err, data) => {
			if (err) {
				console.error('[Trustifi] Attachments EWS error: '+err.message);
				return cb(err);
			}

			attachments.forEach((att,idx) => {
				if (!data[idx].content) return;

				let content = Buffer.from(data[idx].content, 'base64');
				att.content = data[idx].content;
				att.hash = shaJs('sha256').update(content).digest('hex');
				att.size = content.length;
			});

			cb(null, attachments);
		});
	}

	getEmailHeadersEWS(cb) {
		const fName = 'getEmailHeadersEWS';

		this.makeEwsRequest(fName, HEADERS_REQUEST, (err, headers) => {
			if (err) {
				console.error('[Trustifi] Headers EWS error: '+err.message);
				return cb(err);
			}

			cb(null, headers);
		});
	}

	getExchangeMessageContentEWS(cb) {
		const fName = 'getExchangeMessageContentEWS';

		return this.makeEwsRequest(fName, MIME_REQUEST, cb);
	}

	//NOTE: https://stackoverflow.com/questions/32851325/makeewsrequestasync-not-working-in-outlook-desktop-client
	//https://github.com/OfficeDev/office-js/issues/4124
	private makeEwsRequest(fName:string, requestTemplate:string, cb) {

		let itemId = Office.context.mailbox.item.itemId;
		if (!itemId || Office.context.mailbox.diagnostics.hostName === 'OutlookIOS' || !Office.context.mailbox.makeEwsRequestAsync) {
			let error = {message: 'EWS Request is not supported on your device'};
			this.rs.sendLogs('error', fName, {error});
			return cb(error);
		}

		if (requestTemplate.includes('%s')) {
			//itemId = Office.context.mailbox.convertToEwsId(itemId, Office.MailboxEnums.RestVersion.v2_0);
			requestTemplate = util.format(requestTemplate, itemId);
		}

		let getItemRequest = util.format(SOAP_ENVELOPE, requestTemplate);

		//In Outlook on the web, on Windows (starting in Version 2303 (Build 16225.10000)), and on Mac (starting in Version 16.73 (23042601)),
		// if the response exceeds 5 MB in size, an error message is returned in the asyncResult.error property.
		// In earlier versions of the Outlook desktop client, an error message is returned if the response exceeds 1 MB in size.

		//When you use the makeEwsRequestAsync method in add-ins that run in Outlook versions earlier than Version 15.0.4535.1004,
		// you must set the encoding value to ISO-8859-1 (<?xml version="1.0" encoding="iso-8859-1"?>).

		Office.context.mailbox.makeEwsRequestAsync(getItemRequest, (result) => {
			if (result.status === Office.AsyncResultStatus.Failed) {
				this.rs.sendLogs('error', fName, {message:'EWS api error', result});
				return cb(result.error);
			}

			if (!result.value) {
				let error = {message: 'Received empty EWS response'};
				this.rs.sendLogs('error', fName, {error});
				return cb(error);
			}

			let responseDom = $($.parseXML(result.value.replace(/&#x0;/g, '')));
			if (!responseDom) {
				return cb();
			}

			let response = responseDom.find("t\\:FileAttachment");
			if (response && response.length) {
				let attachments = [];
				response.each((idx,itm) => {
					attachments.push({
						name: $(itm).find("t\\:Name").text(),
						type: $(itm).find("t\\:ContentType").text(),
						content: $(itm).find("t\\:Content").text(),
						cid: $(itm).find("t\\:ContentId").text(),
					});
				});

				return cb(null, attachments);
			}

			response = responseDom.find("t\\:MimeContent").text();
			if (response) {
				response = Buffer.from(response, 'base64').toString();
				return cb(null, response);
			}

			response = responseDom.find("t\\:ExtendedProperty").text();
			if (response) {
				return cb(null, response);
			}

			let responseCode = responseDom.find("m\\:ResponseCode").text();
			if (responseCode && responseCode !== 'NoError') {
				return cb({message: 'EWS Error: '+responseCode});
			}

			cb();
		});
	}
}

