<ng-container *ngIf="userInfo">

    <div class="app-container" [ngClass]="!isVerified && 'loading-blur'">
        <app-mailbox *ngIf="office365Service.currentPage === dic.CONSTANTS.appPages.mailbox"></app-mailbox>
        <app-compose *ngIf="office365Service.currentPage === dic.CONSTANTS.appPages.compose"></app-compose>

        <contact-us-component *ngIf="gs.showSupportForm"></contact-us-component>
    </div>

    <div *ngIf="!isVerified" class="loading-blur-verify-email animated bounceIn">
        <h1 style="text-align: center;">{{'emailVerification.verifyYourEmail' | translate}}</h1>
        <br/>
        <label class="t-color-primary">{{'emailVerification.verificationEmailSent' | translate}}.</label>
        <br/>
        <div style="text-align:center;">
            <br/>
            <label class="t-color-primary">{{'emailVerification.complete' | translate}}</label>
            <a style="color:#486db5;cursor:pointer;" (click)="checkVerificationEmail()">{{'emailVerification.clickRefresh' | translate}}</a>
            <br *ngIf="!verificationSent"/>
            <label *ngIf="!verificationSent" class="t-color-primary">{{'emailVerification.notGetEmail' | translate}}</label>
            <label *ngIf="!verificationSent" style="color:#486db5;cursor:pointer;" (click)="sendVerificationEmail()">{{'emailVerification.resendEmail' | translate}}</label>
            <br *ngIf="verificationSent"/>
            <label *ngIf="verificationSent" style="color:green;">{{'emailVerification.emailSentTo' | translate}} {{verificationEmail}}</label>
        </div>
        <hr/>
        <div style="text-align:center;">
            <label>{{'emailVerification.wrongAccount' | translate}}
                <a style="color:#486db5" (click)="logout()">{{'emailVerification.logout' | translate}}</a>
            </label>
        </div>
    </div>

</ng-container>
