import {Inject, Injectable } from '@angular/core';
import {dic} from "../dictionary";
import {ENV_CONSTS} from "../constants";
import * as shaJs from 'sha.js';
import {Buffer} from 'safe-buffer';
import {RestService} from "./rest.service";
import {Office365EWSService} from "./office365.ews.service";
import {Office365RestService} from "./office365.rest.service";
import * as _ from 'lodash';
import env from '../../env.json';


@Injectable({
	providedIn: 'root'
})
export class Office365Service {

	constructor(private rs: RestService,
				private office365EWS: Office365EWSService,
				private office365Rest: Office365RestService) {

		try {
			const version = [Office.context.mailbox?.diagnostics?.hostVersion, Office.context.diagnostics?.version];
			const platform = [Office.context.mailbox?.diagnostics?.hostName, Office.context.diagnostics?.platform];
			const owaView = Office.context.mailbox?.diagnostics?.OWAView;
			const isOldOutlook = !version[1].startsWith('0') && !version[1].startsWith('16');

            const mailboxVersions = ["1.14", "1.11", "1.8", "1.7", "1.6", "1.5", "1.4", "1.3", "1.2", "1.1"];
            const isSupported = mailboxVersions.map(itm => Office.context.requirements.isSetSupported('Mailbox', itm) && itm).filter(itm => itm)[0];

            console.log(`Client application: ${platform}`);		//Outlook, newOutlookWindows, OutlookMac, OutlookWebApp, OutlookIOS, or OutlookAndroid
            console.log(`Client version: ${version}`);			//Outlook client or Exchange Server version
            console.log(`OWA/Desktop view: ${owaView}`);		//OneColumn - mobile, TwoColumns - tablet, ThreeColumns - PC
            console.log(`Old Outlook desktop client: ${isOldOutlook}`);
            console.log(`Supported Requirement Sets: ${isSupported}`);

            if (isOldOutlook) {
                //version 16 - Outlook 2016+
                //version 15 - Outlook 2013
                //version 14 - Outlook 2010
				this.sendInitData = {message: 'Using old Outlook Desktop Client', version, platform, isSupported};
            }
			else if (!env.environment.startsWith('production')) {
				this.sendInitData = {message: 'Using new Outlook Client', version, platform, isSupported};
			}

			this.setUserProfile();
        }
        catch (ex) {
            this.rs.sendLogs('error', 'Init', {message: 'Failed to validate Outlook version', error: ex});
        }
    }

	sendInitData;
	currentPage;
	profile;

	internetMessageId: string;
	itemClass: string;

	isWindowsDesktop = Office.context.mailbox.diagnostics.hostName !== 'OutlookWebApp' && window.navigator.platform.indexOf('Win') >= 0;
	isSafari = Office.context.mailbox.diagnostics.hostName === 'OutlookWebApp' && /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent);
	isOutlookClient = Office.context.mailbox.diagnostics.hostName !== 'OutlookWebApp';

	//for read-mode message only, compose-mode message require async methods
	//we can get attachments only in the read mode
	//subject without "RE:" or "FW:": Office.context.mailbox.item.normalizedSubject;

	logout() {
		this.rs.setDefaultHeaders({});
		this.isOutlookClient && window.localStorage.clear();
		this.isOutlookClient && window.sessionStorage.clear();
		//deleting illegal value
		this.roamingSet('this.userAuthenticated', null, () => {});

		this.roamingSet('userAuthenticated', {}, () => {
			this.isOutlookClient && window.sessionStorage.clear();
			this.roamingSet('userAuthenticated', null, () => {
				this.isOutlookClient && window.sessionStorage.clear();
			});
		});

		this.currentPage = null;
	}

	getLocation() {
		try {
			return Office.context.mailbox.item.location;
		}
		catch(ex) {
			console.error('[Trustifi] cannot get item location', ex);
		}
	}

	updateCurrentPage() {
		this.currentPage = this.isCompose() ? dic.CONSTANTS.appPages.compose : dic.CONSTANTS.appPages.mailbox;
	}

	getBodyHTML(cb) {
		try {
			Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, res => {
				if(res.status === Office.AsyncResultStatus.Failed) {
					console.error('[Trustifi] getAsync HTML', res.error);

					Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, res => {
						if(res.status === Office.AsyncResultStatus.Failed) {
							console.error('[Trustifi] getAsync Text', res.error);
						}

						res['type'] = Office.CoercionType.Text;
						cb(res);
					});
				}
				else {
					res['type'] = Office.CoercionType.Html;
					cb(res);
				}
			});
		}
		catch(ex) {
			console.error('[Trustifi] getBodyHTML', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	};

	getBodyText() {
		return new Promise((resolve, reject) => {
			try {
				Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, res => {
					if (res.status === Office.AsyncResultStatus.Failed) {
						console.error('[Trustifi] getBodyText', res.error);
						return reject();
					}

					resolve(res.value);
				})
			}
			catch(ex) {
				console.error('[Trustifi] getBodyText', ex);
				reject();
			}
		});
	}

	getResourceId() {
		let restItemId = Office.context.mailbox.item.itemId;
		if (restItemId && Office.context.mailbox.diagnostics.hostName !== 'OutlookIOS') {
			restItemId = Office.context.mailbox.convertToRestId(restItemId, Office.MailboxEnums.RestVersion.v2_0);
		}
		return restItemId;
	}

	private getSelectedItems(cb) {
		if (!Office.context.requirements.isSetSupported('MailBox', '1.13')) {
			console.error('[Trustifi] selecting items is not supported by this API');
			return cb();
		}

		Office.context.mailbox.getSelectedItemsAsync(result => {
			if (result.status !== Office.AsyncResultStatus.Succeeded) {
				let restItemIds = result.value;
				if (restItemIds && Office.context.mailbox.diagnostics.hostName !== 'OutlookIOS') {
					for(let itm of restItemIds) {
						itm.itemId = Office.context.mailbox.convertToRestId(itm.itemId, Office.MailboxEnums.RestVersion.v2_0);
					}
				}

				cb(null, restItemIds);
			} else {
				cb(result);
			}
		});
	}

	getAttachmentsContent(cb) {
		const fName = 'getAttachmentsContent';

		if(!Office.context.mailbox.item) {
			console.error('[Trustifi] email item doesnt exist');
			return cb();
		}

		let attachments = Office.context.mailbox.item.attachments.filter(itm => !itm.isInline);
		if (!attachments.length) {
			return cb();
		}

		this.office365Rest.getAttachmentsContentRest((err, attachments) => {
			if (err) {
				this.getAttachmentsContentAPI((err, attachments) => {
					if (err) {
						this.office365EWS.getAttachmentsContentEWS((err, attachments) => {
							cb(attachments);
						});
					}
					else {
						cb(attachments);
					}
				});
			}
			else {
				cb(attachments);
			}
		});
	}

	addAttachment(url, filename, cb) {
		try {
			Office.context.mailbox.item.addFileAttachmentAsync(url, filename, cb);
		}
		catch(ex) {
			console.error('[Trustifi] Error', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	getHeaders(names, cb) {
		try {
			Office['cast'].item.toItemCompose(Office.context.mailbox.item).internetHeaders.getAsync(names, cb);
		}
		catch(ex) {
			console.error('[Trustifi] Error', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	setHeaders(headersObj, cb) {
		try {
			Office['cast'].item.toItemCompose(Office.context.mailbox.item).internetHeaders.setAsync(headersObj, cb);
		}
		catch(ex) {
			console.error('[Trustifi] Error', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	setSubject(text, cb) {
		try {
			Office['cast'].item.toItemCompose(Office.context.mailbox.item).subject.setAsync(text, cb);
		}
		catch(ex) {
			console.error('[Trustifi] Error', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	// in compose
	getSubject() {
		return new Promise((resolve, reject) => {
			try {
				Office['cast'].item.toItemCompose(Office.context.mailbox.item).subject.getAsync(res => {
					if (res.status === Office.AsyncResultStatus.Failed) {
						console.error('[Trustifi] getSubject', res.error);
						return reject();
					}

					resolve(res.value);
				});
			}
			catch(ex) {
				console.error('[Trustifi] getSubject', ex);
				reject();
			}
		});
	}

	// in read mode
	getEmailSubject() {
		try {
			return Office.context.mailbox.item.subject;
		}
		catch (ex) {
			console.error('[Trustifi] getEmailSubject', ex);
		}
	}

	getRecipients() {
		function stripDisplayName(displayName) {
			return (displayName || '').replace(/["',\s]+/g, ' ');
		}

		function getRecipientsOfSource(source, cb) {
			Office['cast'].item.toItemCompose(Office.context.mailbox.item)[source].getAsync(res => {
				if (res.status === Office.AsyncResultStatus.Failed) {
					console.error('[Trustifi] getRecipientsOfSource: ' + source, res.error);
					return cb(true);
				}
				const recipientsOfScope = _.map(res.value || [], itm => {
					return {
						emailAddress: itm.emailAddress,
						displayName: stripDisplayName(itm.displayName),
						source: source
					}
				});

				return cb(false, recipientsOfScope);
			});
		}

		return new Promise((resolve, reject) => {
			try {
				let allRecipients = [];

				getRecipientsOfSource('to', (err, res) => {
					if (err) { return reject(err); }
					allRecipients = allRecipients.concat(res);

					getRecipientsOfSource('cc', (err, res) => {
						if (err) { return reject(err); }
						allRecipients = allRecipients.concat(res);

						getRecipientsOfSource('bcc', (err, res) => {
							if (err) { return reject(err); }
							allRecipients = allRecipients.concat(res);

							resolve(allRecipients);
						});
					});
				});
			}
			catch(ex) {
				console.error('[Trustifi] getRecipients', ex);
				reject(ex);
			}
		});
	}

	setRecipients(recipients) {

		function setRecipientsIntoSource(source, cb) {
			const recipientsOfSource = _.filter(recipients, recipient => {
				const recipientSources = (recipient.source || 'to').split(',');
				return recipientSources.includes(source);
			});

			Office['cast'].item.toItemCompose(Office.context.mailbox.item)[source].setAsync(recipientsOfSource, res => {
				if (res.status === Office.AsyncResultStatus.Failed) {
					console.error('[Trustifi] setRecipientsIntoSource: ' + source, res.error);
				}

				cb();
			});
		}

		return new Promise<void>((resolve, reject) => {
			try {
				setRecipientsIntoSource('to', () => {
					setRecipientsIntoSource('cc', () => {
						setRecipientsIntoSource('bcc', () => {
							resolve();
						});
					});
				});
			}
			catch(ex) {
				console.error('[Trustifi] setRecipients', ex);
				reject(ex);
			}
		});
	}

	addAll(recipients, cb) {
		try {
			let errors = {
				to: '',
				cc: '',
				bcc: ''
			};
			Office['cast'].item.toItemCompose(Office.context.mailbox.item).to.addAsync(recipients.filter(itm => !itm.source || itm.source === 'to'), res => {
				if (res.status === Office.AsyncResultStatus.Failed) {
					errors.to = res.error;
				}

				Office['cast'].item.toItemCompose(Office.context.mailbox.item).cc.addAsync(recipients.filter(itm => itm.source === 'cc'), res => {
					if (res.status === Office.AsyncResultStatus.Failed) {
						errors.cc = res.error;
					}

					Office['cast'].item.toItemCompose(Office.context.mailbox.item).bcc.addAsync(recipients.filter(itm => itm.source === 'bcc'), res => {
						if (res.status === Office.AsyncResultStatus.Failed) {
							errors.bcc = res.error;
						}

						res = {
							error: errors,
							status: errors.to || errors.cc || errors.bcc ? Office.AsyncResultStatus.Failed : Office.AsyncResultStatus.Succeeded
						};

						cb(res);
					});
				});
			});
		}
		catch(ex) {
			console.error('[Trustifi] addAll', ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	showDialog(url, width, height) {
		//#9394 - when cookies disabled the localStorage is blocked in OWA.
		this.isOutlookClient &&  window.localStorage.removeItem('trustifi_authwnd_handler');

		return new Promise((resolve, reject) => {
			let dialog;
			try {
				window['trustifi_authwnd_handler'] = message => {
					try {dialog && dialog.close()} catch(ex) {
						console.log('ex ???', ex);
					}
					resolve({message: message});
				};

				Office.context.ui.displayDialogAsync(url, { height: height, width: width }, (asyncResult) => {
					if (asyncResult.status === Office.AsyncResultStatus.Failed) {
						console.error('[Trustifi] showDialog error: ', asyncResult.error);
						reject(dic.DIALOG_EVENTS[asyncResult.error.code] || asyncResult.error.message);
					} else {
						dialog = asyncResult.value;
						dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
							//let messageFromDialog = JSON.parse(arg.message);
							//this.showNotification('dlg', arg.message, Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage);

							//change child dialog URL:
							//window.location.href = "/newPage.html";

							//close dialog:
							dialog.close();
							resolve(arg);
						});

						dialog.addEventHandler(Office.EventType.DialogEventReceived, arg => {
							console.error('[Trustifi] showDialog error: ', arg);
							if(arg.error === 12006) {
								//#9394 - when cookies disabled the localStorage is blocked in OWA.
								if (this.isOutlookClient) {
									let message = window.localStorage.getItem('trustifi_authwnd_handler');
									if (message) {
										window.localStorage.removeItem('trustifi_authwnd_handler');
										return resolve({message: message});
									}
								}

								return reject(true);
							}

							reject(dic.DIALOG_EVENTS[arg.error] || arg.error);
						});

						/*dialog.eval = function(name) { return eval(name) }
						dialog.eval("this.getWindow = " + function() {
							return "Hacked " + username;
						}.toSource());*/
					}
				});
			}
			catch(ex) {
				console.error('[Trustifi] showDialog error: ', ex);
				reject(ex.message);
			}
		});
	};

	showWindow(url, width, height, cb) {
		let hwnd = window.open(url, '_blank', 'location=no,width='+width+',height='+height+',scrollbars=yes,top=100,left=100,resizable=no');
		try {
			hwnd.focus();
			hwnd.onunload = () => cb(true);
		}
		catch (e) {
			cb("Pop-up Blocker is enabled! Please add this site to your exception list");
		}

		hwnd['trustifi_authwnd_handler'] = message => {
			try {hwnd.close()} catch(ex) {}
			cb(null, {message: message});
		};
	};

	getUserProfile() {
		let user;
		try {
			user = Office.context.mailbox && Office.context.mailbox.userProfile;
			this.office365Rest.accountType = user && user.accountType;
			this.office365Rest.emailAddress = user && user.emailAddress;	//?.toLowerCase();
		}
		catch(ex) {
			console.error('[Trustifi] userProfile error: '+ex.message, ex);
			this.office365Rest.accountType = null;
			this.office365Rest.emailAddress = null;
		}

		return {
			user: user || {},
			sender: Office.context.mailbox && Office.context.mailbox.item && Office.context.mailbox.item.sender || {},
			from: Office.context.mailbox && Office.context.mailbox.item && Office.context.mailbox.item.from || {}
		};
	}

	isCompose() {
		return !Office.context.mailbox.item.itemId;
	}

	getSharedMailboxUserId(cb) {
		if (Office.context.mailbox.item.getSharedPropertiesAsync) {
			Office.context.mailbox.item.getSharedPropertiesAsync(asyncResult =>  {
				if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
					console.error(`Getting shared properties failed with error: ${asyncResult.error.message}`);
					return cb(asyncResult.error);
				}

				cb(null, asyncResult.value.targetMailbox);
			});
		} else {
			// non-shared item: return user id of primary mailbox user
			cb(null, this.office365Rest.emailAddress);
		}
	}

	private parseHeaders(rawHeaders) {
		if (!rawHeaders) {
			return [];
		}

		try {
			let lines = rawHeaders.split(/\r?\n/);
			for (let i = lines.length - 1; i > 0; i--) {
				if (/^\s/.test(lines[i])) {
					lines[i - 1] += '\n' + lines[i];
					lines.splice(i, 1);
				}
			}

			return lines.filter(line => line.trim()).map(line => ({
				Name: line.substring(0, line.indexOf(':')).trim().toLowerCase(),
				Value: line.substring(line.indexOf(':') + 1).trim()
			}));
		}
		catch(ex) {
			console.error('[Trustifi] parseHeaders Error', ex);
			return [];
		}
	}

	getEmailHeaders(cb) {
		if (!Office.context.mailbox.item) {
			console.error('[Trustifi] email item doesnt exist');
			return cb();
		}

		this.office365Rest.getEmailHeadersRest((err, messageDetails) => {
			if (err) {
				this.getEmailHeadersAPI((err, messageDetails) => {
					if (err) {
						if (!messageDetails) {
							return cb();
						}

						this.office365EWS.getEmailHeadersEWS((err, headers) => {
							headers = this.parseHeaders(headers);
							messageDetails.headers = headers || [];
							cb(messageDetails);
						});
					}
					else {
						cb(messageDetails);
					}
				});
			}
			else {
				cb(messageDetails);
			}
		});
	}

	getExchangeMessageId(cb) {
		if(!Office.context.mailbox.item) {
			let error = {message: 'email item doesnt exist'};
			console.error('[Trustifi] '+error.message);
			return cb(error);
		}

		this.itemClass = Office.context.mailbox.item.itemClass || 'IPM.Note';
		this.internetMessageId = Office.context.mailbox.item.internetMessageId;
		if (this.internetMessageId) {
			if (this.internetMessageId[0] === '<') {
				this.internetMessageId = this.internetMessageId.substring(1, this.internetMessageId.length - 1);
			}
			return cb(null, this.internetMessageId);
		}

		this.office365Rest.getExchangeMessageIdRest((err, data) => {
			if(err) {
				return cb(err);
			}

			//GraphAPI - pascale, RestAPI - camel
			this.internetMessageId = data?.InternetMessageId || data?.internetMessageId;
			if (this.internetMessageId && this.internetMessageId[0] === '<') {
				this.internetMessageId = this.internetMessageId.substring(1, this.internetMessageId.length - 1);
			}
			cb(null, this.internetMessageId);
		});
	}

	getExchangeMessageContent(cb) {
		if(!Office.context.mailbox.item) {
			console.error('[Trustifi] email item doesnt exist');
			return cb({message: 'email item doesnt exist'});
		}

		this.office365Rest.getExchangeMessageContentRest((err, data) => {
			if(err) {
				return this.office365EWS.getExchangeMessageContentEWS((err, data) => {
					if (err) {
						console.error('[Trustifi] MIME EWS error: '+err.message);
					}

					cb(err, data);
				});
			}

			cb(null, data);
		});
	}

	replyEmail(html_content, cb) {
		Office.context.mailbox.item.displayReplyAllForm({htmlBody: html_content, callback: cb});
	}

	roamingClear(cb) {
		for (let key in Office.context.roamingSettings['settingsData']) {
			Office.context.roamingSettings.remove(key);
		}

		Office.context.roamingSettings.saveAsync(result => {
			if (result.status === Office.AsyncResultStatus.Failed) {
				console.error('error: roamingClear', result);
			}

			cb(result);
		});
	}

	roamingGet(name) {
		//const myPartitionKey = Office.context.partitionKey || ';
		let result = Office.context.roamingSettings.get('trustifi.' + name);
		if (!result && this.isWindowsDesktop) {
			result = window.localStorage.getItem('trustifi.' + name);
			try {
				if (result) {
					result = JSON.parse(result);
					this.roamingSet(name, result, () => {});
				}
			}
			catch(ex) {
				result = null;
			}
		}

		return result;
	}

	roamingSet(name, data, cb) {
		if (!data) {
			Office.context.roamingSettings.remove('trustifi.' + name);
			if(this.isWindowsDesktop) window.localStorage.removeItem('trustifi.' + name);
		}
		else {
			Office.context.roamingSettings.set('trustifi.' + name, data);
			if(this.isWindowsDesktop) window.localStorage.setItem('trustifi.' + name, JSON.stringify(data));
		}

		try {
			//https://web.dev/storage-for-the-web/
			if (navigator.storage && navigator.storage.estimate) {
				navigator.storage.estimate().then(quota => {
					// quota.usage -> Number of bytes used.
					// quota.quota -> Maximum number of bytes available.
					const percentageUsed = (quota.usage / quota.quota) * 100;
					console.log(`You've used ${percentageUsed}% of the available storage.`);
					const remaining = quota.quota - quota.usage;
					console.log(`You can write up to ${remaining} more bytes.`);
				}, err => {});
			}
		}
		catch(ex) {}

		try {
			Office.context.roamingSettings.saveAsync(result => {
				if (result.status === Office.AsyncResultStatus.Failed) {
					let settingsDataSize = JSON.stringify(Office.context.roamingSettings['settingsData']).length;
					console.error('error: ' + name + ', settingsData size is ' + settingsDataSize, result);

					//ISSUE #7139: we can remove all user roaming settings
					//this.roamingClear(result => {});
				}

				cb(result);
			});
		}
		catch(ex) {
			console.error('error: ' + name, ex);
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	createMail(cb) {
		/*
		* toRecipients
		* ccRecipients
		* bccRecipients
		* htmlBody
		* subject
		* attachments
		* */
		try {
			Office.context.mailbox.displayNewMessageForm({
				subject: 'Trustifi Secure Mail',
				htmlBody: '<html><body><h1>Trustifi Secure Mail</h1></body></html>'
			});

			cb({status: Office.AsyncResultStatus.Succeeded });
		}
		catch(ex) {
			cb({status: Office.AsyncResultStatus.Failed, error: ex });
		}
	}

	showNotification(name, message, type) {
		if (!message) {
			return;
		}

		let options = {
			type: type || Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage,
			message: message.substring(0, 150)
		};
		if (type === Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage) {
			options['icon'] = 'icon16';
			options['persistent'] = true;
		}
		Office.context.mailbox.item.notificationMessages.addAsync(name, options);
	}

	private getAttachmentsContentAPI(cb) {
		try {
			if (!Office.context.requirements.isSetSupported('MailBox', '1.8') || !Office.context.mailbox.item.getAttachmentContentAsync) {
				let error = 'attachment content is not supported by this API';
				console.error('[Trustifi] '+error);
				return cb({message: error});
			}

			let attachments:any[] = Office.context.mailbox.item.attachments.filter(itm => !itm.isInline);
			if (!attachments.length) {
				return cb();
			}

			attachments = attachments.map(att => ({
				id: att.id,
				name: att.name,
				type: att.contentType,
				size: att.size
			}));

			let promises = attachments.map(att => {
				let promise = new Promise<any>((resolve, reject) =>
					Office.context.mailbox.item.getAttachmentContentAsync(att.id, res => {
						if(res.status === Office.AsyncResultStatus.Failed) {
							console.error('[Trustifi] error extracting attachments', res);
							return reject(res.error);
						}

						let attachmentData;
						if (res.value) {
							//file formats can be: base64,url,eml,iCalendar. No binaries.
							if (res.value.format === 'base64') {
								attachmentData = Buffer.from(res.value.content, 'base64');
							} else {
								attachmentData = Buffer.from(res.value.content);
							}
						}

						att.content = attachmentData.toString('base64');
						att.hash = shaJs('sha256').update(attachmentData).digest('hex');
						att.size = attachmentData.length;
						resolve(att);
					}));

				return Promise.race([
					new Promise<any>(resolve => setTimeout(() => resolve(att), 5000)),
					promise
				])
			});

			Promise.all<any>(promises).then(res => {
				let attachments = res && res.filter(itm => itm);
				cb(null, attachments);
			}, err => {
				console.error('[Trustifi] error extracting attachments', err);
				cb(err);
			});
		}
		catch(ex) {
			console.error('[Trustifi] error extracting attachments', ex);
			cb(ex);
		}
	}

	private getEmailHeadersAPI(cb) {
		let messageDetails = {
			headers: [],
			ReplyTo: [],
			toTrustifi: false,
			Id: this.getResourceId(),
			isMeetingRequest: Office.context.mailbox.item.itemType === 'appointment',
			InternetMessageId: this.internetMessageId,
			ToRecipients: Office.context.mailbox.item.to.map(itm => ({EmailAddress: {Name: itm.displayName, Address: itm.emailAddress}})),
			//CcRecipients: Office.context.mailbox.item.cc.map(itm => ({EmailAddress: {Name: itm.displayName, Address: itm.emailAddress}})),
			HasAttachments: !!Office.context.mailbox.item.attachments.filter(itm => !itm.isInline).length,
			Body: {ContentType:'HTML', Content:''},
			Subject: Office.context.mailbox.item.subject,
			InternetMessageHeaders: [],
			ParentFolderId: ''
		};

		messageDetails.toTrustifi = messageDetails.ToRecipients.some(itm => itm.EmailAddress.Address.indexOf(ENV_CONSTS.emailsuffix) >= 0);

		this.getBodyHTML(res => {
			if(res.status === Office.AsyncResultStatus.Succeeded) {
				messageDetails.Body.Content = res.value;
				messageDetails.Body.ContentType = res.type === Office.CoercionType.Html ? 'HTML':'Text';
			}

			if (!Office.context.requirements.isSetSupported('MailBox', '1.8') || !Office.context.mailbox.item.getAllInternetHeadersAsync) {
				let error = 'email headers are not supported by this API';
				console.error('[Trustifi] '+error);
				return cb({message: error}, messageDetails);
			}

			Office.context.mailbox.item.getAllInternetHeadersAsync(res => {
				if(res.status === Office.AsyncResultStatus.Succeeded) {
					messageDetails.headers = this.parseHeaders(res.value);
				}

				cb(res.error, messageDetails);
			});
		});
	}

	setUserProfile() {
		this.profile = this.getUserProfile();
	}
}

