import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { dic } from '../../dictionary';
import {GeneralService} from '../../services/general.service';
import _ from 'lodash';

@Component({
	selector: 'app-advanced',
	templateUrl: './advanced.component.html'
})
export class AdvancedComponent implements OnInit {

	dic = dic;
	_ = _;
	showPassword = false;

	@Input() methods: any;
	@Input() advanced: any;
	@Input() userPolicy: any;
	@Input() isMailbox: boolean;
	@Input() attachmentsExist: boolean;

	@Output() saveDefaultsEvent = new EventEmitter<any>();
	@Output() resetAdvancedEvent = new EventEmitter<void>();
	@Output() onChangeMethodToPhone = new EventEmitter<void>();

	allowedAuthMethods;
	trustifiDefault: any;

	constructor(public gs: GeneralService) {
		this.trustifiDefault = this.gs.trustifiDefault;
	}

	ngOnInit() {
		this.allowedAuthMethods = Object.values(dic.CONSTANTS.authenticationMethod);
		if (this.userPolicy.allowed_auth_methods?.length) {
			this.allowedAuthMethods = this.userPolicy.allowed_auth_methods;

			if (!this.userPolicy.allowed_auth_methods.includes(this.advanced.secure.method_2factor.value)) {
				this.advanced.secure.method_2factor = {value: this.allowedAuthMethods[0], strict: false};
			}
		}
	}

	changeAdvanced() {
		if (this.advanced.email_me.on_link_clicked && this.advanced.email_me.on_link_clicked.value) {
			this.advanced.general.track_links.value = true;
		}
		this.saveDefaultsEvent.emit(false);
	}

	updateAuthMethod(selectedMethod: string, event?: Event) {
		if (this.allowedAuthMethods && !this.allowedAuthMethods.includes(selectedMethod)) {
			event?.stopPropagation();
			return;
		}
		if (selectedMethod === dic.CONSTANTS.authenticationMethod.phone) {
			this.validateRecipientsHasPhone();
		}
		this.advanced.secure.method_2factor.value = selectedMethod;
		this.changeAdvanced();
	}

	setAdvancedValue(modelRef: string, value: any) {
		_.set(this.advanced, modelRef, value);
		this.changeAdvanced();
	}

	isRequirePassword() {
		return (
			this.advanced?.secure?.method_2factor &&
			this.advanced.secure.method_2factor.value === dic.CONSTANTS.authenticationMethod.password &&
			this.methods?.secure_send?.value
		);
	}

	validateRecipientsHasPhone() {
		if (this.methods.secure_send.value) {
			this.onChangeMethodToPhone.emit();
		}
	}

	ResetAdvanced() {
		this.resetAdvancedEvent.emit();
	}
}
