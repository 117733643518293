import {Component, OnDestroy, NgZone, OnInit} from '@angular/core';
import * as _ from 'lodash';
import * as util from 'util';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {Office365Service} from '../../services/office365.service';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';
import {RestService} from '../../services/rest.service';
import {ENV_CONSTS} from '../../constants';
import { dic } from '../../dictionary';
import {FileUploader} from 'ng2-file-upload';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import moment from 'moment';

@Component({
	selector: 'app-compose',
	templateUrl: './compose.component.html',
	styleUrls: ['./compose.component.scss']
})
export class ComposeComponent implements OnInit, OnDestroy {

	dic = dic;
	message:any = this.gs.getMessage();
	advanced:any = this.gs.getAdvanced();
	attachments = [];
	uploadedFiles = 0;
	advancedId = null;
	_ = _;
	util = util;

	mouseOverAttachment = false;
	userContacts = [];
	isAdvancedExpanded;
	showPassword;
	showAttachmentsSourceMenu;
	recipientEmailInput;
	recipientEmailInputError;
	currentOfficeRecipients:any = [];
	contactInEdit:any;
	isApplyTrustifi = false;
	isPlanExpired = false;

	trustifiDefault = this.gs.trustifiDefault;
	disableApplyBtn;
	isLoaded;

	getOfficeRecipientsInProcess;
	getOfficeMessageContentInProcess;
	getOfficeMessageSubjectInProcess;
	turnOffIntervals = false;
	createOtokenInProcess;
	emailSubject = '';
	oTokenTimeout;
	is_relay = false;
	notification = this.ns.notification;

	userAuthenticated = this.office365Service.roamingGet('userAuthenticated');
	sensitiveStatus = false;

	userPolicy;
	userPlan;
	uploader = new FileUploader({url: ENV_CONSTS.beBaseAttachmentUrl + '/attachment'});
	uploadSubscribers = {};
	allowedAuthMethods;
	showScheduler = false;
	scheduleMessage: string;
	scheduleError = '';
	minTimeSchedule = this.gs.round(moment().add(5, 'minutes'), moment.duration(5, 'minutes'), 'ceil').format();
	attachmentsManagerData:any = {openAttachmentsManager: false};
	libraryAttachments = [];
	getLibraryAttachmentsInProcess;
	showAttachmentLibrary = false;

	constructor(private authService: AuthService,
				private ns: NotificationService,
				private office365Service: Office365Service,
				public gs: GeneralService,
				private translateService: TranslationService,
				private rs: RestService,
				private http: HttpClient,
				private ngZone: NgZone) {

		// subscribe to local notification service
		this.ns.updateGlobalNotification$.subscribe((data: any) => {
			this.notification = data;
		});

		this.gs.contactImportSubject.subscribe(state => {
			if (state) {
				this.getContacts();
			}
		});

		if (Office.context.mailbox.addHandlerAsync) {
			// happens in OWA: start a new message, then open and pin the addin window, then click again on new message.
			Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, eventArgs => {
				location.reload();
			}, {}, res => {});
		}

		this.officeRecipientsCheck();
		this.sensitivityContentCheck();
		this.subjectChangedCheck();
	}

	ngOnInit() {
		// "signed in as ..." message
		if (this.authService.authNotify && this.userAuthenticated.email) {
			this.authService.authNotify = false;
			this.ns.showInfoMessage(dic.MESSAGES.signedInSuccess, this.userAuthenticated.email);
			this.ns.notificationPriority();
		}
		//

		this.getContacts();
		
		this.isOTokenExpired();

		this.gs.getUserInfo(true).then((userInfo) => {
			if (!userInfo) {
				return;
			}

			this.userPlan = userInfo.plan;
			this.userPolicy = userInfo.plan.policy;
			this.message.methods = {
				encrypt_content: userInfo.plan.policy.encrypt_content || {value: false},
				secure_send: userInfo.plan.policy.secure_send || {value: false}
			};

			if (this.message.methods.encrypt_content.strict && this.message.methods.encrypt_content.allow_user_revert) {
				this.message.methods.encrypt_content.strict = false;
			}

			this.advanced = userInfo.advanced || this.advanced;
			this.enforceUserPolicyInAdvancedObj();

			this.allowedAuthMethods = Object.values(dic.CONSTANTS.authenticationMethod);
			if (this.userPolicy.allowed_auth_methods?.length) {
				this.allowedAuthMethods = this.userPolicy.allowed_auth_methods;

				if (!this.userPolicy.allowed_auth_methods.includes(this.advanced.secure.method_2factor?.value)) {
					this.advanced.secure.method_2factor = {value: this.allowedAuthMethods[0], strict: false};
				}
			}

			this.isPlanExpired = !userInfo.plan.is_unlimited && Date.parse(userInfo.plan.expired) < Date.now();

			this.is_relay = userInfo.is_relay;

			if (this.message.methods?.encrypt_content?.strict || this.message.methods?.secure_send?.strict) {
				this.isApplyTrustifi = true;
			}

			this.isLoaded = true;
		}, err => {
			if (err.data && err.data.display_bar && err.data.message) {
				this.showErrorMessage(err.data.message);
			}
		});

		this.getAllOfficeRecipients().then(() => {
			if (_.some(this.currentOfficeRecipients, itm => itm.emailAddress.includes(ENV_CONSTS.emailsuffix) && this.validateTrustifiEmailAddressInOfficeMessage(itm.emailAddress))) {
				this.isApplyTrustifi = true;
			}
		});
	}

	ngOnDestroy() {
		clearTimeout(this.oTokenTimeout);
		this.turnOffIntervals = true;

		if (Office.context.mailbox.removeHandlerAsync) {
			Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged);
		}
	}

	getContacts = () => {
		this.rs.getContacts().then((response) => {
			this.userContacts = response;
		}, err => {
			if (err.data && err.data.display_bar && err.data.message) {
				this.showErrorMessage(err.data.message);
			}
		});
	}

	// interval: check for sensitive content in office email body and subject
	sensitivityContentCheck() {
		if (this.getOfficeMessageContentInProcess || this.turnOffIntervals) {
			return;
		}

		this.getOfficeMessageContentInProcess = true;

		this.office365Service.getBodyText().then(htmlRes => {
			this.office365Service.getSubject().then(subjectRes => {
				// on both successful: detectSensitiveData
				const value = (htmlRes || '') + ' ' + (subjectRes || '');
				this.detectSensitiveData(value);


				this.getOfficeMessageContentInProcess = false;
				setTimeout(() => {this.sensitivityContentCheck(); }, dic.CONSTANTS.timeout.sensitivity);
			}, err => {
				this.getOfficeMessageContentInProcess = false;
				setTimeout(() => {this.sensitivityContentCheck(); }, dic.CONSTANTS.timeout.sensitivity);
			});
		}, err => {
			this.getOfficeMessageContentInProcess = false;
			setTimeout(() => {this.sensitivityContentCheck(); }, dic.CONSTANTS.timeout.sensitivity);
		});
	}

	// interval: check changes in office email recipients
	officeRecipientsCheck() {
		if (this.getOfficeRecipientsInProcess || this.turnOffIntervals) {
			return;
		}

		this.getOfficeRecipientsInProcess = true;

		this.getAllOfficeRecipients().then(() => { 
			this.getOfficeRecipientsInProcess = false;
			this.syncWithOfficeRecipients();
			setTimeout(() => {this.officeRecipientsCheck(); }, dic.CONSTANTS.timeout.recipients);
		}, err => {
			this.getOfficeRecipientsInProcess = false;
			setTimeout(() => {this.officeRecipientsCheck(); }, dic.CONSTANTS.timeout.recipients);
		});
	}

	// interval: check changes in office email subject. if there are changes then notify BE
	subjectChangedCheck() {
		if (this.getOfficeMessageSubjectInProcess || this.turnOffIntervals) {
			return;
		}

		if (!this.advancedId) {
			setTimeout(() => {this.subjectChangedCheck(); }, dic.CONSTANTS.timeout.subject);
			return;
		}

		this.getOfficeMessageSubjectInProcess = true;

		this.office365Service.getSubject().then((subjectRes:string) => {
			subjectRes = (subjectRes || '').trim();
			if (subjectRes !== this.emailSubject) {
				this.emailSubject = subjectRes;
				this.saveDefaults();
			}

			this.getOfficeMessageSubjectInProcess = false;
			setTimeout(() => {this.subjectChangedCheck(); }, dic.CONSTANTS.timeout.subject);
		}, err => {
			this.getOfficeMessageSubjectInProcess = false;
			setTimeout(() => {this.subjectChangedCheck(); }, dic.CONSTANTS.timeout.subject);
		});
	}

	detectSensitiveData(value) {
		value = value && value.trim();
		if (!value) {
			return;
		}
		
		this.sensitiveStatus = this.gs.checkHIPAA(value.trim());
	}

	getNewRecipientsChanges = () => {
		let newRecipientsFromOffice = _.differenceWith(this.currentOfficeRecipients, this.message.contacts, (officeRecipient:any,addinRecipient:any) => this.isSameRecipient(officeRecipient, addinRecipient));
		newRecipientsFromOffice = _.filter(newRecipientsFromOffice, recipient => this.gs.validateEmail(recipient.emailAddress));

		const newRecipientsFromAddin = _.differenceWith(this.message.contacts, this.currentOfficeRecipients, (addinRecipient:any, officeRecipient:any) => this.isSameRecipient(officeRecipient, addinRecipient));

		return {
			newRecipientsFromAddin,
			newRecipientsFromOffice
		}
	}

	syncWithOfficeRecipients = () => {
		if (this.isPlanExpired || !this.isApplyTrustifi || this.isOTokenExpired()) {
			return;
		}

		let {newRecipientsFromAddin, newRecipientsFromOffice} = this.getNewRecipientsChanges();

		if (!newRecipientsFromAddin.length && !newRecipientsFromOffice.length) {
			// no new recipients
			return;
		}

		// 1. first push possibly new contacts from Office and add to addin:
		if (newRecipientsFromOffice.length) {
			newRecipientsFromOffice.forEach(recipient => {

				const equivalentContactInAddin = _.find(this.message.contacts, contact => this.isSameRecipient(recipient, contact, true));

				// if already exist in addin contacts - add only the source to the source string (eg. 'to,cc,bcc')
				if (equivalentContactInAddin) {
					equivalentContactInAddin.source = _.union((equivalentContactInAddin.source || 'to').split(','), [recipient.source]).join(',');
				}
				// if not exist in addin contacts - add the entire contact
				else {
					this.message.contacts.push(this.getOfficeRecipientAsContact(recipient));
				}
			});
		}

		// 2. then update all contacts back to office:
		if (this.message.contacts.length) {
			this.saveUnsavedRecipients().then(() => { // we need all contacts in addin to have ID
				// format as office recipient.
				const allRecipientsFromAddinFormatted = _.map(this.message.contacts, recipient => {
					return this.getContactAsOfficeRecipient(recipient);
				});

				this.office365Service.setRecipients(allRecipientsFromAddinFormatted).then(() =>{
					this.currentOfficeRecipients = allRecipientsFromAddinFormatted;
				});
			});
		}
	}

	getAllOfficeRecipients() {
		return new Promise<void>((resolve, reject) => {
			this.office365Service.getRecipients().then((res:any) => {

				// check if office recipients were removed (or moved from source to source) since last check and then remove them from addin
				if (res?.length <= this.currentOfficeRecipients.length) {
					const removedRecipients = _.differenceWith(this.currentOfficeRecipients, res, (oldRecipient:any, newRecipient:any) => oldRecipient.emailAddress === newRecipient.emailAddress && oldRecipient.source === newRecipient.source);
					removedRecipients.forEach((removedRecipient:any) => {
						const recipientInAddin = _.find(this.message.contacts, (contact:any) => this.isSameRecipient(removedRecipient, contact, true));

						if (recipientInAddin) {
							let sources = (recipientInAddin.source || 'to').split(',');

							// if the equivalent contact in add-in exist in multiple sources
							if (sources.length > 1) {
								// remove only the source reference of the recipient that was removed
								_.remove(sources, source => source === removedRecipient.source);
								recipientInAddin.source = sources.join(',');
							}
							else {
								// remove the contact from addin entirely
								_.remove(this.message.contacts, recipientInAddin);
							}
						}
					});
				}

				this.currentOfficeRecipients = res;

				return resolve();
			}, err => {
				return reject(err);
			});
		});
	}

	getOfficeRecipientAsContact(officeRecipient) {
		const contactInContactsLibrary = _.cloneDeep(_.find(this.userContacts, contact => this.isSameRecipient(officeRecipient, contact, true)));
		if (contactInContactsLibrary) {
			contactInContactsLibrary.source = officeRecipient.source;
			return contactInContactsLibrary;
		}


		return {
			_id: '',
			email: officeRecipient.emailAddress.toLowerCase(),
			name: this.gs.validateEmail(officeRecipient.displayName) ? officeRecipient.displayName.toLowerCase() : officeRecipient.displayName,
			phone: {country_code: '', phone_number: ''},
			default_password: {password: '', hint: ''},
			contacts: '',
			source: officeRecipient.source
		};
	}

	getContactAsOfficeRecipient(contact) {
		if (this.is_relay) {
			return {
				source: contact.source || 'to',
				displayName: this.gs.validateEmail(contact.name) ? contact.name.toLowerCase() : contact.name,
				emailAddress: contact.email.toLowerCase()
			};
		}
		else {
			return {
				source: contact.source || 'to',
				displayName: this.trustifiDefault.name + '-' + (contact.name && contact.name.trim() || contact.email || contact._id).replace(/[<@]/g, '-'),
				emailAddress: `${contact._id}_${this.userAuthenticated.otoken}@${ENV_CONSTS.emailsuffix}`
			};
		}
	}

	validateTrustifiEmailAddressInOfficeMessage = (email) => {
		// validate email parsing
		const localPart = email.split('@')[0];
		const domainPart = email.split('@')[1];

		if (!localPart || !domainPart) {
			return false;
		}
		// check that before and after the '_' in the local part there's a string and not more
		if (localPart.split('_')[0] && localPart.split('_')[1] && !localPart.split('_')[2]) {
			return false;
		}

		return true;
	}

	saveUnsavedRecipients() {
		return new Promise<void>((resolve, reject) => {
			// unsaved recipients = recipients without _id and that are not exist in userContacts
			let unsavedRecipients = _.reject(this.message.contacts, '_id');

			if (unsavedRecipients.length) {
				this.rs.addNewContact({contacts: unsavedRecipients}).then((response) => {
					unsavedRecipients.forEach((recipient, idx) => {
						recipient._id = response[idx];

						if (!_.find(this.userContacts, {_id: recipient._id})) { // should always be true
							this.userContacts.push(recipient);
						}
					});

					return resolve();
				}, (err) => {
					console.error('[Trustifi] ClientError 0x1003');
					if (err.data && err.data.display_bar && err.data.message) {
						this.showErrorMessage(err.data.message);
					}
					return reject();
				});
			}
			else {
				return resolve();
			}
		});


	}

	isRequirePassword() {
		return this.advanced?.secure?.method_2factor && this.advanced.secure.method_2factor?.value === dic.CONSTANTS.authenticationMethod.password && this.message.methods?.secure_send?.value;
	}

	isRequirePhone() {
		return this.advanced?.secure?.method_2factor && this.advanced.secure.method_2factor?.value === dic.CONSTANTS.authenticationMethod.phone && this.message.methods?.secure_send?.value;
	}

	isPasswordMissingForOneOrMore = () => {
		return this.isRequirePassword() && this.advanced.secure.use_contact_2factor_password?.value && !_.every(this.message.contacts, contact => !!contact.default_password?.password);
	}

	isPhoneMissingForOneOrMore = () => {
		return this.isRequirePhone() && !_.every(this.message.contacts, contact => !!contact.phone?.phone_number);
	}

	isOTokenExpired = () => {
		if (!this.userAuthenticated.otoken || this.userAuthenticated.oexpired < Date.now() + dic.CONSTANTS.msMargin) {
			this.renewOToken();

			return true;
		}
		
		// set token timeout if there is no such
		if (!this.oTokenTimeout) {
			this.oTokenTimeout = setTimeout(() => {
				this.renewOToken();
			}, this.userAuthenticated.oexpired - Date.now() - dic.CONSTANTS.msMargin);
		}

		return false;
	}

	renewOToken() {
		if (!this.userAuthenticated || this.isPlanExpired || this.createOtokenInProcess)  {
			return;
		}

		this.createOtokenInProcess = true;

		this.rs.createOToken().then(otres => {
			this.userAuthenticated.otoken = otres.oToken;
			this.userAuthenticated.oexpired = otres.expired;
			this.createOtokenInProcess = false;
			this.oTokenTimeout = setTimeout(() => this.renewOToken(), otres.expired - Date.now() - dic.CONSTANTS.msMargin);
		}, err => {
			if (err.data && err.data.display_bar && err.data.message) {
				this.showErrorMessage(err.data.message);
			}
			this.createOtokenInProcess = false;
		});
	}

	applyBtnExecute() {
		if (this.isPlanExpired || this.isOTokenExpired()) {
			return; 
		}

		this.isApplyTrustifi = true;

		this.saveDefaults();
	}

	changeMethod() {
		// if "require authentication" is disabled then change auth method from password (TODO: fix the bug in BE)
		if (!this.message.methods.secure_send?.value && this.advanced.secure.method_2factor?.value === this.dic.CONSTANTS.authenticationMethod.password) {
			this.advanced.secure.method_2factor.value = _.reject(this.allowedAuthMethods, method => method === this.dic.CONSTANTS.authenticationMethod.password)[0] || this.advanced.secure.method_2factor?.value;
		}

		this.saveDefaults();
	}
	
	addRecipientByEmail = (newUserEmail) => {
		if (!newUserEmail) {
			return;
		}

		if (!this.gs.validateEmail(newUserEmail)) {
			this.showErrorMessage(this.dic.ERRORS.emailInvalidOrAbsent);
			this.recipientEmailInputError = true;
			return;
		}
		if (!!_.find(this.message.contacts, {email: newUserEmail.toLowerCase()})) {
			this.showErrorMessage(this.dic.ERRORS.contactExistAlready, newUserEmail);
			this.recipientEmailInputError = true;

			return;
		}

		this.message.contacts.unshift({
			name: '',
			email: newUserEmail,
			phone: {country_code: '', phone_number: ''},
			default_password: {password: '', hint: ''},
		});

		this.recipientEmailInput = '';
		this.recipientEmailInputError = false
		setTimeout(() => {
			document.getElementById('recipientInput').focus();
		});

		this.syncWithOfficeRecipients();
	}

	addRecipientFromExistingContacts = (contact) => {
		if (_.some(this.message.contacts, {email: contact.email.toLowerCase()})) {
			this.showErrorMessage(this.dic.ERRORS.contactExistAlready, contact.name || contact.email);
			this.recipientEmailInputError = true;
			return;
		}

		this.message.contacts.unshift(contact);

		this.syncWithOfficeRecipients();

		this.recipientEmailInput = '';
		this.recipientEmailInputError = false
		setTimeout(() => {
			document.getElementById('recipientInput').focus();
		});

	}

	openEditContactModal = (contact) => {
		if (!contact || contact.edit) {
			return;
		}

		contact.edit = {
			name: contact.name,
			email: contact.email,
			phone: _.clone(contact.phone) || {country_code: '', phone_number: ''},
			default_password: _.clone(contact.default_password) || {password: '', hint: ''},
			passwordError: false,
			phoneNumberError: false,
			emailError: false
		}

		this.contactInEdit = contact;
	};

	isSameRecipient = (officeRecipient, addinRecipient, ignoreSource=false) => {
		const isSameEmail = officeRecipient.emailAddress.toLowerCase() === (addinRecipient.originalEmail || addinRecipient.email).toLowerCase() || (officeRecipient.emailAddress.includes(ENV_CONSTS.emailsuffix.toString()) && addinRecipient._id && officeRecipient.emailAddress.startsWith(addinRecipient._id.toString()));
		const isSameSource = ignoreSource ? true : (addinRecipient.source || 'to').split(',').includes(officeRecipient.source);

		addinRecipient.originalEmail = null;

		return isSameEmail && isSameSource;
	}

	removeFromContacts(contact) {
		return new Promise<void>((resolve, reject) => {
			if (this.isApplyTrustifi && !this.isPlanExpired && !this.isOTokenExpired()) {
				this.getAllOfficeRecipients().then(res => {
					let officeRecipients = _.reject(this.currentOfficeRecipients, officeRecipient => this.isSameRecipient(officeRecipient, contact, true));
					this.office365Service.setRecipients(officeRecipients).then(() => {
						this.currentOfficeRecipients = officeRecipients;
						_.remove(this.message.contacts, contact);
						return resolve();
					});
				});
				return;
			}
			else {
				_.remove(this.message.contacts, contact);
				return resolve();
			}
		});
	}

	addNewContact() {
		this.contactInEdit.errorMessage = null;

		if (!this.validateContactDataInputs()) {
			return;
		}

		this.rs.addNewContact({
			contacts: [{
				name: this.contactInEdit.edit.name,
				email: this.contactInEdit.edit.email,
				phone: this.contactInEdit.edit.phone,
				default_password: this.contactInEdit.edit.default_password || {}
			}]
		}).then((response) => {
			Object.assign(this.contactInEdit, this.contactInEdit.edit);

			this.contactInEdit._id = response[0];

			if (!this.contactInEdit.name) {
				this.contactInEdit.name = this.contactInEdit.email;
			}

			this.contactInEdit.edit = null;
			this.contactInEdit = null;

			}, err => {
			if (err.data && err.data.display_bar && err.data.message) {
				this.showErrorMessage(err.data.message);
			}
		});
	}

	validateContactDataInputs = () => {
		const dataObj = this.contactInEdit.edit;
		this.contactInEdit.emailError = false;
		this.contactInEdit.passwordError = false;
		this.contactInEdit.phoneNumberError = false;

		if (!dataObj.email) {
			this.contactInEdit.errorMessage = dic.ERRORS.emailRequired
			this.contactInEdit.emailError = true;
			return false;
		}

		dataObj.email = dataObj.email.toLowerCase();

		if (!this.gs.validateEmail(dataObj.email)) {
			this.contactInEdit.errorMessage = util.format(this.translateService.getTranslationText('ERRORS.emailInvalid'), dataObj.email);
			this.contactInEdit.emailError = true;
			return false;
		}

		if (!this.gs.validateTel(dataObj.phone)) {
			this.contactInEdit.errorMessage = util.format(this.translateService.getTranslationText('ERRORS.phoneInvalid'), dataObj.email);
			this.contactInEdit.phoneNumberError = true;
			return false;
		}

		if (!dataObj.default_password.password && dataObj.default_password.hint) {
			this.contactInEdit.errorMessage = this.translateService.getTranslationText('ERRORS.contactHintWithoutPassword');
			this.contactInEdit.passwordError = true;
			return false;
		}

		if (!!_.find(this.userContacts, userContact => userContact.email === dataObj.email && this.contactInEdit._id !== userContact._id) ||
				!!_.find(this.message.contacts, messageContact => messageContact.email === dataObj.email && this.contactInEdit._id !== messageContact._id)) {
			this.contactInEdit.errorMessage = util.format(this.translateService.getTranslationText('ERRORS.contactExistAlready'), dataObj.email);
			this.contactInEdit.emailError = true;
			return;
		}

		return true;

		/*if (this.allRecipientsHasPassword) {
			if (!contact.default_password || !contact.default_password.password) {
				this.allRecipientsHasPassword = false;
				this.allRecipientsHasPasswordSubject.next(this.allRecipientsHasPassword);
			}
		}*/
	}

	confirmEditContact(cb=null) {
		if (!!this.contactInEdit._id) { // update contact
			this.updateContactExecute();
		}
		else { // new contact
			this.addNewContact();
		}
	}

	updateContactExecute() {
		this.contactInEdit.errorMessage = null;

		if (!this.validateContactDataInputs()) {
			return;
		}

		if (this.isRequirePhone() && (!this.contactInEdit.edit.phone || !this.contactInEdit.edit.phone.phone_number)) {
			this.contactInEdit.errorMessage = util.format(this.translateService.getTranslationText('ERRORS.phoneMissing'), this.contactInEdit.edit.email);
			this.contactInEdit.phoneNumberError = true;
			return;
		}

		return this.rs.updateContact(this.contactInEdit._id, {
			_id: this.contactInEdit._id,
			name: this.contactInEdit.edit.name,
			email: this.contactInEdit.edit.email,
			phone: this.contactInEdit.edit.phone,
			default_password: this.contactInEdit.edit.default_password
		}).then((response) => {

			this.contactInEdit.originalEmail = this.contactInEdit.email;

			Object.assign(this.contactInEdit, this.contactInEdit.edit);

			const contactInResponse = response.email === this.contactInEdit.edit.email ? response : _.find(response.contacts, {email : this.contactInEdit.edit.email});
			if (contactInResponse) {
				Object.assign(this.contactInEdit, contactInResponse);
			}

			this.contactInEdit.edit = null;

			const inUserContacts = _.find(this.userContacts, {_id: this.contactInEdit._id});
			if (inUserContacts) {
				Object.assign(inUserContacts, this.contactInEdit);
			}

			if (this.isApplyTrustifi && !this.isPlanExpired && !this.isOTokenExpired()) {
				// remove the old contact from office too and insert the updated one
				this.removeFromContacts(this.contactInEdit).then(() => {
					this.message.contacts.push(this.contactInEdit);
					this.contactInEdit = null;
				});

				return;
			}

			this.contactInEdit = null;

		}, err => {
			if (err.data && err.data.display_bar && err.data.message) {
				this.contactInEdit.errorMessage = err.data.message;
			} else {
				this.contactInEdit.errorMessage = util.format(this.translateService.getTranslationText('MESSAGES.failedSavingEmail'), this.contactInEdit.edit.email);
			}
		});
	}

	enforceUserPolicyInAdvancedObj() {
		this.advanced.secure.expired_enable				= this.userPolicy.expired_enable?.strict			?  this.userPolicy.expired_enable			: this.advanced.secure.expired_enable;
		this.advanced.secure.expired_days				= this.userPolicy.expired_enable?.strict			?  this.userPolicy.expired_days				: this.advanced.secure.expired_days;

		this.advanced.secure.delete_attachment_enable	= this.userPolicy.delete_attachment_enable?.strict	?  this.userPolicy.delete_attachment_enable	: this.advanced.secure.delete_attachment_enable;
		this.advanced.secure.delete_attachment_days		= this.userPolicy.delete_attachment_enable?.strict	?  this.userPolicy.delete_attachment_days	: this.advanced.secure.delete_attachment_days;

		this.advanced.secure.secure_received			= this.userPolicy.secure_received?.strict			?  this.userPolicy.secure_received			: this.advanced.secure.secure_received;
		this.advanced.general.track_links				= this.userPolicy.track_links?.strict				?  this.userPolicy.track_links				: this.advanced.general.track_links;
		this.advanced.general.allow_download_as_eml		= this.userPolicy.allow_download_as_eml?.strict		?  this.userPolicy.allow_download_as_eml	: this.advanced.general.allow_download_as_eml;
	}



	ResetAdvanced() {
		this.disableApplyBtn = true;

		this.rs.updateUserAdvanced(null, 'default').then(response => {
			this.advanced = response;
			this.enforceUserPolicyInAdvancedObj();

			this.disableApplyBtn = false;
		}, err => {
			if (err.data && err.data.display_bar && err.data.message) {
				this.showErrorMessage(err.data.message);
			} else {
				this.showErrorMessage(dic.ERRORS.failedResetAdvancedSettings);
			}

			this.disableApplyBtn = false;
		});
	}

	saveDefaults(isPermanent=false):any {
		if (!this.isApplyTrustifi) {
			return;
		}

		this.ns.clear();

		const data: any = this.gs.flatAdvanced(this.advanced);

		if (isPermanent) {
			return this.updateUserAdvancedExecute(data, 'default');
		}

		if (this.attachments?.length) {
			data.attachments = _.map(this.attachments, 'id');
		}
		if (this.advancedId) {
			data.advancedId = this.advancedId;
		}

		if (this.message.scheduled_time) {
			data.scheduled_time = this.message.scheduled_time;
		}

		data.methods = {};
		if (this.userPlan.methods.postmark?.value) {
			data.methods.postmark = true;
		}
		if (this.message.methods.secure_send?.value) {
			data.methods.secure_send = true;
		}
		if (this.message.methods.encrypt_content?.value) {
			data.methods.encrypt_content = true;
		}
		data.subject = this.emailSubject;

		this.office365Service.getSubject().then((subjectRes:string) => {
			subjectRes = (subjectRes || '').trim();
			data.subject = subjectRes;
			this.emailSubject = subjectRes;
			return this.updateUserAdvancedExecute(data, 'with_index');
		});
	}

	updateUserAdvancedExecute(flat, action) {
		this.disableApplyBtn = true;

		return this.rs.updateUserAdvanced(flat, action).then((response) => {
			this.advanced = response;
			this.enforceUserPolicyInAdvancedObj();

			if (response.advancedId) { // is falsy only on 'isPermanent'
				this.advancedId = response.advancedId;
			}

			this.disableApplyBtn = false;
		}, err => {
			if (err.data && err.data.display_bar && err.data.message) {
				this.showErrorMessage(err.data.message);
			} else {
				this.showErrorMessage(dic.ERRORS.failedResetAdvancedSettings);
			}

			this.disableApplyBtn = false;
		});
	}

	isMessageSecured() {
		return !this.sensitiveStatus || this.message.methods?.secure_send?.value && this.message.methods?.encrypt_content?.value;
	}

	setMessageSecured() {
		this.message.methods.secure_send.value = true;
		this.message.methods.encrypt_content.value = true;

		this.applyBtnExecute();
	}

	// TODO: duplicate code from authentication component -- START
	uploadFiles(files) {
		this.showAttachmentsSourceMenu = false;

		if (!files || !files.length) { return; }

		files = _.map(files, f => f._file);

		if (this.attachmentsManagerData.openAttachmentsManager) {
			files.length = 0;
			this.uploader.queue.length = 0;
			this.showErrorMessage(dic.ERRORS.composeCannotUploadAttachments);
			return;
		}

		if (files.length > dic.CONSTANTS.composeMaxAttachments
			|| this.attachments.length + files.length > dic.CONSTANTS.composeMaxAttachments) {
			files.length = 0;
			this.uploader.queue.length = 0;
			this.showErrorMessage(dic.ERRORS.composeTooManyAttachments);
			return;
		}

		let totalSize = 0;
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (_.find(this.attachments, {name: file.name, size: file.size})) {
				if (this.uploadedFiles > 0) {
					this.uploadedFiles = 0;
				}
				this.showErrorMessage(dic.ERRORS.attachmentAlreadyUploaded, file.name);
				files.length = 0;
				this.uploader.queue.length = 0;
				return;
			}
			totalSize += file.size;
		}
		const isValid = this.gs.checkUploadFileSize(totalSize, 'Current upload attachments');
		if (totalSize === 0 || !isValid) {
			files.length = 0;
			this.uploader.queue.length = 0;
			return;
		}

		this.showAttachmentLibrary = false;
		this.ns.clear();

		const headers = new HttpHeaders(this.authService.getHeaders());

		for (let i = 0; i < files.length; i++) {
			this.uploadFilesExecute(files[i], headers);
		}

		this.advanced.secure.enforce_attachment_encryption.value = true;
		this.uploadedFiles += files.length;
		files.length = 0;
	}

	uploadFilesExecute(file, headers) {
		if (!file) { return; }

		const currentAttachment: any = {
			name: file.name,
			size: file.size,
			finished: false,
			progressPercentage: 0,
			upload: {}
		};
		this.attachments.push(currentAttachment);

		const fd = new FormData();
		fd.append('file', file);

		const req = new HttpRequest('POST', ENV_CONSTS.beBaseAttachmentUrl + '/attachment', fd, {
			headers,
			reportProgress: true
		});

		this.uploadSubscribers[file.name] = this.http.request(req).subscribe(event => {
			// reporting percentages - updating local variables
			if (event.type === HttpEventType.UploadProgress) {
				currentAttachment.progressPercentage = 100.0 * event.loaded / event.total;
			} else if (event.type === HttpEventType.Response) {
				// update finished state and new id for attachments
				currentAttachment.finished = true;
				currentAttachment.id = event.body[0];
				// save _id for attachmentsManagerData
				currentAttachment._id = event.body[0];
				if (this.libraryAttachments?.length && !_.find(this.libraryAttachments, a => a._id === currentAttachment._id)) {
					this.libraryAttachments.push(currentAttachment);
				}

				if (this.uploadedFiles > 0) {
					this.uploadedFiles--;
				}

				if (this.uploadedFiles === 0) {
					this.uploader.queue.length = 0;

					// simulate Apply press
					this.applyBtnExecute();
				}
			}
		}, err => {
			if (err.error && err.error.message) {
				this.showErrorMessage(err.error.message);
			}
			_.remove(this.attachments, attachment => {
				return currentAttachment.name === attachment.name;
			});

			if (this.uploadedFiles > 0) {
				this.uploadedFiles--;
			}
		});
	}

	deleteAttachment(attachment) {
		_.remove(this.attachments, attachment);
		if (this.uploadedFiles > 0) {
			this.uploadedFiles--;
		}
		if (!this.attachments.length && this.advanced.email_me.on_attachment_download?.value) {
			this.advanced.email_me.on_attachment_download.value = false;
		}
		if (this.uploadSubscribers[attachment.name]) {
			this.uploadSubscribers[attachment.name].unsubscribe();
		}
		this.saveDefaults();
	}

	fileOver(isFileOver: boolean) {
		this.mouseOverAttachment = isFileOver;
	}

	showSchedulerMenu() {
		this.showScheduler = true;
	}

	applyScheduler() {
		this.showScheduler = false;
		this.scheduleMessage = this.translateService.getTranslationText('compose.schedule.scheduledSuccess', moment(this.message.scheduled_time).format('MM/DD/yyyy'), moment(this.message.scheduled_time).format('HH:mm'))

		this.saveDefaults();
	}

	updateCurrentMinTime() {
		this.minTimeSchedule = this.gs.round(moment().add(5, 'minutes'), moment.duration(5, 'minutes'), 'ceil').format();
	}

	setScheduleTime() {
		this.updateCurrentMinTime();
		if (this.message.scheduled_time < new Date(this.minTimeSchedule)) {
			this.message.scheduled_time = this.minTimeSchedule;
		}
	}

	minutesFilter = (d: Date): boolean => {
		const minutes = d.getMinutes();
		// Prevent not rounded minutes
		const isValid = minutes % 5 === 0;
		if (!isValid) {
			this.notification.message = this.translateService.getTranslationText('compose.schedule.scheduleMinutesInvalid');
			this.ns.notificationPriority();
		} else {
			this.scheduleError = '';
		}
		return isValid;
	}

	cancelScheduler() {
		this.clearScheduler();
		this.saveDefaults();
	}

	clearScheduler() {
		this.showScheduler = false;
		this.message.scheduled_time = '';
		this.scheduleMessage = '';
	}

	getAttachmentsData() {
		if (!this.libraryAttachments?.length && !this.getLibraryAttachmentsInProcess) {
			this.getLibraryAttachmentsInProcess = true;
			this.rs.getAttachment('').then((response) => {
				this.libraryAttachments = response || [];
				this.getLibraryAttachmentsInProcess = false;

				if (this.libraryAttachments.length) {
					this.getAttachmentsData();
				}
			}, err => {

			});
		}

		this.libraryAttachments.forEach(attachment => {
			try {
				attachment.name = decodeURIComponent(attachment.name);
			} catch (e) {
				console.log('Could not decode: ' + attachment.name);
			}

			attachment.selected = !!_.find(this.attachments, {_id: attachment._id});
		});

		this.showAttachmentLibrary = true;
		this.showAttachmentsSourceMenu = false;
	}

	confirmAttachmentsLibrary = () => {
		const selectedLibraryAttachments = _.filter(this.libraryAttachments, attachment => {
			if (attachment.selected) {
				attachment.id = attachment._id;
				attachment.finished = true;
			}

			return !!attachment.selected;
		});

		if (selectedLibraryAttachments.length > dic.CONSTANTS.composeMaxAttachments) {
			this.showErrorMessage(dic.ERRORS.composeTooManyAttachments);
			return;
		}

		this.attachments = selectedLibraryAttachments;
		this.advanced.secure.enforce_attachment_encryption.value = true;
		this.showAttachmentLibrary = false;
		this.saveDefaults();
	}

	showErrorMessage = (message, ...messageParams: any[]) => {
		this.ns.clear();
		
		this.ns.showErrorMessage(message, ...messageParams);
		const scope = this;

		setTimeout(() => {
			if (scope.notification.message === util.format(this.translateService.getTranslationText(`ERRORS.${message}`), ...messageParams)) {
				scope.ns.clear();
			}
		}, 4500);
	}
}
