<div *ngIf="sensitivity" style="padding-top: 10px;">
	<div class="flxClmn">
		<div *ngIf="!sensitivity.score" style="font-style: italic;">
			{{'mailbox.sensitivity.notSensitive' | translate}}
			<input type="button" value="0" class="redborder fa" style="background:limegreen;width:25px; height: 24px;margin: 0 10px;color: white !important;border: 0; cursor: default;padding: 6px 2px;">
		</div>
		<div *ngIf="!sensitivity.score" style="color: #486cb5; opacity: 0.95; max-width: 612px; white-space: break-spaces;" class="flxRow hSpace1">
			<i class="fas fa-exclamation-triangle"></i>
			<span>{{'mailbox.sensitivity.sensitiveDlpMsg' | translate}}</span>
		</div>
	</div>

	<div *ngIf="sensitivity.score">
		<div class="sensitive-row" *ngIf="sensitivity.score && (!sensitivity.sensitive_sub_types || !sensitivity.sensitive_sub_types.length)">
			<div class="header">{{'mailbox.sensitivity.subType' | translate}}:</div>
			<div class="value">{{sensitivity.sensitive_sub_type}}</div>
		</div>
		<div class="sensitive-row no-height" *ngIf="sensitivity.sensitive_sub_types && sensitivity.sensitive_sub_types.length">
			<div class="header">{{'mailbox.sensitivity.subTypes' | translate}}:</div>
			<div class="value">{{sensitivity.sensitive_sub_types.join(', ')}}</div>
		</div>
		<div class="sensitive-row no-height" *ngIf="sensitivity.score">
			<div class="header">{{'mailbox.sensitivity.sensitiveContent' | translate}}:</div>
			<div class="value long">{{sensitivity.sensitive_found}}</div>
		</div>
		<div class="sensitive-row no-height">
			<div class="header">{{'mailbox.sensitivity.sensitiveScore' | translate}}:</div>
			<input type="button" value="{{sensitivity.score}}"
				   [ngStyle]="sensitivity.score < 4 && {'background':'var(--warning)'} || sensitivity.score < 5 && {'background':'#FF851B'} || sensitivity.score === 5 && {'background':'#FF4136'}"
				   class="redborder fa" style="width: 25px; height: 24px; margin: 0px; border: 0px; color: white !important; cursor: default; padding: 6px 2px;"
				   tooltip="{{'mailbox.sensitivity.sensitiveScoreTooltip' | translate}}">
			<a (click)="sensitiveReportText = ''; sensitiveInfoMessage = ''; showReportWrongSensitivity = true;" style="margin-left: 5px;">{{'mailbox.sensitivity.reportWrongResult' | translate}}</a>
		</div>
		<div class="sensitive-row" *ngIf="sensitivity.compliance && sensitivity.compliance.length">
			<div class="header">{{'mailbox.sensitivity.compliance' | translate}}:</div>
			<div class="value">{{sensitivity.compliance.join(', ')}}</div>
		</div>
	</div>
	<div *ngIf="showReportWrongSensitivity" style="margin-top: 30px;">
		<div>{{'mailbox.sensitivity.reportWrong' | translate}}.</div>
		<textarea style="width: 100%; height: 60px; margin-bottom: 0;"
				  placeholder="{{'mailbox.sensitivity.reportWrongPlaceholder' | translate}}"
				  [(ngModel)]="sensitiveReportText">
		</textarea>
		<div class="warning-action-wrapper">
			<a class="warning-action-btn first"
			   (click)="showReportWrongSensitivity = false;">
				{{'mailbox.sensitivity.cancelReport' | translate}}
			</a>
			<a class="warning-action-btn"
			   (click)="reportEmailSensitivity()"
			   [ngClass]="{'disabled': !sensitiveReportText || reportWrongSensitivityInProcess}">
				{{'mailbox.sensitivity.reportAction' | translate}}
			</a>
		</div>
	</div>
	<span class="sensitive-message">{{sensitiveInfoMessage}}</span>
</div>
