<div class="compose-popup-block">
	<div class="compose-popup-wrap">
		<div class="loading-spinner" *ngIf="showSpinner"></div>
		<span class="title">{{'compose.files.selectLibAttachments' | translate}}</span>
		<a class="close1 closeRed" (click)="attachmentsManagerData.openAttachmentsManager = false">
			<i class="zmdi zmdi-close"></i>
		</a>

		<!--Search library attachments-->
		<div class="row" style="padding: 0">
			<div class="input-holder"
				 style="top: 0;">
				<input type="search"
					   placeholder="{{'compose.files.librarySearchAttachments' | translate}}"
					   autofocus appAutoFocus
					   maxlength="{{dic.CONSTANTS.maxCharsInSearch}}"
					   [(ngModel)]="attachmentSearch">
				<i class="zmdi zmdi-search"></i>
			</div>
		</div>

		<div class="row">
			<div class="row-top"
				 [ngClass]="{'alt' : revDate}">
				<span class="contact">{{filteredAttachments.length}} {{'compose.files.attachments' | translate | lowercase}}</span>
				<a class="select black" (click)="revDate = !revDate; $event.stopPropagation()">
					{{'compose.files.sortAttachmentsTime' | translate}}
					<i *ngIf="!revDate" class="icon zmdi zmdi-chevron-down"></i>
					<i *ngIf="revDate" class="icon zmdi zmdi-chevron-up"></i>
				</a>
			</div>
			<ul class="list-wrap">
				<ng-container *ngFor="let attachment of filteredAttachments | orderBy: 'last_sent': revDate">
					<li *ngIf="attachment.name.toLowerCase().indexOf(attachmentSearch.toLowerCase()) >= 0"
						(click)="toggleAttachment(attachment)"
						(mouseover)="attachment.mouseover = true"
						(mouseleave)="attachment.mouseover = false">
						<a class="ellipsis" style="max-width: 80%;">
							<i class="zmdi"
							   [ngClass]="{'zmdi-plus' : !attachment.checked && attachment.mouseover,
									  'zmdi-check' : attachment.checked && !attachment.mouseover,
									  'zmdi-minus' : attachment.checked && attachment.mouseover}">
							</i>
							{{attachment.name}}
							<span *ngIf="attachment.size"
								  [style.right]="filteredAttachments.length >= 5 && isOutlookClient ? '20px' : '5px'">
								{{attachment.size | formatBytes }}
							</span>
						</a>
					</li>
				</ng-container>
			</ul>
		</div>
		<div class="row" style="padding: 0;">
			<div class="row-top">
				<span class="contact black" style="line-height: 30px;">{{selectedAttachments.length || 0}} {{'compose.files.libraryAttachmentsSelected' | translate}}</span>
				<button type="button" class="btn" style="float: right;" (click)="addAttachmentsToMessage()">{{'compose.files.libraryDone' | translate}}</button>
			</div>
			<ul class="select-list">
				<ng-container *ngFor="let attachment of selectedAttachments">
					<li *ngIf="attachment.checked"
						(click)="toggleAttachment(attachment);$event.stopPropagation()">
						<a class="closeRed">{{attachment.name}} <span
								*ngIf="attachment.size">{{attachment.size | formatBytes }}</span><i
								class="zmdi zmdi-close"></i></a>
					</li>
				</ng-container>
			</ul>
		</div >
	</div>
</div>
