<div class="form-holder"
	 [ngClass]="{'transition-blur': showTransitionCover}">
	<div class="transition-blur-label" *ngIf="showTransitionCover">
		<div class="loading-spinner"></div>
	</div>
	<form class="message-form" novalidate>
		<fieldset style="min-width: auto;">
			<div class="form-content">
				<div class="col">
					<div class="message-heading content">
						<ul class="message-tab-list">
							<li *ngIf="!isTrustifiEmail" [ngStyle]="{'max-width': getActiveTabsCount(currentMessage, authTabExists) >= 4 ? '55px': 'unset'}">
								<a placement="right" class="ellipsis" [ngClass]="{'active' : tab === dic.CONSTANTS.mailboxTabs.inboundShield}" (click)="updateTab(dic.CONSTANTS.mailboxTabs.inboundShield);preventDefault($event)">{{'mailbox.general.tabs.inboundShield' | translate}}</a>
							</li>
							<li *ngIf="!authTabExists && currentMessage.methods.track">
								<a [ngClass]="{'active' : tab === dic.CONSTANTS.mailboxTabs.tracking}" (click)="updateTab(dic.CONSTANTS.mailboxTabs.tracking);preventDefault($event)">{{'mailbox.general.tabs.tracking' | translate}}</a>
							</li>
							<li *ngIf="!authTabExists && currentMessage.methods.postmark">
								<a [ngClass]="{'active' : tab === dic.CONSTANTS.mailboxTabs.postmark}" (click)="updateTab(dic.CONSTANTS.mailboxTabs.postmark);preventDefault($event)">{{'mailbox.general.tabs.postmark' | translate}}</a>
							</li>
							<li *ngIf="!authTabExists && currentMessage.advanced">
								<a [ngClass]="{'active' : tab === dic.CONSTANTS.mailboxTabs.advanced}" (click)="updateTab(dic.CONSTANTS.mailboxTabs.advanced);preventDefault($event)">{{'mailbox.general.tabs.advanced' | translate}}</a>
							</li>
							<li *ngIf="!authTabExists && currentMessage.sensitivity && (!userInfo.plan.policy.allow_admin_policy_only || !userInfo.plan.policy.allow_admin_policy_only.value)">
								<a [ngClass]="{'active' : tab === dic.CONSTANTS.mailboxTabs.sensitivity}" (click)="updateTab(dic.CONSTANTS.mailboxTabs.sensitivity);preventDefault($event)">{{'mailbox.general.tabs.sensitivity' | translate}}</a>
							</li>
							<li *ngIf="authTabExists" [ngStyle]="{'max-width': getActiveTabsCount(currentMessage, authTabExists) >= 5 ? '55px': 'unset'}">
								<a placement="left" class="ellipsis" [ngClass]="{'active' : tab === dic.CONSTANTS.mailboxTabs.authentication}" (click)="updateTab(dic.CONSTANTS.mailboxTabs.authentication);preventDefault($event)">{{'mailbox.general.tabs.authentication' | translate}}</a>
							</li>
						</ul>
					</div>
					<div class="actionRow flex-container" *ngIf="currentMessage._id && tab !== dic.CONSTANTS.mailboxTabs.authentication && tab !== dic.CONSTANTS.mailboxTabs.inboundShield">
						<div class="actionLabel flex-item" style="color: var(--primary-color-dark)" [hidden]="tab === 'recall'">{{'mailbox.general.actions.actions' | translate}}</div>
						<div class="actionItem flex-item" *ngIf="(tab === dic.CONSTANTS.mailboxTabs.tracking) && currentMessage.start_sending_time && getBlockingOpreations(false)"
							 (click)="changeMessageForRecipient()">
							<a *ngIf="recipientTrack.name && (recipientTrack.is_blocked || recipientTrack.is_access_blocked)" tooltip="{{'mailbox.general.actions.unblockRecipientTooltip' | translate}}" placement="bottom" [width]="160">{{'mailbox.general.actions.unblockRecipient' | translate}}</a>
							<a *ngIf="recipientTrack.name && !recipientTrack.is_blocked && !recipientTrack.is_access_blocked" tooltip="{{'mailbox.general.actions.blockRecipientTooltip' | translate}}" placement="bottom" [width]="160">{{'mailbox.general.actions.blockRecipient' | translate}}</a>
						</div>
						<div class="actionItem flex-item" tooltip="{{currentMessage.numberRecipientsBlocked === currentMessage.recipients_count ? ('mailbox.general.actions.recipientsBlockedTooltip' | translate) : ('mailbox.general.actions.blockEmailAllTooltip' | translate)}}"
							 *ngIf="tab === dic.CONSTANTS.mailboxTabs.advanced && !currentMessage.methods.secure_mass && currentMessage.start_sending_time && (currentMessage.methods.encrypt_content || currentMessage.methods.secure_reply || currentMessage.methods.secure_send || (currentMessage.sent.attachments.length && !currentMessage.skip_attachments_encryption))"
							 (click)="changeMessageStateForAllRecipients('block')"
							 [ngStyle]="currentMessage.numberRecipientsBlocked === currentMessage.recipients_count && {'opacity':'0.5','cursor':'not-allowed'}">
							<a [ngClass]="currentMessage.numberRecipientsBlocked === currentMessage.recipients_count ? 'disabled' : ''">{{'mailbox.general.actions.blockEmail' | translate}}</a>
						</div>
						<div class="actionItem flex-item" tooltip="{{currentMessage.numberRecipientsBlocked === 0 ? ('mailbox.general.actions.recipientsUnBlockedTooltip' | translate) : ('mailbox.general.actions.unblockEmailAllTooltip' | translate) }}"
							 *ngIf="tab === dic.CONSTANTS.mailboxTabs.advanced && !currentMessage.methods.secure_mass && currentMessage.start_sending_time && (currentMessage.methods.encrypt_content || currentMessage.methods.secure_reply || currentMessage.methods.secure_send || (currentMessage.sent.attachments.length && !currentMessage.skip_attachments_encryption))"
							 (click)="changeMessageStateForAllRecipients('unblock')"
							 [ngStyle]="currentMessage.numberRecipientsBlocked === 0 && {'opacity':'0.5','cursor':'not-allowed'}">
							<a [ngClass]="currentMessage.numberRecipientsBlocked === 0 ? 'disabled' : ''">{{'mailbox.general.actions.unblockEmail' | translate}}</a>
						</div>
						<div class="actionItem flex-item" tooltip="{{'mailbox.general.actions.updateMsgTooltip' | translate}}" placement="left"
							 *ngIf="currentMessage.methods.encrypt_content && allowPolicyRecall && (tab === dic.CONSTANTS.mailboxTabs.advanced || tab === dic.CONSTANTS.mailboxTabs.tracking) && tab !== 'recall' && !currentMessage.reply_to"
							 (click)="showUpdateMessageEditor()">
							<a>{{'mailbox.general.actions.updateMsg' | translate}}</a>
						</div>
                        <div class="actionItem flex-item" tooltip="{{'mailbox.general.actions.recallMsgTooltip' | translate}}" placement="left"
                             *ngIf="userInfo.plan.policy?.allow_recall_email?.value"
                             (click)="openConfirmRecallPopup()">
                            <a>{{'mailbox.general.actions.recallMsg' | translate}}</a>
                        </div>
						<div class="actionItem flex-item"
							 *ngIf="tab === dic.CONSTANTS.mailboxTabs.tracking && !userPolicy.allow_admin_policy_only.value"
							 (click)="printEmail()">
							<a>{{'mailbox.general.actions.print' | translate}}</a>
						</div>
						<div class="actionItem flex-item" *ngIf="tab === dic.CONSTANTS.mailboxTabs.postmark"
							 (click)="downloadAsPdf()">
							<a>{{'mailbox.general.actions.downloadAsPdf' | translate}}</a>
						</div>
						<div class="actionItem flex-item" *ngIf="tab === dic.CONSTANTS.mailboxTabs.postmark"
							 (click)="downloadFile('/postmark/download/' +currentMessage._id + '/' +recipientPostmark.recipient.id,
                                                                  'postmark_' + recipientPostmark.id +'.zip', 'application/zip', 0)">
							<a>{{'mailbox.general.actions.downloadData' | translate}}</a>
						</div>
						<div class="actionItem flex-item" *ngIf="tab === dic.CONSTANTS.mailboxTabs.postmark"
							 (click)="verifyPostmarkReceipt()">
							<a>{{'mailbox.general.actions.verify' | translate}}</a>
						</div>
						<div class="actionItem flex-item" *ngIf="tab === dic.CONSTANTS.mailboxTabs.sensitivity"
							 (click)="checkSensitivity()"
							 [ngClass]="{'disabled': checkSensitivityInProcess}">
							<a>{{'mailbox.general.actions.scanEmailSensitivity' | translate}}</a>
						</div>
					</div>
					<div class="message-tab-content"
						 [ngStyle]="{'padding': tab === dic.CONSTANTS.mailboxTabs.authentication ? '0px': '0 10px'}">
						<app-message-authentication
								[hidden]="tab !== dic.CONSTANTS.mailboxTabs.authentication"
								[resetData]="resetDataSubject.asObservable()">
						</app-message-authentication>
						<app-html-editor
								*ngIf="tab === 'recall'"
								[height]="contentHeight"
								[html]="currentMessage.sent.html"
								[showButtons]="true"
								(onSave)="updateEmailContent($event)"
								(onCancel)="closeRecallEditor()">
						</app-html-editor>
						<app-advanced class="bs-enabled"
                                *ngIf="tab === dic.CONSTANTS.mailboxTabs.advanced" style="display: block; max-height: calc(100vh - 178px); overflow-y: auto;"
                                [isMailbox]="true"
                                [methods]="methods"
                                [attachmentsExist]="currentMessage.sent.attachments && currentMessage.sent.attachments.length"
								[advanced]="advanced"
                                [userPolicy]="userPolicy"
                                (saveDefaultsEvent)="saveDefaults()"
                                (onChangeMethodToPhone)="validateRecipientsHasPhone()">
						</app-advanced>
						<app-message-inbound-shield
								class="displayFlow"
								*ngIf="!isTrustifiEmail && tab === dic.CONSTANTS.mailboxTabs.inboundShield"
								[itemChanged]="itemChangedSubject.asObservable()"
						>
						</app-message-inbound-shield>
						<app-message-tracking
								[style.display]="tab === dic.CONSTANTS.mailboxTabs.tracking ? 'block': 'none'"
								[hidden]="tab !== dic.CONSTANTS.mailboxTabs.tracking"
								[ngClass]="{'displayFlowGeneral': isTrustifiEmail,'displayFlow': !isTrustifiEmail,
											'tracking-attachments': currentMessage && currentMessage.sent && currentMessage.sent.attachments}"
								[currentMessage]="currentMessage"
								[trackingDataInput]="trackingDataSubject.asObservable()"
								[recipientTrackInput]="recipientTrackSubject.asObservable()"
								[showRecipientDetails]="showRecipientDetails"
								[updateRecipientError]="updateRecipientError"
								[onUpdateRecipientDetails]="updateRecipientDetails"
								[onValidateRecipientPhone]="validateRecipientPhone"
								[onValidateContactName]="validateContactName"
								[autoRefreshTracking]="autoRefreshTracking"
								(updateRecipientTrack)="updateRecipientTrack($event)"
								(onInitMailbox)="ngOnInit()">
						</app-message-tracking>
						<app-message-postmark
								*ngIf="tab === dic.CONSTANTS.mailboxTabs.postmark"
								[ngClass]="isTrustifiEmail ? 'displayFlowGeneral enable-ms-overflow' : 'displayFlow'"
								[recipientPostmark]="recipientPostmark"
								[currentMessage]="currentMessage"
								[contentWidth]="contentWidth"
								[trustifiDefault]="trustifiDefault">
						</app-message-postmark>
						<app-message-sensitivity
								class="displayFlowGeneral"
								[style.display]="isTrustifiEmail && tab === dic.CONSTANTS.mailboxTabs.sensitivity ? 'block': 'none'"
								[hidden]="!isTrustifiEmail || tab !== dic.CONSTANTS.mailboxTabs.sensitivity"
								[sensitivityInput]="sensitivitySubject.asObservable()">
						</app-message-sensitivity>
					</div>
					<div class="attachment-wrap" style="position:fixed; left: 5px; bottom: 90px; width: 95%;"
						 *ngIf="tab === dic.CONSTANTS.mailboxTabs.tracking && !showRecipientDetails && currentMessage && currentMessage.sent && currentMessage.sent.attachments">
						<div class="attachment-items"
							 [hidden]="!showUploadedAttachmentsPopup"
							 (mouseover)="showUploadedAttachmentsPopup = true"
							 (mouseleave)="showUploadedAttachmentsPopup = false">
							<ul class="attachment-list">
								<li *ngFor="let attachment of currentMessage.sent.attachments; let i = index">
									<a class="item" tooltip="{{'general.download' | translate}}"
									   [ngStyle]="{width: allowPolicyRecall && (currentMessage.methods.encrypt_content || currentMessage.methods.secure_send) ? '90%' : ''}"
									   (click)="downloadFile('/attachment/file/' + attachment._id, attachment.name, attachment.type, attachment.size)">
										<span class="name" style="font-size: 12px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;padding-right: 5px;">{{attachment.name}}</span>
										<span class="size" style="font-size: 11px;min-width: 60px; text-align: right;">{{attachment.size | formatBytes:2 }}</span>
										<span class="after"
											  [ngStyle]="{'width': attachment.progressPercentage + '%'}"
											  *ngIf="attachment.hasOwnProperty('progressPercentage')">
                                        </span>
									</a>
									<a *ngIf="allowPolicyRecall && !currentMessage.skip_attachments_encryption"
									   (click)="deleteAttachmentWarning(i)" tooltip="{{'general.delete' | translate}}">
										<i class="zmdi zmdi-close"></i>
									</a>
								</li>
							</ul>
						</div>
						<div class="upload-files-counter"
							 [hidden]="uploading"
							 (mouseover)="showUploadedAttachmentsPopup = true"
							 (mouseleave)="showUploadedAttachmentsPopup = false">
							{{currentMessage.sent.attachments && currentMessage.sent.attachments.length ? currentMessage.sent.attachments.length : ('general.no' | translate)}} {{'compose.files.filesUploaded' | translate}}
						</div>
						<div class="upload-files-counter loading-dots"
							 [hidden]="!uploading"
							 (mouseover)="showUploadedAttachmentsPopup = true"
							 (mouseleave)="showUploadedAttachmentsPopup = false">
							{{'recipient.uploading' | translate}}
						</div>
						<div class="attachment-row" style="position: relative; margin: 0;"
							 ng2FileDrop
							 [uploader]="uploader"
							 *ngIf="allowPolicyRecall && !currentMessage.skip_attachments_encryption && (currentMessage.methods.encrypt_content || currentMessage.methods.secure_send || (currentMessage.sent.attachments && currentMessage.sent.attachments.length))"
							 [ngClass]="{'hover': mouseOverAttachment, 'drag-over': mouseOverAttachment}"
							 (fileOver)="fileOver($event)"
							 (onFileDrop)="uploadFiles(uploader.queue)">
							<input class="file-input-uploader" type="file" multiple
								   ng2FileSelect
								   [uploader]="uploader"
								   (focus)="loseFocus()"
								   (onFileSelected)="uploadFiles(uploader.queue)"/>
							<div class="attachment-popup-wrap">
								<div class="attachment-popup">
									<div class="opener">
										<i class="zmdi zmdi-attachment-alt">&nbsp;</i>
										{{'compose.files.attachFiles' | translate}}
									</div>
								</div>
								<div class="title">{{'compose.files.dragDrop' | translate}}</div>
							</div>
						</div>
					</div>

					<!-----notification label (error/success)----->
					<!--some new pages has their own notifications viewer or styling, such as authentication page or inbound shield-->
					<div *ngIf="notification && notification.message && tab !== dic.CONSTANTS.mailboxTabs.authentication"
						 class="row w100 animated fadeIn" style="padding: 0 10px; min-height: 18px; position: fixed; bottom: 30px;"
						 [ngClass]="{'inbound-shield-notification' : tab === dic.CONSTANTS.mailboxTabs.inboundShield}"
						 [ngStyle]="{margin: notification.type === dic.CONSTANTS.NotificationTypes.REGULAR ? '0': '10px 0' }">
						<div class="check-row report-sent-msg" style="position: relative; max-width: calc(100% - 15px);">
                            <span style="display: block; font-size: 12px;"
								  [innerHTML]="notification.message"
								  [ngStyle]="{'color': notification.type === dic.CONSTANTS.NotificationTypes.INFO ? 'green':
                                                       notification.type === dic.CONSTANTS.NotificationTypes.WARNING ? '#CC6633':
                                                       notification.type === dic.CONSTANTS.NotificationTypes.REGULAR ? 'black': '#c2272e'}">
                                <i class="fa fa-check-circle" style="padding-right:3px;"
								   *ngIf="notification.type === dic.CONSTANTS.NotificationTypes.INFO">
                                </i>
                                <i class="fa fa-exclamation-circle" style="padding-right:3px;"
								   *ngIf="notification.type !== dic.CONSTANTS.NotificationTypes.INFO">
                                </i>
                            </span>
							<div *ngIf="deleteAttachmentIndex !== null" class="warning-action-wrapper flex">
								<a class="warning-action-btn first"
								   (click)="deleteAttachment()">
									{{'general.delete' | translate}}
								</a>
								<a class="warning-action-btn"
								   (click)="cancelDeleteAttachment()">
									{{'general.cancel' | translate}}
								</a>
							</div>
						</div>
						<a class="close-notification" style="display: none; position: absolute; top: 0; right: 0; padding: 10px;"
						   (click)="clear()">
							<i class="fa fa-times"></i>
						</a>
					</div>
					<div id="notificationSection" class="trustifiEmail animated fadeIn t-bg-secondary"
						 *ngIf="isTrustifiEmail && !(notification && notification.message) && !authTabExists && tab !== dic.CONSTANTS.mailboxTabs.authentication">
						<div style="float:left;">{{'compose.emailSent' | translate}} {{trustifiDefault.name}}.</div>
						<div style="display:inline-block;">
							{{finalSummary ? finalSummary : ""}}
							<i class="fas fa-check-circle t-color-success" style="margin-left:2px;display:inline-block;"></i>
						</div>
					</div>
				</div>

                <confirmation-popup
                    *ngIf="recallConfirmPopup?.show"
                    [title]="('mailbox.general.actions.recallMsg' | translate) + '?'"
                    [text]="'mailbox.general.actions.recallMsgConfirmText' | translate"
                    [isLoading]="recallConfirmPopup.recallEmailInProcess"
                    (closeCb)="recallConfirmPopup = null;"
                    (confirmCb)="recallEmailExecute();"
                    [error]="recallConfirmPopup.error">
                </confirmation-popup>

			</div>
		</fieldset>
	</form>
</div>
