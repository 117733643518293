export const dic = {

	STEP: {
		// key and value must be the same!
		Authenticated: 'Authenticated',
		Code: 'Code',
		Phone: 'Phone',
		Email: 'Email',
		Password: 'Password',
		Blocked: 'Blocked',
		Reply: 'Reply',
		Sign: 'Sign',
		Empty: 'Empty',
		Tracking: 'Tracking',
		SSO: 'SSO',
		ssoIdp: 'ssoIdp'
	},

	CONSTANTS: {
		samlAudience: 'https://trustifi.com',
		localStorageLang: 'localStorageLang',
		userRole: {
			user: 'user',
			admin: 'admin',
			subAdmin: 'sub-admin'
		},

		timeout: {
			recipients: 1000,
			sensitivity: 5200,
			subject: 1500
		},

		reportType: {
			trust: 'trust',
			report: 'report'
		},

		userProfileActions: {
			darkmode: 'darkmode',
			timezone: 'timezone'
		},

		recipientAuthAction: {
			wrongPhone: 'wrongPhone',
			askAccess: 'askAccess',
			changeAuthentication: 'changeAuthentication'
		},

		appStates: {
			login: 'login',
			content: 'content',
		},

		appPages: {
			compose: 'compose',
			mailbox: 'mailbox',
		},

		mailboxTabs: {
			inboundShield: 'Inbound Shield™',
			tracking: 'tracking',
			postmark: 'postmark',
			advanced: 'advanced',
			sensitivity: 'sensitivity',
			authentication: 'authentication',
			recall: 'recall',
		},

		status: {
			loading: 'loading',
			waiting: 'waiting',
			apply: 'apply',
			applying: 'applying',
			ready: 'ready',
			uploading: 'uploading'
		},

		emailStatus: {
			blocked: 'Blocked'
		},

		contentStatus: {
			initial: 'initial',
			process: 'process',
			decrypted: 'decrypted'
		},

		pinType: {
			text: 'text',
			voice: 'voice'
		},
		resendTimeout: 30000,
		lastTenPercentRate: 0.9,
		loadContactsLimit: 400,
		notificationTimeout: 7000,
		msMargin: 600,
		contentHeightMargin: 235,
		contentWidthMargin: 13,
		maxCharsInSearch: 100,

		listType: {
			mass: 'mass',
			regular: 'regular',
			default: 'default'
		},

		reportToList: {
			plan: 'plan',
			personal: 'personal'
		},

		authenticationMethod: {
			phone: 'phone',
			password: 'password',
			email: 'email',
			sso: 'SSO'
		},
		signupState: {
			fillForm: 'fill_form',
			success: 'success'
		},
		emailMaxSize: 254,
		composeMaxAttachments: 20,
		secureReplyMaxAttachments: 10,
		threatDetectionCacheSize: 20,
		maxAttachmentScanSize: 10485760,

		NotificationTypes: {
			INFO: 'info',
			WARNING: 'warn',
			ERROR: 'error',
			REGULAR: 'regular'
		},
		recipientStatus: {
			block: 'block',
			unblock: 'unblock'
		},
		recipientTpActions: {
			trust: 'trust',
			confirmStatus: 'confirm status',
			report: 'report'
		},
		threatProtection: {
			status: {
				analyzing: 'Analyzing',
				analyzingLinks: 'Analyzing links',
				safe: 'Safe',
				spam: 'Spam',
				grayMail: 'Graymail',
				malicious: 'Malicious',
				suspicious: 'Suspicious',
				error: 'Error',
				unsecured: 'Unsecured',
				pending: 'Pending'
			},
			message: {
				analyzingLinks: 'Analyzing links',
				analyzingFiles: 'Analyzing files',
				analyzingSender: 'Analyzing sender',
				noLinks: 'No malicious links detected.',
				noAttachments: 'No malicious attachments detected.',
				noSender: 'The message has been sent with a low-level signature or missing information.'
			},
			maxRetries: 26,
			retryTimeout: 12000
		},
		countryCode: {
			il: '972',
			us: '1',
			au: '61',
			br: '55',
			no: '47',
			es: '34',
			uk: '44',
			ch: '41',
			in: '91',
			nl: '31',
			hk: '852',
			nz: '64',
			za: '27',
			pa: '507'
		},
		countryCodeToPngSrc: {
			972: 'il.png',
			1: 'us.png',
			61: 'au.png',
			55: 'br.png',
			47: 'no.png',
			34: 'es.png',
			44: 'uk.png',
			41: 'ch.png',
			91: 'in.png',
			31: 'nl.png',
			852: 'hk.png',
			64: 'nz.png',
			27: 'za.png',
			30: 'gr.png',
			230: 'ml.png',
			507: 'pa.png'
		},
		supportedLanguages: {
			english: {code: 'us', labelLocal: 'English', shortened: 'EN'},
			spanish: {code: 'es', labelLocal: 'Español', shortened: 'ES'},
		},
		authenticationStatus: {
			authenticated: 'Authenticated',
			code: 'Code',
			phone: 'Phone',
			email: 'Email',
			password: 'Password',
			blocked: 'Blocked',
			totp: 'Totp'
		},
		localStorageFp: 'fp',

		updateEmailTypes: {
			content: 'content',
			attachment: 'attachment'
		},

		generalReportType: {
			sensitivity: 'sensitivity',
			spam: 'spam'
		},
		junkFolderName: 'Junk Email',

		hiddenFilesList: [
			'5c384ac184f3e9b8ff39c2a627eb7ea68968c16d3d1b21c829985aca1fa4559c'
		],

		planType: {
			free: 'free',
			pro: 'pro',
			heroku: 'heroku'
		},
		freePlanScansLimit: 10
	},

	HEADERS: {
		xAccessEnc: 'x-access-enc',
		xAccessToken: 'x-access-token',
		xTrustifiKey: 'x-trustifi-key',
		xTrustifiSecret: 'x-trustifi-secret',
		xFingerprint: 'x-trustifi-fingerprint',
		x2FAFingerprint: 'x-trustifi-2fa-fingerprint',
		xTrustifiSource: 'x-trustifi-source'
	},

	DIALOG_EVENTS: {
		0:     'Unknown error in dialog box.',
		12002: 'The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.',
		12003: 'The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.',
		12006: 'Dialog closed.',
		12007: 'A dialog box is already opened from this host window.',
		12009: 'The user chose to ignore the dialog box.'
	},

	SUB_DOMAINS: [
		'stagecompany',
		'trustifystage',
		'trustify',
		'iowabar',
		'sovasecure'
	],

	MESSAGES: {

		advancedSettingsSaved: 'advancedSettingsSaved',
		failedResetAdvancedSettings: 'failedResetAdvancedSettings',
		failedSavingEmail: 'failedSavingEmail',

		repliedSuccess: 'repliedSuccess',

		supportContactUs: 'supportContactUs',

		accessOnce: 'accessOnce',
		replyMsg: 'replyMsg',

		recipientUpdatedSuccess: 'recipientUpdatedSuccess',
		recipientStateBlock: 'recipientStateBlock',
		recipientStateUnBlock: 'recipientStateUnBlock',

		emailStateBlock: 'emailStateBlock',
		emailStateUnBlock: 'emailStateUnBlock',

		resendPinCode: 'resendPinCode',
		attachmentsUploaded: 'attachmentsUploaded',
		askAccessSuccess: 'askAccessSuccess',
		recipientNotMyPhone: 'recipientNotMyPhone',
		attachmentRemove: 'attachmentRemove',
		attachmentDeleteQuestion: 'attachmentDeleteQuestion',
		attachmentAdded: 'attachmentAdded',
		attachmentDeleted: 'attachmentDeleted',
		attachmentAddedFromLibrary: 'attachmentAddedFromLibrary',

		importContacts: 'importContacts',
		importNoContacts: 'importNoContacts',

		messageRecalled: 'messageRecalled',
		messageUpdated: 'messageUpdated',
		signedInSuccess: 'signedInSuccess',
		downloadSuccess: 'downloadSuccess'
	},

	ERRORS: {
		adminConnectError: 'Sign in failed with error %s. If you created or modified this admin lately then it may take some time until it will be updated, you can try again in a few minutes',
		multipleLogins: 'Unable to login to an account different from your original login',

		emailInvalidOrAbsent: 'emailInvalidOrAbsent',
		changefailed: 'changefailed',
		authSignInError:  'authSignInError',
		serverError: 'serverError',
		ssoError: 'ssoError',
		lowmacver: 'lowmacver',
		timeout: 'timeout',
		toomany: 'toomany',
		repeatreq: 'repeatreq',
		invalidPhoneNumber: 'invalidPhoneNumber',
		attachmentAlreadyUploaded: 'attachmentAlreadyUploaded',
		recipAuthReplyTooManyAttachments: 'recipAuthReplyTooManyAttachments',
		composeTooManyAttachments: 'composeTooManyAttachments',
		composeCannotUploadAttachments: 'composeCannotUploadAttachments',
		downloadingNotAllowed: 'downloadingNotAllowed',
		replyWithOneRecipient: 'replyWithOneRecipient',
		recallPostmark: 'recallPostmark',
		retrieveFailed: 'retrieveFailed',
		downloadFailed: 'downloadFailed',
		failedUpdateOfficeContact: 'failedUpdateOfficeContact',

		emailRequired: 'emailRequired',
		emailInvalid: 'emailInvalid',
		contactInvalid: 'contactInvalid',
		contactExistAlready: 'contactExistAlready',
		phoneInvalid: 'phoneInvalid',
		phoneMissing: 'phoneMissing',
		phoneMissingOneOrMore: 'phoneMissingOneOrMore',
		cannotAddOwnDomain: 'cannotAddOwnDomain',
		contactHintWithoutPassword: 'contactHintWithoutPassword',
		cannotDeleteRecipientPassword: 'cannotDeleteRecipientPassword',
		emailNoDefaultMFAPassword: 'emailNoDefaultMFAPassword',
		hintCannotHaveMFAPassword: 'hintCannotHaveMFAPassword',
		requiredField: 'requiredField',
	}
};
