<div class="flxClmn bs-enabled" style="height: 100vh; overflow: auto; padding: 15px; padding-top: 0;">

	<div class="flex-column login-box" style="padding: 0 5%; flex: 4 0 500px;">
		<div class="align-self-center d-flex centralize p-2" style="flex-basis: 95px; flex-shrink: 0; max-width: 80%;">
			<a [href]="corpBaseUrl" target="_blank">
				<img src="images/logo-transparent.png" style="max-height: 35px;" alt="{{trustifiDefault.name}}">
			</a>
		</div>
		<br/>
		<!--FORM: email or SSO (initial)-->
		<div class="flex-column f-1-0 w-100 gap-3 animated fadeIn" *ngIf="loginStatus === loginStatuses.emailOrSSOForm">
			<span class="fw-bold text-center" style="font-size: 1rem;">{{'login.LoginWithEmail' | translate}}</span>
			<br/>
			<div class="position-relative w-100">
				<input type="text" class="bs-form-control" placeholder="{{'authentication.authWithEmailPlaceholder' | translate}}" aria-label="Email address"
					   [ngClass]="{'inputError' : initialEmailError}"
					   autocomplete="off"
					   [autocomplete]="random"
					   (keydown)="initialEmailError = null;"
					   (keyup.enter)="confirmInitialEmail(userEmailInput)"
					   [(ngModel)]="userEmailInput">
				<span style="position: absolute; bottom: 2px; right: 0; transform: translateY(100%) translateY(3px); font-size: 11px; color: red;"
					  *ngIf="initialEmailError">
					{{userEmailInput ? ('signup.emailAddressError' | translate) : ('ERRORS.requiredField' | translate)}}
				</span>
			</div>
			<div class="single-button flex-row centralize w-100"
				 [ngClass]="{'disabled' : sendInitialEmailInProcess}"
				 (click)="confirmInitialEmail(userEmailInput)">
				<i class="fa fa-spin fa-spinner" *ngIf="sendInitialEmailInProcess"></i>
				<span class="fw-normal" *ngIf="!sendInitialEmailInProcess">{{'general.continue' | translate}}</span>
			</div>
			<div class="flex-row gap-1 w-100">
				<div class="md-checkbox" [ngClass]="{'disabled': loading}">
					<input id="reportTrustCheckbox" type="checkbox" name="reportTrustCheckbox" style="height: 0; width: 0;"
						   [(ngModel)]="inflogin">
					<label for="reportTrustCheckbox" style="color: var(--color-primary)">
						<span>{{'login.keepMe' | translate}}</span>
					</label>
				</div>
				<i class="fa fa-question-circle" style="font-weight: 400; font-size: 0.75rem;" tooltip="{{'login.keepMeTooltip' | translate}}" placement="bottom"></i>
			</div>
			<div class="OR-seperator-line my-4 w-100 opacity-50"></div>
			<div class="flex-column centralize gap-1 w-100">
				<div class="single-button color-inverted border align-items-center flex-row gap-3 w-75" style="min-width: fit-content;"
					 (click)="socialLogin()">
					<i class="fab fa-microsoft fa-microsoft-colorful" style="font-size: 1.15rem; flex-basis: 20px;"></i>
					<span class="fw-normal text-black">{{'login.signInWithMicrosoft' | translate}} Microsoft</span>
				</div>
			</div>
			<br/>
			<div class="flex-column gap-1 centralize">
				<span>{{'login.newTo' | translate}} {{trustifiDefault.name}}?</span>
				<a class="blue-link no-underline"
				   (click)="updateLoginStatus(loginStatuses.signupForm);">
					{{'login.signUpNow' | translate}}
				</a>
			</div>
		</div>
		
		
		
		<!--FORM: login with password-->
		<div class="flex-column f-1-0 w-100 centralize gap-3 position-relative animated fadeIn" *ngIf="loginStatus === loginStatuses.passwordLoginForm">
			<span class="fw-500 text-muted text-center" style="font-size: 1rem; margin-top: -65px;">{{'login.LoginWithPassword' | translate}}</span>
			<br/>
			<input type="text" id="loginEmail" class="bs-form-control"
				   aria-label="Email"
				   value="{{userEmailInput}}"
				   [disabled]="true"
				   [readOnly]="true">
			<div class="position-relative w-100">
				<input type="{{!showPassword ? 'password' : 'text'}}" id="loginPassword"
					   class="w-100" style="padding-right: 27px;"
					   placeholder="{{'signup.password' | translate}}"
					   appAutoFocus [autoFocus]="true"
					   autocomplete="off"
					   [autocomplete]="random"
					   [(ngModel)]="password"
					   [ngClass]="{'inputError' : errorMsg , 'nofill': password && !showPassword}"
					   (keyup.enter)="continue();">
				<label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer hvr-darken">
					<input type="checkbox" [(ngModel)]="showPassword"/>
					<i class="fa fa-eye unchecked"></i>
					<i class="fa fa-eye-slash checked"></i>
				</label>
			</div>
			<div class="single-button flex-row centralize w-100"
				 [ngClass]="{'disabled' : loading}"
				 (click)="continue()">
				<i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
				<span class="fw-normal" *ngIf="!loading">{{'login.logIn' | translate}}</span>
			</div>
			<div class="flex-row gap-1 w-100">
				<div class="md-checkbox" [ngClass]="{'disabled': loading}">
					<input id="reportTrustCheckbox" type="checkbox" name="reportTrustCheckbox" style="height: 0; width: 0;"
						   [(ngModel)]="inflogin">
					<label for="reportTrustCheckbox" style="color: var(--color-primary)">
						<span>{{'login.keepMe' | translate}}</span>
					</label>
				</div>
				<i class="fa fa-question-circle" style="font-weight: 400; font-size: 0.75rem;" tooltip="{{'login.keepMeTooltip' | translate}}" placement="bottom"></i>
			</div>
			<div class="flex-row align-items-center mt-2 w-100 justify-content-between">
				<a class="flex-row align-items-center gap-1 no-underline blue-link"
				   (click)="updateLoginStatus(loginStatuses.emailOrSSOForm);">
					<i class="fa fa-arrow-left" style="font-size: 0.6rem;"></i>
					<span>{{'login.changeEmail' | translate}}</span>
				</a>
				<a class="no-underline blue-link"
				   (click)="changePassword()"
				   [ngClass]="{'disabled': changePasswordDisabled()}">
					{{'login.forgotPassword' | translate}}
				</a>
			</div>
		</div>


		<!--FORM: signup-->
		<signup-form-component class="flex-column f-1-0 w-100 animated fadeIn"
							   *ngIf="loginStatus === loginStatuses.signupForm"
							   (cancelCb)="updateLoginStatus(loginStatuses.emailOrSSOForm);">
		</signup-form-component>


		<!--FORM: MFA code-->
		<div class="flex-column gap-3 f-1-0 w-100 animated fadeIn" *ngIf="loginStatus === loginStatuses.mfaCodeForm">
			<span class="text-muted text-center fw-normal" style="font-size: 0.9rem;">{{'login.mfaTitle' | translate}}</span>
			<br/>

			<!--MFA phone-->
			<div class="flex-column f-1-0 w-100 justify-content-evenly gap-3 animated fadeIn position-relative"
				 *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.phone">
				<div class="flex-column gap-3 align-items-center w-100" style="margin-top: -20px;">
					<span class="fw-bold text-center w-100" style="font-size: 18px;">{{'authentication.authPhoneNumber' | translate}}</span>
					<span class="text-center text-muted" style="word-break: break-word;" *ngIf="data2Fa.hint">
						({{'authentication.phoneEndsWith' | translate}} <b>xxxxxxx{{data2Fa.hint}}</b>)
					</span>
				</div>
				<div class="flex-column gap-3 w-100">
					<div class="flex-row w-100 position-relative">
						<input class="bs-form-control" style="letter-spacing: 1px; background: white; text-align: center; padding: 7px 35px; max-width: 100%; border: 1px solid #ccc; font-size: 14px; min-height: 32px; transition: border 0.2s; margin: 0"
							   type="tel" required
							   (keydown.enter)="getPinCode(false); "
							   pattern="^[0-9]*$"
							   knifeOnlyNumbers
							   [ngStyle]="{'color' : data2Fa.phone && data2Fa.phone.phone_number && data2Fa.phone.phone_number.length ? 'black' : ''}"
							   [ngClass]="{'inputError' : errorMsg}"
							   autocomplete="off"
							   [autocomplete]="random"
							   appAutoFocus [autoFocus]="true"
							   [(ngModel)]="data2Fa.phone.phone_number">
						<div class="flex-row align-items-center gap-1" style="position: absolute; left: 12px; color: var(--gray2); top: 50%; transform: translateY(-50%);">
							<img *ngIf="data2Fa.flagIcon" style="width: 22px;" alt="Country code flag"
								 [src]="'images/countryFlags/'+data2Fa.flagIcon"
								 [ngClass]="{'ch': data2Fa.flagIcon === 'ch.png'}">
							<span style="font-size: 13px;">+{{data2Fa.country_code}}</span>
						</div>
						<a class="closeRed animated fadeIn" style="position: absolute; right: 0; top: 50%; font-size: 13px; opacity: 0.6; transform: translate(-13px, -47%);"
						   *ngIf="data2Fa.phone.phone_number"
						   (click)="data2Fa.phone.phone_number = ''">
							<i class="fa fa-times" style="opacity: 0.6"></i>
						</a>
					</div>
					<div class="single-button flex-row centralize w-100"
						 [ngClass]="{'disabled' : disableConfirmAuth}"
						 (click)="getPinCode(false);">
						<i class="fa fa-spin fa-spinner" *ngIf="disableConfirmAuth"></i>
						<span class="fw-normal" *ngIf="!disableConfirmAuth">Confirm</span>
					</div>
				</div>
				<!--choose phone or sms-->
				<div class="flex-row justify-content-center gap-3" *ngIf="data2Fa.isVoiceSupported">
					<div id="smsPIN" style="color: grey; width: 40%; max-width: 200px;">
						<a class="flex-column align-items-center gap-2 w100 p-1 no-underline"
						   [ngStyle]="data2Fa.type === dic.CONSTANTS.pinType.text && {'color': '#324c7e' , 'text-shadow' : '1px 0 0 #324c7e'}"
						   (click)="data2Fa.type = dic.CONSTANTS.pinType.text; data2Fa.resendType = dic.CONSTANTS.pinType.text">
							<i class="far fa-comment-dots" style="font-size: 20px;"></i>
							<span style="text-align: center">{{'authentication.sendPinSms' | translate}}</span>
						</a>
					</div>
					<span style="text-transform: uppercase; opacity: 0.7; margin-top: 20px;">or</span>
					<div id="callPIN" style="color: grey; width: 40%; max-width: 200px;">
						<a class="flex-column align-items-center gap-2 w100 p-1 no-underline"
						   [ngStyle]="data2Fa.type === dic.CONSTANTS.pinType.voice && {'color': '#324c7e' , 'text-shadow' : '1px 0 0 #324c7e'}"
						   (click)="data2Fa.type = dic.CONSTANTS.pinType.voice; data2Fa.resendType = dic.CONSTANTS.pinType.voice">
							<i class="fas fa-phone-volume" style="font-size: 20px;"></i>
							<span class="w100" style="text-align: center">{{'authentication.sendPinPhoneCall' | translate}}</span>
						</a>
					</div>
				</div>
				<!--description-->
				<div class="flex-column centralize gap-2 mt-4"
					 *ngIf="!data2Fa.isVoiceSupported">
					<i class="far fa-comment-dots" style="font-size: 20px;"></i>
					<span class="text-center" style="line-height: 1.4;">{{'authentication.pinCodeSent' | translate}}</span>
				</div>
				<br/>
				<a class="mx-auto text-center hSpace05 blue-link no-underline hSpace1" (click)="navigateToLogin()">
					<i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
					<span>{{'login.changeUser' | translate}}</span>
				</a>
			</div>

			<!--MFA email-->
			<div class="flex-column f-1-0 w-100 justify-content-evenly gap-3 animated fadeIn position-relative" *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.email">
				<span class="fw-bold text-center" style="font-size: 18px;">{{'authentication.authWithEmail' | translate}}</span>
				<div class="flex-column gap-3 w-100">
					<div class="flex-row w-100 position-relative">
						<input class="bs-form-control" style="letter-spacing: 1px; background: white; text-align: center; padding: 7px 35px; max-width: 100%; border: 1px solid #ccc; font-size: 14px; min-height: 32px; transition: border 0.2s; margin: 0"
							   type="text"
							   (keydown.enter)="confirmEmail(false); "
							   placeholder="{{data2Fa.hint}}" required
							   [ngClass]="{'inputError' : errorMsg || data2Fa.invalidEmail}"
							   autocomplete="off"
							   [autocomplete]="random"
							   appAutoFocus [autoFocus]="true"
							   [(ngModel)]="data2Fa.emailAuth">
						<a class="closeRed animated fadeIn" style="position: absolute; right: 0; top: 50%; font-size: 13px; opacity: 0.6; transform: translate(-13px, -47%);"
						   *ngIf="data2Fa.emailAuth"
						   (click)="data2Fa.emailAuth = ''">
							<i class="fa fa-times" style="opacity: 0.6"></i>
						</a>
					</div>
					<div class="single-button flex-row centralize w-100"
						 [ngClass]="{'disabled' : disableConfirmAuth}"
						 (click)="confirmEmail(false); ">
						<i class="fa fa-spin fa-spinner" *ngIf="disableConfirmAuth"></i>
						<span class="fw-normal" *ngIf="!disableConfirmAuth">Confirm</span>
					</div>
				</div>
				<!--description-->
				<div class="flex-column gap-2 mt-4" style="align-items: center; color: #748FC3;">
					<div class="flxRow hSpace2" style="font-size: 20px;">
						<i class="fas fa-key"></i>
						<i class="fas fa-arrow-right"></i>
						<i class="far fa-envelope"></i>
					</div>
					<span class="text-center" style="line-height: 1.4;">{{'authentication.authPinCode' | translate}}</span>
				</div>
				<br/>
				<a class="mx-auto hSpace05 blue-link no-underline" (click)="navigateToLogin()">
					<i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
					<span>{{'login.changeUser' | translate}}</span>
				</a>
			</div>

			<!--MFA ToTp-->
			<div class="flex-column f-1-0 w-100 justify-content-evenly gap-3 animated fadeIn position-relative" *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.totp">
				<span class="fw-bold text-center" style="font-size: 18px;">{{'authentication.authTotp' | translate}}</span>
				<div class="flex-column align-items-center gap-2 w-100">
					<pin-code style="width: 100%; height: 45px; font-size: 1.3rem;"
							  [(pinCodeModel)]="data2Fa.totpCode"
							  (autoSubmit)="confirmTotp()"
							  [length]="6"
							  [(incorrect)]="errorMsg">
					</pin-code>
					<br/>
					<div class="single-button flex-row centralize w-100"
						 [ngClass]="{'disabled' : (!data2Fa.totpCode || data2Fa.totpCode.length < 6 || disableConfirmAuth)}"
						 (click)="confirmTotp(); ">
						<i class="fa fa-spin fa-spinner" *ngIf="disableConfirmAuth"></i>
						<span class="fw-normal" *ngIf="!disableConfirmAuth">{{'general.confirm' | translate}}</span>
					</div>
				</div>
				<div class="flex-column gap-2 mt-4" style="align-items: center; color: #748FC3;">
					<!--description-->
					<i style="font-size: 25px;" class="fas fa-key icons"></i>
					<span class="text-center" style="max-width: 60%; line-height: 1.4;">{{'authentication.authTotpDesc' | translate}}</span>
				</div>
				<br/>
				<a class="mx-auto hSpace05 blue-link no-underline" (click)="navigateToLogin()">
					<i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
					<span>{{'login.changeUser' | translate}}</span>
				</a>
			</div>

			<!--PIN code validation (email/phone)-->
			<div class="flex-column f-1-0 w-100 justify-content-evenly gap-3 animated fadeIn position-relative" 
				 *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.code">
				<div class="flex-column gap-3 align-items-center w-100">
					<span class="fw-bold text-center w-100" style="font-size: 18px;">{{'login.enterPinCodeLogIn' | translate}}</span>
					<span class="text-center text-muted" style="word-break: break-word;" *ngIf="isPhone">
						 {{'authentication.pinCodeWasSentTo' | translate}} <b>+{{data2Fa.phone.country_code}} {{data2Fa.phone_number_after}}</b>
					</span>
					<span class="text-center text-muted" style="word-break: break-word;" *ngIf="isEmail">
						 {{'authentication.pinCodeWasSentTo' | translate}} <b>{{data2Fa.emailAuth}}</b>
					</span>
				</div>
				<div class="flex-column align-items-center gap-3 w-100">
					<pin-code style="width: 100%; height: 45px; font-size: 1.3rem;"
							  [(pinCodeModel)]="data2Fa.pinCode"
							  (autoSubmit)="confirmPinCode()"
							  [length]="5"
							  [(incorrect)]="errorMsg">
					</pin-code>
					<br/>
					<div class="single-button flex-row centralize w-100"
						 [ngClass]="{'disabled' : disableConfirmAuth || !data2Fa.pinCode || data2Fa.pinCode.length < 5}"
						 (click)="confirmPinCode()">
						<i class="fa fa-spin fa-spinner" *ngIf="disableConfirmAuth"></i>
						<span class="fw-normal" *ngIf="!disableConfirmAuth">{{'authentication.validate' | translate}}</span>
					</div>
				</div>
				<div class="flex-column gap-2 w-100 align-items-center">
					<span>{{'authentication.codeNotReceive' | translate}}</span>
					<a *ngIf="isEmail"
					   (click)="!disableConfirmAuth && confirmEmail(true)"
					   [tooltip]="!enableResend ? ('authentication.codeResendTooltip' | translate) : ('authentication.resend' | translate)"
					   [ngStyle]="!enableResend ? {'cursor': 'not-allowed', 'color': 'var(--gray)'} : {'color' : 'rgb(50, 76, 126)'}"
					   class="fw-bold no-underline">
						{{'authentication.resend' | translate}}
					</a>
					<div class="flxRow-center w100 hSpace1" *ngIf="isPhone">
						<a (click)="data2Fa.type = dic.CONSTANTS.pinType.text; data2Fa.resendType = dic.CONSTANTS.pinType.text; getPinCode(true)"
						   [tooltip]="!enableResend ? ('authentication.codeResendTooltip' | translate) : ('authentication.resendSmsTooltip' | translate)"
						   [ngStyle]="!enableResend ? {'cursor': 'not-allowed', 'color': 'var(--gray)'} : {'color' : 'rgb(50, 76, 126)'}"
						   class="fw-bold no-underline f-1-0 text-center">
							{{'authentication.resendSms' | translate}}
						</a>
						<span *ngIf="data2Fa.isVoiceSupported" [ngStyle]="!enableResend ? {'color': 'var(--gray)'} : {'color' : 'var(--color-primary)'}">/</span>
						<a *ngIf="data2Fa.isVoiceSupported"
						   (click)="data2Fa.type = dic.CONSTANTS.pinType.voice; data2Fa.resendType = dic.CONSTANTS.pinType.voice; getPinCode(true)"
						   [tooltip]="!enableResend ? ('authentication.codeResendTooltip' | translate) : ('authentication.resendPhoneTooltip' | translate)"
						   [ngStyle]="!enableResend ? {'cursor': 'not-allowed', 'color': 'var(--gray)'} : {'color' : 'rgb(50, 76, 126)'}"
						   class="fw-bold no-underline f-1-0 text-center">
							{{'authentication.resendPhoneCall' | translate}}
						</a>
					</div>
				</div>
				<br/>
				<a class="mx-auto hSpace05 blue-link no-underline" (click)="navigateToLogin()">
					<i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
					<span>{{'login.changeUser' | translate}}</span>
				</a>
			</div>

			<!--Auth success massage-->
			<div class="flex-column f-1-0 w-100 centralize gap-3 animated fadeIn position-relative"
				 *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.authenticated">
				<div class="flxRow hSpace05" style="align-items: center; text-align: center; color: green; opacity: 0.75;">
					<i class="far fa-check-circle"></i>
					<h4 style="font-weight: 400;">{{'login.authenticated' | translate}}</h4>
				</div>
			</div>

			<!--account blocked-->
			<div class="flex-column justify-content-evenly animated fadeIn" style="align-items: center; flex: 1 0; color: #282828;"
				 *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.blocked">
				<i class="fas fa-ban t-color-danger opacity-25" style="font-size: 6.5rem;"></i>
				<h4 class="text-dark fw-500">{{'login.blocked' | translate}}</h4>
				<span class="text-center text-dark" style="font-size: 0.9rem;">{{data2Fa.currentBlockMessage || ' '}}</span>
				<a class="mx-auto hSpace05 blue-link no-underline" (click)="navigateToLogin()">
					<i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
					<span>{{'login.goBack' | translate}}</span>
				</a>
			</div>
		</div>
	</div>

	<div class="flxClmn-end gap-2 pt-4 pb-4" style="flex: 1 0 155px;">
		<!--commercial text-->
		<span class="p-3 py-2 text-muted border animated fadeIn" style="font-size: 12px; text-align: justify;" *ngIf="loginStatus === loginStatuses.emailOrSSOForm">
			{{'login.getTrueEmailPower' | translate}}  <a [href]="userGuideUrl" target="_blank" class="blue-link text-decoration-underline">{{'login.guide' | translate}}</a>.
		</span>
		<!--error/success message-->
		<div class="t-color-danger text-start animated fadeIn w-100" *ngIf="errorMsg  && !(loginStatus === loginStatuses.mfaCodeForm && data2Fa.status === dic.CONSTANTS.authenticationStatus.blocked)">
			{{errorMsg}}
		</div>
		<div class="t-color-success text-start animated fadeIn w-100" *ngIf="successMessage">
			{{successMessage}}
		</div>
	</div>
</div>
