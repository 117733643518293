import {Inject, Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {Restangular_EmailAccess, Restangular_Graph, Restangular_ScanLinks, Restangular_ScanFiles, Restangular_ScanHeaders, Restangular_Attachments} from "../constants";
import {BehaviorSubject} from "rxjs";
import env from '../../env.json';

@Injectable({
	providedIn: 'root'
})
export class RestService {

	constructor(@Inject(Restangular) public Restangular,
				@Inject(Restangular_EmailAccess) public RestangularEmailAccess,
				@Inject(Restangular_Graph) public RestangularGraph,
				@Inject(Restangular_ScanLinks) public RestangularScanLinks,
				@Inject(Restangular_ScanFiles) public RestangularScanFiles,
				@Inject(Restangular_ScanHeaders) public RestangularScanHeaders,
				@Inject(Restangular_Attachments) public RestangularAttachments) {
		this.Restangular.configuration.require2FA = new BehaviorSubject<any>(null);
		this.Restangular.configuration.blockUser = new BehaviorSubject<any>(null);
	};

	setDefaultHeaders(headers: any) {
		this.Restangular.configuration.defaultHeaders = headers;
		this.RestangularGraph.configuration.defaultHeaders = headers;
		this.RestangularScanLinks.configuration.defaultHeaders = headers;
		this.RestangularScanFiles.configuration.defaultHeaders = headers;
		this.RestangularScanHeaders.configuration.defaultHeaders = headers;
		this.RestangularAttachments.configuration.defaultHeaders = headers;
	};

	setDefaultHeadersForAuthentication = (headers) => {
		this.RestangularEmailAccess.configuration.defaultHeaders = headers;
	};

	addDefaultHeaderForAuthentication = (headerKey, headerValue) => {
		this.RestangularEmailAccess.configuration.defaultHeaders[headerKey] = headerValue;
	};

	threatProtectionScanLinks = (threatData) => this.RestangularScanLinks.all('scan').all('links').post(threatData).toPromise();
	threatProtectionScanFiles = (threatData) => this.RestangularScanFiles.all('scan').all('files').post(threatData).toPromise();
	threatProtectionScanHeaders = (threatData) => this.RestangularScanHeaders.all('scan').all('headers').post(threatData).toPromise();

	getAuthStatus(data) {
		let promise;
		if (data.metrics) {
			promise = this.RestangularEmailAccess.all('emailAccess').all('authStatus').post(data).toPromise();
		}
		else {
			promise = this.RestangularEmailAccess.all('emailAccess').all('authStatus').customGET('', data).toPromise();
		}
		return promise;
	}

	sendLogs(type, fName, data) {
		try {
			if (typeof data === 'string') {
				data = {message: data};
			}

			let user = Office.context.mailbox && Office.context.mailbox.userProfile;
			data.accountType = user && user.accountType;
			data.userName = user && user.emailAddress;
		}
		catch(ex) {}

		try {
			data.contextInformation = Office.context.diagnostics;
			data.mailboxInformation = Office.context.mailbox && Office.context.mailbox.diagnostics;
		}
		catch(ex) {}

		data.type = type;
		data.fName = fName;
		data.agent = window.navigator.userAgent;
		data.message = data.message || data.error?.message || data.err?.message || data.ex?.message || 'Error';
		console[data.type || 'error']('[Trustifi] '+data.message, data);

		//DEBUG #12125
		//if(data.accountType === 'enterprise' || data.accountType === 'office365' || !env.environment.startsWith('production')) {
			this.Restangular.all('general').all('logs').post(data).toPromise().catch(err => {
				console.error('[Trustifi] '+err.message, data);
			});
		//}
	}

	doRecipientAuthentication2Fa = (data) =>                 this.RestangularEmailAccess.all('emailAccess').all('2fa').post(data).toPromise();
	doAuthAction = (action) =>                         		 this.RestangularEmailAccess.all('emailAccess').all('authAction').one(action).post('').toPromise();
	replyDeleteAttachment = (attachmentId) =>    		     this.RestangularEmailAccess.all('emailAccess').one('ReplyAttachmentRemove', attachmentId).post('').toPromise();
	replyMessage = (data) =>                     		     this.RestangularEmailAccess.all('emailAccess').all('Reply').post(data).toPromise();
	getRecipientSignature = () => 							 this.RestangularEmailAccess.all('emailAccess').all('signature').get('').toPromise();

	getEmailDetailsOmail = (emailId) =>          		     this.Restangular.all('email').all('display').all('omail').one(emailId).get('').toPromise();

	getAttachment = (attachmentId) =>            			 this.Restangular.one('attachment', attachmentId).get('').toPromise();

	getContacts = () =>                 		     	 	 this.Restangular.all('contact').get('').toPromise();
	addNewContact = (contact) =>                 		     this.Restangular.all('contact').post(contact).toPromise();
	updateContact = (contactId, contact) =>      		     this.Restangular.one('contact', contactId).customPUT(contact).toPromise();
	getContactByEmail = (email) =>               			 this.Restangular.all('contact').all('email').one(email).get('').toPromise();

	verifyReceiptPostmark = (emailId, recId) =>  		     this.Restangular.all('postmark').all('verify').one(emailId, recId).get('').toPromise();

	getEmailEvents = (emailId) =>                  		     this.Restangular.all('email').all('tracking').one(emailId).get('').toPromise();

	changeMessageForRecipient = (emailId, recId, data) =>      this.Restangular.all('email').all('state').all(emailId).all(recId).customPUT(data).toPromise();
	changeMessageStateForAllRecipients = (emailId, data) =>   this.Restangular.all('email').all('state').all(emailId).customPUT(data).toPromise();
	updateAdvancedMessage = (emailId, data) =>   		     this.Restangular.all('email').all('advanced').all(emailId).customPUT(data).toPromise();

	recallEmail = (emailId) =>     		     				this.RestangularAttachments.all('email').all('recall').all(emailId).customPUT('').toPromise();
	updateEmailContent = (emailId, data) =>     		     this.Restangular.all('email').all('data').all(emailId).customPUT(data).toPromise();
	decryptEmailContent = (emailId) =>          		     this.Restangular.all('email').all('data').one(emailId).get('').toPromise();
	scheduleSendNow = (emailId) =>               			 this.Restangular.all('email').one('schedule', emailId).customPUT('').toPromise();
	updateScheduledTime = (data) =>              			 this.Restangular.all('email').all('schedule').all(data.email_id).post(data).toPromise();
	deleteMessage = (emailId) =>                 			 this.Restangular.one('email', emailId).customDELETE('').toPromise();
	checkEmailSensitivity = (emailId) =>                 	 this.RestangularAttachments.all('email').one('sensitivity', emailId).customPUT('').toPromise();

	supportContact = (data) =>                  		     this.Restangular.all('general').all('support').all('contact').post(data).toPromise();
	sendReport = (data) =>                       			 this.Restangular.all('general').all('report').customPUT(data).toPromise();

	updateUserSettings = (data) =>                  		 this.Restangular.all('user').all('settings').customPUT(data).toPromise();

	sendUserThreats = (data) =>                  		     this.Restangular.all('user').all('threats').customPUT(data).toPromise();

	userLogin = (email) =>                                   this.Restangular.all('user').all('login').one(email).get('').toPromise();
	exchangeTokenOpenID = (data) =>                          this.RestangularEmailAccess.all('openid').all('token').post(data).toPromise();
	getSignedUrl = (data) =>                                 this.Restangular.all('admin').all('archive').all('migration').post(data).toPromise();
	signupUser = (user) =>                      		     this.Restangular.all('user').post(user).toPromise();
	updateUserAdvanced = (data, tmp) =>         		     this.Restangular.all('user').all('preferences').all('advanced').one(tmp).customPUT(data).toPromise();
	createApiKey = () =>                        		     this.Restangular.all('user').all('api').post({useOld: true, enabled: true, skipNotify: true}).toPromise();
	createOToken = () =>                        		     this.Restangular.all('user').all('oToken').post('').toPromise();
	getUserVerified = () =>                     		     this.Restangular.all('user').all('verficationMail').get('').toPromise();
	sendVerficationMail = () =>                 		     this.Restangular.all('user').all('verficationMail').post('').toPromise();
	do2FaAuthentication = (data) => 					     this.Restangular.all('user').all('2fa').post(data).toPromise();
}
