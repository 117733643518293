<div class="dropdown"
	 *ngIf="originalDropdownObjects"
	 [style.color]="originalDropdownObjects.length === 1 ? 'grey' : 'black'"
	 [ngClass]="dropdownShow ? 'active' : ''"
	 (click)="toggleDropdown()"
	 (focus)="dropdownShow"
	 (blur)="!dropdownShow"
	 (clickOutside)="dropdownShow = false">
	<div class="select w100 flxRow-between ellipsis">
		<a style="cursor: pointer; margin-right: 5px; flex: 1 0; display: block;" class="ellipsis" (click)="clickObject($event)">
			{{innerSearchField ? selectedObject[innerSearchField][searchField] : selectedObject[searchField]}}
		</a>
		<span *ngIf="clickSelected" class="zmdi zmdi-edit" title="{{clickTooltip}}" style="font-size: 15px; margin: 0 3px; padding: 0 3px;" (click)="clickObject($event); dropdownShow = false;"></span>
		<i class="fas fa-chevron-left" style="position: absolute;top: 9px;right: 10px;color: #888;"
		   *ngIf="originalDropdownObjects.length > 1">
		</i>
	</div>
	<div class="input-holder"
		 *ngIf="originalDropdownObjects.length > 1"
		 (click)="$event.stopPropagation();"
		 [ngStyle]="dropdownShow ? {'display': 'block'} : {'display': 'none'}"
		 style="position: relative; top: 1px;">
		<a class="search-icon">
			<i class="fas fa-search" style="font-size: 16px;padding-top: 2px;"></i>
		</a>
		<input id="search" type="text"
			   appAutoFocus
			   autocomplete="off"
			   placeholder="Search"
			   [maxLength]="dic.CONSTANTS.maxCharsInSearch"
			   [(ngModel)]="searchText"
			   (ngModelChange)="searchObject(searchText)">
		<a class="search-icon close-x" style="top: 20px;" (click)="clearSearch()">
			<i class="zmdi zmdi-close" style="font-size: 21px;"></i>
		</a>
	</div>
	<ul class="dropdown-menu"
		*ngIf="originalDropdownObjects.length > 1"
		[ngClass]="dropdownShow ? 'active' : ''"
		[style.height.px]="dropdownShow ? dropdownObjects.length * 36 : 0">
		<li *ngFor="let object of dropdownObjects"
			(click)="selectObject(object); $event.stopPropagation();"
			id="{{innerSearchField ? object[innerSearchField][searchField] : object[searchField]}}">
			{{innerSearchField ? object[innerSearchField][searchField] : object[searchField]}}
		</li>
	</ul>
</div>
