import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ENV_CONSTS} from '../../constants';
import {AuthService} from '../../services/auth.service';
import {Office365Service} from '../../services/office365.service';
import {GeneralService} from '../../services/general.service';
import { dic } from '../../dictionary';
import {RestService} from '../../services/rest.service';
import {NotificationService} from '../../services/notification.service';
import * as _ from 'lodash';
import * as util from 'util';
import {AccessClientService} from '../../services/access-client.service';
import {Subject} from 'rxjs';

import 'jspdf';
import 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {FileUploader} from 'ng2-file-upload';

@Component({
	selector: 'app-mailbox',
	templateUrl: './mailbox.component.html',
	styleUrls: ['./mailbox.component.scss']
})
export class MailboxComponent implements OnInit, OnDestroy {

	tab = '';
	dic = dic;
	downloadedFiles = [];
	currentMessage: any = {trackingData: [], postmarkData: [], methods: {}, advanced: {}};
	showPhoneWarning = false;
	contentHeight = window.innerHeight - dic.CONSTANTS.contentHeightMargin;
	contentWidth = window.innerWidth - dic.CONSTANTS.contentWidthMargin;
	errNotTrustifiEmail = false;
	recipient = null;
	lastEmailId = '';
	maxCountRefresh = 0;
	maxRequestCounter = 5;
	trustifiDefault;
	clear;
	isCustomized;
	showTransitionCover = false;

	intervalHwnd = 0;
	counter = 0;
	authTabExists: boolean;
	previousTab: string;
	notification: any;
	advanced: any = {};
	methods: any;
	recipientPostmark;
	finalSummary: string;
	userAuthenticated;
	advancedChanged = false;
	isTrustifiEmail = false;
	userProfile;
	subject = null;
	attachments = null;
	advancedChangedFilter: {};
	recipientTrack: any;
	recipientTrackSubject: Subject<object> = new Subject<object>();
	trackingDataSubject: Subject<object> = new Subject<object>();
	previousContent;
	showTabMenu: boolean;
	showInboxTabs = false;
	showRecipientDetails = false;
	userInfo: any;
	uploadedFiles = 0;
	deleteAttachmentIndex = null;
	uploader: FileUploader = new FileUploader({url: ENV_CONSTS.beBaseAttachmentUrl + '/attachment'});
	uploadSubscribers = {};
	uploading = false;
	mouseOverAttachment = false;

	isOutlookClient = Office.context.mailbox.diagnostics.hostName !== 'OutlookWebApp';
	showUploadedAttachmentsPopup: boolean;

	itemChangedSubject: Subject<void> = new Subject<void>();
	public resetDataSubject: Subject<void> = new Subject<void>();
	secureMethods: any;
	userPolicy: any;
	allowPolicyRecall: boolean;
	sensitivitySubject: Subject<object> = new Subject<object>();
	checkSensitivityInProcess = false;
	updateRecipientError = '';

	constructor(private authService: AuthService,
				private notificationService: NotificationService,
				private office365Service: Office365Service,
				private gs: GeneralService,
				private rs: RestService,
				private accessClientService: AccessClientService,
				private http: HttpClient,
				private ngZone: NgZone) {
		this.trustifiDefault = this.gs.trustifiDefault;
		this.clear = this.notificationService.clear;
		this.isCustomized = this.trustifiDefault.name.toLowerCase() !== ENV_CONSTS.trustifiDefault.toLowerCase();
		this.userAuthenticated = this.office365Service.roamingGet('userAuthenticated');
		this.userProfile = this.office365Service.getUserProfile();

		this.notificationService.updateGlobalNotification$.subscribe((data) => {
			this.notification = data;
		});

		this.gs.refreshDataSubj.subscribe(() => {
			this.refreshMailbox();
		});

		if (Office.context.mailbox.addHandlerAsync) {
			Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, eventArgs => {
				this.ngZone.run(() => {
					this.showInboxTabs = false;
					this.showTransitionCover = true;
				});
				this.accessClientService.useSmartAuthentication = false;
				this.ngOnInit();
			});
		}

		window.onresize = (e) => {
			// ngZone.run will help to run change detection
			this.ngZone.run(() => {
				this.contentHeight = window.innerHeight - dic.CONSTANTS.contentHeightMargin;
				this.contentWidth = window.innerWidth - dic.CONSTANTS.contentWidthMargin;
			});
		};
	}

	ngOnInit() {
		this.notificationService.clear();
		this.itemChangedSubject.next();

		this.authTabExists = this.authTabExists || false;
		if (!this.authTabExists && this.tab === dic.CONSTANTS.mailboxTabs.authentication) {
			this.tab = '';
		}

		this.tab = this.tab || dic.CONSTANTS.mailboxTabs.inboundShield;
		this.previousTab = '';
		this.notification = this.notificationService.notification;
		this.downloadedFiles = [];
		this.advanced = {};
		this.methods = {secure_send: {}, secure_reply: {}, encrypt_content: {}, postmark: {}};
		this.currentMessage = {trackingData: [], postmarkData: [], methods: {}};
		this.errNotTrustifiEmail = false;
		this.recipientPostmark = null;
		this.lastEmailId = '';
		this.maxCountRefresh = 50;
		this.finalSummary = '';
		this.userAuthenticated = this.office365Service.roamingGet('userAuthenticated');
		this.advancedChanged = false;
		this.isTrustifiEmail = false;
		this.userProfile = this.office365Service.getUserProfile();
		this.subject = null;

		if (this.authService.authNotify && this.userAuthenticated.email) {
			this.authService.authNotify = false;
			this.notificationService.showInfoMessage(dic.MESSAGES.signedInSuccess, this.userAuthenticated.email);
			this.notificationService.notificationPriority();
		}

		this.office365Service.getExchangeMessageId((err, messageId) => {
			if (err || !messageId) {
				this.errNotTrustifiEmail = true;
				// TODO: need to clear interval as well.
				return;
			}

			this.gs.getUserInfo(false).then((response) => {
				this.userInfo = response;
				this.secureMethods = this.userInfo.plan.policy && this.userInfo.plan.policy.allowed_auth_methods || Object.values(dic.CONSTANTS.authenticationMethod);
				this.allowPolicyRecall = this.userInfo.plan.policy?.allow_recall_message?.value;
				this.userPolicy = this.userInfo.plan.policy;

				if (this.blockFreeThreatScan()) {
					if (this.intervalHwnd) {
						clearInterval(this.intervalHwnd);
					}
				}
			}, err => {});

			this.counter = 0;
			if (this.intervalHwnd) {
				clearInterval(this.intervalHwnd);
			}

			if (messageId?.includes('p.trustifisimulation.com')) {
				return;
			}

			this.getEmailDetails(messageId);
			this.intervalHwnd = setInterval(() => this.getEmailDetails(messageId), 6000);
		});

		this.updateTrustifiBody();
	}

	ngOnDestroy(): void {
		if (this.intervalHwnd) {
			clearInterval(this.intervalHwnd);
		}

		if (Office.context.mailbox.removeHandlerAsync) {
			Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged);
		}
	}

	blockThreatScan() {
		return this.userInfo && this.userInfo.plan && this.userInfo.plan.plan === dic.CONSTANTS.planType.pro &&
			this.userInfo.plan.outbound_relay && this.userInfo.plan.outbound_relay.enabled && this.userInfo.plan.outbound_relay.is_connected &&
			(!this.userInfo.plan.inbound_relay || !this.userInfo.plan.inbound_relay.enabled);
	}

	blockFreeThreatScan() {
		return this.userInfo && this.userInfo.plan && this.userInfo.plan.plan === dic.CONSTANTS.planType.free &&
			this.userInfo.plan.scanned_emails > dic.CONSTANTS.freePlanScansLimit;
	}

	refreshMailbox() {
		this.gs.threatDetectionCache = {};
		this.ngOnInit();
	}

	private getEmailDetails(messageId) {
		this.rs.getEmailDetailsOmail(messageId).then((response) => {
			if (response.error) {
				this.ngZone.run(() => {
					this.tab = this.tab === dic.CONSTANTS.mailboxTabs.authentication ? this.tab : dic.CONSTANTS.mailboxTabs.inboundShield;
				});
				if (this.counter++ >= this.maxRequestCounter) {
					this.intervalHwnd && clearInterval(this.intervalHwnd);
					this.errNotTrustifiEmail = true;

					// 7520
					if (!this.isTrustifiEmail && this.blockThreatScan()) {
						this.errNotTrustifiEmail = false;
					}
				}
				return;
			}

			this.getCurrentMessage(response);

			this.intervalHwnd && clearInterval(this.intervalHwnd);
		}, err => {
			this.errNotTrustifiEmail = true;
			this.intervalHwnd && clearInterval(this.intervalHwnd);

			// 7520
			if (!this.isTrustifiEmail && this.blockThreatScan()) {
				this.errNotTrustifiEmail = false;
			}
		});
	}

	private getCurrentMessage(response) {
		this.lastEmailId = response.info._id;
		this.currentMessage = response.info;
		this.advanced = this.gs.deflateAdvanced(this.currentMessage.advanced);
		if (this.currentMessage.advanced) {
			this.advancedChangedFilter = _.clone(this.currentMessage.advanced);
		}
		this.enforcePolicy(this.userInfo.plan.policy);

		this.methods = {
			secure_send: {value: this.currentMessage.methods.secure_send},
			secure_reply: {value: this.currentMessage.methods.secure_reply},
			encrypt_content: {value: this.currentMessage.methods.encrypt_content},
			postmark: {value: this.currentMessage.methods.postmark}
		};

		this.currentMessage.postmarkData = response.postmark || [];
		this.currentMessage.trackingData = response.track || [];

		this.recipientTrack = this.currentMessage.trackingData[0] || {track_events: []};
		this.recipientPostmark = this.currentMessage.postmarkData[0] || {};
		this.ngZone.run(() => {
			this.tab = dic.CONSTANTS.mailboxTabs.tracking;
		});

		this.updateTab(this.tab);
		this.currentMessage.numberRecipientsBlocked = this.getNumOfBlockedRecipients(this.currentMessage);

		this.isTrustifiEmail = true;
		this.initSummary();

		this.autoRefreshTracking(true, null);
	}

	enforcePolicy(userPolicy) {
		if (!userPolicy) { return; }
		if (userPolicy.delete_attachment_enable && userPolicy.delete_attachment_enable.strict) {
			this.advanced.secure.delete_attachment_enable = userPolicy.delete_attachment_enable;
		}
		if (userPolicy.expired_enable && userPolicy.expired_enable.strict) {
			this.advanced.secure.expired_enable = userPolicy.expired_enable;
		}
	}

	private clearTrustifiMsgLoading() {
		this.authTabExists = false;
		this.tab = this.isTrustifiEmail ? dic.CONSTANTS.mailboxTabs.tracking : dic.CONSTANTS.mailboxTabs.inboundShield;
		this.showTransitionCover = false;
	}

	resetData() {
		this.resetDataSubject.next();
	}

	private updateTrustifiBody() {
		if (this.accessClientService.timeout1 || this.accessClientService.timeout2) {
			this.resetData();
			if (this.accessClientService.timeout1) {
				clearTimeout(this.accessClientService.timeout1);
				this.accessClientService.timeout1 = null;
			}
			if (this.accessClientService.timeout2) {
				clearTimeout(this.accessClientService.timeout2);
				this.accessClientService.timeout2 = null;
			}
		}
		this.office365Service.getBodyHTML(res => {
			if (!res.value) {
				this.showInboxTabs = false;
				this.clearTrustifiMsgLoading();
				return;
			}

		   let msoNormal = res.value.indexOf('<div id="appendonsend"></div>');

		   if (msoNormal < 0) {
				msoNormal = res.value.indexOf('<b>Subject:</b>');
		   }

		   if (msoNormal < 0) {
				msoNormal = res.value.indexOf('href="mailto:');
		   }

			if (msoNormal < 0) {
				msoNormal = res.value.indexOf('MsoNormal"');
			}

			const trustifiHeader = res.value.indexOf('trustifiHeader_');
			if (trustifiHeader < 0 || msoNormal >= 0 && msoNormal < trustifiHeader) {
				// no wrapper template case
				const trustifiContent = res.value.indexOf('trustifiContent_');
				if (trustifiContent < 0) {
					this.showInboxTabs = false;
					this.clearTrustifiMsgLoading();
					return;
				}
			}

			const links = this.accessClientService.getURL(res.value);
			if (!links || !links.length) {
				this.showInboxTabs = false;
				this.clearTrustifiMsgLoading();
				return;
			}

			if (links.length > 1) {
				console.error('[Trustifi] Multiple Trustifi links found: ', links);
			}

			this.accessClientService.link = links[0];
			this.accessClientService.getMessage(this.accessClientService.link, isEncrypted => {
				if (isEncrypted) {
					this.ngZone.run(() => {
						this.showInboxTabs = true;
						this.notificationService.clear();
						this.authTabExists = true;
						this.tab = dic.CONSTANTS.mailboxTabs.authentication;
						this.showTransitionCover = false;
					});
				} else {
					this.clearTrustifiMsgLoading();
				}
			});
		});
	}

	autoRefreshTracking(firstRun, prevRecipient) {
		if (!firstRun && (this.tab !== dic.CONSTANTS.mailboxTabs.tracking || --this.maxCountRefresh < 0) || !this.currentMessage || !this.lastEmailId) { return; }

		this.rs.getEmailEvents(this.lastEmailId).then((response) => {
			if (!response || response.error) { return; }
			this.currentMessage.trackingData = response.tracking || [];

			if (prevRecipient) {
				for (let i = 0; i < this.currentMessage.trackingData.length; i++) {
					if (prevRecipient.id === this.currentMessage.trackingData[i].id) {
						this.recipientTrack = this.currentMessage.trackingData[i];
						setTimeout(() => {
							this.recipientTrackSubject.next(this.recipientTrack);
						});
						break;
					}
				}

				for (let i = 0; i < this.recipientTrack.track_events.length; i++) {
					if (this.recipientTrack.track_events[i] && prevRecipient.track_events[i]) {
						this.recipientTrack.track_events[i].opened = prevRecipient.track_events[i].opened;
					}
				}
			} else {
				this.recipientTrack = this.currentMessage.trackingData[0] || {track_events: []};
			}
			setTimeout(() => {
				this.autoRefreshTracking(false, this.recipientTrack);
			}, 8000);
		});
	}

	preventDefault(event) {
		this.previousTab = '';

		event.cancelBubble = true;
		event.returnValue = false;

		if (event.stopPropagation) {
			event.stopPropagation();
			event.preventDefault();
		}

		return false;
	}

	verifyPostmarkReceipt() {
		this.notificationService.clear();
		this.showTabMenu = false;
		this.rs.verifyReceiptPostmark(this.currentMessage._id, this.recipientPostmark.recipient.id).then(res => {
			if (!res.meta || !res.meta.message) { return; }

			if (res.meta.type === dic.CONSTANTS.NotificationTypes.INFO) {
				this.notificationService.showInfoMessage(res.meta.message);
				this.notificationService.notificationPriority();
			} else if (res.meta.type === dic.CONSTANTS.NotificationTypes.WARNING) {
				this.notificationService.showErrorMessage(res.meta.message);
				this.notificationService.notificationPriority();
			} else {
				this.notificationService.showErrorMessage(res.meta.message);
				this.notificationService.notificationPriority();
			}
		});
	}

	downloadAsPdf() {
		this.showTabMenu = false;

		if (this.isOutlookClient) {
			const url = ENV_CONSTS.feBaseUrl + '/#/index/mailBox/postmark/' + this.currentMessage._id;
			window.open(url);
			/*if(this.userAuthenticated.otoken && this.userAuthenticated.oexpired > Date.now() + dic.CONSTANTS.msMargin) {
				url += '/' + this.userAuthenticated.otoken;
				window.open(url);
			}
			else {
				this.rs.createOToken().then(otres => {
					this.userAuthenticated.otoken = otres.oToken;
					this.userAuthenticated.oexpired = otres.expired;

					url += '/' + this.userAuthenticated.otoken;
					window.open(url);

					this.office365Service.roamingSet('this.userAuthenticated', this.userAuthenticated, _ => {});
				}, err => {
					if (err.data && err.data.display_bar && err.data.message) {
						this.notificationService.showErrorMessage(err.data.message);
					}
				});
			}*/
		} else {
			const element = document.getElementById('postmark-src');
			const htmldata:any = element.querySelector('#htmldata');
			if (htmldata && this.currentMessage.postmarkData && this.currentMessage.postmarkData.length && this.currentMessage.postmarkData[0].html) {
				htmldata.style.display = 'block';
				htmldata.innerHTML = this.currentMessage.postmarkData[0].html;
			}

			const options = {
				margin: 10,
				filename: 'postmark.pdf',
				enableLinks: false,
				image: {type: 'jpeg', quality: 1},
				html2canvas: {dpi: 384, letterRendering: true, logging: false},
				jsPDF: {unit: 'pt', format: 'b5', orientation: 'p', pagesplit: false}
			};

			html2pdf().from(element).set(options).save();
		}
	}

	downloadFile(url, fileName, fileType, size) {
		this.showTabMenu = false;

		if (this.downloadedFiles.indexOf(fileName) >= 0) {
			return;
		}

		this.downloadedFiles.push(fileName);

		if (this.isOutlookClient) {
			if (!this.userAuthenticated.api_key) {
				url = ENV_CONSTS.beBaseAttachmentUrl + url + '?state=' + this.userAuthenticated.access_token;
				if (this.userAuthenticated[dic.HEADERS.x2FAFingerprint]) {
					url += `&fingerprint=${this.userAuthenticated[dic.HEADERS.x2FAFingerprint]}`;
				}
				window.open(url);
				const index = this.downloadedFiles.indexOf(fileName);
				this.downloadedFiles.splice(index, 1);
			} else if (this.userAuthenticated.otoken && this.userAuthenticated.oexpired > Date.now() + dic.CONSTANTS.msMargin) {
				url = ENV_CONSTS.beBaseAttachmentUrl + url + '?state=' + this.userAuthenticated.otoken;
				if (this.userAuthenticated[dic.HEADERS.x2FAFingerprint]) {
					url += `&fingerprint=${this.userAuthenticated[dic.HEADERS.x2FAFingerprint]}`;
				}
				window.open(url);
				const index = this.downloadedFiles.indexOf(fileName);
				this.downloadedFiles.splice(index, 1);
			} else {
				this.rs.createOToken().then(otres => {
					this.userAuthenticated.otoken = otres.oToken;
					this.userAuthenticated.oexpired = otres.expired;

					url = ENV_CONSTS.beBaseAttachmentUrl + url + '?state=' + this.userAuthenticated.otoken;
					if (this.userAuthenticated[dic.HEADERS.x2FAFingerprint]) {
						url += `&fingerprint=${this.userAuthenticated[dic.HEADERS.x2FAFingerprint]}`;
					}
					window.open(url);
					const index = this.downloadedFiles.indexOf(fileName);
					this.downloadedFiles.splice(index, 1);
				}, err => {
					if (err.data && err.data.display_bar && err.data.message) {
						this.notificationService.showErrorMessage(err.data.message);
					}
				});
			}
		} else {
            let headers = {};

            if (this.userAuthenticated.api_key && this.userAuthenticated.api_secret) {
				headers = {
					'x-trustifi-key': this.userAuthenticated.api_key,
					'x-trustifi-secret': this.userAuthenticated.api_secret
				};
			} else {
				headers = {
					'x-access-token': this.userAuthenticated.access_token,
				};
			}

            if (this.userAuthenticated[dic.HEADERS.x2FAFingerprint]) {
                headers['x-trustifi-2fa-fingerprint'] = this.userAuthenticated[dic.HEADERS.x2FAFingerprint];
            }
			         headers['x-trustifi-source'] = 'outlook';

			         this.gs.downloadFile(ENV_CONSTS.beBaseAttachmentUrl + url, fileName, fileType, size, headers, false, (err) => {
				if (err) {
					this.notificationService.showErrorMessage(dic.ERRORS.downloadFailed);
				} else {
					const index = this.downloadedFiles.indexOf(fileName);
					this.downloadedFiles.splice(index, 1);
					this.notificationService.showInfoMessage(dic.MESSAGES.downloadSuccess);
				}
				this.notificationService.notificationPriority();
			});
		}
	}

	changeMessageForRecipient() {
		this.showTabMenu = false;
		this.notificationService.clear();
		const action = this.recipientTrack.is_blocked || this.recipientTrack.is_access_blocked ? dic.CONSTANTS.recipientStatus.unblock : dic.CONSTANTS.recipientStatus.block;

		this.rs.changeMessageForRecipient(this.currentMessage._id, this.recipientTrack.id, {action}).then(() => {
			if (action === dic.CONSTANTS.recipientStatus.block) {
				this.recipientTrack.is_blocked = true;
				this.notificationService.showInfoMessage(dic.MESSAGES.recipientStateBlock);
				this.notificationService.notificationPriority();
				this.currentMessage.numberRecipientsBlocked++;
			} else {
				this.recipientTrack.is_access_blocked = false;
				this.recipientTrack.is_blocked = false;
				this.notificationService.showInfoMessage(dic.MESSAGES.recipientStateUnBlock);
				this.notificationService.notificationPriority();
				this.currentMessage.numberRecipientsBlocked--;
			}
		});
	}

	saveDefaults() {
		if (!this.currentMessage && !this.currentMessage._id) {
			return;
		}

		const data = this.advanced;

		const flat = this.gs.flatAdvanced(data);
		this.rs.updateAdvancedMessage(this.currentMessage._id, flat)
		.then((response) => {
			this.showPhoneWarning = false;
			this.advancedChanged = false;
			this.notificationService.showInfoMessage(dic.MESSAGES.advancedSettingsSaved);
			this.notificationService.notificationPriority();
		}, err => {
			this.notificationService.showErrorMessage(err.data.message);

			if (this.showPhoneWarning) {
				this.advanced.secure.method_2factor.value = dic.CONSTANTS.authenticationMethod.password;
			}

			this.notificationService.notificationPriority();

		});
	}

	validateContactName(newContact) {
		return !newContact || !newContact.name;
	}

	updateRecipientDetails(contactInfo) {
		if (contactInfo._id) {
			this.rs.updateContact(contactInfo._id, contactInfo).then((response) => {
				this.showRecipientDetails = false;

				this.notificationService.showInfoMessage(dic.MESSAGES.recipientUpdatedSuccess);
				this.notificationService.notificationPriority();
			});
		} else {
			this.rs.addNewContact({contacts: [contactInfo]}).then((response) => {
				this.showRecipientDetails = false;

				this.notificationService.showInfoMessage(dic.MESSAGES.recipientUpdatedSuccess);
				this.notificationService.notificationPriority();
			});
		}
	}

	validateRecipientPhone(newContact) {
		const isSecure = this.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authenticationMethod.phone && this.currentMessage.methods.encrypt_content && (this.currentMessage.methods.secure_send || this.currentMessage.methods.secure_reply);
		return isSecure && !newContact.phone.phone_number || !this.gs.validateTel(newContact.phone);
	}

	showRecallConfirm() {
		this.rs.recallEmail(this.currentMessage._id).then(() => {
			this.notificationService.showInfoMessage(dic.MESSAGES.messageRecalled);
		}, err => {
			if (err.data && err.data.message) {
				this.notificationService.showErrorMessage(err.data.message);
			}
		});
	}

	showUpdateMessageEditor() {
		let emailContent = Promise.resolve(this.currentMessage.sent.html);
		if (!this.currentMessage.sent.html) {
			emailContent = this.rs.decryptEmailContent(this.currentMessage._id);
		}

		emailContent.then(res => {
			this.currentMessage.sent.html = res;
			this.previousContent = this.currentMessage.sent.html;
			this.notificationService.clear();
		}, err => {
			this.previousContent = '';
			this.notificationService.showErrorMessage(dic.ERRORS.retrieveFailed);
		}).finally(() => {
			this.previousTab = this.tab !== dic.CONSTANTS.mailboxTabs.recall && this.tab;

			this.tab = dic.CONSTANTS.mailboxTabs.recall;
			this.showTabMenu = false;
			if (this.currentMessage.methods.postmark) {
				this.notificationService.showErrorMessage(dic.ERRORS.recallPostmark);
			} else {
				this.notificationService.clear();
			}

			this.notificationService.notificationPriority();
		});
	}

	updateEmailContent(content: string) {
		const emailID = this.currentMessage._id;

		this.rs.updateEmailContent(emailID, {type: dic.CONSTANTS.updateEmailTypes.content, html: content}).then(() => {
			this.currentMessage.sent.html = content;
			this.tab = this.previousTab;
			if (!this.tab) {
				this.tab = this.isTrustifiEmail ? dic.CONSTANTS.mailboxTabs.tracking : dic.CONSTANTS.mailboxTabs.inboundShield;
			}
			this.updateTab(this.tab);

			this.notificationService.showInfoMessage(dic.MESSAGES.messageUpdated);
		}, err => {
			if (err.data && err.data.message) {
				this.notificationService.showErrorMessage(err.data.message);
			}
		});

		this.notificationService.notificationPriority();
	}

	closeRecallEditor() {
		this.tab = this.previousTab;
		if (!this.tab) {
			this.tab = this.isTrustifiEmail ? dic.CONSTANTS.mailboxTabs.tracking : dic.CONSTANTS.mailboxTabs.inboundShield;
		}
		this.updateTab(this.tab);

		// reverse content
		this.currentMessage.sent.html = this.previousContent;
		this.notificationService.clear();
	}

	validateRecipientsHasPhone() {
		this.showPhoneWarning = false;
		if (this.requirePhone() && this.currentMessage.recipientsIDs.some(itm => !itm.phone || !itm.phone.phone_number)) {
			this.showPhoneWarning = true;
		}
	}

	printEmail() {

		if (this.isOutlookClient) {
			const url = ENV_CONSTS.feBaseUrl + `/#/index/mailBox/${this.currentMessage._id}`; // ?token=${this.userAuthenticated.access_token}`;
			window.open(url);
		} else {
			const getBasicHeadersHtml = () => {
				let headers = `<div><b>${this.userProfile.sender.emailAddress}</b></div>
						<hr style="border-width: 1px; border-color: black">
						<br><div>
						<span style="padding-right: 57px;">From: </span>${this.userProfile.sender.emailAddress}</div><div>
						<span style="padding-right: 63px;">Sent: </span>${this.currentMessage.created && (new Date(this.currentMessage.created)).toUTCString()}</div><div>
						${this.currentMessage.recipientsIDs && this.currentMessage.recipientsIDs.length ? `<span style="padding-right: 75px;">To:   </span>${_.map(this.currentMessage.recipientsIDs, 'email').join(', ')}</div><div>` : ''}
						${this.currentMessage.recipientsCCIDs && this.currentMessage.recipientsCCIDs.length ? `<span style="padding-right: 72px;">CC:   </span>${_.map(this.currentMessage.recipientsCCIDs, 'email').join(', ')}</div><div>` : ''}
						<span style="padding-right: 43px;">Subject: </span>${this.currentMessage.sent.title}</div>`;
				if (this.currentMessage.sent.attachments && this.currentMessage.sent.attachments.length) {
					headers += `<div><span style="padding-right: 10px;">Attachments: </span>${_.map(this.currentMessage.sent.attachments, 'name').join(', ')}</div>`;
				}
				headers += '<br><br>';
				return headers;
			};

			this.office365Service.getBodyHTML(res => {
				if (!res.value) {
					res.value = '';
				}

				const printTab = window.open();
				printTab.document.write(getBasicHeadersHtml() + res.value);
				printTab.document.close();
				printTab.print();
				setTimeout(() => {
					printTab.close();
				}, 1000);
			});
		}
	}

	initSummary() {
		const message = {
			methods: {
				secure_send: {value: this.currentMessage.methods.secure_send},
				postmark: {value: this.currentMessage.methods.postmark},
				encrypt_content: {value: this.currentMessage.methods.encrypt_content},
			},
			skip_attachments_encryption: this.currentMessage.skip_attachments_encryption,
			sent: this.currentMessage.sent
		};
		const advanced = {
			secure: {
				method_2factor: {value: this.currentMessage.advanced.secure.method_2factor}
			}
		};

		this.finalSummary = this.gs.initSummary(message, advanced, false);
	}

	getBlockingOpreations(showInTooltip) {
		const breaker = showInTooltip ? '<br>' : ', ';
		const lineStarter = showInTooltip ? '- ' : '';
		let message = '';
		if (this.currentMessage.methods.secure_reply) {
			message = message.concat(lineStarter + 'Encrypted reply' + breaker);
		}
		if (this.currentMessage.sent.attachments && this.currentMessage.sent.attachments.length && !this.currentMessage.skip_attachments_encryption) {
			message = message.concat(lineStarter + 'Download attachments' + breaker);
		}
		if (this.currentMessage.methods.encrypt_content) {
			message = message.concat(lineStarter + 'Read email content');
		}
		return message;
	}

	getActiveTabsCount(currentMessage, authTabExists) {
		// Init as 1 for InboundShield tab
		let count = 1;
		if (currentMessage.methods.track) {
			count++;
		}
		if (currentMessage.methods.postmark) {
			count++;
		}
		if (currentMessage.advanced) {
			count++;
		}
		if (authTabExists) {
			count++;
		}
		return count;
	}

	private requirePhone() {
		return this.advanced.secure.method_2factor.value === dic.CONSTANTS.authenticationMethod.phone && this.currentMessage.methods.secure_send;
	}

	updateRecipientTrack(track: object) {
		this.recipientTrack = track;
	}

	updateTab(tab) {
		this.ngZone.run(() => {
			this.tab = tab;
			this.clear();
		});
		setTimeout(() => {
			if (this.tab === dic.CONSTANTS.mailboxTabs.tracking) {
				this.recipientTrackSubject.next(this.recipientTrack);
				this.trackingDataSubject.next({
					trackingData: this.currentMessage.trackingData,
					is_deleted: this.currentMessage.is_deleted,
					scheduled_time: this.currentMessage.scheduled_time,
					start_sending_time: this.currentMessage.start_sending_time,
					_id: this.currentMessage._id
				});
			} else if (this.tab === dic.CONSTANTS.mailboxTabs.sensitivity) {
				this.sensitivitySubject.next({
					sensitivity: this.currentMessage.sensitivity,
					messageDetails: {_id: this.currentMessage._id, title: this.currentMessage.sent.title},
					isUpdate: false
				});
			}
		});
	}

	changeMessageStateForAllRecipients(action) {
		if ((action === 'block' && this.currentMessage.numberRecipientsBlocked === this.currentMessage.recipients_count)
			|| (action === 'unblock' && this.currentMessage.numberRecipientsBlocked === 0)) {
			return;
		}

		this.rs.changeMessageStateForAllRecipients(this.currentMessage._id, {action}).then((response) => {
			this.autoRefreshTracking(true, null);
			this.notificationService.showInfoMessage(action === 'block' ? dic.MESSAGES.emailStateBlock : dic.MESSAGES.emailStateUnBlock);
			this.notificationService.notificationPriority();
			if (action === 'block') {
				this.currentMessage.numberRecipientsBlocked = this.currentMessage.recipients_count;
			} else {
				this.currentMessage.numberRecipientsBlocked = 0;
			}
		});
	}

	getNumOfBlockedRecipients(emailObj) {
		let blockCount = 0;
		if (emailObj.recipientsIDs && emailObj.recipientsIDs.length) {
			for (let i = 0; i < emailObj.recipientsIDs.length; i++) {
				if (emailObj.recipientsIDs[i].is_blocked) {
					blockCount++;
				}
			}
		}

		if (emailObj.recipientsCCIDs && emailObj.recipientsCCIDs.length) {
			for (let i = 0; i < emailObj.recipientsCCIDs.length; i++) {
				if (emailObj.recipientsCCIDs[i].is_blocked) {
					blockCount++;
				}
			}
		}

		if (emailObj.recipientsBCCIDs && emailObj.recipientsBCCIDs.length) {
			for (let i = 0; i < emailObj.recipientsBCCIDs.length; i++) {
				if (emailObj.recipientsBCCIDs[i].is_blocked) {
					blockCount++;
				}
			}
		}
		return blockCount;
	}

	// TODO: duplicate code from authentication component -- START
	uploadFiles(files) {
		if (!files || !files.length) { return; }

		files = _.map(files, f => f._file);

		let totalSize = 0;
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (_.find(this.currentMessage.sent.attachments, {name: file.name, size: file.size})) {
				if (this.uploadedFiles > 0) {
					this.uploadedFiles = 0;
				}
				this.notificationService.showErrorMessage(dic.ERRORS.attachmentAlreadyUploaded, file.name);
				files.length = 0;
				this.uploader.queue.length = 0;
				return;
			}
			totalSize += file.size;
		}
		const isValid = this.gs.checkUploadFileSize(totalSize, 'Current upload attachments');
		if (totalSize === 0 || !isValid) {
			return;
		}

		this.notificationService.clear();
		this.uploading = true;
		const headers = new HttpHeaders(this.authService.getHeaders());

		for (let i = 0; i < files.length; i++) {
			this.uploadFilesExecute(files[i], headers);
		}

		this.uploadedFiles += files.length;
		files.length = 0;
	}

	uploadFilesExecute(file, headers) {
		if (!file) { return; }

		const currentAttachment: any = {
			name: file.name,
			size: file.size,
			finished: false,
			progressPercentage: 0,
			upload: {}
		};
		this.currentMessage.sent.attachments.push(currentAttachment);

		const fd = new FormData();
		fd.append('file', file);

		const req = new HttpRequest('POST', ENV_CONSTS.beBaseAttachmentUrl + '/attachment', fd, {
			headers,
			reportProgress: true
		});

		this.uploadSubscribers[file.name] = this.http.request(req).subscribe(event => {
			// reporting percentages - updating local variables
			if (event.type === HttpEventType.UploadProgress) {
				currentAttachment.progressPercentage = 100.0 * event.loaded / event.total;
			} else if (event.type === HttpEventType.Response) {
				// update finished state and new id for attachments
				currentAttachment.finished = true;
				currentAttachment._id = event.body[0];
				this.addAttachment(currentAttachment);

				if (this.uploadedFiles > 0) {
					this.uploadedFiles--;
				}

				if (this.uploadedFiles === 0) {
					this.uploader.queue.length = 0;
					this.uploading = false;
				}
			}
		}, err => {
			if (err.error && err.error.message) {
				this.notificationService.showInfoMessage(err.error.message);
			}
			_.remove(this.currentMessage.sent.attachments, (attachment: any) => {
				return currentAttachment.name === attachment.name;
			});

			if (this.uploadedFiles > 0) {
				this.uploadedFiles--;
			}
			if (this.uploadedFiles === 0) {
				this.uploading = false;
			}
		});
	}

	fileOver(isFileOver: boolean) {
		this.mouseOverAttachment = isFileOver;
	}

	// Hack for IE cursor on file input
	loseFocus() {
		((document.activeElement) as any).blur();
	}
	// duplicate code from authentication component -- END

	addAttachment = (attachment) => {
		const data = {
			type: dic.CONSTANTS.updateEmailTypes.attachment,
			action: 'add',
			attachmentId: attachment._id
		};
		this.rs.updateEmailContent(this.currentMessage._id, data).then(() => {
			delete attachment.progressPercentage;
			this.notificationService.showInfoMessage(dic.MESSAGES.attachmentAdded);
		}, (err) => {
			if (err.data && err.data.message) {
				this.notificationService.showErrorMessage(err.data.message);
			}
			_.remove(this.currentMessage.sent.attachments, (a: any) => a._id === attachment._id);
		});
	}

	deleteAttachmentWarning = (index) => {
		this.deleteAttachmentIndex = index;
		this.notificationService.showInfoMessage(dic.MESSAGES.attachmentDeleteQuestion);
	}

	deleteAttachment = () => {
		const attachment = this.currentMessage.sent.attachments[this.deleteAttachmentIndex];
		const data = {
			type: dic.CONSTANTS.updateEmailTypes.attachment,
			action: 'delete',
			attachmentId: attachment._id
		};
		this.rs.updateEmailContent(this.currentMessage._id, data).then(() => {
			this.deleteAttachmentIndex = null;
			this.currentMessage.sent.attachments.splice(this.deleteAttachmentIndex, 1);
			this.notificationService.showInfoMessage(dic.MESSAGES.attachmentDeleted);
		}, (err) => {
			this.deleteAttachmentIndex = null;
			if (err.data && err.data.message) {
				this.notificationService.showErrorMessage(err.data.message);
			}
		});
	}

	cancelDeleteAttachment = () => {
		this.deleteAttachmentIndex = null;
		this.notificationService.clear();
	}

	stripHTML(data) {
		if (!data) {
			return '';
		}

		return data
			.replace(/(?=<link[^>]*>)([\s\S]*?)[>]/ig, ' ')
			.replace(/(?=<style[^>]*>)([\s\S]*?)<\/style>/ig, ' ')
			.replace(/(?=<script[^>]*>)([\s\S]*?)<\/script>/ig, ' ')
			.replace(/src=['"]data[:][^\n\s'"]*['"]/ig, ' ')
			.replace(/alt=['"]cid[:][^\n\s'"]*['"]/ig, ' ')
			.replace(/<[^>]+(href=|src=|action=|title=|alt=)"([^"]+)"[^>]*>/ig, ' $2 ')
			.replace(/<(https?)([^>]+)>/ig, ' $1$2 ')
			.replace(/<\/?[^>]+>/ig, ' ')
			.replace(/&nbsp;/g, ' ')
			.replace(/\s\s+/g, ' ')
			.trim();
	}

	checkSensitivity() {
		if (this.checkSensitivityInProcess) { return; }
		this.checkSensitivityInProcess = true;
		this.rs.checkEmailSensitivity(this.currentMessage._id).then(updatedSensitivity => {
			this.currentMessage.sensitivity = updatedSensitivity;
			this.checkSensitivityInProcess = false;
			this.sensitivitySubject.next({sensitivity: this.currentMessage.sensitivity, messageDetails: {_id: this.currentMessage._id, title: this.currentMessage.sent.title}, isUpdate: true});
		});
	}
}
