import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {ENV_CONSTS} from '../../../constants';
import { dic } from '../../../dictionary';
import * as _ from 'lodash';
import {GeneralService} from '../../../services/general.service';
import {RestService} from '../../../services/rest.service';
import {Observable, Subscription} from 'rxjs';
import {Office365Service} from '../../../services/office365.service';
import {NotificationService} from '../../../services/notification.service';
import {TranslationService} from '../../../services/translation.service';
import * as q from 'q';

@Component({
	selector: 'app-message-inbound-shield',
	templateUrl: './message-inbound-shield.component.html',
	styleUrls: ['./message-inbound-shield.component.scss']
})
export class MessageInboundShieldComponent implements OnInit {

	threatProtection: any = {};
	userProfile: any = {}
	headers: any;
	private isTrustifiEmail: any;
	private statusPriority = {
		Malicious: 1,
		Suspicious: 2,
		Spam: 3,
		Graymail: 4,
		Unsecured: 5,
		External: 6,
		Pending: 7,
		Safe: 8
	};

	attachments: any;

	subject: string;
	rawData: any;

	private bodyContent: any;

	private sender: any;

	dic = dic;
	userInfo;
	isIE = false;
	feBaseUrl = ENV_CONSTS.feBaseUrl;
	showConfirmStatus = false;
	inboundConfLink = '';
	upgradePlanLink = '';
	reportSent = false;
	reportError = false;
	sendReportInProcess = false;
	confirmTPResultsInProcess = false;
	reportOptions;
	trustifiDefault;
	reportTo = dic.CONSTANTS.reportToList.personal;
	isJunkFolder = true;
	blockScan = false;
	accordionTabs = {
		messageAnalysis: 'message analysis',
		linksAnalysis: 'links analysis',
		attachmentsAnalysis: 'attachments analysis',
	};
	activeAccordionTab;
	actionToConfirm;
	reportTrustifi = true;
	isShrinkSummary = false;
	_ = _;

	@Input() itemChanged: Observable<void>;
	private itemChangedSubscription: Subscription;

	constructor(private gs: GeneralService,
				private office365Service: Office365Service,
				private rs: RestService,
				private ngZone: NgZone,
				private translateService: TranslationService,
				public notificationService: NotificationService) {
		this.trustifiDefault = this.gs.trustifiDefault;
		this.initReportOptions();
	}

	ngOnInit() {
		this.isIE = this.gs.isIE();
		this.activeAccordionTab = null;
		this.reportError = false;
		this.reportSent = false;
		this.actionToConfirm = null;
		this.activeAccordionTab = null;

		this.itemChangedSubscription = this.itemChanged.subscribe(() => {
			this.actionToConfirm = null;
			this.initReportOptions();
			this.notificationService.clear();
			this.doThreatProtectionScan(false);
		});

		this.gs.getUserInfo(false).then((userInfo) => {
			this.userInfo = userInfo;
			this.upgradePlanLink = this.feBaseUrl + '/#/upgrade/company/' + this.trustifiDefault.name;
			if (userInfo.user_role === dic.CONSTANTS.userRole.admin || userInfo.user_role === dic.CONSTANTS.userRole.subAdmin) {
				this.inboundConfLink = this.feBaseUrl + '/inbound/configuration/senderLists';
			}
			else {
				this.inboundConfLink = this.feBaseUrl + '/inbound/userInboundConf/senderLists';
				this.reportTo = dic.CONSTANTS.reportToList.personal;
			}

			this.doThreatProtectionScan(false);

		});
	}

	initThreatProtectionObject() {
		this.threatProtection = {
			url: {status: dic.CONSTANTS.threatProtection.status.pending, message: dic.CONSTANTS.threatProtection.message.analyzingLinks},
			file: {status: dic.CONSTANTS.threatProtection.status.pending, message: dic.CONSTANTS.threatProtection.message.analyzingFiles},
			headers: {status: dic.CONSTANTS.threatProtection.status.pending, message: dic.CONSTANTS.threatProtection.message.analyzingSender},
			status: dic.CONSTANTS.threatProtection.status.analyzing,
			message: ''
		};
	}

	doThreatProtectionScan(forceScan) {
		this.userProfile = this.office365Service.getUserProfile();
		this.sender = this.userProfile && this.userProfile.sender;

		this.checkBlockThreatScan();

		if (this.blockScan) {
			return;
		}

		this.initThreatProtectionObject();

		// fetch data for threat analyzing and send to backend
		this.office365Service.getEmailHeaders(resHeaders => {
			const whatToScan = {
				analyzeHeaders: true,
				analyzeURLs: true,
				analyzeAttachments: true
			};

			this.isTrustifiEmail = this.isTrustifiEmail || resHeaders && resHeaders.toTrustifi;
			if (resHeaders) {
				this.headers = resHeaders.headers;
				this.headers.messageID = resHeaders.InternetMessageId;
				this.bodyContent = resHeaders.Body;
				this.subject = resHeaders.Subject;

				if (!forceScan && this.headers && this.headers.messageID && this.gs.threatDetectionCache && this.gs.threatDetectionCache[this.headers.messageID]) {
					this.ngZone.run(() => {
						this.threatProtection = this.gs.threatDetectionCache[this.headers.messageID];
						this.headers.messageHeaders = this.gs.threatDetectionCache[this.headers.messageID].messageHeaders;
					});
					return;
				}

				// means we will scan the headers
				resHeaders.headers.push({Name: 'parentFolderId', Value: resHeaders.ParentFolderId});

				// Calendar Location - Links are not detected in OWA when being sent as Locations in meeting requests. #4686
				const location = this.office365Service.getLocation();
				if (location) {
					if (this.bodyContent && this.bodyContent.Content) {
						this.bodyContent.Content += ' ' + location + ' ';
					} else {
						this.bodyContent = {Content: ' ' + location + ' '};
					}
				}

				this.attachments = [];
				if (resHeaders.HasAttachments) {
					this.office365Service.getAttachmentsContent(attachments => {
						if (attachments && attachments.length) {
							for (let idx = 0; idx < attachments.length; idx++) {
								if(attachments[idx].hash && this.dic.CONSTANTS.hiddenFilesList.includes(attachments[idx].hash)) continue;

								this.attachments.push({
									name: attachments[idx].name,
									type: attachments[idx].type,
									content: attachments[idx].content,
									hash: attachments[idx].hash,
									size: attachments[idx].size
								});
							}
						}
						this.threatsDetectionAndAlert(whatToScan);
					});
				}
				else {
					this.threatsDetectionAndAlert(null);
				}
			}
			else {
				whatToScan.analyzeHeaders = false;
				whatToScan.analyzeAttachments = false;
				this.threatProtection.headers.status = dic.CONSTANTS.threatProtection.status.error;
				this.threatProtection.url.status = dic.CONSTANTS.threatProtection.status.error;
				this.threatProtection.file.status = dic.CONSTANTS.threatProtection.status.error;
			}

			this.office365Service.getExchangeMessageContent((err, rawData) => {
				if (err || !rawData) {
					console.error(err);
					//whatToScan.analyzeURLs = false;
					//this.threatsDetectionAndAlert(whatToScan);
				}
				else {
					this.rawData = rawData;
				}
			});
		});
	}

	private filterDuplicateSafeLinks() {
		const linksMap = {};
		this.threatProtection.url.list = _.filter(this.threatProtection.url.list, link => {
			if (link.name) {
				if (linksMap[link.name]) {
					if (link.status === dic.CONSTANTS.threatProtection.status.safe) {
						return false;
					}
				}
				else {
					if (link.status !== dic.CONSTANTS.threatProtection.status.safe) {
						linksMap[link.name] = link.url;
					}
					// safe duplicates
					else {
						if (link.name === link.url &&
							_.find(this.threatProtection.url.list, l => l.status === dic.CONSTANTS.threatProtection.status.safe && l.name === link.name && l.url !== link.url)) {
							return false;
						}
					}
				}
			}
			return true;
		});
	}

	private threatsDetectionAndAlert(actions, counter=0) {
		// protection for endless loop

		if (counter > dic.CONSTANTS.threatProtection.maxRetries) {
			return;
		}
		if (!counter) {
			counter = 0;
		}
		if (!actions) {
			// default - do all
			actions = {
				analyzeHeaders: true,
				analyzeURLs: true,
				analyzeAttachments: true
			}
		}

		if (this.userInfo.plan && this.userInfo.plan.plan === dic.CONSTANTS.planType.free &&
			this.userInfo.plan.scanned_emails <= dic.CONSTANTS.freePlanScansLimit) {
			this.userInfo.plan.scanned_emails++;
		}

		const analyzeHeaders = () => {
			if (this.headers) {
				const user = {
					email: this.userProfile && this.userProfile.user && this.userProfile.user.emailAddress
				};

				const validateHeaderReqData = {
					value: {
						headers: this.headers,
						sender: this.userProfile && this.userProfile.sender && this.userProfile.sender.emailAddress || '',
						sender_name: this.userProfile && this.userProfile.sender && this.userProfile.sender.displayName || '',
						trustifi: this.isTrustifiEmail || false,
						body: this.bodyContent && this.bodyContent.Content || '',
						type: this.office365Service.itemClass, // Content Type
						message_id: this.office365Service.internetMessageId,
						includes_attachments: this.attachments && !!this.attachments.length
					},
					user: user,
					client: 'Outlook',
					messageId: this.office365Service.internetMessageId
				};

				return this.rs.threatProtectionScanHeaders(validateHeaderReqData).then((response) => {
					this.ngZone.run(() => {
						if (response && response.messageId === this.office365Service.internetMessageId) {
							this.threatProtection.headers = response;
						}
					}, err => {
						this.ngZone.run(() => {
							this.threatProtection.headers = {
								status: dic.CONSTANTS.threatProtection.status.suspicious,
								message: this.threatProtection.headers && this.threatProtection.headers.message || dic.CONSTANTS.threatProtection.message.noSender,
							};
						});
						throw err;
					});
				});
			}
		};

		const analyzeURLs = () => {
			const urls = [];
			const urlTextRegex = /((https?:\/\/)?(www\.)|(https?:\/\/)(www\.)?)[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)/gi;
			if (this.bodyContent && this.bodyContent.Content) {
				const doc: any = new DOMParser().parseFromString(this.bodyContent.Content, 'text/html');
				if (doc && doc.links.length) {
					this.reportOptions.maliciousLinks.show = true;
					for (let idx = 0; idx < doc.links.length; idx++) {
						if (!(doc.links[idx].href.startsWith('tel:') || doc.links[idx].href.startsWith('mailto:') || doc.links[idx].href.endsWith('://'))) {
							if (!(doc.links[idx].innerText && doc.links[idx].innerText.trim() || doc.links[idx].alt && doc.links[idx].alt.trim() || doc.links[idx].title && doc.links[idx].title.trim())) {
								if (doc.links[idx].querySelector('img') && doc.links[idx].querySelector('img').alt && doc.links[idx].querySelector('img').alt.trim()) {
									urls.push({
										href: doc.links[idx].href,
										text: doc.links[idx].querySelector('img').alt.trim(),
										type: 'Image'
									});
								} else {
									urls.push({
										href: doc.links[idx].href,
										text: '',
										type: 'Text'
									});
								}
							} else {
								urls.push({
									href: doc.links[idx].href,
									text: (doc.links[idx].innerText || doc.links[idx].alt || doc.links[idx].title).trim(),
									type: 'Link'
								});
							}
						}
					}
				}
				if (doc && doc.documentElement && doc.documentElement.innerText) {
					let innerText = this.stripHTML(doc.documentElement.innerHTML);
					let findNonHTTPLinks = innerText.match(/(?:^|\b)([^'"`<>(\s]+[.][a-z]{2,5}(?:[.][a-z]{2,5})?\/(?:[^'"`<>)\s]+)?)/ig) // Detect non-HTTP/HTTPS links
					if (findNonHTTPLinks && findNonHTTPLinks.length) {
						findNonHTTPLinks = findNonHTTPLinks.filter(itm => !/^\w+[:]\/\//.test(itm)); // Filter HTTP/HTTPS links
						for (let idx = 0; idx < findNonHTTPLinks.length; idx++) {
							const replaceUrl = new RegExp('(' + _.escapeRegExp(findNonHTTPLinks[idx]) + ')', 'ig'); // Safe replacer
							innerText = innerText.replace(replaceUrl, ' http://$1 ');
						}
					}
					innerText.replace(urlTextRegex, (match) => {
						if (match) {
							if (/(https?:\/\/)(www\.)?/i.test(match)) {
								urls.push({
									href: match,
									text: '',
									type: 'Text'
								});
							} else {
								match = match.trim();
								urls.push({
									href: 'http://' + match,
									text: '',
									type: 'Text'
								});
							}
						}
					});
				}
			}

			if (urls && urls.length) {
				const threatData = {value: urls, messageId: this.office365Service.internetMessageId, client: 'Outlook'};
				return this.rs.threatProtectionScanLinks(threatData).then((response) => {
					if (response && response.url && response.url.list && response.messageId === this.office365Service.internetMessageId) {
						response.url.list = response.url.list.sort((a, b) => this.statusPriority[a.status] - this.statusPriority[b.status]);
						this.threatProtection.url = response.url;
						this.filterDuplicateSafeLinks();
					}
				});
			} else {
				this.threatProtection.url = {
					status: dic.CONSTANTS.threatProtection.status.safe,
					message: dic.CONSTANTS.threatProtection.message.noLinks,
					list: []
				};
			}
		};

		const analyzeAttachments = () => {
			if (this.attachments && this.attachments.length) {
				this.reportOptions.maliciousFiles.show = true;
				if (this.bodyContent && this.bodyContent.Content || this.subject) {
					const pdfFile = this.attachments.filter(att => att.type === 'application/pdf');
					if (pdfFile.length) {
						const password = this.gs.findEmailPasswords(this.subject +' '+ (this.bodyContent && this.bodyContent.Content));
						if (password.length) {
							pdfFile.forEach(att => att.password = password);
						}
					}
				}

				return new Promise<void>((resolve, reject) => {
					const currentMessageId = this.office365Service.internetMessageId;
					const attachmentsHash = _.map(this.attachments, 'hash');
					let threatData:any = {
						value: attachmentsHash,
						options: {
							fromAddress: this.userProfile && this.userProfile.sender && this.userProfile.sender.emailAddress || '',
						},
						messageId: currentMessageId,
						client: 'Outlook',
						isHash: true}
					;

					this.rs.threatProtectionScanFiles(threatData).then((hashResponse) => {
						let newAttachments = [], existAttachments = [], attachment;
						if (hashResponse && hashResponse.messageId === currentMessageId) {
							for (let i = 0; i < this.attachments.length; i++) {
								if (hashResponse && hashResponse.hash && hashResponse.hash.list && hashResponse.hash.list.length) {
									attachment = _.find(hashResponse.hash.list, a => a.hash === this.attachments[i].hash);
								}
								if (attachment) {
									attachment.name = this.attachments[i].name;
									attachment.type = this.attachments[i].type;
									attachment.size = this.attachments[i].size;
									existAttachments.push(attachment);
								}
								else {
									if (this.attachments[i].size > dic.CONSTANTS.maxAttachmentScanSize) {
										this.attachments[i].status = dic.CONSTANTS.threatProtection.status.safe;
										existAttachments.push(this.attachments[i]);
									}
									else {
										newAttachments.push(this.attachments[i]);
									}
								}
							}
						}
						else {
							newAttachments = this.attachments;
						}

						if (!newAttachments.length && existAttachments.length) {
							existAttachments = existAttachments.sort((a, b) => {
								if (a.status === dic.CONSTANTS.threatProtection.status.malicious || a.status === dic.CONSTANTS.threatProtection.status.suspicious || a.status === dic.CONSTANTS.threatProtection.status.unsecured) {
									return -1;
								}
								return 1;
							});
							if (existAttachments.find(itm => itm.status === dic.CONSTANTS.threatProtection.status.malicious)) {
								hashResponse.hash = {
									status: dic.CONSTANTS.threatProtection.status.malicious,
									message: hashResponse.hash && hashResponse.hash.message || 'Malicious attachments detected.'
								};
							}
							else {
								hashResponse.hash = {
									status: dic.CONSTANTS.threatProtection.status.safe,
									message: hashResponse.hash && hashResponse.hash.message || dic.CONSTANTS.threatProtection.message.noAttachments
								};
							}
							hashResponse.hash.list = existAttachments;
							this.threatProtection.file = hashResponse.hash;
							return resolve();
						}

						threatData = {
							value: newAttachments,
							options: {
								fromAddress: this.userProfile && this.userProfile.sender && this.userProfile.sender.emailAddress || '',
							},
							messageId: currentMessageId,
							client: 'Outlook'
						};
						this.rs.threatProtectionScanFiles(threatData).then((response) => {
							if (response && response.hash && response.hash.list && response.messageId === currentMessageId) {
								if (existAttachments && existAttachments.length) {
									response.hash.list = response.hash.list.concat(existAttachments);
									if (hashResponse.hash && (hashResponse.hash.status === dic.CONSTANTS.threatProtection.status.malicious ||
										(hashResponse.hash.status === dic.CONSTANTS.threatProtection.status.suspicious && response.hash.status !== dic.CONSTANTS.threatProtection.status.malicious) ||
										(hashResponse.hash.status === dic.CONSTANTS.threatProtection.status.unsecured && response.hash.status !== dic.CONSTANTS.threatProtection.status.malicious && response.hash.status !== dic.CONSTANTS.threatProtection.status.suspicious) ||
										(hashResponse.hash.status === dic.CONSTANTS.threatProtection.status.spam && response.hash.status === dic.CONSTANTS.threatProtection.status.safe))) {
										response.hash.status = hashResponse.hash.status;
									}
								}

								response.hash.list = response.hash.list.sort((a, b) => this.statusPriority[a.status] - this.statusPriority[b.status]);
								this.ngZone.run(() => {
									this.threatProtection.file = response.hash;
								});
								resolve();
							}
						}, err => {
							console.error(err);
							this.threatProtection.file = {
								status: dic.CONSTANTS.threatProtection.status.safe,
								message: this.threatProtection.file && this.threatProtection.file.message || dic.CONSTANTS.threatProtection.message.noAttachments,
								list: []
							};
							throw err;
						});
					}, err => {
						console.error(err);
						this.threatProtection.file = {
							status: dic.CONSTANTS.threatProtection.status.safe,
							message: this.threatProtection.file && this.threatProtection.file.message || dic.CONSTANTS.threatProtection.message.noAttachments,
							list: []
						};
						throw err;
					});
				});
			}
			else {
				this.threatProtection.file = {
					status: dic.CONSTANTS.threatProtection.status.safe,
					message: dic.CONSTANTS.threatProtection.message.noAttachments,
					list: []
				};
			}
		};

		q.allSettled([
			actions.analyzeHeaders && analyzeHeaders(),
			actions.analyzeURLs && analyzeURLs(),
			actions.analyzeAttachments && analyzeAttachments()
		]).then((results) => {
			results.filter((itm:any) => itm.status === 'rejected').forEach((itm:any) => {
				console.error(itm.error);
			});

			if (!this.gs.threatDetectionCache || !this.gs.threatDetectionCache[this.headers.messageID]) {
				if (!actions.analyzeHeaders) {
					this.threatProtection.headers.status = dic.CONSTANTS.threatProtection.status.safe;
				}
				if (!actions.analyzeURLs) {
					this.threatProtection.url.status = dic.CONSTANTS.threatProtection.status.safe;
				}
				if (!actions.analyzeAttachments) {
					this.threatProtection.file.status = dic.CONSTANTS.threatProtection.status.safe;
				}
			}

			this.ngZone.run(() => {
				if (this.threatProtection.url.status === dic.CONSTANTS.threatProtection.status.malicious || this.threatProtection.file.status === dic.CONSTANTS.threatProtection.status.malicious || this.threatProtection.headers.status === dic.CONSTANTS.threatProtection.status.malicious) {
					this.threatProtection.status = dic.CONSTANTS.threatProtection.status.malicious;
				}
				else if ( (!this.threatProtection.headers.isCertainContact && this.threatProtection.url.status === dic.CONSTANTS.threatProtection.status.suspicious) || (!this.threatProtection.headers.isCertainContact && this.threatProtection.file.status === dic.CONSTANTS.threatProtection.status.suspicious) || this.threatProtection.headers.status === dic.CONSTANTS.threatProtection.status.suspicious) {
					this.threatProtection.status = dic.CONSTANTS.threatProtection.status.suspicious;
				}
				else if (this.threatProtection.url.status === dic.CONSTANTS.threatProtection.status.spam || this.threatProtection.file.status === dic.CONSTANTS.threatProtection.status.spam || this.threatProtection.headers.status === dic.CONSTANTS.threatProtection.status.spam) {
					this.threatProtection.status = dic.CONSTANTS.threatProtection.status.spam;
				}
				else if (this.threatProtection.headers.status === dic.CONSTANTS.threatProtection.status.grayMail) {
					this.threatProtection.status = dic.CONSTANTS.threatProtection.status.grayMail;
				}
				else if (this.threatProtection.url.status === dic.CONSTANTS.threatProtection.status.unsecured || this.threatProtection.file.status === dic.CONSTANTS.threatProtection.status.unsecured || this.threatProtection.headers.status === dic.CONSTANTS.threatProtection.status.unsecured) {
					this.threatProtection.status = dic.CONSTANTS.threatProtection.status.safe;
				}
				else if (this.threatProtection.url.status === dic.CONSTANTS.threatProtection.status.pending || this.threatProtection.file.status === dic.CONSTANTS.threatProtection.status.pending || this.threatProtection.headers.status === dic.CONSTANTS.threatProtection.status.pending) {
					this.threatProtection.status = dic.CONSTANTS.threatProtection.status.pending;
					// repeat the relevant action/s every 12 sec to get status
					setTimeout(() => {
						const actions = {
							analyzeURLs: this.threatProtection.url.status === dic.CONSTANTS.threatProtection.status.pending,
							analyzeAttachments: this.threatProtection.file.status === dic.CONSTANTS.threatProtection.status.pending,
							analyzeHeaders: this.threatProtection.headers.status === dic.CONSTANTS.threatProtection.status.pending
						};
						if (actions.analyzeAttachments) {
							console.warn('removing files content on retry');
							this.attachments.forEach(file => delete file.content);
						}
						this.threatsDetectionAndAlert(actions, ++counter);
					}, dic.CONSTANTS.threatProtection.retryTimeout);
				}
				else {
					this.threatProtection.status = dic.CONSTANTS.threatProtection.status.safe;
				}

				if (this.threatProtection.status === dic.CONSTANTS.threatProtection.status.safe && this.threatProtection.headers.userTag) {
					this.threatProtection.status = this.threatProtection.headers.userTag;
				}

				const cacheKeys = Object.keys(this.gs.threatDetectionCache);
				if (cacheKeys && cacheKeys.length >= dic.CONSTANTS.threatDetectionCacheSize) {
					for (let i = 0; i < dic.CONSTANTS.threatDetectionCacheSize / 2; i++) {
						delete this.gs.threatDetectionCache[cacheKeys[i]];
					}
				}
				if (this.threatProtection && this.threatProtection.status !== dic.CONSTANTS.threatProtection.status.pending && this.threatProtection.status !== dic.CONSTANTS.threatProtection.status.analyzing) {
					this.gs.threatDetectionCache[this.headers.messageID] = this.threatProtection;
					this.gs.threatDetectionCache[this.headers.messageID].messageHeaders = this.headers;
				}
			});
		});
	}

	stripHTML(data) {
		if (!data) {
			return '';
		}

		return data
			.replace(/(?=<link[^>]*>)([\s\S]*?)[>]/ig, ' ')
			.replace(/(?=<style[^>]*>)([\s\S]*?)<\/style>/ig, ' ')
			.replace(/(?=<script[^>]*>)([\s\S]*?)<\/script>/ig, ' ')
			.replace(/src=['"]data[:][^\n\s'"]*['"]/ig, ' ')
			.replace(/alt=['"]cid[:][^\n\s'"]*['"]/ig, ' ')
			.replace(/<[^>]+(href=|src=|action=|title=|alt=)"([^"]+)"[^>]*>/ig, ' $2 ')
			.replace(/<(https?)([^>]+)>/ig, ' $1$2 ')
			.replace(/<\/?[^>]+>/ig, ' ')
			.replace(/&nbsp;/g, ' ')
			.replace(/\s\s+/g, ' ')
			.trim();
	}

	initReportOptions() {
		this.reportOptions =  {
			spamEmail: {enabled: false, label: 'inboundShield.report.reportOptions.spam', tooltip: 'inboundShield.report.reportOptions.spamTooltip', show: true},
			trustSender: {enabled: false, label: 'inboundShield.report.reportOptions.sender', tooltip: 'inboundShield.report.reportOptions.senderTooltip', show: true},
			maliciousLinks: {enabled: false, label: 'inboundShield.report.reportOptions.links', tooltip: 'inboundShield.report.reportOptions.linksTooltip', show: false},
			maliciousFiles: {enabled: false, label: 'inboundShield.report.reportOptions.attachments', tooltip: 'inboundShield.report.reportOptions.attachmentsTooltip', show: false},
			emailSuspicious: {enabled: false, label: 'inboundShield.report.reportOptions.suspicious', tooltip: 'inboundShield.report.reportOptions.suspiciousTooltip', reason: '', show: true},
        };
	}


	isSendReportDisabled() {
		return !this.reportOptions.spamEmail.enabled && !this.reportOptions.trustSender.enabled && !this.reportOptions.maliciousFiles.enabled &&
			!this.reportOptions.maliciousLinks.enabled && !this.reportOptions.emailSuspicious.enabled;
	}

	checkBlockThreatScan() {
		this.blockScan = !this.userInfo.email_verified || (this.userInfo.plan.plan === dic.CONSTANTS.planType.pro &&
			(!this.userInfo.plan.threat_protection.enabled || !this.userInfo.plan.threat_protection.allowed_users
				|| (this.userInfo.plan.threat_protection.allowed_users === 1 && this.userInfo.user_role !== dic.CONSTANTS.userRole.admin))); // you need to have inbound licenses
	}

	private updateUserThreatsExecute(data) {
		data.reportTo = this.reportTo;
		data.reportedBy = this.userInfo.email;
		data.message_id = this.office365Service.internetMessageId;
		data.threatProtection = this.threatProtection;
		data.rawData = this.rawData;
		data.resourceId = this.office365Service.getResourceId();
		data.sender_info = {
			name: this.sender.displayName,
			email: this.sender.emailAddress
		};
		data.subject = this.office365Service.getEmailSubject();

		const parentFolderIdHeader = this.headers.find(h => h.Name === 'parentFolderId');
		if (parentFolderIdHeader && parentFolderIdHeader.Value) {
			data.parentFolderId = parentFolderIdHeader.Value;
		}

		this.rs.sendUserThreats(data).then(() => {
			this.reportSent = true;
			this.reportError = false;
			this.sendReportInProcess = false;
			this.doThreatProtectionScan(true);
		}, (err) => {
			this.sendReportInProcess = false;
			this.reportError = err.data.message;
			console.error(err);
		});
	}

	//[NOT IN USE]
	closePostReportMessage() {
		this.actionToConfirm = null;
		this.reportSent = false;
		this.reportTo = dic.CONSTANTS.reportToList.personal;
		let isSpam = false;
		if (this.reportOptions.spamEmail.enabled) {
			isSpam = true;
		}
		this.reportOptions = _.mapValues(this.reportOptions, (option: any) => {
			option.enabled = false;
			if (option.reason) {
				option.reason = '';
			}
			return option;
		});

		if (isSpam) {
			this.office365Service.moveEmail(error => {
				if (error) {
					console.error('[Trustifi] failed moving email to junk folder', error);
				}
			});
		}
	}

	setInboundStatusStyles(status) {
		switch (status) {
			case dic.CONSTANTS.threatProtection.status.malicious:
				return {color: 'darkred', icon: 'fa-bug', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.suspicious:
				return {color: 'darkred', icon: 'fa-bug', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.error:
				return {color: 'darkred', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.spam:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
			case dic.CONSTANTS.threatProtection.status.grayMail:
				return {color: 'dimgray', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--gray2)'};
			case dic.CONSTANTS.threatProtection.status.unsecured:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
			case dic.CONSTANTS.threatProtection.status.pending:
			case dic.CONSTANTS.threatProtection.status.analyzing:
			case dic.CONSTANTS.threatProtection.status.analyzingLinks:
				return {color: 'grey', icon: 'fa-spinner fa-spin', loading: true, displayColor: 'var(--gray)'};
			case dic.CONSTANTS.threatProtection.status.safe:
				return {color: 'darkgreen', icon: 'fa-check-circle', loading: false, displayColor: 'var(--success)'};
			default:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
		}
	}

	selectAllLinksReport = () => {
		this.reportOptions.maliciousLinks.error = false;
		const isSelectAll = !_.every(this.threatProtection.url.list, 'selected');

		this.threatProtection.url.list.forEach(link => {
			link.selected = isSelectAll;
		});
	}

	sendReport() {
		if (this.sendReportInProcess) {
			return;
		}

		if (this.reportOptions.maliciousLinks.enabled) {
			if (!_.some(this.threatProtection.url.list, 'selected')) {
				this.reportOptions.maliciousLinks.error = true;
				this.notificationService.showErrorMessage(dic.ERRORS.requiredField);
				return;
			}
		}

		this.reportSent = false;
		this.sendReportInProcess = true;
		this.notificationService.clear();

		const data: any = {
			reportType: dic.CONSTANTS.reportType.report,
			action: 'Add'
		};

		if (this.reportOptions.trustSender.enabled) {
			data.sender = {
				name: this.sender.displayName,
				email: this.sender.emailAddress
			};
			data.items = [{type: 'email', value: this.sender.emailAddress}];

			if (this.userInfo.plan.domains && this.userInfo.plan.domains.length) {
				const domain = this.gs.getDomain(data.sender.email);

				if (domain === 'trustificorp.com' || this.userInfo.plan.domains.find(d => d.domain === domain && d.verified)) {
					this.sendReportInProcess = false;
					return this.notificationService.showErrorMessage(dic.ERRORS.cannotAddOwnDomain);
				}
			}
		}
		if (this.reportOptions.maliciousFiles.enabled) {
			data.attachments = _.map(this.attachments, attachment => {
				return {
					name: attachment.name,
					hash: attachment.hash
				};
			});
		}
		if (this.reportOptions.maliciousLinks.enabled) {
			data.links = [];
			this.threatProtection.url.list.forEach((linkObj) => {
				if (linkObj.url.indexOf('trustifi.com') < 0 && linkObj.selected) {
					data.links.push({link: linkObj.url});
				}
			});
		}
		if (this.reportOptions.emailSuspicious.enabled) {
			data.suspicious = true;
			data.reportReason = this.reportOptions.emailSuspicious.reason;
		}
		if (this.reportOptions.spamEmail.enabled) {
			data.spam = true;
		}

		this.updateUserThreatsExecute(data);
	}

	trustThisEmail() {
		if (this.sendReportInProcess) {
			return;
		}
		this.reportSent = false;
		this.sendReportInProcess = true;
		const data: any = {
			reportType: dic.CONSTANTS.reportType.trust,
			action: 'Add',
			sender: {
				name: this.sender.displayName,
				email: this.sender.emailAddress
			}
		};

		let signature;
		if (this.threatProtection.headers && this.threatProtection.headers.from) {
			signature = {
				spf_domain: this.threatProtection.headers.from.receivedSPF && this.threatProtection.headers.from.receivedSPF.domain || '',
				mta_domain: this.threatProtection.headers.from.sendingMTA && this.threatProtection.headers.from.sendingMTA.domain || ''
			};
		}
		data.items = [{type: 'email', value: this.sender.emailAddress, name: this.sender.displayName, signature}];

		if (this.attachments && this.attachments.length) {
			data.attachments = _.map(this.attachments, attachment => {
				return {
					name: attachment.name,
					hash: attachment.hash
				};
			});
		}
		if (this.threatProtection.url.list && this.threatProtection.url.list.length) {
			data.links = [];
			this.threatProtection.url.list.forEach((linkObj) => {
				if (linkObj.url.indexOf('trustifi.com') < 0) {
					data.links.push({link: linkObj.url});
				}
			});
		}

		this.updateUserThreatsExecute(data);
	}

	//[NOT IN USE]
	confirmTPResults() {
		this.confirmTPResultsInProcess = true;
		this.office365Service.moveEmail(error => {
			this.showConfirmStatus = false;
			if (error) {
				console.error('[Trustifi] failed moving email to junk folder', error);
			}

			if (this.threatProtection.headers.isGrayMail || this.threatProtection.status === dic.CONSTANTS.threatProtection.status.grayMail || this.threatProtection.headers.isSpam || this.threatProtection.status === dic.CONSTANTS.threatProtection.status.spam) {
				const titleHeader = this.headers.find(h => h.Name === 'Subject');
				this.rs.sendReport({
					type: dic.CONSTANTS.generalReportType.spam,
					message_id: this.office365Service.internetMessageId,
					title: titleHeader && titleHeader.Value || '',
					emailContent: this.bodyContent.Content
				}).then(() => {
					this.confirmTPResultsInProcess = false;
					this.notificationService.showInfoMessage('mailbox.sensitivity.resultsReported');
					setTimeout(() => this.notificationService.clear(), 5000);
				});
			} else {
				this.confirmTPResultsInProcess = false;
			}
		});
	}

	//[NOT IN USE]
	checkCurrentFolder() {
		if (!this.headers) { return; }
		const parentFolderIdHeader = this.headers.find(h => h.Name === 'parentFolderId');
		if (parentFolderIdHeader && parentFolderIdHeader.Value) {
			this.office365Service.getExchangeFolder(parentFolderIdHeader.Value, 'json', (err, data) => {
				if (err || !data) {
					return;
				}
				this.isJunkFolder = data.DisplayName === dic.CONSTANTS.junkFolderName;
			});
		}
	}

	asIsOrder(a, b) {
		return 0;
	}
}
