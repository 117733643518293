<div class="flxRow-between" style="position: fixed; bottom: 0; padding: 5px 0 0; right: 4%; z-index: 10; align-items: center;"
	 [ngClass]="{'t-bg-primary' : office365Service.currentPage !== dic.CONSTANTS.appPages.compose}"
	 [ngStyle]="{'width' : office365Service.currentPage === dic.CONSTANTS.appPages.compose ? 'auto' : '92%', 'padding-bottom' :  office365Service.currentPage === dic.CONSTANTS.appPages.compose ? '7px' : '1px'}">

	<div>
		<img src="images/logo-transparent.svg" alt="{{trustifiDefault.name}}" [hidden]="office365Service.currentPage === dic.CONSTANTS.appPages.compose" style="height:20px;"/>
	</div>

	<div class="footer-links">
		<div class="language-select drop-menu-container" style="display: inline;">
			<a class="button t-color-primary" style="font-size:12px; font-weight: bold; padding: 0 3px;" id="selectLanguageMenuTrigger"
			   tooltip="{{'general.language' | translate}}"
			   (click)="showLanguageMenu = !showLanguageMenu;">
				<span>{{translateService.selectedAppLang.shortened}}</span>
			</a>
			<div class="drop-menu show-above"
				 (clickOutside)="showLanguageMenu = false;"
				 [exclude]="'#selectLanguageMenuTrigger'"
				 [ngClass]="{'active' : showLanguageMenu}">
				<ul>
					<li *ngFor="let langObj of _.values(dic.CONSTANTS.supportedLanguages)"
						(click)="changeLanguage(langObj)">
						<span [ngStyle]="{'font-weight' : translateService.selectedAppLang.code === langObj.code ? 'bold' : 'normal'}">{{langObj.labelLocal}}</span>
					</li>
				</ul>
			</div>
		</div>
		<a class="button t-color-primary" *ngIf="authService.isFullyAuthenticated"
		   (click)="darkModeToggle()" style="font-size: 14px;">
			<i class="fas fa-adjust" style="transform: rotate(180deg);" tooltip="{{'footer.darkMode' | translate}}"></i>
		</a>
		<a class="button t-color-primary" *ngIf="office365Service.currentPage === dic.CONSTANTS.appPages.mailbox"
		   (click)="refreshData();" style="font-size:12px;">
			<i class="fas fa-sync-alt" tooltip="{{'footer.refresh' | translate}}"></i>
		</a>
		<a class="button t-color-primary" *ngIf="authService.isFullyAuthenticated && office365Service.profile?.user?.accountType !== 'enterprise'"
		   (click)="loadContacts()" [ngStyle]="office365Service.currentPage === dic.CONSTANTS.appPages.mailbox && {'padding-left': '3px'}">
			<i class="fas fa-user-plus" tooltip="{{'footer.importContacts' | translate}}" placement="top" max-width="140"></i>
		</a>
		<a class="button t-color-primary" *ngIf="authService.isFullyAuthenticated && !hideSupportContactBtn.includes(gs.userInfo && gs.userInfo.plan && gs.userInfo.plan._id)"
		   (click)="showSupportForm()" style="padding-left:3px;">
			<i class="fas fa-headset" tooltip="{{'footer.contactSupport' | translate}}"></i>
		</a>
		<a class="button t-color-primary" *ngIf="!hideOpenWebAppBtn.includes(gs.userInfo && gs.userInfo.plan && gs.userInfo.plan._id)"
		   [href]="feBaseUrl" style="padding-left:3px;" target="_blank">
			<i class="fas fa-globe open-website" tooltip="{{'footer.openWebsite' | translate}}" placement="left"></i>
		</a>
		<a class="button t-color-primary" *ngIf="authService.isFullyAuthenticated"
		   (click)="logout()" style="padding-left:3px;">
			<i class="fas fa-sign-out-alt" tooltip="{{'footer.logOut' | translate}}" placement="left"></i>
		</a>
	</div>
</div>
