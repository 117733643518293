<div class="autocomplete-drop-menu" [ngClass]="{'active' : showMenu}">
    <ul (clickOutside)="showMenu = false;"
        (keyup.arrowDown)="navigateInAutoCompleteResults('down')"
        (keyup.arrowUp)="navigateInAutoCompleteResults('up')">
        <!--list-->
        <ng-container *ngIf="!customListTemplate">
            <li class="flex-row align-items-center"
                tabindex="0"
                *ngFor="let item of filteredItems; trackBy: gs.trackByIndex"
                [highlightText]="searchTerm"
                [isHighlightOn]="true"
                (keyup.enter)="showMenu = false; onItemClick.emit({item: item});"
                (click)="showMenu = false; onItemClick.emit({item: item});">
                <span>{{item[displayKey] || item}}</span>
            </li>
        </ng-container>
        <!--custom list-->
        <ng-container
                *ngIf="customListTemplate"
                [ngTemplateOutlet]="customListTemplate"
                [ngTemplateOutletContext]="{$implicit: filteredItems}">
        </ng-container>

        <!--add new item-->
        <li class="focused" style="font-weight: bold;" tabindex="0"
            *ngIf="newItem"
            (click)="showMenu = false; onItemClick.emit({item: newItem});"
            (keyup.enter)="showMenu = false; onItemClick.emit({item: newItem});">
            <span>{{newItem}}</span>
        </li>
    </ul>
</div>
