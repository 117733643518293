<div class="flxClmn bs-enabled centralize confirm-action-outer-container animated fadeIn">
    <div class="flex-column confirm confirm-action-inner-container p-3 gap-2 shadow t-bg-primary">
        <div class="flxRow-between" style="align-items: center; font-size: 13px;">
            <span style="font-weight: bold;">{{title}}</span>
            <a class="t-color-primary" [ngClass]="{'disabled': isLoading || isDisabled}" (click)="closeCb.emit();">
                <i class="fa fa-times"></i>
            </a>
        </div>
        <hr class="mt-1 mb-2 w-100">
        <span class="f-1-0" [innerHTML]="text"></span>
        <div class="flex-row gap-2 mt-5 justify-content-end align-items-center">
            <button class="btn-rect silent"
                    [ngClass]="{'disabled': isLoading || isDisabled}"
                    (click)="closeCb.emit();">
                {{'general.cancel' | translate}}
            </button>
            <button class="btn-rect" style="width: 100px; height: 27px;" (click)="confirmCb.emit();" [ngClass]="{'disabled': isLoading || isDisabled}">
                <span *ngIf="!isLoading">{{'general.confirm' | translate}}</span>
                <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
            </button>
        </div>
        <span style="color: red; line-height: 1.2; font-size: 12px;">
            {{error || ''}}
        </span>
    </div>
</div>
