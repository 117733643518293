import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import { dic } from '../../dictionary';


@Component({
    selector: 'app-attachments-manager',
    templateUrl: './attachments-manager.component.html',
    styleUrls: ['./attachments-manager.component.scss']
})
export class AttachmentsManagerComponent implements OnInit {
    dic = dic;
    showSpinner = true;
    attachmentSearch = '';
    revDate = false;
    selectedAttachments = []; // the selected data inside the directive
    filteredAttachments;
    isOutlookClient = Office.context.mailbox.diagnostics.hostName !== 'OutlookWebApp';

    @Input() attachmentsManagerData;
    @Input() attachments;
    @Input() messageAttachments;

    @Output() doneCb: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
        if (!this.attachments) return;
        this.showSpinner = false;
        this.selectedAttachments = [];
        this.filteredAttachments = this.attachments;

        for (let i = 0; i < this.filteredAttachments.length; i++) {
            if (_.findIndex(this.messageAttachments, {_id: this.filteredAttachments[i]._id}) >= 0) {
                this.filteredAttachments[i].checked = true;
                this.selectedAttachments.push(this.filteredAttachments[i]);
            }
            else {
                this.filteredAttachments[i].checked = false;
            }
        }
    }

    toggleAttachment(attachment) {
        attachment.checked = !attachment.checked;
        this.selectedAttachments = _.filter(this.filteredAttachments, {checked : true});
    }

    addAttachmentsToMessage() {
        this.doneCb.emit(this.selectedAttachments);
    }
}
