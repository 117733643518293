<div class="input-wrap" style="float: inherit;" [style.z-index]="1000 - index">
	<input type="text" required name="email_{{index}}"
		   style="width: 80%;margin-bottom: 1px" placeholder="{{'compose.recipients.email' | translate}}"
		   [disabled]="isMailBox"
		   [(ngModel)]="clone.email"
		   (keyup.enter)="executeAction()"
		   (input)="validateEmail()"
		   [ngClass]="{'validation-error': emailError}">

	<input type="text" style="width: 80%;margin-bottom: 0px" name="name_{{index}}" placeholder="{{'compose.recipients.name' | translate}}"
		   (keyup.enter)="executeAction()"
		   [(ngModel)]="clone.name" maxlength="72"
		   (input)="validateName()"
		   [ngClass]="{'validation-error' : nameError}">
	<br/>

	<div class="bottom-row" style="margin-bottom: 1px;">
		<international-phone-number
				class="country-code" placeholder="{{'compose.recipients.phoneNumber' | translate}}"
				[(ngModel)]="clone.phone.country_code"
				(onCountryCodeChanged)="updateCountry($event); validatePhone()"
				[maxlength]="20"
				[allowedCountries]="allowedCountries">
		</international-phone-number>

		<input type="text" [name]="random + 'phoneInput_' + index" [attr.id]="'phone_'+index" placeholder="{{'compose.recipients.phoneNumber' | translate}}" style="width: calc(80% - 90px); height: 32px; margin: 2px 0 0px 0px;"
			   (keyup.enter)="executeAction()"
			   [(ngModel)]="clone.phone.phone_number" name="{{index}}"
			   (input)="validatePhone()"
			   [ngClass]="{'validation-error': phoneError}">
	</div>

	<div>
		<input type="text" placeholder="{{'compose.recipients.password' | translate}}"
			   style="width: 90px;margin: 0px;" name="password_{{index}}"
			   (keyup.enter)="executeAction()"
			   [(ngModel)]="clone.default_password.password" />
		<input type="text" placeholder="{{'compose.recipients.hint' | translate}}"
			   style="width: calc(80% - 90px);margin: 0px;" name="hint_{{index}}"
			   (keyup.enter)="executeAction()"
			   [(ngModel)]="clone.default_password.hint" />
		<i class="fas fa-question-circle" style="font-weight: 400;position: relative;"
		   tooltip="{{'compose.recipients.passwordTooltip' | translate}}">
		</i>

		<ul class="btn-list" style="width: 35px; padding-top: 7px;">
			<li style="width: 12px">
				<a (click)="executeAction()">
					<i class="zmdi zmdi-check"></i>
				</a>
			</li>
			<li style="margin-right: 0;">
				<a (click)="cancelAction()">
					<i class="zmdi zmdi-close"></i>
				</a>
			</li>
		</ul>
	</div>

	<div *ngIf="clone._id && isMailBox" style="position: absolute; color: red; margin-top: 5px;">{{updateRecipientError}}</div>
</div>
