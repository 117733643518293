import {Inject, Injectable } from '@angular/core';
import {dic} from "../dictionary";
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import {ENV_CONSTS} from "../constants";
import * as shaJs from 'sha.js';
import {Buffer} from 'safe-buffer';
import {GeneralService} from "./general.service";
import {RestService} from "./rest.service";
import {BrowserCacheLocation, createNestablePublicClientApplication, IPublicClientApplication, LogLevel} from '@azure/msal-browser';
import {lastValueFrom, take} from 'rxjs';

const USE_NAA_AUTH = true;
const NAA_APP_SCOPE_READ = ["Mail.Read", "User.Read", "openid", "profile"];
const NAA_APP_SCOPE_WRITE = ["Mail.ReadWrite", "User.Read", "openid", "profile"];
const NAA_APP_ID = '8234097e-7057-40b2-a0b6-c0ce63d56251';
const isIEOrEdge = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
const REST_API_BASE = 'https://outlook.office365.com/api/v2.0';
const GRAPH_API_BASE = 'https://graph.microsoft.com/v1.0';

@Injectable({
	providedIn: 'root'
})
export class Office365RestService {

	useNAAAuthentication = USE_NAA_AUTH;

	clientAppPromise: any;
	clientApp: IPublicClientApplication;

	restToken = {
		source: '',
		token: null,
		expired: 0,
		email: null,
		tid: null,
		restUrl: null
	};

	accountType = '';
	emailAddress = '';

	constructor(private gs: GeneralService,
				private rs: RestService,
				private http: HttpClient) {

		//this code is unnecessary:
		try {
			let user = Office.context.mailbox && Office.context.mailbox.userProfile;
			this.accountType = user && user.accountType;
			this.emailAddress = user && user.emailAddress;
		}
		catch(ex) {
			console.error('[Trustifi] userProfile error: '+ex.message, ex);
			this.accountType = null;
			this.emailAddress = null;
		}

		this.useNAAAuthentication = USE_NAA_AUTH && Office.context.requirements.isSetSupported('NestedAppAuth');
		if (!this.useNAAAuthentication) {
			this.rs.sendLogs('info', 'NAA', {message: `NAA is not supported`});
			return;
		}

		//In Outlook, the NestedAppAuth 1.1 requirement set isn't supported if the add-in is loaded in an Outlook.com or Gmail mailbox.
		if (this.accountType && (this.accountType === 'outlookCom' || this.accountType === 'gmail')) {
			this.rs.sendLogs('info', 'NAA', {message: 'NAA is not supported for this account type'});
			this.useNAAAuthentication = false;
			return;
		}

		// Initialize the public client application
		this.clientAppPromise = createNestablePublicClientApplication({
			auth: {
				clientId: NAA_APP_ID,
				authority: 'https://login.microsoftonline.com/common/',
				//knownAuthorities: ["login.live.com"],
				//protocolMode: "OIDC",
				redirectUri: '/redirect.html',			// You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
				postLogoutRedirectUri: '/redirect.html'	// Simply remove this line if you would like navigate to index page after logout.
			},
			cache: {
				cacheLocation: BrowserCacheLocation.LocalStorage,
				storeAuthStateInCookie: isIEOrEdge,	// If you wish to store cache items in cookies as well as browser cache, set this to "true".
			},
			system: {
				//tokenRenewalOffsetSeconds: 5*60,
				//asyncPopups: true,
				//allowRedirectInIframe: true,
				allowNativeBroker: false, // acquiring tokens from WAM on Windows
				loggerOptions: {
					loggerCallback: (level, message) => {
						const logMethod = ['error', 'warn', 'info', 'debug', 'trace'][level];
						if (logMethod === 'error') {
							this.rs.sendLogs('error', 'NAA', {message: `[NAA] Error: ${message}`});
						} else {
							console[logMethod]('[NAA] ' + message);
						}
					},
					logLevel: LogLevel.Verbose,
					piiLoggingEnabled: true,
				},
			},
		}).then(res => {
			this.clientAppPromise = null;
			this.clientApp = res;
		}, err => {
			this.rs.sendLogs('error', 'NAA', {message: 'create client app Error', error: err});
			this.useNAAAuthentication = false;
			this.clientAppPromise = null;
		});
	}

	private getResourceId() {
		let restItemId = Office.context.mailbox.item.itemId;
		if (restItemId && Office.context.mailbox.diagnostics.hostName !== 'OutlookIOS') {
			restItemId = Office.context.mailbox.convertToRestId(restItemId, Office.MailboxEnums.RestVersion.v2_0);
		}
		return restItemId;
	}

	private getRestUrl(fName, restUrl, cb) {
		if(restUrl) {
			return cb(null, restUrl);
		}

		//NOTE: the "this.gs.userInfo" should already exist so, we can theoretically flatten this function.
		this.gs.getUserInfo(false).then((userInfo) => {
			let planId = userInfo.plan && userInfo.plan._id && userInfo.plan._id.toString();
			restUrl = (planId === '60ad1289354477000427623d') ? 'https://aguamail.acbci.net/api/v2.0' : REST_API_BASE;
			cb(null, restUrl);
		}, err => {
			this.rs.sendLogs('error', fName, {message:'getUserInfo Error', error: err.message});
			restUrl = REST_API_BASE;
			cb(null, restUrl);
		});
	}

	private getRestAPIToken(fName, requireWrite, cb) {
		console.log('enter getRestAPIToken: '+fName);

		if (this.useNAAAuthentication) {
			this.getGraphToken(fName, requireWrite).then(() => {
				cb(null, this.restToken.token, this.restToken.restUrl, this.restToken.expired);
			}, err => {
				if (!this.useNAAAuthentication) {
					return this.getRestAPIToken(fName, requireWrite, cb);
				}
				cb(err);
			});
			return;
		}
		else if (Office.auth && Office.context.requirements.isSetSupported('IdentityAPI', '1.3')) {
			this.rs.sendLogs('info', fName, {message: `NAA is not supported but IdentityAPI is supported`});
		}

		//the token is refreshed every 4min
		const maxExpirationTime = 250000;

		if (this.restToken.token && this.restToken.expired && (Date.now() < this.restToken.expired)) {
			if (this.emailAddress?.toLowerCase() === this.restToken.email?.toLowerCase()) {
				return cb(null, this.restToken.token, this.restToken.restUrl, this.restToken.expired);
			}

			this.restToken.token = null;
			console.error('[Trustifi] cannot get mailbox or mailbox is different from token email address', {user:this.emailAddress, token:this.restToken.email});
		}

		// Calling the getCallbackTokenAsync method in compose mode requires you to have saved the item.
		Office.context.mailbox.getCallbackTokenAsync({isRest: true}, result => {
			if (result && result.status === Office.AsyncResultStatus.Succeeded) {
				let payload:any = {};
				try {
					payload = atob(result.value.split('.')[1]);
					payload = JSON.parse(payload);
				}
				catch(ex) {
					console.error('[Trustifi] getCallbackTokenAsync parse JSON: '+ex.message, {token:result.value, ex});
					payload = {};
				}

				let tokenEmail = payload.email || payload.upn || payload.smtp || payload.preferred_username || payload.unique_name;
				if (!this.emailAddress || !tokenEmail || this.emailAddress.toLowerCase() !== tokenEmail.toLowerCase()) {
					let error = {message: `Mailbox user ${this.emailAddress} is different from token user ${tokenEmail}`};
					this.rs.sendLogs('warn', fName, {payload, error});
				}

				let exp = payload.exp && new Date(payload.exp*1000);
				if (exp && exp < new Date()) {
					let error = {message: `Wrong system time, The token is already expired at:${exp.toISOString()}, now is:${new Date().toISOString()}`};
					this.restToken.token = null;
					this.rs.sendLogs('error', fName, {iat: payload.iat && new Date(payload.iat).toISOString(), error});
					return cb(error);
				}

				this.restToken = {
					source: 'restapi',
					token: result.value,
					expired: Date.now() + maxExpirationTime,
					tid: payload.tid,
					email: tokenEmail,
					restUrl: Office.context.mailbox.restUrl + '/v2.0'
				};

				//if "payload.exp" is missing we use it as a one-time token
				let expired = payload.exp && (payload.exp*1000) || 0;
				if (expired < this.restToken.expired) {
					this.restToken.expired = expired;
				}

				if (!Office.context.mailbox.restUrl && payload.aud && payload.aud.startsWith('http')) {
					this.restToken.restUrl = payload.aud + '/api/v2.0';
				}

				this.getRestUrl(fName, this.restToken.restUrl, (err, restUrl) => {
					this.rs.sendLogs('info', fName, {message: 'getCallbackTokenAsync authenticated'});

					this.restToken.restUrl = restUrl;
					cb(null, this.restToken.token, this.restToken.restUrl, this.restToken.expired);
				});
			} else {
				this.restToken.token = null;
				this.rs.sendLogs('error', fName, {message:'getCallbackTokenAsync error', result});
				cb(result.error);
			}
		});
	}

	private async getGraphToken(fName:string, requireWrite=false) {
		console.log('enter getGraphToken: '+fName);

		if (this.clientAppPromise) {
			console.log('[Trustifi] waiting for NAA');
			await this.clientAppPromise;
		}

		if (!this.clientApp) {
			this.rs.sendLogs('error', 'NAA', {message:'NAA authentication failed'});
			this.useNAAAuthentication = false;
			return Promise.reject(new Error('NAA authentication failed'));
		}

		let naaScope = requireWrite ? NAA_APP_SCOPE_WRITE : NAA_APP_SCOPE_READ;
		let accessToken = null, authResult = null, restUrl = '', tokenSource = '', logs = [], account = null;
		try {
			account = this.clientApp.getActiveAccount();
			console.log(account);

			if (!account) {
				logs.push(`Doing "handleRedirectPromise"`);
				let tokenResponse = await this.clientApp.handleRedirectPromise();
				account = tokenResponse?.account;
			}

			account = this.clientApp.getAccountByUsername(this.emailAddress);
			if (account) {
				const idTokenClaims: { exp?: number } = account.idTokenClaims;
				const forceRefresh = (new Date(idTokenClaims.exp + 1000) < new Date());

				logs.push(`Doing "acquireTokenSilent"`);
				authResult = await this.clientApp.acquireTokenSilent({scopes:naaScope, forceRefresh, account});
				tokenSource = 'acquireTokenSilent';
			}
			else {
				logs.push(`Doing "ssoSilent"`);
				authResult = await this.clientApp.ssoSilent({scopes:naaScope});
				tokenSource = 'ssoSilent';
			}

			accessToken = authResult?.accessToken;
			if (!accessToken) {
				logs.push(`Error: Unable to acquire token silent`);
			}
		} catch (error) {
			logs.push(`Error: Unable to acquire token silent`, error);
		}

		if (!accessToken) {
			// Acquire token silent failure. Send an interactive request via popup.
			try {
				logs.push(`Doing "acquireTokenPopup"`);
				authResult = await this.clientApp.acquireTokenPopup({scopes: naaScope, account, loginHint: this.emailAddress});

				tokenSource = 'acquireTokenPopup';
				accessToken = authResult?.accessToken;
				if (!accessToken) {
					logs.push(`Error: Unable to acquire token by popup`);
				}
			} catch (error) {
				logs.push(`Error: Unable to acquire token by popup`, error);

				/*if (error.message.includes('User cancelled the flow')) {
					this.ns.showErrorMessage(error.errorMessage || error.message);
					this.rs.sendLogs('error', fName, {message: `NAA authentication failed`, logs});
					return Promise.reject(error);
				}*/

				/*if (error.message.includes('Error opening popup window') || error.message.includes('Interaction is currently in progress')) {
					this.ns.showErrorMessage(error.errorMessage || error.message);
					this.rs.sendLogs('error', fName, {message: `NAA authentication failed`, logs});
					return Promise.reject(error);
				}*/

				//TODO: implement acquireTokenRedirect inside displayDialogAsync
				//authResult = await this.clientApp.acquireTokenRedirect({scopes:naaScope, loginHint: this.emailAddress});
				//tokenResponse = await this.clientApp.handleRedirectPromise();
				//account = tokenResponse?.account;
			}
		}

		if (!accessToken) {
			// This flow requires publishing new manifest
			try {
				//https://learn.microsoft.com/azure/active-directory/develop/active-directory-v2-protocols-oauth-on-behalf-of
				//In Outlook, this API isn't supported if you load an add-in in an Outlook.com or Gmail mailbox.
				//In Outlook on the web, this API isn't supported if you use the Safari browser.
				//if you use the displayDialogAsync method to open a dialog, you must close the dialog before you can call `getAccessToken`.
				//Office only supports consent to Graph scopes when the add-in has been deployed by a tenant admin. Setting this option to true will cause Office to inform your add-in beforehand (by returning a descriptive error) if Graph access will fail.
				//If you develop an Outlook add-in that uses SSO and you sideload it for testing, Office will always return error 13012 when forMSGraphAccess is passed to getAccessToken even if administrator consent has been granted.

				if (!Office.auth || !Office.context.requirements.isSetSupported('IdentityAPI', '1.3')) {
					logs.push(`Error: Unable to acquire token by fallback`, 'This feature is not supported');
				}
				else {
					logs.push(`Doing "getAccessToken"`);
					accessToken = await Office.auth.getAccessToken({forMSGraphAccess: true, allowConsentPrompt: true, allowSignInPrompt: true});
					tokenSource = 'getAccessToken';
					if (!accessToken) {
						logs.push(`Error: Unable to acquire token by fallback`);
					}
				}
			}
			catch (error) {
				logs.push(`Error: Unable to acquire token by fallback`, error);

				if (error.message.toLowerCase().includes('challenge')) {
					//Microsoft Graph can send an error requesting the additional factor and the string that should be used with the `authChallenge` option.
					//accessToken = await Office.auth.getAccessToken({authChallenge: err.authChallenge, forMSGraphAccess:true, allowConsentPrompt:true, allowSignInPrompt:true});
				}
			}
		}

		if (!accessToken) {
			this.rs.sendLogs('error', fName, {message: `NAA authentication failed`, logs});
			this.useNAAAuthentication = false;
			return Promise.reject(new Error('NAA authentication failed'));
		}

		let payload:any = {};
		try {
			payload = atob(accessToken.split('.')[1]);
			payload = JSON.parse(payload);
			console.log('[Trustifi] received token for '+this.emailAddress, {payload});
		}
		catch(ex) {
			console.error('[Trustifi] getGraphToken parse JSON: '+ex.message, {token:accessToken, ex});
			payload = {};
		}

		let tokenEmail = payload.email || payload.upn || payload.smtp || payload.preferred_username || payload.unique_name;
		if (!this.emailAddress || !tokenEmail || this.emailAddress.toLowerCase() !== tokenEmail.toLowerCase()) {
			let error = {message: `Mailbox user ${this.emailAddress} is different from token user ${tokenEmail}`};
			this.rs.sendLogs('warn', fName, {tokenSource, payload, logs, error});
		}

		let exp = payload.exp && new Date(payload.exp*1000);
		if (exp && exp < new Date()) {
			let error = {message: `Wrong system time, The token is already expired at:${exp.toISOString()}, now is:${new Date().toISOString()}`};
			this.restToken.token = null;
			this.rs.sendLogs('error', fName, {iat: payload.iat && new Date(payload.iat).toISOString(), logs, error});
			return Promise.reject(error);
		}

		this.restToken = {
			source: 'graphapi',
			token: accessToken,
			expired: 0,
			tid: payload.tid,
			email: tokenEmail,
			restUrl: restUrl || GRAPH_API_BASE
		};

		this.rs.sendLogs('info', fName, {message: 'NAA token acquired by: '+tokenSource, logs});
		return accessToken;
	}

	private getSharedMailboxItem(sharedProperties, cb) {
		const fName = 'getSharedMailboxItem';

		this.getRestAPIToken(fName, false, (err, token) => {
            if (err) {
				return cb(err);
			}

			let restId = this.getResourceId();
			let rest_url = sharedProperties.targetRestUrl + "/users/" + sharedProperties.targetMailbox + "/messages/" + restId;

			this.http.get(rest_url, {
				headers: { Authorization: "Bearer " + token }
			}).subscribe({ next: (response) => {
				console.log(response);
				cb(null, response);
			}, error: (error) => {
				console.error(error);
				cb(error);
			}});
        });
    }

	private getExchangeMessage(fName, querystring, responseType, cb) {

		let restItemId = this.getResourceId();
		if (!restItemId) {
			console.error('[Trustifi] failed to convert: ' + Office.context.mailbox.item.itemId, querystring);
			return cb({message: 'failed to convert ' + Office.context.mailbox.item.itemId});
		}

		this.getRestAPIToken(fName, false, (err, token, restUrl, expired) => {
			if (err) {
				return cb(err);
			}

			this.http.get(restUrl + '/me/messages/' + restItemId + querystring, {
				headers: {'Authorization': 'Bearer ' + token},
				responseType: responseType
			}).subscribe({next: (item: any) => {
				cb(null, item);
			}, error: err => {
				this.rs.sendLogs('error', fName, {message: 'exchange rest api error, expired: '+(Date.now()-expired), error:err, accountType:this.accountType, restItemId, querystring});
				cb(err);
			}});
		});
	}

	//[NOT IN USE]
	getExchangeFolder(folderId, responseType, cb) {
		const fName = 'getExchangeFolder';

		if (!folderId) {
			console.error('[Trustifi] folder ID is empty');
			return cb({message: 'folder ID is empty'});
		}

		this.getRestAPIToken(fName, false, (err, token, restUrl) => {
			if (err) {
				return cb(err);
			}

			this.http.get(restUrl + '/me/MailFolders/' + folderId, {
				headers: {'Authorization': 'Bearer ' + token},
				responseType: responseType
			}).subscribe({next: (item: any) => {
				cb(null, item);
			}, error: error => {
				console.error('[Trustifi] exchange rest api error: ', {error, folderId});
				cb(error);
			}});
		});
	}

	//Used by: closePostReportMessage
	moveEmail(cb) {
		const fName = 'moveEmail';

		if(!Office.context.mailbox.item) {
			console.error('[Trustifi] email item doesnt exist');
			return cb({message: 'email item doesnt exist'});
		}

		let restItemId = this.getResourceId();
		if (!restItemId) {
			console.error('[Trustifi] failed to convert: ' + Office.context.mailbox.item.itemId, restItemId);
			return cb({message: 'failed to convert ' + Office.context.mailbox.item.itemId});
		}

		this.getRestAPIToken(fName, true, (err, token, restUrl) => {
			if (err) {
				return cb(err);
			}

			const body = this.restToken.source === 'graphapi' ? {destinationId: 'junkemail'} : {DestinationId: 'junkemail'};
			this.http.post(restUrl + '/me/messages/' + restItemId + '/move', body, {
				headers: {'Authorization': 'Bearer ' + token}
			}).subscribe({next: (item: any) => {
				cb(null, item);
			}, error: error => {
				console.error('[Trustifi] exchange rest api error: ', {error, restItemId});
				cb(error);
			}});
		});
	}

	getAttachmentsContentRest(cb) {
		const fName = 'getAttachmentsContentRest';

		try {
			//It always returns no-attachments for signed email
			//let item = Office.context.mailbox.item.attachments;
			//if (!item.length) return cb();

			this.getRestAPIToken(fName, false, (err, token, restUrl, expired) => {
				if (err) {
					console.error('[Trustifi] Attachments REST Error: ' + err.message);
					return cb(err);
				}

				let restItemId = this.getResourceId();

				this.http.get(restUrl + '/me/messages/' + restItemId + '/attachments', {
					headers: {'Authorization': 'Bearer ' + token}
				}).subscribe({next: (res: any) => {
					let attachmentData, attachmentName, attachmentType, attachmentHash, attachmentSize, attachments = res.value;
					let attachmentList = [];
					let promises = [];

					for (let idx = 0; idx < attachments.length; idx++) {
						//console.log(attachments[idx]);

						if (this.restToken.source === 'graphapi') {
							attachments[idx] = convertToPascalCase(attachments[idx]);
						}

						if (attachments[idx].IsInline) { // Skip inline attachments
							continue;
						}

						if (attachments[idx]['@odata.type'] === '#Microsoft.OutlookServices.ItemAttachment') {
							promises.push([idx, lastValueFrom(this.http.get(restUrl + '/me/messages/' + restItemId + '/attachments/' + attachments[idx].Id + '/$value', {
								headers: {'Authorization': 'Bearer ' + token},
								responseType: 'text'
							}).pipe(take(1)))]);

							continue;
						}

						if (attachments[idx].ContentBytes) {
							attachmentData = Buffer.from(attachments[idx].ContentBytes, 'base64');
							attachmentName = attachments[idx].Name;
							attachmentType = attachments[idx].ContentType;
							attachmentHash = shaJs('sha256').update(attachmentData).digest('hex');
							attachmentSize = attachments[idx].ContentBytes.length;
							attachmentList.push({
								name: attachmentName,
								type: attachmentType,
								content: attachmentData.toString('base64'),
								hash: attachmentHash,
								size: attachmentSize
							});
						}
					}

					Promise.all(promises.map(itm => itm[1])).then(res => {
						res.forEach((itm, idx) => {
							if (itm) {
								attachmentData = Buffer.from(itm, 'utf8');
								attachmentName = attachments[promises[idx][0]].Name + '.eml';
								attachmentType = attachments[promises[idx][0]].ContentType;
								attachmentHash = shaJs('sha256').update(attachmentData).digest('hex');
								attachmentSize = itm.length;
								attachmentList.push({
									name: attachmentName,
									type: attachmentType,
									content: attachmentData.toString('base64'),
									hash: attachmentHash,
									size: attachmentSize
								});
							}
						});

						cb(null, attachmentList);
					}, err => {
						console.error('[Trustifi] error extracting attachments, token expires in: '+(Date.now()-expired), {accountType:this.accountType, err});
						cb(null, attachmentList);
					});
				}, error: err => {
					console.error('[Trustifi] error extracting attachments, token expires in: '+(Date.now()-expired), {accountType:this.accountType, err});
					cb(err);
				}});
			});
		}
		catch(ex) {
			console.error('[Trustifi] error extracting attachments', ex);
			cb(ex);
		}
	}

	getEmailHeadersRest(cb) {
		const fName = 'getEmailHeadersRest';

		const select = '?$select=InternetMessageId,InternetMessageHeaders,replyTo,ToRecipients,HasAttachments,Body,Subject,ParentFolderId';
		this.getExchangeMessage(fName, select, 'json', (err, messageDetails) => {
			if(err) {
				return cb(err);
			}

			if (this.restToken.source === 'graphapi') {
				messageDetails = convertToPascalCase(messageDetails);
			}

			messageDetails.toTrustifi = messageDetails.ToRecipients && messageDetails.ToRecipients.some(itm => itm.EmailAddress.Address.indexOf(ENV_CONSTS.emailsuffix) >= 0);
			messageDetails.headers = messageDetails.InternetMessageHeaders || [];

			if (messageDetails.ReplyTo) {
				messageDetails.ReplyTo.forEach(itm => {
					if (itm && itm.EmailAddress && itm.EmailAddress.Address) {
						messageDetails.headers.push({Name: 'Reply-To', Value: itm.EmailAddress.Address});
					}
				});
			}

			if (messageDetails['@odata.type'] === '#Microsoft.OutlookServices.EventMessage') {
				messageDetails.isMeetingRequest = true;
			}

			cb(null, messageDetails);
		});
	}

	getExchangeMessageContentRest(cb) {
		const fName = 'getExchangeMessageContentRest';

		this.getExchangeMessage(fName, '/$value', 'text', cb);
	}

	getExchangeMessageIdRest(cb) {
		const fName = 'getExchangeMessageIdRest';

		this.getExchangeMessage(fName, '?$select=InternetMessageId', 'json', cb);
	}
}

function convertToPascalCase(obj) {

	if (Array.isArray(obj)) {
		return obj.map(v => convertToPascalCase(v));
	}
	if (obj !== null && obj.constructor === Object) {
		return Object.keys(obj).reduce((result, key) => {
			return {...result, [key.charAt(0).toUpperCase() + key.slice(1)]: convertToPascalCase(obj[key])}
		}, {});
	}
	return obj;
}
