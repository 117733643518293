<div class="flxClmn bs-enabled t-bg-primary image-as-bg" style="height: 100vh; padding-bottom: 25px; background-size: contain; background-image: linear-gradient(90deg, #ffffffeb, #ffffffeb), url('images/general_bg1.png');">
	<div class="message-tab-list d-flex centralize w-100 t-bg-primary">
		<a class="active text-center p-4 w-100" style="cursor: default;">{{'compose.title' | translate}}</a>
	</div>

	<div class="flex-column f-1-0 overflow-auto">

		<!--SECTION: Recipients-->
		<div class="t-bg-primary flex-column gap-2 w-100 border border-1 border-secondary-subtle border-left-0 border-right-0 my-1" style="position: relative;"
			 [style.min-height]="'clamp(130px,' + (110 + (message.contacts.length * 22)) + 'px, 230px)'">
			<div class="flex-row gap-2 p-2 align-items-center" style="background: linear-gradient(90deg, var(--t-bg-primary), var(--t-light-blue)); color: var(--t-gray-dark);">
				<i class="fa fa-users"></i>
				<span>{{'compose.recipients.recipientsTitle' | translate}}</span>
			</div>

			<div class="flex-column f-1-0 gap-3 pt-1 pb-3">
				<div class="position-relative mx-3">
					<input type="text" id="recipientInput" #recipientInputEl
						   class="m-0 w-100 f-1-0"
						   [(ngModel)]="recipientEmailInput"
						   [ngClass]="{'validation-error' : recipientEmailInputError}"
						   placeholder="{{'compose.recipients.searchRecipient' | translate}}"
						   (keyup)="recipientEmailInputError = false;"
						   (clickOutside)="addRecipientByEmail(recipientEmailInput)"
						   [exclude]="'#AutoCompleteContainer'"
						   (keydown.backspace)="$event.preventDefault(); !recipientEmailInput && removeFromContacts(_.last(message.contacts)); recipientEmailInput = recipientEmailInput.slice(0,-1);"
						   (keyup.enter)="contactAutoCompleteEl.filteredItems?.length ? addRecipientFromExistingContacts(contactAutoCompleteEl.filteredItems[0]) : addRecipientByEmail(recipientEmailInput)" />
					<auto-complete-menu id="AutoCompleteContainer"
										[autoCompleteItems]="userContacts"
										[searchTerm]="recipientEmailInput"
										[inputElementRef]="recipientInputEl"
										[showInputTextAsNewItemWhen]="gs.validateEmail"
										(onItemClick)="addRecipientByEmail($event.item)"
										searchByKeys="name,email"
										#contactAutoCompleteEl>
						<ng-template #customListTemplate let-filteredItems>
							<li class="flxClmn vSpace05"
								tabindex="0"
								*ngFor="let contact of filteredItems"
								(keyup.enter)="addRecipientFromExistingContacts(contact)"
								(click)="addRecipientFromExistingContacts(contact)">
								<span class="fw-500 ellipsis" *ngIf="contact.name !== contact.email">
									{{contact.name}}
								</span>
								<span class="ellipsis">
									{{contact.email}}
								</span>
							</li>
						</ng-template>
					</auto-complete-menu>
				</div>
				<div class="flex-column gap-1 f-1-0 overflow-auto px-3 thinner-scrollbar" style="max-height: 120px; font-size: 12px;" #contactsContainerEl>
					<virtual-scroller class="f-1-0" #contactsScrollerEl [items]="message.contacts" [parentScroll]="contactsContainerEl">
						<div class="flex-row justify-content-between gap-3 pb-2" *ngFor="let contact of contactsScrollerEl.viewPortItems">
							<span class="ellipsis f-1-0">
								{{contact.name || contact.email}}
							</span>
							<div class="flex-row gap-2 align-items-center">
								<i class="fa fa-exclamation-circle t-color-danger hvr-darken animated fadeIn" tooltip="{{'compose.recipients.phoneMissing' | translate}}" *ngIf="isPhoneMissingForOneOrMore() && (!contact.phone?.phone_number)"></i>
								<i class="fa fa-exclamation-circle t-color-danger hvr-darken animated fadeIn" tooltip="{{'compose.recipients.passwordMissing' | translate}}" *ngIf="isPasswordMissingForOneOrMore() && (!contact.default_password?.password)"></i>
								<i class="fa fa-edit text-muted cursor-pointer hvr-darken" tooltip="{{'compose.recipients.editContact' | translate}}" (click)="openEditContactModal(contact);"></i>
								<i class="fa fa-times text-muted cursor-pointer hvr-darken" tooltip="{{'compose.recipients.removeRecipient' | translate}}" (click)="removeFromContacts(contact);"></i>
							</div>
						</div>
					</virtual-scroller>
				</div>
			</div>

			<!--edit contact modal-->
			<div class="edit-contact-overlay"
				 [ngClass]="{'active' : contactInEdit}"
				 (click)="contactInEdit.edit = null; contactInEdit = null;">
			</div>
			<div class="t-bg-primary p-3 w-100 border border-1 border-secondary-subtle border-left-0 border-right-0 animated fadeIn edit-contact-modal"
				 style="position: absolute; bottom: 0; z-index: 1002; transform: translateY(100%); animation-duration: 0.15s !important;"
				 *ngIf="contactInEdit?.edit">
				<div class="flex-column gap-2" style="font-size: 12px;">
					<div class="flex-row align-items-center gap-2">
						<span style="flex-basis: 65px;">{{'compose.recipients.name' | translate}}:</span>
						<input type="text" placeholder="{{'compose.recipients.name' | translate}}"
							   style="flex: 1 0; height: 25px;"
							   id="editContactNameInput"
							   [autofocus]="true"
							   [(ngModel)]="contactInEdit.edit.name">
					</div>
					<div class="flex-row align-items-center gap-2">
						<span style="flex-basis: 65px;">{{'compose.recipients.email' | translate}}:</span>
						<input type="text" placeholder="{{'compose.recipients.email' | translate}}"
							   style="flex: 1 0; height: 25px;"
							   id="editContactEmailInput"
							   [(ngModel)]="contactInEdit.edit.email"
							   [ngClass]="{'validation-error' : contactInEdit.edit.emailError}"
							   (keyup)="contactInEdit.edit.emailError = false;">
					</div>
					<div class="flex-row align-items-center gap-2">
						<span style="flex-basis: 65px;">{{'compose.recipients.phone' | translate}}:</span>
						<div class="flex-row f-1-0 gap-1">
							<country-code-select class="compose-country-code" [(model)]="contactInEdit.edit.phone.country_code"></country-code-select>
							<input type="text" style="height: 25px; flex: 1 0; width: 100%;"
								   id="editContactPhoneNumberInput"
								   placeholder="{{'compose.recipients.phoneNumber' | translate}}"
								   pattern="^[0-9]*$"
								   maxlength="11"
								   [ngClass]="{'validation-error' : contactInEdit.edit.phoneNumberError}"
								   (keyup)="contactInEdit.edit.phoneNumberError = false;"
								   [(ngModel)]="contactInEdit.edit.phone.phone_number">
						</div>
					</div>
					<div class="flex-row align-items-center gap-2">
						<span style="flex-basis: 65px;">{{'compose.recipients.password' | translate}}:</span>
						<input type="text" placeholder="{{'compose.recipients.password' | translate}}"
							   style="flex: 1 0; height: 25px;"
							   id="editContactPasswordInput"
							   [(ngModel)]="contactInEdit.edit.default_password.password"
							   [ngClass]="{'validation-error' : contactInEdit.edit.passwordError}"
							   (keyup)="contactInEdit.edit.passwordError = false;">
					</div>
					<div class="flex-row align-items-center gap-2">
						<span style="flex-basis: 65px;">{{'compose.recipients.hint' | translate}}:</span>
						<input type="text" id="editContactHintInput"
							   style="flex: 1 0; height: 25px;"
							   placeholder="{{'compose.recipients.hint' | translate}}"
							   [(ngModel)]="contactInEdit.edit.default_password.hint" />
					</div>
					<div class="flex-row justify-content-end mt-3 gap-2 align-items-center">
						<btn variant="outlined" (action)="contactInEdit.edit = null; contactInEdit = null;">
							{{'general.cancel' | translate}}
						</btn>
						<btn (action)="confirmEditContact();">
							{{'general.confirm' | translate}}
						</btn>
					</div>
					<span class="t-color-danger" *ngIf="contactInEdit.errorMessage">
						{{contactInEdit.errorMessage}}
					</span>
				</div>
			</div>
		</div>


		<!--SECTION: Attachments-->
		<div class="t-bg-primary flex-column gap-2 w-100 border border-1 border-secondary-subtle border-left-0 border-right-0 my-1 drop-menu-container"
			 ng2FileDrop
			 [uploader]="uploader"
			 [ngClass]="{'dragOverClassObj': mouseOverAttachment}"
			 (fileOver)="fileOver($event)"
			 (onFileDrop)="uploadFiles(uploader.queue)">
			<div class="flex-row gap-2 p-2 align-items-center justify-content-between" style="background: linear-gradient(90deg, var(--t-bg-primary), var(--t-light-blue)); color: var(--t-gray-dark);">
				<div class="flex-row gap-2 align-items-center">
					<i class="fa fa-paperclip"></i>
					<span>{{'compose.files.attachments' | translate}}</span>
				</div>
				<a id="attachmentsSourceMenuTrigger" class="pe-1" style="font-size: 1rem;"
				   (click)="showAttachmentsSourceMenu = !showAttachmentsSourceMenu;">
					<i class="fa fa-plus opacity-75 " style="font-size: 1rem;"></i>
				</a>
			</div>

			<div class="flex-column gap-1 p-3 pt-1 overflow-auto thinner-scrollbar" style="max-height: 120px">
				<ng-container *ngIf="attachments?.length">
					<ng-container *ngFor="let attachment of attachments">
						<div class="flex-row gap-2 align-items-center cursor-default" style="font-size: 12px;"
							 [display]="!!attachment.progressPercentage && !attachment.finished"
							 [tooltip]="('recipient.uploading' | translate) + ' ' + (attachment.progressPercentage | number : '1.2-2') + '%...'">
							<i class="fa fa-file-alt text-muted"></i>
							<span class="f-1-0 ellipsis">{{attachment.name}}</span>
							<span class="text-muted" *ngIf="attachment.size">({{attachment.size | formatBytes:2 }})</span>
							<div class="radial-progress-bar" style="width: 20px; height: 20px; align-self: center; animation-delay: 1s; transition: max-width 0.5s 1.5s; max-width: 20px;"
								 *ngIf="!attachment.isFromLibrary"
								 [ngClass]="{'animated fadeOut' : attachment.finished}"
								 [ngStyle]="attachment.finished && {'max-width' : '0'}">
								<div class="p-bar" [ngStyle]="{'background' : attachment.finished ? 'var(--bs-success)' : 'conic-gradient(royalblue 0deg ' + (attachment.progressPercentage / 100 * 365) +'deg, #d7d7d7 ' + (attachment.progressPercentage / 100 * 365) + 'deg 365deg)'}"></div>
								<i class="fa fa-check t-color-success" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10; font-size: 8px;"
								   *ngIf="attachment.finished"></i>
							</div>
							<i class="zmdi zmdi-close hvr-darken text-muted cursor-pointer"
							   tooltip="{{'quarantined.removeAction' | translate}}"
							   (click)="deleteAttachment(attachment)"></i>
						</div>
					</ng-container>
				</ng-container>

				<a class="text-center w-100 centralize text-muted cursor-pointer hvr-darken"
				   id="attachmentsSourceMenuTrigger2"
				   [ngClass]="attachments?.length ? 'd-none' : 'd-flex'"
				   (click)="showAttachmentsSourceMenu = !showAttachmentsSourceMenu;">
					<span>{{'compose.files.addOrDragDrop' | translate}}</span>
				</a>

				<input id="attachmentSelectInput" style="position:absolute; top: 0; left: 0; height: 0; width: 0;" type="file"
					   multiple
					   ng2FileSelect
					   [uploader]="uploader"
					   (onFileSelected)="uploadFiles(uploader.queue)" />
			</div>

			<div class="drop-menu attachments-source-menu show-above triggered-by-click p-1" style="width: 68%; max-width: 215px; right: 8px; border: solid 1px #616161; box-shadow: 0 0 5px grey;"
				 (clickOutside)="showAttachmentsSourceMenu = false;"
				 [exclude]="'#attachmentsSourceMenuTrigger, #attachmentsSourceMenuTrigger2'"
				 [ngClass]="{'active' : showAttachmentsSourceMenu}">
				<ul>
					<label class="cursor-pointer w-100" for="attachmentSelectInput">
						<li class="flex-row cursor-pointer gap-2 p-2 align-items-center">
							<i class="fas fa-laptop"></i>
							<span>{{'compose.files.fromMyComputer' | translate}}</span>
						</li>
					</label>
					<li class="flex-row gap-2 p-2 align-items-center"
						[ngClass]="{'opacity-50' : getLibraryAttachmentsInProcess}"
						[ngStyle]="{'cursor' : getLibraryAttachmentsInProcess ? 'wait' : 'pointer'}"
						(click)="getAttachmentsData(); ">
						<i class="fas fa-cloud"></i>
						<span>{{'compose.files.fromMyLibrary' | translate}}</span>
					</li>
				</ul>
			</div>
		</div>


		<!--SECTION: Protect your email-->
		<div class="t-bg-primary flex-column gap-2 w-100 border border-1 border-secondary-subtle border-left-0 border-right-0 my-1">
			<div class="flex-row gap-2 p-2 align-items-center" style="background: linear-gradient(90deg, var(--t-bg-primary), var(--t-light-blue)); color: var(--t-gray-dark);">
				<i class="fa fa-shield-alt"></i>
				<span>{{'compose.methods.protectYourEmail' | translate | titlecase}}</span>
			</div>

			<div class="flex-column gap-3 pt-1 pe-2 ps-3 pb-4">
				<div class="flex-row gap-2 align-items-baseline ps-2 text-nowrap" style="font-size: 0.9rem;">
					<div class="md-checkbox transparent-box success-color" [ngClass]="{'disabled': message.methods.encrypt_content.strict || userPolicy?.allow_admin_policy_only?.value}">
						<input id="encryptMessageCheckbox" type="checkbox" style="height: 0; width: 0;"
							   [(ngModel)]="message.methods.encrypt_content.value"
							   (change)="changeMethod();"
							   [disabled]="message.methods.encrypt_content.strict">
						<label class="fw-500" for="encryptMessageCheckbox" [ngClass]="{'t-color-success' : message.methods.encrypt_content?.value}">
							{{'compose.methods.encryptMessage' | translate}}
						</label>
					</div>
					<i style="font-weight: 400; position: relative; bottom:5px;" class="fas fa-question-circle" tooltip="{{'compose.methods.encryptMessageTooltip' | translate}}"></i>
					<i *ngIf="message.methods.encrypt_content.strict" style="color: var(--t-gray); top: 2px; position: relative;" class="zmdi zmdi-lock-outline" tooltip="{{'compose.methods.methodLockedByAdmin' | translate}}"></i>
				</div>
				<div class="flex-row gap-2 align-items-baseline ps-2 text-nowrap" style="font-size: 0.9rem;">
					<div class="md-checkbox transparent-box success-color" [ngClass]="{'disabled': !userPlan.methods.secure_send || userPolicy?.allow_admin_policy_only?.value || message.methods.secure_send.strict}">
						<input id="AuthenticateSenderCheckbox" type="checkbox" style="height: 0; width: 0;"
							   [(ngModel)]="message.methods.secure_send.value"
							   (change)="changeMethod();"
							   [disabled]="message.methods.secure_send.strict">
						<label class="fw-500" for="AuthenticateSenderCheckbox" [ngClass]="{'t-color-success' : message.methods.secure_send?.value}">
							{{'compose.methods.requireAuth' | translate}}
						</label>
					</div>
					<i style="font-weight: 400; position: relative; bottom:5px;" class="fas fa-question-circle" tooltip="{{'compose.methods.requireAuthTooltip' | translate}}"></i>
					<i *ngIf="message.methods.secure_send.strict" style="color: var(--t-gray); top: 2px; position: relative;" class="zmdi zmdi-lock-outline" tooltip="{{'compose.methods.methodLockedByAdmin' | translate}}"></i>
				</div>
			</div>
		</div>


		<!--SECTION: Advanced-->
		<div class="flex-column f-1-0"
			 [style.min-height]="isAdvancedExpanded ? '' : '40px'">
			<div class="t-bg-primary flex-column w-100 border border-1 border-secondary-subtle border-left-0 border-right-0 my-1 position-relative overflow-hidden"
				 [style.max-height]="isAdvancedExpanded ? '800px' : '32px'"
				 style="transition: max-height 0.15s ease-out, overflow 0.15s 0.15s; max-height: 32px;">
				<div class="flex-row gap-2 p-2 align-items-center justify-content-between cursor-pointer" style="background: linear-gradient(90deg, var(--t-bg-primary), var(--t-light-blue)); color: var(--t-gray-dark);"
					 (click)="isAdvancedExpanded = !isAdvancedExpanded">
					<div class="flex-row gap-2 align-items-center">
						<i class="fa fa-cog"></i>
						<span>{{'mailbox.general.tabs.advanced' | translate}}</span>
					</div>
					<i class="fa fa-chevron-down opacity-75 hvr-opacity pe-1" style="font-size: 1rem; transition: all 0.15s;"
					   [ngStyle]="{'transform' : isAdvancedExpanded ? 'rotate(180deg)' : ''}"></i>
				</div>

                <app-advanced
                        *ngIf="isLoaded && (!disableApplyBtn || message.methods)"
                        [isMailbox]="false"
                        [methods]="message.methods"
                        [advanced]="advanced"
                        [userPolicy]="userPolicy"
                        (saveDefaultsEvent)="saveDefaults($event)"
                        (resetAdvancedEvent)="ResetAdvanced()">
                </app-advanced>
			</div>
		</div>
	</div>

	<!--SECTION: notifications panel-->
	<div class="flex-column gap-2 t-bg-primary px-3 py-2 mt-2 hide-when-empty-force" style="font-size: 12px;">
		<ng-container *ngIf="!notification?.message">
			<!--password is missing alert-->
			<div class="flex-row gap-2 align-items-center t-color-danger" *ngIf="isPasswordMissingForOneOrMore() && !advanced.secure.method_2factor_password.value">
				<i class="fa fa-exclamation-triangle"></i>
				<span>{{'ERRORS.passwordMissingOneOrMore' | translate}}.</span>
			</div>
			<!--phone is missing for recipient(s) alert-->
			<div class="flex-row gap-2 align-items-center t-color-danger" *ngIf="isPhoneMissingForOneOrMore()">
				<i class="fa fa-exclamation-triangle"></i>
				<span>{{'ERRORS.phoneMissingOneOrMore' | translate}}.</span>
			</div>
			<!--no default password for email-->
			<div class="flex-row gap-2 align-items-center t-color-danger" *ngIf="!isApplyTrustifi && isRequirePassword() && !advanced.secure.method_2factor_password.value && !advanced.secure.use_contact_2factor_password.value">
				<i class="fa fa-exclamation-triangle"></i>
				<span>{{'ERRORS.emailNoDefaultMFAPassword' | translate}}.</span>
			</div>
			<!--auth method by email alert-->
			<span class="t-color-danger text-decoration-underline" style="margin: -6px 0 0 21px;" *ngIf="(isPasswordMissingForOneOrMore() && !advanced.secure.method_2factor_password.value) || isPhoneMissingForOneOrMore()">
				{{'ERRORS.mfaMethodEmailAsFallbackForContacts' | translate}}
			</span>
		</ng-container>

		<!--general notifications-->
		<div class="flex-row gap-1 t-color-danger animated fadeIn"
			 *ngIf="notification?.message && !scheduleMessage"
			 [ngClass]="{'t-color-success': notification.type === dic.CONSTANTS.NotificationTypes.INFO,
						 't-color-danger' : notification.type === dic.CONSTANTS.NotificationTypes.WARNING || notification.type === dic.CONSTANTS.NotificationTypes.ERROR}">
			<i class="fa" [ngClass]="{'fa-check-circle': notification.type === dic.CONSTANTS.NotificationTypes.INFO, 'fa-exclamation-circle' : notification.type !== dic.CONSTANTS.NotificationTypes.INFO}"></i>
			<span>{{notification.message}}</span>
		</div>
	</div>


	<!--SECTION: info panel-->
	<div class="flex-column gap-2 t-bg-primary p-3 mt-2" style="min-height: 60px; font-size: 12px;">
		<div class="f-1-0 flex-column gap-2">
			<!--press apply-->
			<div class="flex-row gap-1 t-color-danger animated fadeIn"
				 *ngIf="(currentOfficeRecipients.length || message.contacts.length) && !isApplyTrustifi && isMessageSecured()">
				<i class="fa fa-exclamation-circle"></i>
				<span>{{'compose.messages.pressApply' | translate}}&nbsp;<a (click)="applyBtnExecute();">[{{'compose.statusButton.apply' | translate}}]</a></span>
			</div>
			<!--add new recipients-->
			<div class="flex-row gap-1 t-color-danger animated fadeIn"
				 *ngIf="!currentOfficeRecipients.length && !message.contacts.length && isMessageSecured() && !isPlanExpired && (!showScheduler && !message.scheduled_time)">
				<i class="far fa-address-book"></i>
				<span>{{'compose.messages.addNewRecipients' | translate}}</span>
			</div>
			<!--plan expired-->
			<div class="flex-row gap-1 t-color-danger animated fadeIn" *ngIf="isPlanExpired">
				<i class="fa fa-exclamation-circle"></i>
				<span>{{'general.planExpired' | translate}}&nbsp;
					<a target="_blank" rel="noopener noreferrer" href="https://trustifi.com/get-a-quote/">{{'general.here' | translate}}</a>.
				</span>
			</div>
			<!--sensitive content detected-->
			<div class="flex-row gap-1 t-color-danger animated fadeIn"
				 *ngIf="!isMessageSecured() && !userPolicy?.allow_admin_policy_only?.value">
				<i class="fa fa-exclamation-triangle"></i>
				<span>{{'compose.messages.sensitiveContentDetected' | translate}}&nbsp;
					<a (click)="(!message.methods.secure_send.strict && !message.methods.encrypt_content.strict) && setMessageSecured();"
					   [ngClass]="{'disabled': message.methods.secure_send.strict || message.methods.encrypt_content.strict}">{{'general.here' | translate}}</a>.
				</span>

			</div>

			<!--schedule summary-->
			<span class="t-color-success animated fadeIn" *ngIf="scheduleMessage"><span [innerHTML]="scheduleMessage"></span>&nbsp;<a (click)="cancelScheduler()">{{'general.here' | translate}}</a></span>

			<!--security methods summary-->
			<span class="animated fadeIn t-color-success hide-when-empty"
				  *ngIf="currentOfficeRecipients.length && message.contacts.length && isMessageSecured() && isApplyTrustifi">
				{{gs.initSummary(this.message, this.advanced, true)}}
			</span>
		</div>

		<div class="flex-column gap-2 mt-3">
			<div class="flex-row justify-content-between gap-2" style="align-items: flex-end;">
				<btn [loading]="{text: uploadedFiles > 0 ? ('compose.sendButtonModes.uploading' | translate) : !isLoaded ? ('compose.sendButtonModes.loading' | translate) : '',
								 flag: disableApplyBtn || uploadedFiles > 0 || !isLoaded}"
					 (action)="applyBtnExecute()"
					 [endIcon]="isApplyTrustifi ? 'fa fa-check' : ''"
					 [isDisabled]="isApplyTrustifi || isPlanExpired || (!currentOfficeRecipients.length && !message.contacts.length)">
					{{(isApplyTrustifi ? 'compose.sendButtonModes.ready' : 'compose.sendButtonModes.apply') | translate}}
				</btn>
				<a (click)="message.scheduled_time ? cancelScheduler(): showSchedulerMenu()">
					{{message.scheduled_time ? ('compose.schedule.scheduleCancel' | translate) : ('compose.schedule.schedule' | translate)}}
				</a>
			</div>
		</div>
	</div>
</div>


<!--scheduler popup-->
<div class="bottom-menu-overlay flxClmn-end bs-enabled"
	 [ngClass]="{'active' : showScheduler}"
	 [hidden]="!showScheduler"
	 (click)="showScheduler = false">
	<div class="content-container" style="position: relative;" (click)="$event.stopPropagation()">
		<!--<a style="padding: 5px; align-self: flex-end; position: absolute; top: 10px; right: 15px;" (click)="showScheduler = false">
			<i class="fa fa-times"></i>
		</a>-->
		<div class="flex-column content gap-3">
			<span>{{'compose.schedule.scheduledPickTime' | translate}}</span>
			<div class="flex-row gap-1 align-items-center">
				<i class="far fa-calendar-alt pe-2" style="font-size: 1rem; width: auto;"></i>
				<input class="cursor-pointer"
					   [owlDateTimeTrigger]="scheduled_time_picker" [owlDateTime]="scheduled_time_picker"
					   placeholder="{{'compose.schedule.datePlaceholder' | translate}}" readonly="readonly"
					   [(ngModel)]="message.scheduled_time"
					   name="scheduled_time" type="text"
					   [min]="minTimeSchedule"
					   (focus)="updateCurrentMinTime()"
					   [owlDateTimeFilter]="minutesFilter"
					   (dateTimeChange)="setScheduleTime()">
				<owl-date-time #scheduled_time_picker [stepMinute]="5"></owl-date-time>


				<btn class="flex-shrink-0" style="min-width: 0;"
					 endIcon="zmdi zmdi-check"
					 [isDisabled]="!message.scheduled_time"
					 (action)="applyScheduler();">
				</btn>
				<btn class="flex-shrink-0" style="min-width: 0;"
					 endIcon="zmdi zmdi-close" color="danger"
					 variant="text"
					 (action)="clearScheduler();">
				</btn>
			</div>
		</div>
	</div>
</div>

<!--attachments library popup-->
<div class="bottom-menu-overlay flxClmn-end bs-enabled"
	 [ngClass]="{'active' : showAttachmentLibrary}"
	 [hidden]="!showAttachmentLibrary"
	 (click)="showAttachmentLibrary = false">
	<div class="content-container p-3" style="position: relative;" (click)="$event.stopPropagation()">
		<!--<a style="padding: 5px; align-self: flex-end; position: absolute; top: 10px; right: 15px;" (click)="showScheduler = false">
			<i class="fa fa-times"></i>
		</a>-->
		<span>{{'compose.files.selectLibAttachments' | translate}}</span>
		<div class="flex-column gap-1 py-2 px-1 border border-light overflow-auto thinner-scrollbar my-4" style="max-height: 120px; font-size: 12px; min-height: 80px;"
			 [ngClass]="{'mt-2' : libraryAttachments?.length}">
			<ng-container *ngIf="libraryAttachments?.length">
				<ng-container *ngFor="let attachment of libraryAttachments">
					<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
						<input [id]="attachment.id || attachment._id" type="checkbox" style="height: 0; width: 0;"
							   [(ngModel)]="attachment.selected"/>
						<label class="flex-row f-1-0 gap-2 align-items-center ellipsis" style="margin: 0; position: relative;"
							   [ngClass]="{'fw-500 text-primary' : attachment.selected}" [for]="attachment.id || attachment._id" >
							<span class="f-1-0 ellipsis">{{attachment.name}}</span>
							<span class="text-muted" style="font-size: 10px;" *ngIf="attachment.size">({{attachment.size | formatBytes:2 }})</span>
						</label>
					</div>
				</ng-container>
			</ng-container>

			<span class="text-center w-100 f-1-0 d-flex centralize text-muted cursor-pointer hvr-darken" *ngIf="!libraryAttachments?.length">
				<span>{{'compose.files.noAttachmentsInLibrary' | translate}}</span>
			</span>
		</div>

		<div class="flex-row justify-content-end gap-1 align-items-center" [ngClass]="{'centralize' : !libraryAttachments?.length}">
			<btn variant="outlined"
				 (action)="showAttachmentLibrary = false;">
				{{'general.cancel' | translate}}
			</btn>
			<btn *ngIf="libraryAttachments?.length"
				 (action)="confirmAttachmentsLibrary();">
				{{'general.confirm' | translate}}
			</btn>
		</div>
	</div>
</div>
